import React, {
  FunctionComponent,
  useCallback,
  useEffect,
  useMemo,
  useState
} from 'react'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Checkbox,
  Container,
  createStyles,
  Drawer,
  FormControl,
  Grid,
  InputLabel,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  makeStyles,
  MenuItem,
  Select,
  Theme,
  Typography
} from '@material-ui/core'
import PageLoading from '../../components/Loading/PageLoading'
import ProductionGrid, {
  NodePath,
  ProductionDataType,
  ProductionGridDataLine,
  ProductionGridInputData
} from '../../components/ProductionGrid/ProductionGrid'
import TimeRange from '../../components/TimeRange'
import { sub, Duration } from 'date-fns'
import Hierarchy from '../../components/Hierarchy/Hierarchy'
import {
  AccumulatorCfgKeys,
  AccumulatorKeys,
  EventAlphaVarCfgKeys,
  EventVarCfgKeys,
  EventVariablesKeys,
  Expression,
  ExpressionOperator,
  IdentifierKeys,
  IdentifiersConfigKeys,
  IncidentKeys,
  IncidentsConfigKeys,
  LogicalOperator,
  NodeKeys,
  Order,
  Paths,
  UserViewDataItemKeys,
  UserViewKeys
} from '../../api/RcfactoryApi'
import { useMutation, useQuery } from 'react-query'
import { api, queryClient } from '../..'
import { useSnackbar } from 'notistack'
import { isEqual, isNil, sortBy, uniq } from 'lodash'
import { useIntl } from 'react-intl'
import { NumericArrayParam, useQueryParam } from 'use-query-params'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import clsx from 'clsx'
import GridDialog from '../../components/Dialog/GridDialog'
import AlertDialogue from '../../components/AlertDialogue'
import useConfigure, { ConfigurationMode } from '../../hooks/useConfigure'
import DataForm from '../../components/DataForm'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    listItem: {
      width: '100%',
      maxWidth: 360,
      backgroundColor: theme.palette.background.paper
    },
    formControl: {
      margin: theme.spacing(1),
      minWidth: 200
    },
    selectNodeButton: {
      margin: theme.spacing(1),
      height: 57
    },
    deleteButton: {
      borderColor: theme.palette.warning.main,
      color: theme.palette.warning.main
    }
  })
)

const defaultDuration: Duration = { hours: 24 }

enum ParamKeys {
  NodeId = 'nodeId'
}

enum ConfigurationKeys {
  SavedView = 'savedview'
}

const ProductionView: FunctionComponent = () => {
  const classes = useStyles()
  const intl = useIntl()
  const configurator = useConfigure(ConfigurationKeys.SavedView)
  const { enqueueSnackbar } = useSnackbar()
  const [endTime, setEndTime] = useState<Date | null>(new Date())
  const [startTime, setStartTime] = useState<Date | null>(
    sub(new Date(), defaultDuration)
  )
  const [range, setRange] = useState<number>(24)
  const [interval, setInterval] = useState<number>(60)
  const [gridSize, setGridSize] = useState<number>(97)
  const [openDrawer, setOpenDrawer] = useState<boolean>(false)
  const [selectedNodes, setSelectedNodes] = useQueryParam(
    ParamKeys.NodeId,
    NumericArrayParam
  )
  const [checked, setChecked] = useState<number[]>([])
  const [inputData, setInputData] = useState<ProductionGridInputData[]>([])
  const [dialogOpen, setDialogOpen] = useState<boolean>(false)
  const [dialogData, setDialogData] = useState<Record<string, unknown>[]>([])
  const [dataItems, setDataItems] = useState<DataItem[]>([])
  const [selectedDataItems, setSelectedDataItems] = useState<DataItem[]>([])
  const [selectedUserViewId, setSelectedUserViewId] = useState<number>(0)
  const [showDeleteAlert, setShowDeleteAlert] = useState<boolean>(false)

  enum Range {
    LastDay = 24,
    LastWeek = 168,
    Custom = 0
  }
  const defaultRange = Range.LastDay
  const defaultInterval = 60
  const defaultGridSize = 97

  interface DataItem {
    nodeId: number
    index: number
    name?: string
    type: DataItemType
    id: number
  }

  interface GetExpressionMapping {
    expressions: Expression[]
    id: number
  }

  enum DataItemType {
    Accumulator = 2,
    Identifier = 3,
    EventVarNumeric = -97,
    EventVarAlpha = -98,
    Incident = 1
  }

  enum EventVarFilterType {
    Alpha = 'Alpha',
    Numeric = 'Numeric'
  }

  const nodesQuery = useQuery(
    Paths.Nodes,
    () =>
      api.getList({
        modelExpressions: {
          Expressions: [
            {
              Prop: NodeKeys.Active,
              Op: ExpressionOperator.Equal,
              Val: true
            }
          ]
        },
        order1: Order.asc,
        orderBy1: NodeKeys.OrdinalPosition,
        path: Paths.Nodes
      }),
    {
      onError: () =>
        enqueueSnackbar(
          intl.formatMessage({
            id: 'productionView.failedNodes',
            description: 'Failed to get nodes notification text',
            defaultMessage: 'Failed to get Nodes!'
          }),
          {
            variant: 'error'
          }
        )
    }
  )

  const userViewsQuery = useQuery(
    Paths.UserView,
    () =>
      api.getList({
        path: Paths.UserView,
        orderBy1: UserViewKeys.Name,
        order1: Order.asc
      }),
    {
      onError: () =>
        enqueueSnackbar(
          intl.formatMessage({
            id: 'productionView.failedSavedViews',
            description:
              'Fetch Saved views tag mapping schema error notification text',
            defaultMessage: 'Failed to get Saved Views!'
          }),
          {
            variant: 'error'
          }
        )
    }
  )

  const userViewsDescQuery = useQuery(
    Paths.UserView + Paths.UtilsGetDesc,
    () =>
      api.getDesc({
        path: Paths.UserView
      }),
    {
      onError: () =>
        enqueueSnackbar(
          intl.formatMessage({
            id: 'productionView.failedSavedViewMappingSchema',
            description:
              'Fetch Saved view tag mapping schema error notification text',
            defaultMessage: 'Failed to get Saved View Mapping Schema!'
          }),
          {
            variant: 'error'
          }
        )
    }
  )

  const savedViewCreateMutation = useMutation(
    (items: Record<string, unknown>[]) =>
      api.create({
        items: items,
        path: Paths.UserView
      }),
    {
      onError: () => {
        enqueueSnackbar(
          intl.formatMessage({
            id: 'productionView.failedCreateSavedView',
            description: 'Saved view create error notification text',
            defaultMessage: 'Failed to create saved view!'
          }),
          {
            variant: 'error'
          }
        )
      },
      onSuccess: () => {
        enqueueSnackbar(
          intl.formatMessage({
            id: 'productionView.successfulCreateSavedView',
            description: 'Saved view create success notification text',
            defaultMessage: 'Successfully created Saved View!'
          }),
          {
            variant: 'success'
          }
        )
        configurator.clear()
        queryClient.invalidateQueries(Paths.UserView)
      }
    }
  )

  const userViewItemsCreateMutation = useMutation(
    (items: Record<string, unknown>[]) =>
      api.create({
        items: items,
        path: Paths.UserViewItems
      }),
    {
      onError: () => {
        enqueueSnackbar(
          intl.formatMessage({
            id: 'productionView.failedCreateSavedView',
            description: 'Saved view create error notification text',
            defaultMessage: 'Failed to create saved view!'
          }),
          {
            variant: 'error'
          }
        )
      }
    }
  )

  const userViewItemsDeleteMutation = useMutation(
    (ids: number[]) =>
      api.delete({
        ids: ids,
        path: Paths.UserViewItems
      }),
    {
      onError: () => {
        enqueueSnackbar(
          intl.formatMessage({
            id: 'productionView.failedUpdateUserViewItem',
            description: 'Saved view item update error notification text',
            defaultMessage: 'Failed to update user view item!'
          }),
          {
            variant: 'error'
          }
        )
      }
    }
  )

  const savedViewUpdateMutation = useMutation(
    (items: Record<string, unknown>[]) =>
      api.update({
        items: items,
        path: Paths.UserView
      }),
    {
      onError: () => {
        enqueueSnackbar(
          intl.formatMessage({
            id: 'productionView.savedViewUpdate',
            description: 'Update Saved View error message',
            defaultMessage: 'Failed to update Saved View!'
          }),
          {
            variant: 'error'
          }
        )
      },
      onSuccess: () => {
        enqueueSnackbar(
          intl.formatMessage({
            id: 'productionView.successfulSavedViewUpdate',
            description: 'Update Saved View success message',
            defaultMessage: 'Successfully updated Saved View!'
          }),
          {
            variant: 'success'
          }
        )
        configurator.clear()
        queryClient.invalidateQueries(Paths.UserView)
      }
    }
  )

  const savedViewDeleteMutation = useMutation(
    (ids: string[]) =>
      api.delete({
        ids: ids,
        path: Paths.UserView
      }),
    {
      onError: () => {
        enqueueSnackbar(
          intl.formatMessage({
            id: 'productionView.failedSavedViewDelete',
            description: 'Failed to delete saved view notification text',
            defaultMessage: 'Failed to delete Saved View!'
          }),
          {
            variant: 'error'
          }
        )
      },
      onSuccess: () => {
        enqueueSnackbar(
          intl.formatMessage({
            id: 'productionView.successfulSavedViewDelete',
            description: 'Successfully deleted saved view text',
            defaultMessage: 'Successfully deleted Saved View!'
          }),
          {
            variant: 'success'
          }
        )
        queryClient.invalidateQueries(Paths.UserView)
        setSelectedUserViewId(0)
      }
    }
  )

  const accumulatorsCfgQuery = useQuery(
    [Paths.AccumulatorCfg],
    () => {
      return api.getList({
        modelExpressions: {
          Expressions: [
            {
              Prop: AccumulatorCfgKeys.Active,
              Op: ExpressionOperator.Equal,
              Val: true
            }
          ]
        },
        orderBy1: AccumulatorCfgKeys.AccumulatorName,
        order1: Order.asc,
        path: Paths.AccumulatorCfg
      })
    },
    {
      onError: () =>
        enqueueSnackbar(
          intl.formatMessage({
            id: 'productionView.failedAccumulators',
            description: 'Fetch accumulators error notification text',
            defaultMessage: 'Failed to get Accumulators!'
          }),
          {
            variant: 'error'
          }
        )
    }
  )

  const buildIndex = useCallback(
    (id: number, nodeId: number, type: DataItemType) => {
      if (type < 0) {
        type = Math.abs(type)
      }
      return Number(`${id}${nodeId}${type}`)
    },
    []
  )

  const accumulators: DataItem[] | undefined = useMemo(() => {
    if (
      isNil(accumulatorsCfgQuery.data) ||
      isNil(accumulatorsCfgQuery.data.Items)
    ) {
      return undefined
    }
    return uniq(
      accumulatorsCfgQuery.data.Items.map((d) => {
        const id = Number(d[AccumulatorCfgKeys.Id])
        const nodeId = Number(d[AccumulatorCfgKeys.NodeId])
        const type: number = DataItemType.Accumulator
        return {
          index: buildIndex(id, nodeId, type),
          name: String(d[AccumulatorCfgKeys.AccumulatorName]),
          nodeId,
          id,
          type
        }
      })
    )
  }, [DataItemType.Accumulator, accumulatorsCfgQuery.data, buildIndex])

  const identifiersCfgQuery = useQuery(
    [Paths.IdentifiersConfig],
    () => {
      return api.getList({
        modelExpressions: {
          Expressions: [
            {
              Prop: IdentifiersConfigKeys.Active,
              Op: ExpressionOperator.Equal,
              Val: true
            }
          ],
          Operator: LogicalOperator.And
        },
        orderBy1: IdentifiersConfigKeys.IdentifierName,
        order1: Order.asc,
        path: Paths.IdentifiersConfig
      })
    },
    {
      onError: () =>
        enqueueSnackbar(
          intl.formatMessage({
            id: 'productionView.failedIdentifiersConfig',
            description: 'Fetch identifiers error notification text',
            defaultMessage: 'Failed to get Identifiers!'
          }),
          {
            variant: 'error'
          }
        )
    }
  )

  const identifiers: DataItem[] | undefined = useMemo(() => {
    if (
      isNil(identifiersCfgQuery.data) ||
      isNil(identifiersCfgQuery.data.Items)
    ) {
      return undefined
    }
    return uniq(
      identifiersCfgQuery.data.Items.map((d) => {
        const id = Number(d[IdentifiersConfigKeys.Id])
        const nodeId = Number(d[IdentifiersConfigKeys.NodeId])
        const type: number = DataItemType.Identifier
        return {
          index: buildIndex(id, nodeId, type),
          name: String(d[IdentifiersConfigKeys.IdentifierName]),
          nodeId,
          id,
          type
        }
      })
    )
  }, [DataItemType.Identifier, buildIndex, identifiersCfgQuery.data])

  const eventAlphaVarCfgQuery = useQuery(
    [Paths.EventAlphaVarCfg],
    () => {
      return api.getList({
        modelExpressions: {
          Expressions: [
            {
              Prop: EventAlphaVarCfgKeys.Active,
              Op: ExpressionOperator.Equal,
              Val: true
            }
          ],
          Operator: LogicalOperator.And
        },
        orderBy1: EventAlphaVarCfgKeys.VariableName,
        order1: Order.asc,
        path: Paths.EventAlphaVarCfg
      })
    },
    {
      onError: () =>
        enqueueSnackbar(
          intl.formatMessage({
            id: 'productionView.failedAlphaEventVarCfg',
            description: 'Fetch events error notification text',
            defaultMessage: 'Failed to get Events!'
          }),
          {
            variant: 'error'
          }
        )
    }
  )

  const eventVarCfgQuery = useQuery(
    [Paths.EventVarCfg],
    () => {
      return api.getList({
        modelExpressions: {
          Expressions: [
            {
              Prop: EventVarCfgKeys.Active,
              Op: ExpressionOperator.Equal,
              Val: true
            }
          ],
          Operator: LogicalOperator.And
        },
        orderBy1: EventVarCfgKeys.VariableName,
        order1: Order.asc,
        path: Paths.EventVarCfg
      })
    },
    {
      onError: () =>
        enqueueSnackbar(
          intl.formatMessage({
            id: 'productionView.failedEventVarCfg',
            description: 'Fetch events error notification text',
            defaultMessage: 'Failed to get Events!'
          }),
          {
            variant: 'error'
          }
        )
    }
  )

  const events: DataItem[] | undefined = useMemo(() => {
    if (
      isNil(eventVarCfgQuery.data) ||
      isNil(eventVarCfgQuery.data.Items) ||
      isNil(eventAlphaVarCfgQuery.data) ||
      isNil(eventAlphaVarCfgQuery.data.Items)
    ) {
      return undefined
    }
    return uniq(
      eventVarCfgQuery.data.Items.map((d) => {
        const id = Number(d[EventVarCfgKeys.Id])
        const nodeId = Number(d[EventVarCfgKeys.NodeId])
        const type: number = DataItemType.EventVarNumeric
        return {
          index: buildIndex(id, nodeId, type),
          name: String(d[EventVarCfgKeys.VariableName]),
          nodeId,
          id,
          type
        }
      }).concat(
        ...eventAlphaVarCfgQuery.data.Items.map((d) => {
          const id = Number(d[EventAlphaVarCfgKeys.Id])
          const nodeId = Number(d[EventAlphaVarCfgKeys.NodeId])
          const type: number = DataItemType.EventVarAlpha
          return {
            index: buildIndex(id, nodeId, type),
            name: String(d[EventAlphaVarCfgKeys.VariableName]),
            nodeId,
            id,
            type
          }
        })
      )
    )
  }, [
    DataItemType.EventVarAlpha,
    DataItemType.EventVarNumeric,
    buildIndex,
    eventAlphaVarCfgQuery.data,
    eventVarCfgQuery.data
  ])

  const incidentsCfgQuery = useQuery(
    [Paths.IncidentsConfig, selectedNodes],
    () => {
      return api.getList({
        modelExpressions: {
          Expressions: [
            {
              Prop: IncidentsConfigKeys.Active,
              Op: ExpressionOperator.Equal,
              Val: true
            }
          ]
        },
        orderBy1: IncidentsConfigKeys.IncidentName,
        order1: Order.asc,
        path: Paths.IncidentsConfig
      })
    },
    {
      onError: () =>
        enqueueSnackbar(
          intl.formatMessage({
            id: 'productionView.failedIncidentsConfig',
            description: 'Fetch identifiers error notification text',
            defaultMessage: 'Failed to get Incidents!'
          }),
          {
            variant: 'error'
          }
        )
    }
  )

  const incidents: DataItem[] | undefined = useMemo(() => {
    if (isNil(incidentsCfgQuery.data) || isNil(incidentsCfgQuery.data.Items)) {
      return undefined
    }
    return uniq(
      incidentsCfgQuery.data.Items.map((d) => {
        const id = Number(d[IncidentsConfigKeys.Id])
        const nodeId = Number(d[IncidentsConfigKeys.NodeId])
        const type: number = DataItemType.Incident
        return {
          index: buildIndex(id, nodeId, type),
          name: String(d[IncidentsConfigKeys.IncidentName]),
          nodeId,
          id,
          type
        }
      })
    )
  }, [DataItemType.Incident, buildIndex, incidentsCfgQuery.data])

  useEffect(() => {
    if (
      isNil(accumulators) ||
      isNil(identifiers) ||
      isNil(events) ||
      isNil(incidents)
    ) {
      return undefined
    }
    setDataItems(accumulators.concat(identifiers, events, incidents))
  }, [accumulators, events, identifiers, incidents])

  const selectedNode = useMemo(() => {
    if (
      isNil(selectedNodes) ||
      selectedNodes.length === 0 ||
      isNil(nodesQuery.data) ||
      isNil(nodesQuery.data.Items)
    ) {
      return undefined
    }
    return nodesQuery.data.Items.find(
      (x) => (x[NodeKeys.Id] as number) === selectedNodes[0]
    )
  }, [selectedNodes, nodesQuery.data])

  const selectedAccumulators = useMemo(() => {
    return selectedDataItems.filter((x) => x.type === DataItemType.Accumulator)
  }, [DataItemType.Accumulator, selectedDataItems])

  const accumulatorsQuery = useQuery(
    [Paths.Accumulators, endTime, selectedAccumulators, startTime],
    () => {
      const expressions: Expression[] = []
      if (!isNil(startTime)) {
        expressions.push({
          Op: ExpressionOperator.GreaterThanEqual,
          Prop: AccumulatorKeys.RecordDateTime,
          Val: startTime
        })
      }
      if (!isNil(endTime)) {
        expressions.push({
          Op: ExpressionOperator.LessThanEqual,
          Prop: AccumulatorKeys.RecordDateTime,
          Val: endTime
        })
      }
      const individualExpressions: GetExpressionMapping[] =
        selectedAccumulators.map((item) => ({
          expressions: [
            {
              Op: ExpressionOperator.Equal,
              Prop: AccumulatorKeys.Id,
              Val: item.id
            },
            {
              Op: ExpressionOperator.Equal,
              Prop: AccumulatorKeys.NodeId,
              Val: item.nodeId
            }
          ],
          id: item.index
        })) ?? []

      return Promise.all(
        individualExpressions.map((item) =>
          api.getListAll(
            {
              modelExpressions: {
                Expressions: expressions.concat(item.expressions),
                Operator: LogicalOperator.And
              },
              order1: Order.desc,
              orderBy1: AccumulatorKeys.RecordDateTime,
              path: Paths.Accumulators
            },
            item.id
          )
        )
      )
    },
    {
      enabled: selectedAccumulators.length > 0,
      onError: () =>
        enqueueSnackbar(
          intl.formatMessage({
            id: 'accumulators.failedAccumulators',
            description: 'Failed to get accumulators notification text',
            defaultMessage: 'Failed to get Accumulators!'
          }),
          {
            variant: 'error'
          }
        )
    }
  )

  const selectedIdentifiers = useMemo(() => {
    return selectedDataItems.filter((x) => x.type === DataItemType.Identifier)
  }, [DataItemType.Identifier, selectedDataItems])

  const identifiersQuery = useQuery(
    [Paths.Identifiers, endTime, selectedIdentifiers, startTime],
    () => {
      const expressions: Expression[] = []
      if (!isNil(startTime)) {
        expressions.push({
          Op: ExpressionOperator.GreaterThanEqual,
          Prop: IdentifierKeys.EndDateTime,
          Val: startTime
        })
      }
      if (!isNil(endTime)) {
        expressions.push({
          Op: ExpressionOperator.LessThanEqual,
          Prop: IdentifierKeys.StartDateTime,
          Val: endTime
        })
      }
      const individualExpressions: GetExpressionMapping[] =
        selectedIdentifiers.map((item) => ({
          expressions: [
            {
              Op: ExpressionOperator.Equal,
              Prop: IdentifierKeys.NodeId,
              Val: item.nodeId
            },
            {
              Op: ExpressionOperator.Equal,
              Prop: IdentifierKeys.Id,
              Val: item.id
            }
          ],
          id: item.index
        })) ?? []
      return Promise.all(
        individualExpressions.map((item) =>
          api.getListAll(
            {
              modelExpressions: {
                Expressions: expressions.concat(item.expressions),
                Operator: LogicalOperator.And
              },
              order1: Order.desc,
              orderBy1: IdentifierKeys.StartDateTime,
              path: Paths.Identifiers
            },
            item.id
          )
        )
      )
    },
    {
      enabled: selectedIdentifiers.length > 0,
      onError: () =>
        enqueueSnackbar(
          intl.formatMessage({
            id: 'productionView.failedIdentifiers',
            description: 'Failed to get identifiers notification text',
            defaultMessage: 'Failed to get Identifiers!'
          }),
          {
            variant: 'error'
          }
        )
    }
  )

  const selectedEventVars = useMemo(() => {
    return selectedDataItems.filter(
      (x) =>
        x.type === DataItemType.EventVarAlpha ||
        x.type === DataItemType.EventVarNumeric
    )
  }, [
    DataItemType.EventVarAlpha,
    DataItemType.EventVarNumeric,
    selectedDataItems
  ])

  const eventVarQuery = useQuery(
    [Paths.EventVariables, endTime, selectedNodes, checked, startTime],
    () => {
      const expressions: Expression[] = []
      if (!isNil(startTime)) {
        expressions.push({
          Op: ExpressionOperator.GreaterThanEqual,
          Prop: EventVariablesKeys.RecordTime,
          Val: startTime
        })
      }
      if (!isNil(endTime)) {
        expressions.push({
          Op: ExpressionOperator.LessThanEqual,
          Prop: EventVariablesKeys.RecordTime,
          Val: endTime
        })
      }
      const individualExpressions: GetExpressionMapping[] =
        selectedEventVars.map((item) => ({
          expressions: [
            {
              Op: ExpressionOperator.Equal,
              Prop: EventVariablesKeys.NodeId,
              Val: item.nodeId
            },
            {
              Op: ExpressionOperator.Equal,
              Prop: EventVariablesKeys.LocalVarId,
              Val: item.id
            },
            {
              Op: ExpressionOperator.Equal,
              Prop: EventVariablesKeys.EventFilter,
              Val:
                item.type === DataItemType.EventVarAlpha
                  ? EventVarFilterType.Alpha
                  : EventVarFilterType.Numeric
            }
          ],
          id: item.index
        })) ?? []
      return Promise.all(
        individualExpressions.map((item) =>
          api.getListAll(
            {
              modelExpressions: {
                Expressions: expressions.concat(item.expressions),
                Operator: LogicalOperator.And
              },
              order1: Order.desc,
              orderBy1: EventVariablesKeys.RecordTime,
              path: Paths.EventVariables
            },
            item.id
          )
        )
      )
    },
    {
      enabled: selectedEventVars.length > 0,
      onError: () =>
        enqueueSnackbar(
          intl.formatMessage({
            id: 'productionView.failedEventVariables',
            description: 'Failed to get event variables notification text',
            defaultMessage: 'Failed to get Event Variables!'
          }),
          {
            variant: 'error'
          }
        )
    }
  )

  const selectedIncidents = useMemo(() => {
    return selectedDataItems.filter((x) => x.type === DataItemType.Incident)
  }, [DataItemType.Incident, selectedDataItems])

  const incidentsQuery = useQuery(
    [Paths.Incidents, endTime, selectedIncidents, startTime],
    () => {
      const expressions: Expression[] = []
      if (!isNil(startTime)) {
        expressions.push({
          Op: ExpressionOperator.GreaterThanEqual,
          Prop: IncidentKeys.EndDateTime,
          Val: startTime
        })
      }
      if (!isNil(endTime)) {
        expressions.push({
          Op: ExpressionOperator.LessThanEqual,
          Prop: IncidentKeys.StartDateTime,
          Val: endTime
        })
      }
      const individualExpressions: GetExpressionMapping[] =
        selectedIncidents.map((item) => ({
          expressions: [
            {
              Op: ExpressionOperator.Equal,
              Prop: IncidentKeys.NodeId,
              Val: item.nodeId
            },
            {
              Op: ExpressionOperator.Equal,
              Prop: IncidentKeys.Id,
              Val: item.id
            }
          ],
          id: item.index
        })) ?? []
      return Promise.all(
        individualExpressions.map((item) =>
          api.getListAll(
            {
              modelExpressions: {
                Expressions: expressions.concat(item.expressions),
                Operator: LogicalOperator.And
              },
              order1: Order.desc,
              orderBy1: IncidentKeys.StartDateTime,
              path: Paths.Incidents
            },
            item.id
          )
        )
      )
    },
    {
      enabled: selectedIncidents.length > 0,
      onError: () =>
        enqueueSnackbar(
          intl.formatMessage({
            id: 'productionView.failedIncidents',
            description: 'Failed to fetch incidents error notification text',
            defaultMessage: 'Failed to get Incidents!'
          }),
          {
            variant: 'error'
          }
        )
    }
  )

  const nodes = useMemo(() => {
    const nodes =
      nodesQuery.data?.Items.map((item) => {
        const path: { id: number; name: string }[] = []
        return {
          id: item[NodeKeys.Id] as number,
          name: item[NodeKeys.Name] as string,
          parentId: item[NodeKeys.ParentId] as number,
          path
        }
      }) ?? []
    nodes?.forEach((node) => {
      let parent = nodes.find((n) => n.id === node.parentId)
      while (!isNil(parent)) {
        node.path.push({ id: parent.id, name: parent.name })
        parent = nodes.find((n) => n.id === parent?.parentId)
      }
      node.path.reverse()
    })
    return nodes
  }, [nodesQuery.data?.Items])

  useEffect(() => {
    const pvInputData: ProductionGridInputData[] = []
    selectedDataItems.forEach((item) => {
      if (isNil(item.name)) {
        item.name = dataItems.find((di) => di.index === item.index)?.name
      }
      const node = nodes.find((n) => n.id === item.nodeId)
      if (isNil(node)) {
        return
      }
      const nodePath: NodePath[] =
        node.path.map((p) => {
          return { id: p.id, name: p.name }
        }) ?? []
      nodePath.push({ id: node.id, name: node.name })
      let input: ProductionGridInputData | null = null
      switch (item.type) {
        case DataItemType.Accumulator: {
          const data: ProductionGridDataLine[] | undefined =
            accumulatorsQuery.data
              ?.find((query) => query.id === item.index)
              ?.Items.map((x) => ({
                startTime: new Date(String(x[AccumulatorKeys.RecordDateTime])),
                value: String(x[AccumulatorKeys.Value])
              }))
          const total = data
            ?.map((d) => Number(d.value))
            .reduce((prev, curr) => prev + curr, 0)
          input = {
            id: item.index,
            label: item.name,
            type: ProductionDataType.Totals,
            data,
            total,
            nodePath
          }
          break
        }
        case DataItemType.EventVarNumeric:
        case DataItemType.EventVarAlpha: {
          const data: ProductionGridDataLine[] | undefined = eventVarQuery.data
            ?.find((query) => query.id === item.index)
            ?.Items.map((x) => ({
              startTime: new Date(String(x[EventVariablesKeys.RecordTime])),
              value: String(x[EventVariablesKeys.Value])
            }))
          const total = data
            ?.map((d) => Number(d.value))
            .reduce((prev, curr) => prev + curr, 0)
          input = {
            id: item.index,
            label: item.name,
            type: ProductionDataType.Count,
            data,
            total,
            nodePath
          }
          break
        }
        case DataItemType.Identifier: {
          const data: ProductionGridDataLine[] | undefined =
            identifiersQuery.data
              ?.find((query) => query.id === item.index)
              ?.Items.map((x) => ({
                startTime: new Date(x[IdentifierKeys.StartDateTime] as string),
                endTime: new Date(x[IdentifierKeys.EndDateTime] as string),
                value: String(x[IdentifierKeys.Value])
              }))
          input = {
            id: item.index,
            label: item.name,
            type: ProductionDataType.Line,
            data,
            nodePath
          }
          break
        }
        case DataItemType.Incident: {
          const data: ProductionGridDataLine[] | undefined = incidentsQuery.data
            ?.find((query) => query.id === item.index)
            ?.Items.map((x) => ({
              startTime: new Date(x[IncidentKeys.StartDateTime] as string),
              endTime: new Date(x[IncidentKeys.EndDateTime] as string),
              value: String(x[IncidentKeys.Status])
            }))
          input = {
            id: item.index,
            label: item.name,
            type: ProductionDataType.StartStop,
            data,
            nodePath
          }
          break
        }
      }
      if (input) {
        pvInputData.push(input)
      }
    })
    setInputData(pvInputData)
  }, [
    DataItemType.Accumulator,
    DataItemType.EventVarAlpha,
    DataItemType.EventVarNumeric,
    DataItemType.Identifier,
    DataItemType.Incident,
    accumulatorsQuery.data,
    dataItems,
    eventVarQuery.data,
    identifiersQuery.data,
    incidentsQuery.data,
    nodes,
    selectedDataItems
  ])

  const handleRangeChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    const range = event.target.value as number
    setRange(range)
    setEndTime(new Date())
    if (range !== 0) {
      setStartTime(sub(new Date(), { hours: range }))
    }
  }

  const handleIntervalChange = (
    event: React.ChangeEvent<{ value: unknown }>
  ) => {
    setInterval(event.target.value as number)
  }

  const handleGridSizeChange = (
    event: React.ChangeEvent<{ value: unknown }>
  ) => {
    setGridSize(event.target.value as number)
  }

  const handleSelectNodes = (ids: number[]) => {
    setSelectedNodes(ids.length > 0 ? ids.map(Number) : undefined)
  }

  const handleToggle = (value: number) => () => {
    const currentIndex = checked.indexOf(value)
    const newChecked = [...checked]
    if (currentIndex === -1) {
      newChecked.push(value)
    } else {
      newChecked.splice(currentIndex, 1)
    }
    setChecked(newChecked)
    setSelectedDataItems(
      dataItems.filter((di) => newChecked.some((nc) => nc === di.index))
    )
  }

  const handleDialogClose = () => {
    setDialogOpen(false)
  }

  const handleCellClicked = (data: Record<string, unknown>[]) => {
    if (data && data.length > 0) {
      setDialogData(data)
      setDialogOpen(true)
    }
  }

  const handleBreadCrumbClicked = (nodeId: number) => {
    setSelectedNodes([nodeId])
    setOpenDrawer(true)
  }

  const handleUserViewChanged = (
    event: React.ChangeEvent<{ value: unknown }>
  ) => {
    const id = Number(event.target.value)
    if (id === 0) {
      setSelectedUserViewId(0)
      return
    }
    const view = userViewsQuery.data?.Items.find(
      (views) => views[UserViewKeys.Id] === id
    )
    if (isNil(view)) {
      return
    }
    setSelectedUserViewId(Number(view[UserViewKeys.Id]))
    setGridSize(Number(view[UserViewKeys.GridSize]))
    setInterval(Number(view[UserViewKeys.Interval]))
    const range = Number(view[UserViewKeys.ViewRange])
    setRange(range)
    setStartTime(sub(new Date(), { hours: range !== 0 ? range : 24 }))
    setEndTime(new Date())
    const userViewItems: Record<string, unknown>[] = JSON.parse(
      String(view[UserViewKeys.UserViewItemList])
    )
    if (!isNil(userViewItems)) {
      const dataItems: DataItem[] = userViewItems.map((item) => ({
        id: Number(item[UserViewDataItemKeys.DataId]),
        index: buildIndex(
          Number(item[UserViewDataItemKeys.DataId]),
          Number(item[UserViewDataItemKeys.NodeId]),
          Number(item[UserViewDataItemKeys.DataCategoryId])
        ),
        type: Number(item[UserViewDataItemKeys.DataCategoryId]),
        nodeId: Number(item[UserViewDataItemKeys.NodeId])
      }))
      setSelectedDataItems(dataItems)
      setChecked(dataItems.map((i) => i.index))
    } else {
      setSelectedDataItems([])
      setChecked([])
    }
  }

  const buildUserViewFromCurrentView = () => {
    const newUserView: Record<string, unknown> = {
      [UserViewKeys.Interval]: interval,
      [UserViewKeys.GridSize]: gridSize,
      [UserViewKeys.ViewRange]: range !== 0 ? range : defaultRange
    }
    return newUserView
  }

  const populateConfiguratorWithCurrentView = (updateSelectedView = false) => {
    if (
      isNil(userViewsDescQuery.data) ||
      (updateSelectedView && isNil(selectedUserViewId))
    ) {
      return
    }
    const newUserView = buildUserViewFromCurrentView()
    if (updateSelectedView && selectedUserViewId) {
      newUserView[UserViewKeys.Id] = Number(selectedUserViewId)
    }
    configurator.create(
      userViewsDescQuery.data.CrudDescription.Properties,
      newUserView
    )
  }

  const selectedUserView = useMemo(() => {
    if (
      isNil(selectedUserViewId) ||
      isNil(userViewsQuery.data) ||
      isNil(userViewsQuery.data.Items)
    ) {
      return undefined
    }
    return userViewsQuery.data.Items.find(
      (x) => (x[UserViewKeys.Id] as number) === selectedUserViewId
    )
  }, [selectedUserViewId, userViewsQuery.data])

  const convertDataItemsToUserViewItems = (
    dataItems: DataItem[],
    userViewId: number
  ): Record<string, unknown>[] => {
    return dataItems.map((di) => ({
      [UserViewDataItemKeys.DataCategoryId]: di.type,
      [UserViewDataItemKeys.NodeId]: di.nodeId,
      [UserViewDataItemKeys.UserViewId]: userViewId,
      [UserViewDataItemKeys.DataId]: di.id
    }))
  }

  const convertUserViewItemsToDataItems = (
    userViewItems: Record<string, unknown>[]
  ): DataItem[] => {
    return userViewItems.map((item) => ({
      type: Number(item[UserViewDataItemKeys.DataCategoryId]),
      nodeId: Number(item[UserViewDataItemKeys.NodeId]),
      id: Number(item[UserViewDataItemKeys.DataId]),
      index: buildIndex(
        Number(item[UserViewDataItemKeys.DataId]),
        Number(item[UserViewDataItemKeys.NodeId]),
        Number(item[UserViewDataItemKeys.DataCategoryId])
      )
    }))
  }

  const handleUpdateCurrentUserView = () => {
    if (isNil(selectedUserViewId) || isNil(selectedUserView)) {
      throw Error(
        'Cannot handleUpdateCurrentSavedView if there is not active an active saved view.'
      )
    }
    const newUserView = buildUserViewFromCurrentView()
    newUserView[UserViewKeys.Id] = selectedUserViewId
    newUserView[UserViewKeys.CreatedBy] = null
    newUserView[UserViewKeys.SharedGroupId] = null
    newUserView[UserViewKeys.Name] = selectedUserView[UserViewKeys.Name]
    savedViewUpdateMutation.mutate([newUserView])

    const currentUserViewItems: Record<string, unknown>[] = JSON.parse(
      String(selectedUserView[UserViewKeys.UserViewItemList])
    )
    if (isNil(currentUserViewItems)) {
      // all items are new because none existed previously.
      const newUserViewItems = convertDataItemsToUserViewItems(
        selectedDataItems,
        selectedUserViewId
      )
      userViewItemsCreateMutation.mutate(newUserViewItems)
    } else {
      const currentDataItems =
        convertUserViewItemsToDataItems(currentUserViewItems)
      const newDataItems = selectedDataItems.filter(
        (sdi) => !currentDataItems.some((cdi) => cdi.index === sdi.index)
      )
      if (!isNil(newDataItems)) {
        userViewItemsCreateMutation.mutate(
          convertDataItemsToUserViewItems(newDataItems, selectedUserViewId)
        )
      }
      const deletedDataItems = currentUserViewItems.filter(
        (cdi) =>
          !selectedDataItems.some(
            (sdi) =>
              sdi.index ===
              buildIndex(
                Number(cdi[UserViewDataItemKeys.DataId]),
                Number(cdi[UserViewDataItemKeys.NodeId]),
                Number(cdi[UserViewDataItemKeys.DataCategoryId])
              )
          )
      )
      if (!isNil(deletedDataItems)) {
        userViewItemsDeleteMutation.mutate(
          deletedDataItems.map((di) =>
            Number(di[UserViewDataItemKeys.UserViewItemId])
          )
        )
      }
    }
  }

  const handleAlertCancel = () => {
    setShowDeleteAlert(false)
  }

  const handleDeleteUserView = () => {
    if (isNil(selectedUserViewId)) {
      throw Error(
        'Cannot handleAlertDelete if alertSelection is there is no active saved view.'
      )
    }
    savedViewDeleteMutation.mutate([String(selectedUserViewId)])
    setSelectedUserViewId(0)
    setChecked([])
    setSelectedDataItems([])
    setRange(defaultRange)
    setInterval(defaultInterval)
    setGridSize(defaultGridSize)
    setShowDeleteAlert(false)
    queryClient.invalidateQueries(Paths.UserView)
  }

  const handleFormCancel = () => {
    configurator.clear()
  }

  const handleFormSubmit = () => {
    if (configurator.data) {
      switch (configurator.mode) {
        case ConfigurationMode.Create: {
          savedViewCreateMutation.mutate([configurator.data], {
            onSuccess: (result) => {
              const newUserViewId = Number(result.data[0])
              setSelectedUserViewId(newUserViewId)
              const userViewItems: Record<string, unknown>[] =
                convertDataItemsToUserViewItems(
                  selectedDataItems,
                  newUserViewId
                )
              userViewItemsCreateMutation.mutate(userViewItems)
            }
          })
          break
        }
        case ConfigurationMode.Edit:
          savedViewUpdateMutation.mutate([configurator.data])
          break
        default:
          throw Error(
            'Cannot handleFormSubmit with saved view data if configuration mode is not set!'
          )
      }
    } else {
      throw Error('Cannot handleFormSubmit if no configurator data is defined!')
    }
    enqueueSnackbar(
      intl.formatMessage({
        id: 'productionView.savedView',
        description: 'Save view button on production view',
        defaultMessage: 'Saved current view!'
      }),
      {
        variant: 'success'
      }
    )
  }

  const validateSavedViewName = (value: string) => {
    const regEx = /[^A-Za-z0-9-_ ]/g
    return !regEx.test(value)
  }

  // detect if any changes have been made to the current user view.
  const userViewChanged = useMemo(() => {
    if (isNil(selectedUserView)) {
      return false
    }
    if (
      Number(selectedUserView[UserViewKeys.Interval]) !== interval ||
      Number(selectedUserView[UserViewKeys.GridSize]) !== gridSize ||
      Number(selectedUserView[UserViewKeys.ViewRange]) !== range
    ) {
      return true
    }
    const userViewItems: Record<string, unknown>[] = JSON.parse(
      String(selectedUserView[UserViewKeys.UserViewItemList])
    )
    if (!isNil(userViewItems)) {
      const selectedIds = userViewItems.map((x) =>
        buildIndex(
          Number(x[UserViewDataItemKeys.DataId]),
          Number(x[UserViewDataItemKeys.NodeId]),
          Number(x[UserViewDataItemKeys.DataCategoryId])
        )
      )
      return !isEqual(sortBy(selectedIds), sortBy(checked))
    }
    return false
  }, [buildIndex, checked, gridSize, interval, range, selectedUserView])

  const pageLoading =
    nodesQuery.isLoading ||
    accumulatorsQuery.isLoading ||
    eventVarQuery.isLoading ||
    identifiersQuery.isLoading ||
    incidentsQuery.isLoading

  return (
    <>
      {!pageLoading && (
        <>
          {configurator.data && userViewsDescQuery.data && (
            <DataForm
              customValidators={[
                {
                  propertyName: UserViewKeys.Name,
                  validateFunction: validateSavedViewName,
                  errorMessage: intl.formatMessage({
                    id: 'preview.validate.connectionName',
                    description:
                      'IoT configuration page, IoT Connection name validation',
                    defaultMessage:
                      'Saved view name can only contain upper and lowercase letters, numbers, hyphens (-) or underscores (_)'
                  })
                }
              ]}
              formSections={[
                {
                  data: configurator.data,
                  ignoredProperties: [
                    UserViewKeys.Id,
                    UserViewKeys.Interval,
                    UserViewKeys.ViewRange,
                    UserViewKeys.GridSize,
                    UserViewKeys.SharedGroupId,
                    UserViewKeys.CreatedBy
                  ],
                  onPropertyChange: (property, value) =>
                    configurator.update({
                      [property]: value
                    }),
                  schema: userViewsDescQuery.data.CrudDescription
                }
              ]}
              onCancel={handleFormCancel}
              onSubmit={handleFormSubmit}
              title={intl.formatMessage({
                id: 'productionView.createUserView',
                description: 'Production view, IoT create user view title',
                defaultMessage: 'Save User View'
              })}
            />
          )}
          <Box paddingTop={3} paddingBottom={3}>
            <Container maxWidth={false}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Grid
                    container
                    direction="row"
                    justify="flex-start"
                    alignItems="flex-start"
                  >
                    <Button
                      className={classes.selectNodeButton}
                      variant="outlined"
                      size="large"
                      onClick={() => {
                        setOpenDrawer(true)
                      }}
                    >
                      Select nodes
                    </Button>
                    <FormControl
                      variant="outlined"
                      className={classes.formControl}
                    >
                      <InputLabel>Date range</InputLabel>
                      <Select
                        value={range}
                        onChange={handleRangeChange}
                        label="Time range"
                      >
                        <MenuItem value={Range.LastDay}>Last day</MenuItem>
                        <MenuItem value={Range.LastWeek}>Last week</MenuItem>
                        <MenuItem value={Range.Custom}>Custom</MenuItem>
                      </Select>
                    </FormControl>
                    {range === Range.Custom && (
                      <TimeRange
                        endTime={endTime}
                        onEndTimeChange={setEndTime}
                        onStartTimeChange={setStartTime}
                        startTime={startTime}
                        inline={true}
                      />
                    )}
                    <FormControl
                      variant="outlined"
                      className={classes.formControl}
                    >
                      <InputLabel>Interval (in minutes)</InputLabel>
                      <Select
                        value={interval}
                        onChange={handleIntervalChange}
                        label="Interval (in minutes)"
                      >
                        <MenuItem value={15}>15</MenuItem>
                        <MenuItem value={30}>30</MenuItem>
                        <MenuItem value={60}>60</MenuItem>
                      </Select>
                    </FormControl>
                    <FormControl
                      variant="outlined"
                      className={classes.formControl}
                    >
                      <InputLabel>Grid size</InputLabel>
                      <Select
                        value={gridSize}
                        onChange={handleGridSizeChange}
                        label="Grid size"
                      >
                        <MenuItem value={97}>small</MenuItem>
                        <MenuItem value={247}>medium</MenuItem>
                        <MenuItem value={447}>large</MenuItem>
                      </Select>
                    </FormControl>
                    {userViewsQuery.data?.Items &&
                      userViewsQuery.data?.Items.length > 0 && (
                        <FormControl
                          variant="outlined"
                          className={classes.formControl}
                        >
                          <InputLabel>Views</InputLabel>
                          <Select
                            value={selectedUserViewId}
                            onChange={handleUserViewChanged}
                            label="Views"
                          >
                            <MenuItem key={'select-saved-view-new'} value={0}>
                              -- New View --
                            </MenuItem>
                            {userViewsQuery.data?.Items.map((saved) => (
                              <MenuItem
                                key={`select-saved-view-${
                                  saved[UserViewKeys.Name]
                                }`}
                                value={Number(saved[UserViewKeys.Id])}
                              >
                                {String(saved[UserViewKeys.Name])}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      )}
                    {selectedUserViewId !== 0 && (
                      <>
                        <Button
                          className={classes.selectNodeButton}
                          variant="outlined"
                          size="large"
                          onClick={handleUpdateCurrentUserView}
                          disabled={!userViewChanged}
                        >
                          Update view
                        </Button>
                        <Button
                          className={clsx(
                            classes.selectNodeButton,
                            classes.deleteButton
                          )}
                          variant="outlined"
                          onClick={() => {
                            setShowDeleteAlert(true)
                          }}
                          size="large"
                        >
                          Delete view
                        </Button>
                      </>
                    )}
                    {checked.length > 0 && userViewsDescQuery.data && (
                      <Button
                        className={classes.selectNodeButton}
                        variant="outlined"
                        size="large"
                        onClick={() => populateConfiguratorWithCurrentView()}
                      >
                        Save as new view
                      </Button>
                    )}
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <ProductionGrid
                    start={startTime ?? sub(new Date(), defaultDuration)}
                    end={endTime ?? new Date()}
                    inputData={inputData}
                    intervalInMinutes={interval}
                    cellWidthInPixels={gridSize}
                    onCellClicked={handleCellClicked}
                    onBreadCrumbClicked={handleBreadCrumbClicked}
                  />
                </Grid>
              </Grid>
            </Container>
          </Box>
        </>
      )}
      {pageLoading && <PageLoading />}
      <GridDialog
        title={'Cell information'}
        open={dialogOpen}
        actions={[
          {
            handler: handleDialogClose,
            text: intl.formatMessage({
              id: 'productionView.dialogClose',
              description: 'Close alert dialogue, close button text',
              defaultMessage: 'Close'
            })
          }
        ]}
        data={dialogData}
        columnDefinitions={[
          {
            field: 'time',
            headerName: 'Time',
            flex: 1,
            valueFormatter: ({ value }) =>
              intl.formatDate(new Date(String(value)), {
                dateStyle: 'short',
                timeStyle: 'medium'
              })
          },
          { field: 'value', headerName: 'Value', flex: 1 }
        ]}
      />
      <Drawer
        anchor={'right'}
        open={openDrawer}
        onClose={() => {
          setOpenDrawer(false)
        }}
      >
        <Box paddingTop={3} paddingBottom={3} width={600}>
          <Container maxWidth={false}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography gutterBottom variant="h6">
                  Select nodes
                </Typography>
              </Grid>
              {nodesQuery.data?.Items && (
                <Grid item xs={12}>
                  <Hierarchy
                    data={nodesQuery.data.Items}
                    idProperty={NodeKeys.Id}
                    label="Node"
                    nameProperty={NodeKeys.Name}
                    onSelect={handleSelectNodes}
                    ordinalProperty={NodeKeys.OrdinalPosition}
                    parentIdProperty={NodeKeys.ParentId}
                    selected={selectedNodes}
                    title={intl.formatMessage({
                      id: 'accumulators.nodes',
                      description:
                        'Accumulators viewer page, node hierarchy title',
                      defaultMessage: 'Nodes'
                    })}
                  />
                </Grid>
              )}
              <Grid item xs={12}>
                {selectedNode && (
                  <Typography>
                    Available data for {selectedNode[NodeKeys.Name] as string}
                  </Typography>
                )}
              </Grid>
              <Grid item xs={12}>
                {dataItems && selectedNodes && (
                  <>
                    <Accordion
                      disabled={
                        dataItems.filter(
                          (di) =>
                            di.type === DataItemType.Accumulator &&
                            di.nodeId === selectedNodes[0]
                        ).length === 0
                      }
                      expanded={
                        dataItems.filter(
                          (di) =>
                            di.type === DataItemType.Accumulator &&
                            di.nodeId === selectedNodes[0]
                        ).length > 0
                      }
                    >
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                      >
                        <Typography>
                          {intl.formatMessage({
                            id: 'productionView.accordionAccumulators',
                            description:
                              'accumulators in accordion drawer select',
                            defaultMessage: 'Accumulators'
                          })}
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <List className={classes.listItem}>
                          {dataItems
                            .filter(
                              (di) =>
                                di.type === DataItemType.Accumulator &&
                                di.nodeId === selectedNodes[0]
                            )
                            .map((item) => {
                              const labelId = `checkbox-list-label-${item.index}`
                              return (
                                <ListItem
                                  key={`item-${item.index}`}
                                  role={undefined}
                                  dense
                                  button
                                  onClick={handleToggle(item.index)}
                                >
                                  <ListItemIcon>
                                    <Checkbox
                                      edge="start"
                                      checked={
                                        checked.indexOf(item.index) !== -1
                                      }
                                      tabIndex={-1}
                                      disableRipple
                                      inputProps={{
                                        'aria-labelledby': labelId
                                      }}
                                    />
                                  </ListItemIcon>
                                  <ListItemText
                                    id={labelId}
                                    primary={item.name}
                                  />
                                </ListItem>
                              )
                            })}
                        </List>
                      </AccordionDetails>
                    </Accordion>
                    <Accordion
                      disabled={
                        dataItems.filter(
                          (di) =>
                            di.type === DataItemType.Identifier &&
                            di.nodeId === selectedNodes[0]
                        ).length === 0
                      }
                      expanded={
                        dataItems.filter(
                          (di) =>
                            di.type === DataItemType.Identifier &&
                            di.nodeId === selectedNodes[0]
                        ).length > 0
                      }
                    >
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                      >
                        <Typography>
                          {intl.formatMessage({
                            id: 'productionView.accordionIdentifiers',
                            description:
                              'Identifiers in accordion drawer select',
                            defaultMessage: 'Identifiers'
                          })}
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <List className={classes.listItem}>
                          {dataItems
                            .filter(
                              (di) =>
                                di.type === DataItemType.Identifier &&
                                di.nodeId === selectedNodes[0]
                            )
                            .map((item) => {
                              const labelId = `checkbox-list-label-${item.index}`
                              return (
                                <ListItem
                                  key={`item-${item.index}`}
                                  role={undefined}
                                  dense
                                  button
                                  onClick={handleToggle(item.index)}
                                >
                                  <ListItemIcon>
                                    <Checkbox
                                      edge="start"
                                      checked={
                                        checked.indexOf(item.index) !== -1
                                      }
                                      tabIndex={-1}
                                      disableRipple
                                      inputProps={{
                                        'aria-labelledby': labelId
                                      }}
                                    />
                                  </ListItemIcon>
                                  <ListItemText
                                    id={labelId}
                                    primary={item.name}
                                  />
                                </ListItem>
                              )
                            })}
                        </List>
                      </AccordionDetails>
                    </Accordion>
                    <Accordion
                      disabled={
                        dataItems.filter(
                          (di) =>
                            (di.type === DataItemType.EventVarAlpha ||
                              di.type === DataItemType.EventVarNumeric) &&
                            di.nodeId === selectedNodes[0]
                        ).length === 0
                      }
                      expanded={
                        dataItems.filter(
                          (di) =>
                            (di.type === DataItemType.EventVarAlpha ||
                              di.type === DataItemType.EventVarNumeric) &&
                            di.nodeId === selectedNodes[0]
                        ).length > 0
                      }
                    >
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                      >
                        <Typography>
                          {intl.formatMessage({
                            id: 'productionView.accordionEvents',
                            description: 'Events in accordion drawer select',
                            defaultMessage: 'Events'
                          })}
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <List className={classes.listItem}>
                          {dataItems
                            .filter(
                              (di) =>
                                (di.type === DataItemType.EventVarAlpha ||
                                  di.type === DataItemType.EventVarNumeric) &&
                                di.nodeId === selectedNodes[0]
                            )
                            .map((item) => {
                              const labelId = `checkbox-list-label-${item.index}`
                              return (
                                <ListItem
                                  key={`item-${item.index}`}
                                  role={undefined}
                                  dense
                                  button
                                  onClick={handleToggle(item.index)}
                                >
                                  <ListItemIcon>
                                    <Checkbox
                                      edge="start"
                                      checked={
                                        checked.indexOf(item.index) !== -1
                                      }
                                      tabIndex={-1}
                                      disableRipple
                                      inputProps={{
                                        'aria-labelledby': labelId
                                      }}
                                    />
                                  </ListItemIcon>
                                  <ListItemText
                                    id={labelId}
                                    primary={item.name}
                                  />
                                </ListItem>
                              )
                            })}
                        </List>
                      </AccordionDetails>
                    </Accordion>
                    <Accordion
                      disabled={
                        dataItems.filter(
                          (di) =>
                            di.type === DataItemType.Incident &&
                            di.nodeId === selectedNodes[0]
                        ).length === 0
                      }
                      expanded={
                        dataItems.filter(
                          (di) =>
                            di.type === DataItemType.Incident &&
                            di.nodeId === selectedNodes[0]
                        ).length > 0
                      }
                    >
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                      >
                        <Typography>
                          {intl.formatMessage({
                            id: 'productionView.accordionIncidents',
                            description: 'Incidents in accordion drawer select',
                            defaultMessage: 'Incidents'
                          })}
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <List className={classes.listItem}>
                          {dataItems
                            .filter(
                              (di) =>
                                di.type === DataItemType.Incident &&
                                di.nodeId === selectedNodes[0]
                            )
                            .map((item) => {
                              const labelId = `checkbox-list-label-${item.index}`
                              return (
                                <ListItem
                                  key={`item-${item.index}`}
                                  role={undefined}
                                  dense
                                  button
                                  onClick={handleToggle(item.index)}
                                >
                                  <ListItemIcon>
                                    <Checkbox
                                      edge="start"
                                      checked={
                                        checked.indexOf(item.index) !== -1
                                      }
                                      tabIndex={-1}
                                      disableRipple
                                      inputProps={{
                                        'aria-labelledby': labelId
                                      }}
                                    />
                                  </ListItemIcon>
                                  <ListItemText
                                    id={labelId}
                                    primary={item.name}
                                  />
                                </ListItem>
                              )
                            })}
                        </List>
                      </AccordionDetails>
                    </Accordion>
                  </>
                )}
              </Grid>
            </Grid>
          </Container>
        </Box>
      </Drawer>
      {selectedUserViewId !== 0 && (
        <AlertDialogue
          actions={[
            {
              handler: handleDeleteUserView,
              text: intl.formatMessage({
                id: 'productionView.delete',
                description: 'Delete alert dialogue, delete button text',
                defaultMessage: 'Delete'
              })
            },
            {
              handler: handleAlertCancel,
              text: intl.formatMessage({
                id: 'productionView.cancel',
                description: 'Delete alert dialogue, cancel button text',
                defaultMessage: 'Cancel'
              })
            }
          ]}
          message={intl.formatMessage({
            id: 'productionView.deleteAlertMessage',
            description: 'Delete alert dialogue message text',
            defaultMessage:
              'Are you sure you want to delete the following view?'
          })}
          open={showDeleteAlert}
          title={intl.formatMessage({
            id: 'productionView.deleteAlertTitle',
            description: 'Delete alert title text',
            defaultMessage: 'Delete view'
          })}
          listItems={[
            String(selectedUserView ? selectedUserView[UserViewKeys.Name] : '')
          ]}
        />
      )}
    </>
  )
}

export default ProductionView
