import React, { FunctionComponent } from 'react'
import { defineMessages, useIntl } from 'react-intl'
import { find, isNil, isString } from 'lodash'
import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  FormControlLabel,
  FormGroup,
  Grid,
  makeStyles,
  TextField
} from '@material-ui/core'
import Autocomplete from './Autocomplete'
import DialogueTitle from './DialogueTitle'
import DraggablePaper from './DraggablePaper'
import { camelCaseToTitle } from '../api/Utils'
import {
  Expression,
  ExpressionOperator,
  PropertyLookup,
  Schema
} from '../api/RcfactoryApi'
import { KeyboardDateTimePicker } from '@material-ui/pickers'
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date'

const messages = defineMessages({
  operator: {
    id: 'filterDialogue.operator',
    description: 'FilterDialogue, Operator Autocomplete label',
    defaultMessage: 'Operator'
  }
})

const useStyles = makeStyles((theme) => ({
  form: {
    '& .MuiTextField-root': {
      position: 'relative',
      marginBottom: theme.spacing(2),
      width: '100%'
    }
  }
}))

const dateOperatorToString = (operator: ExpressionOperator): string => {
  switch (operator) {
    case ExpressionOperator.NotEqual: return 'is not'
    case ExpressionOperator.GreaterThan: return 'is after'
    case ExpressionOperator.GreaterThanEqual: return 'is on or after'
    case ExpressionOperator.LessThan: return 'is before'
    case ExpressionOperator.LessThanEqual: return 'is on or before'
    default: return 'is'
  }
}

const numberOperatorToString = (operator: ExpressionOperator): string => {
  switch (operator) {
    case ExpressionOperator.Equal: return '='
    default: return operator
  }
}

const parseDateOperator = (text: string): ExpressionOperator => {
  switch (text) {
    case 'is not': return ExpressionOperator.NotEqual
    case 'is after': return ExpressionOperator.GreaterThan
    case 'is on or after': return ExpressionOperator.GreaterThanEqual
    case 'is before': return ExpressionOperator.LessThan
    case 'is on or before': return ExpressionOperator.LessThanEqual
    default: return ExpressionOperator.Equal
  }
}

const parseNumberOperator = (text: string): ExpressionOperator => {
  switch (text) {
    case '=': return ExpressionOperator.Equal
    default: return text as ExpressionOperator
  }
}

const parseStringOperator = (text: string): ExpressionOperator => {
  switch (text) {
    case 'equals': return ExpressionOperator.Equal
    case 'starts with': return ExpressionOperator.StartsWith
    case 'ends width': return ExpressionOperator.EndsWith
    default: return text as ExpressionOperator
  }
}

const stringOperatorToString = (operator: ExpressionOperator): string => {
  switch (operator) {
    case ExpressionOperator.Equal: return 'equals'
    case ExpressionOperator.StartsWith: return 'starts with'
    case ExpressionOperator.EndsWith: return 'ends width'
    default: return operator
  }
}

interface Props {
  filter: Expression[],
  lookupProperties?: PropertyLookup[],
  onCancel: () => void,
  onExpressionChange: (
    property: string,
    operator: ExpressionOperator,
    value: unknown
  ) => void,
  onReset: () => void,
  onSubmit: () => void,
  schema: Schema,
  title: string
}

const FilterDialogue: FunctionComponent<Props> = (props: Props) => {
  const {
    filter,
    lookupProperties,
    onCancel,
    onExpressionChange,
    onReset,
    onSubmit,
    schema,
    title
  } = props
  const classes = useStyles()
  const intl = useIntl()

  const getLookupProperty = (
    expression: Expression,
    lookupProperty: PropertyLookup,
    valueProperty: string
  ) => {
    const lookupMatch = lookupProperty.data?.find(
      p => expression.Val === p[lookupProperty.remoteProperty]
    )
    return !isNil(lookupMatch) ? String(lookupMatch[valueProperty]) : null
  }

  const setLookupProperty = (
    lookupProperty: PropertyLookup,
    expression: Expression,
    operator: ExpressionOperator,
    value: unknown
  ) => {
    const valueProperty = lookupProperty.nameProperty
      ? lookupProperty.nameProperty
      : lookupProperty.remoteProperty
    const lookupMatch = lookupProperty.data?.find(
      p => p[valueProperty] === value
    )
    onExpressionChange(
      expression.Prop,
      operator,
      lookupMatch ? lookupMatch[lookupProperty.remoteProperty] : undefined
    )
  }

  const handleCheckboxClick = (
    expression: Expression,
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    const checkBox = event.target as HTMLInputElement
    if (checkBox.readOnly) {
      checkBox.checked = checkBox.readOnly = false
    } else if (!checkBox.checked) {
      checkBox.readOnly = checkBox.indeterminate = true
    }
    onExpressionChange(
      expression.Prop,
      expression.Op,
      checkBox.indeterminate ? undefined : checkBox.checked
    )
  }

  const renderExpression = (expression: Expression) => {
    const property = find(schema.Properties, ['PropertyName', expression.Prop])
    if (!property) {
      throw Error('Cannot renderExpression if Prop is not found as PropertyName in schema!')
    }
    if (lookupProperties) {
      const lookupProperty = lookupProperties.find(
        p => p.localProperty === property.PropertyName
      )
      if (lookupProperty) {
        const valueProperty =
          lookupProperty.nameProperty || lookupProperty.remoteProperty
        return (
          <Box
            key={property.PropertyName}
            width="100%"
          >
            <Autocomplete
              label={lookupProperty.label || camelCaseToTitle(valueProperty)}
              options={!isNil(lookupProperty.data)
                ? lookupProperty.data.map(d => d[valueProperty] as string)
                : []
              }
              onValueChange={(value) => {
                setLookupProperty(
                  lookupProperty,
                  expression,
                  expression.Op,
                  value
                )
              }}
              value={getLookupProperty(expression, lookupProperty, valueProperty)}
              variant="outlined"
            />
          </Box>
        )
      }
    }
    switch (property.JsPropertyType) {
      case 'boolean': return (
        <FormControlLabel
          key={property.PropertyName}
          control={
            <Checkbox
              checked={Boolean(expression.Val)}
              indeterminate={expression.Val === undefined}
              onClick={(event) =>
                handleCheckboxClick(expression, event)
              }
              readOnly={expression.Val === undefined}
            />
          }
          label={camelCaseToTitle(property.PropertyName)}
        />
      )
      case 'Date': return (
        <Grid
          container
          key={expression.Prop}
          spacing={1}
        >
          <Grid item xs={8}>
            <KeyboardDateTimePicker
              ampm={false}
              format="Ppp"
              label={camelCaseToTitle(expression.Prop)}
              onChange={(date: MaterialUiPickersDate) => onExpressionChange(
                expression.Prop,
                expression.Op,
                date
              )}
              showTodayButton
              value={expression.Val
                ? expression.Val as Date
                : null
              }
            />
          </Grid>
          <Grid item xs={4}>
            <Autocomplete
              disableClearable={true}
              label={intl.formatMessage(messages.operator)}
              options={[
                ExpressionOperator.Equal,
                ExpressionOperator.NotEqual,
                ExpressionOperator.GreaterThan,
                ExpressionOperator.GreaterThanEqual,
                ExpressionOperator.LessThan,
                ExpressionOperator.LessThanEqual
              ].map(o => dateOperatorToString(o))}
              onValueChange={(value) => {
                if (!isString(value)) {
                  throw Error('Value must be a string!')
                }
                onExpressionChange(
                  expression.Prop,
                  parseDateOperator(value),
                  expression.Val
                )
              }}
              value={dateOperatorToString(expression.Op)}
            />
          </Grid>
        </Grid>
      )
      case 'number': return (
        <Grid
          container
          key={expression.Prop}
          spacing={1}
        >
          <Grid item xs={8}>
            <TextField
              key={expression.Prop}
              label={camelCaseToTitle(expression.Prop)}
              onChange={(event) => onExpressionChange(
                expression.Prop,
                expression.Op,
                event.target.value
              )}
              type="number"
              value={expression.Val ?? ''}
            />
          </Grid>
          <Grid item xs={4}>
            <Autocomplete
              disableClearable={true}
              label={intl.formatMessage(messages.operator)}
              options={[
                ExpressionOperator.Equal,
                ExpressionOperator.NotEqual,
                ExpressionOperator.GreaterThan,
                ExpressionOperator.GreaterThanEqual,
                ExpressionOperator.LessThan,
                ExpressionOperator.LessThanEqual
              ].map(o => numberOperatorToString(o))}
              onValueChange={(value) => {
                if (!isString(value)) {
                  throw Error('Value must be a string!')
                }
                onExpressionChange(
                  expression.Prop,
                  parseNumberOperator(value),
                  expression.Val
                )
              }}
              value={numberOperatorToString(expression.Op)}
            />
          </Grid>
        </Grid>
      )
      default: return (
        <Grid
          container
          key={expression.Prop}
          spacing={1}
        >
          <Grid item xs={8}>
            <TextField
              label={camelCaseToTitle(expression.Prop)}
              onChange={(event) => onExpressionChange(
                expression.Prop,
                expression.Op,
                event.target.value
              )}
              value={expression.Val ?? ''}
            />
          </Grid>
          <Grid item xs={4}>
            <Autocomplete
              disableClearable={true}
              label={intl.formatMessage(messages.operator)}
              options={[
                ExpressionOperator.Contains,
                ExpressionOperator.Equal,
                ExpressionOperator.StartsWith,
                ExpressionOperator.EndsWith
              ].map(o => stringOperatorToString(o))}
              onValueChange={(value) => {
                if (!isString(value)) {
                  throw Error('Value must be a string!')
                }
                onExpressionChange(
                  expression.Prop,
                  parseStringOperator(value),
                  expression.Val
                )
              }}
              value={stringOperatorToString(expression.Op)}
            />
          </Grid>
        </Grid>
      )
    }
  }

  return (
    <Dialog
      open={true}
      fullWidth={true}
      maxWidth="sm"
      PaperComponent={DraggablePaper}
    >
      {title &&
        <DialogueTitle draggable onClose={onCancel}>
          {title}
        </DialogueTitle>
      }
      <DialogContent>
        <form
          className={classes.form}
          noValidate
          autoComplete="off"
        >
          <FormGroup>
            {
              filter.map((expression) => renderExpression(expression))
            }
          </FormGroup>
        </form>
      </DialogContent>
      <DialogActions>
        <Button onClick={onSubmit}>
          Submit
        </Button>
        <Button onClick={onReset}>
          Clear
        </Button>
        <Button onClick={onCancel}>
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default FilterDialogue
