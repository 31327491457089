import React, { FunctionComponent, useMemo, useState } from 'react'
import { defineMessages, useIntl } from 'react-intl'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { NumericArrayParam, useQueryParam } from 'use-query-params'
import { includes, isNil } from 'lodash'
import { useSnackbar } from 'notistack'
import { Box, Container, Grid } from '@material-ui/core'
import AlertDialogue from '../../../../components/AlertDialogue'
import DataForm from '../../../../components/DataForm'
import Table from '../../../../components/Table/Table'
import FilterDialogue from '../../../../components/FilterDialogue'
import PageLoading from '../../../../components/Loading/PageLoading'
import useConfigure, { ConfigurationMode } from '../../../../hooks/useConfigure'
import useFilter from '../../../../hooks/useFilter'
import usePagination from '../../../../hooks/usePagination'
import useSort from '../../../../hooks/useSort'
import {
  AccumulatorDefCfgKeys,
  LogicalOperator,
  Paths,
  useApi
} from '../../../../api/RcfactoryApi'

enum ParamKeys {
  DefinitionId = 'definitionId'
}

enum ConfigurationKeys {
  Definition = 'definition'
}

const messages = defineMessages({
  alertMessage: {
    id: 'accumulatorDefinitions.alertMessage',
    description: 'Delete alert dialogue message content',
    defaultMessage: 'Are you sure you want to delete the selected Accumulator ' +
      '{count, plural, one{Definition} other{Definitions}}?'
  },
  alertTitle: {
    id: 'accumulatorDefinitions.alertTitle',
    description: 'Delete alert dialogue title',
    defaultMessage: 'Delete Accumulator {count, plural, one{Definition} other{Definitions}}'
  }
})

const omitFilterProperties = [
  AccumulatorDefCfgKeys.Id
]

const AccumulatorDefinitions: FunctionComponent = () => {
  const intl = useIntl()
  const api = useApi()
  const configurator = useConfigure(ConfigurationKeys.Definition)
  const filterer = useFilter()
  const pagination = usePagination()
  const sort = useSort(AccumulatorDefCfgKeys.Id)
  const [alertOpen, setAlertOpen] = useState<boolean>(false)
  const [selected, setSelected] = useQueryParam(
    ParamKeys.DefinitionId, NumericArrayParam
  )
  const { enqueueSnackbar } = useSnackbar()
  const queryClient = useQueryClient()
  const descQuery = useQuery(
    Paths.AccumulatorDefCfg + Paths.UtilsGetDesc,
    () => api.getDesc({
      path: Paths.AccumulatorDefCfg
    }), {
      onError: () => enqueueSnackbar(
        intl.formatMessage({
          id: 'accumulatorDefinitions.failedSchema',
          description: 'Fetch accumulator definition schema error notification text',
          defaultMessage: 'Failed to get Accumulator Definition Schema!'
        }), {
          variant: 'error'
        }
      )
    }
  )
  const dataQuery = useQuery([
    Paths.AccumulatorDefCfg,
    filterer.active,
    sort.order,
    sort.orderBy,
    pagination.page,
    pagination.rowsPerPage
  ], () => api.getList({
    modelExpressions: {
      Expressions: filterer.active,
      Operator: LogicalOperator.And
    },
    order1: sort.order,
    orderBy1: sort.orderBy,
    pageNumber: pagination.page,
    pageSize: pagination.rowsPerPage,
    path: Paths.AccumulatorDefCfg
  }), {
    onError: () => enqueueSnackbar(
      intl.formatMessage({
        id: 'accumulatorDefinitions.failedAccumulatorDefinitions',
        description: 'Fetch accumulator definitions error notification text',
        defaultMessage: 'Failed to get Accumulator Definitions!'
      }), {
        variant: 'error'
      }
    )
  })
  const createMutation = useMutation(
    (items: Record<string, unknown>[]) => api.create({
      items: items,
      path: Paths.AccumulatorDefCfg
    }), {
      onError: () => {
        enqueueSnackbar(
          intl.formatMessage({
            id: 'accumulatorDefinitions.failedCreate',
            description: 'Create accumulator definition error notification text',
            defaultMessage: 'Failed to create Accumulator Definition!'
          }), {
            variant: 'error'
          }
        )
      },
      onSuccess: () => {
        enqueueSnackbar(
          intl.formatMessage({
            id: 'accumulatorDefinitions.successfulCreate',
            description: 'Create accumulator definition success notification text',
            defaultMessage: 'Successfully created Accumulator Definition!'
          }), {
            variant: 'success'
          }
        )
        configurator.clear()
        queryClient.invalidateQueries(Paths.AccumulatorDefCfg)
      }
    }
  )
  const deleteMutation = useMutation(
    (ids: number[]) => api.delete({
      ids: ids,
      path: Paths.AccumulatorDefCfg
    }), {
      onError: () => {
        enqueueSnackbar(
          intl.formatMessage({
            id: 'accumulatorDefinitions.failedDelete',
            description: 'Delete accumulator definition error notification text',
            defaultMessage: 'Failed to delete Accumulator Definitions!'
          }), {
            variant: 'error'
          }
        )
      },
      onSuccess: () => {
        enqueueSnackbar(
          intl.formatMessage({
            id: 'accumulatorDefinitions.successfulDelete',
            description: 'Delete accumulator definition success notification text',
            defaultMessage: 'Successfully deleted Accumulator Definitions!'
          }), {
            variant: 'success'
          }
        )
        setSelected(undefined)
        queryClient.invalidateQueries(Paths.AccumulatorDefCfg)
      }
    }
  )
  const updateMutation = useMutation(
    (items: Record<string, unknown>[]) => api.update({
      items: items,
      path: Paths.AccumulatorDefCfg
    }), {
      onError: () => {
        enqueueSnackbar(
          intl.formatMessage({
            id: 'accumulatorDefinitions.failedUpdate',
            description: 'Update accumulator definition error notification text',
            defaultMessage: 'Failed to update Accumulator Definition!'
          }), {
            variant: 'error'
          }
        )
      },
      onSuccess: () => {
        enqueueSnackbar(
          intl.formatMessage({
            id: 'accumulatorDefinitions.successfulUpdate',
            description: 'Update accumulator definition success notification text',
            defaultMessage: 'Successfully updated Accumulator Definition!'
          }), {
            variant: 'success'
          }
        )
        configurator.clear()
        queryClient.invalidateQueries(Paths.AccumulatorDefCfg)
      }
    }
  )

  const activeDefinition = useMemo(() => {
    if (!dataQuery.data?.Items) {
      return
    }
    if (selected?.length !== 1) {
      return
    }
    return dataQuery.data.Items.find(
      (d: Record<string, unknown>) => d[AccumulatorDefCfgKeys.Id] === selected[0])
  }, [selected, dataQuery.data?.Items])

  const handleAlertCancel = () => {
    setAlertOpen(false)
  }

  const handleAlertDelete = () => {
    if (isNil(selected)) {
      throw Error('Cannot handleAlertDelete if selected is nil!')
    }
    deleteMutation.mutate(selected.map(Number))
    setAlertOpen(false)
  }

  const handleDelete = () => {
    setAlertOpen(true)
  }

  const handleFilterSubmit = () => {
    filterer.submit()
    pagination.setPage(0)
    filterer.clearData()
  }

  const handleFormCancel = () => {
    configurator.clear()
    filterer.clearData()
  }

  const handleFormSubmit = () => {
    if (!configurator.data) {
      throw Error('Cannot handleFormSubmit if configurator data is undefined!')
    }
    switch (configurator.mode) {
      case ConfigurationMode.Create:
        createMutation.mutate([configurator.data])
        break

      case ConfigurationMode.Edit:
        updateMutation.mutate([configurator.data])
        break

      default:
        throw Error('Cannot handleFormSubmit if configurator mode is undefined!')
    }
  }

  const handlePageChange = (newPage: number) => {
    pagination.setPage(newPage)
    setSelected(undefined)
  }

  const handleRequestSort = (property: string) => {
    sort.requestSort(property)
    setSelected(undefined)
  }

  const handleRowsPerPageChange = (rows: number) => {
    pagination.setRowsPerPage(rows)
    setSelected(undefined)
  }

  const handleSelect = (ids: (number | string | null)[]) => {
    setSelected(ids.length > 0 ? ids.map(Number) : undefined)
  }

  const pageReady = descQuery.isSuccess && dataQuery.isSuccess &&
  !createMutation.isLoading && !deleteMutation.isLoading && !updateMutation.isLoading

  const pageLoading = descQuery.isLoading || dataQuery.isLoading ||
  createMutation.isLoading || deleteMutation.isLoading || updateMutation.isLoading

  return (
    <>
      { pageReady &&
        <Box paddingTop={3} paddingBottom={3}>
          <Container maxWidth="md">
            <Grid container>
              <Grid item xs={12}>
                { configurator.data &&
                  descQuery.data?.CrudDescription &&
                  <DataForm
                    formSections={[{
                      data: configurator.data,
                      ignoredProperties: [AccumulatorDefCfgKeys.Id],
                      onPropertyChange: (property, value) => configurator.update({
                        [property]: value
                      }),
                      schema: descQuery.data.CrudDescription
                    }]}
                    onCancel={handleFormCancel}
                    onSubmit={handleFormSubmit}
                    title={configurator.mode === ConfigurationMode.Create
                      ? intl.formatMessage({
                        id: 'accumulatorDefinitions.create',
                        description: 'Create accumulator definition dialogue title',
                        defaultMessage: 'Create Accumulator Definition'
                      })
                      : intl.formatMessage({
                        id: 'accumulatorDefinitions.edit',
                        description: 'Edit accumulator definition dialogue title',
                        defaultMessage: 'Edit Accumulator Definition'
                      })
                    }
                  />
                }
                { filterer.data &&
                  descQuery.data?.ViewDescription &&
                  <FilterDialogue
                    filter={filterer.data}
                    onCancel={handleFormCancel}
                    onExpressionChange={filterer.update}
                    onReset={filterer.reset}
                    onSubmit={handleFilterSubmit}
                    schema={descQuery.data.ViewDescription}
                    title={intl.formatMessage({
                      id: 'accumulatorDefinitions.filter',
                      description: 'Filter accumulator definitions dialogue title',
                      defaultMessage: 'Filter Accumulator Definitions'
                    })}
                  />
                }
                { dataQuery.data?.Items &&
                  dataQuery.data?.Pagination &&
                  descQuery.data &&
                  <Table
                    data={dataQuery.data.Items}
                    idProperty={AccumulatorDefCfgKeys.Id}
                    isFiltered={filterer.isActive}
                    multiSelect
                    onAdd={() => configurator.create(
                      descQuery.data.CrudDescription.Properties
                    )}
                    onDelete={handleDelete}
                    onEdit={activeDefinition
                      ? () => configurator.edit(activeDefinition)
                      : undefined
                    }
                    onFilter={() => filterer.initialise(
                      descQuery.data.ViewDescription.Properties.filter(
                        p => !includes(omitFilterProperties, p.PropertyName)
                      )
                    )}
                    onPageChange={handlePageChange}
                    onRequestSort={handleRequestSort}
                    onRowsPerPageChange={handleRowsPerPageChange}
                    onSelect={handleSelect}
                    order={sort.order}
                    orderBy={sort.orderBy}
                    page={pagination.page}
                    rowsPerPage={pagination.rowsPerPage}
                    schema={descQuery.data.ViewDescription}
                    selected={selected}
                    title={intl.formatMessage({
                      id: 'accumulatorDefinitions.accumulatorDefinitions',
                      description: 'Accumulator definitions table title',
                      defaultMessage: 'Accumulator Definitions'
                    })}
                    totalRows={dataQuery.data.Pagination.TotalCount}
                  />
                }
              </Grid>
            </Grid>
          </Container>
        </Box>
      }
      {
        pageLoading &&
        <PageLoading/>
      }
      <AlertDialogue
        actions={[{
          handler: handleAlertDelete,
          text: intl.formatMessage({
            id: 'accumulatorDefinitions.alertDelete',
            description: 'Delete alert dialogue, delete button text',
            defaultMessage: 'Delete'
          })
        }, {
          handler: handleAlertCancel,
          text: intl.formatMessage({
            id: 'accumulatorDefinitions.alertCancel',
            description: 'Delete alert dialogue, cancel button text',
            defaultMessage: 'Cancel'
          })
        }]}
        message={intl.formatMessage(messages.alertMessage, {
          count: selected?.length
        })}
        open={alertOpen}
        title={intl.formatMessage(messages.alertTitle, {
          count: selected?.length
        })}
      />
    </>
  )
}

export default AccumulatorDefinitions
