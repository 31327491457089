import React, { FunctionComponent } from 'react'
import { defineMessages, useIntl } from 'react-intl'
import clsx from 'clsx'
import { Fab, makeStyles, Tooltip } from '@material-ui/core'
import { Refresh } from '@material-ui/icons'
import { useSnackbar } from 'notistack'
import { useMutation } from 'react-query'
import {
  Paths,
  UpdateMappedTagResponseKeys,
  useApi
} from '../../../api/RcfactoryApi'

const useStyles = makeStyles(() => ({
  '@keyframes spin': {
    '0%': {
      transform: 'rotate(0deg)'
    },
    '100%': {
      transform: 'rotate(360deg)'
    }
  },
  loading: {
    animation: '$spin 1s linear infinite'
  }
}))

const messages = defineMessages({
  active: {
    id: 'updateMappedIotTags.active',
    description: 'Info notification text displaying active tag mapping count',
    defaultMessage: 'There are {count, plural,' +
      'one {# active IoT tag mapping}' +
      'other {# active IoT tag mappings}}.'
  },
  activated: {
    id: 'updateMappedIotTags.activated',
    description: 'Success notification text when IoT tag mappings are activated',
    defaultMessage: 'Activated {count, plural, one {# IoT Tag} other {# IoT Tags}}!'
  },
  deactivated: {
    id: 'updateMappedIotTage.deactivated',
    description: 'Success notification text when IoT tag mappings are deactivated',
    defaultMessage: 'Deactivated {count, plural, one {# IoT Tag} other {# IoT Tags}}!'
  }
})

const UpdateMappedIotTags: FunctionComponent = () => {
  const classes = useStyles()
  const intl = useIntl()
  const api = useApi()
  const { enqueueSnackbar } = useSnackbar()

  const refreshMutation = useMutation(
    () => api.create({
      items: [],
      path: Paths.UpdateMappedTagStatus
    }), {
      onError: () => {
        enqueueSnackbar(
          intl.formatMessage({
            id: 'updateMappedIotTags.failedRefresh',
            description: 'Error notification text when IoT tag mapping refresh fails',
            defaultMessage: 'Failed to refresh IoT tag mappings!'
          }), {
            variant: 'error'
          }
        )
      },
      onSuccess: (response) => {
        const activated = response.data[UpdateMappedTagResponseKeys.TagsActivated]
        if (activated > 0) {
          enqueueSnackbar(
            intl.formatMessage(messages.activated, { count: activated }), {
              variant: 'success'
            }
          )
        }
        const deactivated = response.data[UpdateMappedTagResponseKeys.TagsDeactivated]
        if (deactivated > 0) {
          enqueueSnackbar(
            intl.formatMessage(messages.deactivated, { count: deactivated }), {
              variant: 'success'
            }
          )
        }
        if (activated === 0 && deactivated === 0) {
          enqueueSnackbar(intl.formatMessage({
            id: 'updateMappedIotTags.noUpdates',
            description: 'Info notification text when no IoT tag mappings were updated',
            defaultMessage: 'No IoT tags were updated.'
          }))
        }
        const active = response.data[UpdateMappedTagResponseKeys.ActiveTags]
        enqueueSnackbar(`There are ${active} active IoT tag mappings.`)
      }
    }
  )

  const handleClick = () => {
    refreshMutation.mutate()
  }

  return (
    <Tooltip title={intl.formatMessage({
      id: 'updateMappedIotTags.updateMappedIotTags',
      description: 'Update mapped IoT tag floating action button tooltip',
      defaultMessage: 'Update Mapped IoT Tags'
    })}>
      <Fab
        className={clsx({ [classes.loading]: refreshMutation.isLoading })}
        color="primary"
        onClick={handleClick}
      >
        <Refresh/>
      </Fab>
    </Tooltip>
  )
}

export default UpdateMappedIotTags
