import React, { FunctionComponent } from 'react'
import { Link } from 'react-router-dom'
import { useIntl } from 'react-intl'
import { useMsal } from '@azure/msal-react'
import { Box, ListItemIcon, ListItemText, makeStyles } from '@material-ui/core'
import { ExitToApp, Home } from '@material-ui/icons'
import { Routes } from '../App'
import { FactoryManagerSideMenu } from '../pages/FactoryManager/FactoryManager'
import { FactoryViewerSideMenu } from '../pages/FactoryViewer/FactoryViewer'
import SideMenuCustomListItem from './SideMenuCustomListItem'

const useStyles = makeStyles((theme) => ({
  link: {
    textDecoration: 'none',
    color: 'inherit'
  },
  collapse: {
    paddingLeft: theme.spacing(2)
  }
}))

const SideMenu: FunctionComponent = () => {
  const classes = useStyles()
  const intl = useIntl()
  const { instance } = useMsal()

  return (
    <Box overflow="none">
      <Link className={classes.link} to={Routes.Home}>
        <SideMenuCustomListItem button>
          <ListItemIcon>
            <Home />
          </ListItemIcon>
          <ListItemText
            primary={intl.formatMessage({
              id: 'sideMenu.rcFactory',
              description: 'Application title (Ricoh Connected Factory)',
              defaultMessage: 'RC Factory'
            })}
          />
        </SideMenuCustomListItem>
      </Link>
      <FactoryManagerSideMenu classes={classes} />
      <FactoryViewerSideMenu classes={classes} />
      <SideMenuCustomListItem button onClick={() => instance.logout()}>
        <ListItemIcon>
          <ExitToApp />
        </ListItemIcon>
        <ListItemText
          primary={intl.formatMessage({
            id: 'sideMenu.signOut',
            description: 'User sign out menu item',
            defaultMessage: 'Sign Out'
          })}
        />
      </SideMenuCustomListItem>
    </Box>
  )
}

export default SideMenu
