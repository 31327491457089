import React from 'react'
import ReactDOM from 'react-dom'
import { IntlProvider, MessageFormatElement } from 'react-intl'
import { BrowserRouter as Router, Route } from 'react-router-dom'
import {
  AuthenticationResult,
  EventType,
  InteractionType,
  PublicClientApplication
} from '@azure/msal-browser'
import { isNil } from 'lodash'
import { enableMapSet } from 'immer'
import { QueryClient, QueryClientProvider } from 'react-query'
import { CssBaseline } from '@material-ui/core'
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import { SnackbarProvider } from 'notistack'
import { MuiPickersUtilsProvider } from '@material-ui/pickers'
import { LicenseInfo } from '@material-ui/x-grid'
import { MsalProvider } from '@azure/msal-react'
import { Locale } from 'date-fns'
import DateFnsUtils from '@date-io/date-fns'
import App from './App'
import * as serviceWorkerRegistration from './serviceWorkerRegistration'
import reportWebVitals from './reportWebVitals'
import ErrorBoundary from './components/ErrorBoundry'
import { msalConfiguration, setHomeAccountId } from './api/MsalService'
import { ApiProvider, RcfactoryApi } from './api/RcfactoryApi'
import { QueryParamProvider } from 'use-query-params'
import pj from '../package.json'
import CustomThemeProvider from './components/CustomThemeContext'

// immer
enableMapSet()

// material-ui x pro
if (isNil(process.env.REACT_APP_MUI_X_PRO_LICENSE_KEY)) {
  throw Error('Environment variable REACT_APP_MUI_X_PRO_LICENSE_KEY is nil!')
}
LicenseInfo.setLicenseKey(process.env.REACT_APP_MUI_X_PRO_LICENSE_KEY)

// msal
export const queryClient = new QueryClient()
export const msalInstance = new PublicClientApplication(msalConfiguration)
msalInstance.addEventCallback((message) => {
  switch (message.eventType) {
    case EventType.LOGIN_SUCCESS:
    case EventType.ACQUIRE_TOKEN_SUCCESS:
      if (message.interactionType === InteractionType.Redirect) {
        const payload = message.payload as AuthenticationResult
        if (payload?.account) {
          setHomeAccountId(payload.account.homeAccountId)
        }
      }
  }
})

// api
export const api = new RcfactoryApi()

// eslint-disable-next-line no-console
console.log(`Connected Factories FE V${pj.version}`)

const renderApp = (
  dateLocale: Locale,
  messages: Record<string, MessageFormatElement[]>
) => {
  const root = document.querySelector('#root')

  if (!isNil(root)) {
    ReactDOM.render(
      <React.StrictMode>
        <IntlProvider locale={navigator.language} messages={messages}>
          <QueryClientProvider client={queryClient}>
            <CustomThemeProvider>
              <CssBaseline />
              <SnackbarProvider>
                <MuiPickersUtilsProvider
                  locale={dateLocale}
                  utils={DateFnsUtils}
                >
                  <DndProvider backend={HTML5Backend}>
                    <MsalProvider instance={msalInstance}>
                      <Router>
                        <QueryParamProvider ReactRouterRoute={Route}>
                          <ErrorBoundary>
                            <ApiProvider api={api}>
                              <App />
                            </ApiProvider>
                          </ErrorBoundary>
                        </QueryParamProvider>
                      </Router>
                    </MsalProvider>
                  </DndProvider>
                </MuiPickersUtilsProvider>
              </SnackbarProvider>
            </CustomThemeProvider>
          </QueryClientProvider>
        </IntlProvider>
      </React.StrictMode>,
      root
    )
  }
}

const loadDateLocale = (locale: string) => {
  switch (locale) {
    default:
      return import('date-fns/locale/en-GB')
  }
}

const loadMessages = (locale: string) => {
  switch (locale) {
    default:
      return import('./compiled-lang/en-GB.json')
  }
}

const bootstrapApplication = async () => {
  const dateLocale = await loadDateLocale(navigator.language)
  const messages = await loadMessages(navigator.language)
  renderApp(
    dateLocale.default as Locale,
    messages.default as Record<string, MessageFormatElement[]>
  )
}

bootstrapApplication()

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register()

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
