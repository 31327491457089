import React, { FunctionComponent, useMemo } from 'react'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle
} from '@material-ui/core'
import { GridColumns, GridRowsProp, XGrid } from '@material-ui/x-grid'

export interface Action {
  handler: () => void
  text: string
}

interface Props {
  actions: Action[]
  open: boolean
  title: string
  message?: string
  data: Record<string, unknown>[]
  idProperty?: string
  columnDefinitions: GridColumns
}

const GridDialog: FunctionComponent<Props> = (props: Props) => {
  const { actions, open, title, message, data, idProperty, columnDefinitions } =
    props

  const rows: GridRowsProp = useMemo(() => {
    if (!data) {
      return []
    }
    if (idProperty) {
      return data.map((item) => ({ id: Number(item[idProperty]), ...item }))
    } else {
      return data.map((item, index) => ({ id: index, ...item }))
    }
  }, [data, idProperty])

  return (
    <Dialog open={open} fullWidth={true}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent style={{ height: 400 }}>
        {message && <DialogContentText>{message}</DialogContentText>}
        <XGrid
          rows={rows}
          columns={columnDefinitions}
          pageSize={5}
          rowCount={rows.length}
          rowsPerPageOptions={[5]}
          disableSelectionOnClick
          pagination
        />
      </DialogContent>
      <DialogActions>
        {actions.map((action, index) => (
          <Button onClick={action.handler} key={index}>
            {action.text}
          </Button>
        ))}
      </DialogActions>
    </Dialog>
  )
}

export default GridDialog
