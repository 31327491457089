import React, { FunctionComponent, useMemo, useState, useContext } from 'react'
import { Link } from 'react-router-dom'
import { useMutation, useQueryClient } from 'react-query'
import { FormattedMessage, useIntl } from 'react-intl'
import { useMsal } from '@azure/msal-react'
import clsx from 'clsx'
import { isNil } from 'lodash'
import {
  AppBar,
  Avatar,
  Box,
  Checkbox,
  IconButton,
  ListItemAvatar,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  makeStyles,
  Menu,
  styled,
  Switch,
  Toolbar,
  Tooltip,
  Typography
} from '@material-ui/core'
import {
  AccountCircle,
  Business,
  ExitToApp,
  Favorite,
  FavoriteBorder,
  Menu as MenuIcon,
  PersonAdd
} from '@material-ui/icons'
import { pink } from '@material-ui/core/colors'
import { useSnackbar } from 'notistack'
import { graphScopes } from '../api/GraphService'
import { getHomeAccountId } from '../api/MsalService'
import { Paths, TenantKeys, useApi } from '../api/RcfactoryApi'
import { CustomThemeContext, ThemeType } from '../components/CustomThemeContext'
import CustomMenuItem from './NavbarCustomMenuItem'

interface StyleProps {
  drawerWidth: number
}

const useStyles = makeStyles((theme) => ({
  appBar: {
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    })
  },
  appBarShift: (props: StyleProps) => ({
    width: `calc(100% - ${props.drawerWidth}px)`,
    marginLeft: props.drawerWidth,
    borderRadius: '0px 0px 20px 0px',
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen
    })
  }),
  hide: {
    display: 'none'
  },
  link: {
    textDecoration: 'none',
    color: 'inherit',
    flexGrow: 1
  },
  title: {
    marginLeft: theme.spacing(2)
  },
  menuBackground: {
    backgroundColor: theme.custom.menuBackground,
    color: theme.custom.menuTextColor,
    '& svg': {
      color: theme.custom.menuTextColor
    }
  },
  switchMarginOverride: {
    marginLeft: '-7px'
  }
}))

interface Props {
  drawerOpen: boolean
  drawerWidth: number
  onMenuClick: () => void
  onTenantSelect: (id: number) => void
  selectedTenant?: number
  tenants: Record<string, unknown>[]
}

const NavBar: FunctionComponent<Props> = (props: Props) => {
  const {
    drawerOpen,
    drawerWidth,
    onMenuClick,
    onTenantSelect,
    selectedTenant,
    tenants
  } = props
  const intl = useIntl()
  const { instance } = useMsal()
  const api = useApi()
  const { enqueueSnackbar } = useSnackbar()
  const classes = useStyles({ drawerWidth })
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const queryClient = useQueryClient()

  const favouriteTenantMutation = useMutation(
    (favourite: number) => api.setFavouriteTenant(favourite),
    {
      onError: () => {
        enqueueSnackbar(
          intl.formatMessage({
            id: 'navBar.failedFavouriteTenant',
            description: 'Failed to set favourite tenant noticiation text',
            defaultMessage: 'Failed to set favourite tenant!'
          }),
          {
            variant: 'error'
          }
        )
      },
      onSuccess: () => {
        enqueueSnackbar(
          intl.formatMessage({
            id: 'navBar.successfulFavouriteTenant',
            description: 'Successfully set favorutie tenant notification text',
            defaultMessage: 'Successfully set favourite tenant!'
          }),
          {
            variant: 'success'
          }
        )
        queryClient.invalidateQueries(Paths.Tenant)
      }
    }
  )

  const highlightedTenant = useMemo(() => {
    return tenants.find((tenant) => tenant[TenantKeys.Id] === selectedTenant)
  }, [selectedTenant, tenants])

  const menuHandleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const menuHandleClose = () => {
    setAnchorEl(null)
  }

  const handleFavoriteClick = (favourite: number) => {
    favouriteTenantMutation.mutate(favourite)
  }

  const homeAccountId = getHomeAccountId()

  const { currentTheme, setTheme } = useContext(CustomThemeContext)
  const isDark = Boolean(currentTheme === ThemeType.Dark)

  const handleThemeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { checked } = event.target
    if (checked) {
      setTheme(ThemeType.Dark)
    } else {
      setTheme(ThemeType.Light)
    }
  }

  const MaterialUISwitch = styled(Switch)(() => ({
    width: 62,
    height: 34,
    padding: 7,
    '& .MuiSwitch-switchBase': {
      margin: 1,
      padding: 0,
      transform: 'translateX(6px)',
      '&.Mui-checked': {
        color: '#fff',
        transform: 'translateX(22px)',
        '& .MuiSwitch-thumb:before': {
          backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" viewBox="0 0 20 20"><path fill="${encodeURIComponent(
            '#fff'
          )}" d="M4.2 2.5l-.7 1.8-1.8.7 1.8.7.7 1.8.6-1.8L6.7 5l-1.9-.7-.6-1.8zm15 8.3a6.7 6.7 0 11-6.6-6.6 5.8 5.8 0 006.6 6.6z"/></svg>')`
        },
        '& + .MuiSwitch-track': {
          opacity: 1,
          backgroundColor:
            currentTheme === ThemeType.Dark ? '#8796A5' : '#aab4be'
        }
      }
    },
    '& .MuiSwitch-thumb': {
      backgroundColor: currentTheme === ThemeType.Dark ? '#003892' : '#001e3c',
      width: 32,
      height: 32,
      '&:before': {
        content: "''",
        position: 'absolute',
        width: '100%',
        height: '100%',
        left: 0,
        top: 0,
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" viewBox="0 0 20 20"><path fill="${encodeURIComponent(
          '#fff'
        )}" d="M9.305 1.667V3.75h1.389V1.667h-1.39zm-4.707 1.95l-.982.982L5.09 6.072l.982-.982-1.473-1.473zm10.802 0L13.927 5.09l.982.982 1.473-1.473-.982-.982zM10 5.139a4.872 4.872 0 00-4.862 4.86A4.872 4.872 0 0010 14.862 4.872 4.872 0 0014.86 10 4.872 4.872 0 0010 5.139zm0 1.389A3.462 3.462 0 0113.471 10a3.462 3.462 0 01-3.473 3.472A3.462 3.462 0 016.527 10 3.462 3.462 0 0110 6.528zM1.665 9.305v1.39h2.083v-1.39H1.666zm14.583 0v1.39h2.084v-1.39h-2.084zM5.09 13.928L3.616 15.4l.982.982 1.473-1.473-.982-.982zm9.82 0l-.982.982 1.473 1.473.982-.982-1.473-1.473zM9.305 16.25v2.083h1.389V16.25h-1.39z"/></svg>')`
      }
    },
    '& .MuiSwitch-track': {
      opacity: 1,
      backgroundColor: currentTheme === ThemeType.Dark ? '#8796A5' : '#aab4be',
      borderRadius: 20 / 2
    }
  }))

  return (
    <>
      <AppBar
        position="static"
        className={clsx(classes.appBar, {
          [classes.appBarShift]: drawerOpen
        })}
      >
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={onMenuClick}
            className={clsx(drawerOpen && classes.hide)}
          >
            <MenuIcon />
          </IconButton>
          <Link className={classes.link} to="/">
            <Typography className={classes.title} variant="h6">
              <FormattedMessage
                id="navbar.rcFactory"
                description="NavBar application title, Ricoh Connected Factory"
                defaultMessage="RC Factory"
              />
            </Typography>
          </Link>
          <Typography variant="subtitle1">
            {!isNil(highlightedTenant) &&
              String(highlightedTenant[TenantKeys.Name])}
          </Typography>
          <IconButton color="inherit" onClick={menuHandleClick}>
            <AccountCircle />
          </IconButton>
        </Toolbar>
      </AppBar>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        keepMounted
        open={Boolean(anchorEl)}
        onClose={menuHandleClose}
        PaperProps={{ classes: { rounded: classes.menuBackground } }}
      >
        {instance.getAllAccounts().map((account, index) => {
          const isSelected = account.homeAccountId === homeAccountId
          return (
            <CustomMenuItem
              key={index}
              onClick={
                isSelected
                  ? undefined
                  : () =>
                      instance.loginRedirect({
                        account: account,
                        scopes: graphScopes
                      })
              }
              selected={isSelected}
            >
              <ListItemIcon>
                <Avatar />
              </ListItemIcon>
              {account.username}
            </CustomMenuItem>
          )
        })}
        {tenants.map((tenant, index) => {
          const favouriteEnabled = tenants.length > 1
          const id = Number(tenant[TenantKeys.Id])
          const name = String(tenant[TenantKeys.Name])
          const isFavourite = Boolean(tenant[TenantKeys.Favourite])
          return (
            <CustomMenuItem
              key={index}
              onClick={
                tenant[TenantKeys.Id] === selectedTenant
                  ? undefined
                  : () => onTenantSelect(id)
              }
              selected={tenant[TenantKeys.Id] === selectedTenant}
            >
              <ListItemAvatar>
                <Avatar>
                  <Business />
                </Avatar>
              </ListItemAvatar>
              <Box marginRight={favouriteEnabled ? 2 : 0}>
                <ListItemText primary={name} />
              </Box>
              {favouriteEnabled && (
                <ListItemSecondaryAction>
                  <Tooltip
                    title={
                      isFavourite
                        ? intl.formatMessage({
                            id: 'navBar.favourite',
                            description:
                              'Favourite checked checkbox tooltip title.',
                            defaultMessage: 'Favourite'
                          })
                        : intl.formatMessage({
                            id: 'navBar.setFavourite',
                            description:
                              'Favourite unchecked checkbox tooltip title.',
                            defaultMessage: 'Set favourite'
                          })
                    }
                  >
                    <Checkbox
                      aria-label="set-favourite"
                      checked={isFavourite}
                      checkedIcon={<Favorite />}
                      style={isFavourite ? { color: pink.A400 } : undefined}
                      edge="end"
                      icon={<FavoriteBorder />}
                      onClick={
                        !isFavourite ? () => handleFavoriteClick(id) : undefined
                      }
                    />
                  </Tooltip>
                </ListItemSecondaryAction>
              )}
            </CustomMenuItem>
          )
        })}
        <CustomMenuItem onClick={() => instance.logoutRedirect()}>
          <ListItemIcon>
            <ExitToApp />
          </ListItemIcon>
          <FormattedMessage
            id="navbar.signOut"
            description="NavBar user sign out menu item"
            defaultMessage="Sign Out"
          />
        </CustomMenuItem>
        <CustomMenuItem
          onClick={() =>
            instance.loginRedirect({
              prompt: 'select_account',
              scopes: graphScopes
            })
          }
        >
          <ListItemIcon>
            <PersonAdd />
          </ListItemIcon>
          <FormattedMessage
            id="navbar.changeAccount"
            description="NavBar user sign in with different account menu item"
            defaultMessage="Sign in with a different account"
          />
        </CustomMenuItem>
        <CustomMenuItem>
          <MaterialUISwitch
            checked={isDark}
            onChange={handleThemeChange}
            color="primary"
            className={classes.switchMarginOverride}
          />
          <FormattedMessage
            id="navbar.darkMode"
            description="NavBar user activate dark mode"
            defaultMessage="Dark Mode"
          />
        </CustomMenuItem>
      </Menu>
    </>
  )
}

export default NavBar
