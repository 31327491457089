import React, { FunctionComponent, useMemo } from 'react'
import { useIntl } from 'react-intl'
import { AxisBottom, AxisLeft } from '@visx/axis'
import { localPoint } from '@visx/event'
import { Group } from '@visx/group'
import { LegendItem, LegendLabel, LegendOrdinal } from '@visx/legend'
import ParentSize from '@visx/responsive/lib/components/ParentSize'
import { coerceNumber, scaleBand, scaleOrdinal, scaleUtc } from '@visx/scale'
import { Bar } from '@visx/shape'
import { useTooltip, useTooltipInPortal } from '@visx/tooltip'
import { Zoom } from '@visx/zoom'
import { ProvidedZoom, TransformMatrix } from '@visx/zoom/lib/types'
import { schemeTableau10 } from 'd3-scale-chromatic'
import { differenceInMilliseconds, parseISO } from 'date-fns'
import prettyMilliseconds from 'pretty-ms'
import { isNil, sortBy, uniq } from 'lodash'
import {
  Box,
  makeStyles,
  Paper,
  Typography,
  useTheme
} from '@material-ui/core'
import { ZoomIn, ZoomOut, ZoomOutMap } from '@material-ui/icons'
import Toolbar from '../Toolbar'

const useStyles = makeStyles(theme => ({
  appGraph: {
    display: 'flex',
    flex: 1,
    overflow: 'hidden'
  },
  legend: {
    lineHeight: '0.9em',
    fontSize: '10px',
    fontFamily: theme.typography.fontFamily,
    padding: '10px 10px',
    margin: '5px 5px',
    display: 'flex',
    justifyContent: 'center'
  },
  axis: {
    fontFamily: theme.typography.fontFamily,
    userSelect: 'none',
    MozUserSelect: 'none',
    WebkitUserSelect: 'none',
    msUserSelect: 'none'
  }
}))

interface Props {
  bandwidth?: number,
  categoryProperty?: string,
  data: Record<string, unknown>[],
  durationProperty?: string,
  endProperty: string,
  height: number,
  margin?: { top: number, left: number, right: number, bottom: number }
  nameProperty: string,
  startProperty: string,
  title?: string,
  width: number
}

interface ZoomableProps extends Props {
  zoom: ProvidedZoom & {
    initialTransformMatrix: TransformMatrix;
    transformMatrix: TransformMatrix;
    isDragging: boolean;
  }
}

type Interval = {
  start: Date,
  end: Date,
  duration: number,
  category?: string
}

type TooltipData = { name: string } & Interval

const getMinMax = (vals: (number | { valueOf(): number })[]) => {
  const numericVals = vals.map(coerceNumber)
  return [Math.min(...numericVals), Math.max(...numericVals)]
}

const defaultMargin = { top: 0, left: 200, right: 25, bottom: 25 }
const maxLeftTickLength = 30

const Timeline: FunctionComponent<ZoomableProps> = (props: ZoomableProps) => {
  const {
    bandwidth,
    categoryProperty,
    data,
    durationProperty,
    endProperty,
    height,
    margin = defaultMargin,
    nameProperty,
    startProperty,
    title,
    width,
    zoom
  } = props
  const classes = useStyles()
  const theme = useTheme()
  const {
    hideTooltip,
    showTooltip,
    tooltipData,
    tooltipLeft,
    tooltipOpen,
    tooltipTop
  } = useTooltip<TooltipData>()
  const intl = useIntl()
  const { containerRef, TooltipInPortal } = useTooltipInPortal({ scroll: true })

  const combinedData = useMemo(() => {
    const combined = new Map<string, Interval[]>()
    const sorted = sortBy(data, nameProperty)
    for (const d of sorted) {
      const name = String(d[nameProperty])
      if (!combined.get(name)) {
        combined.set(name, [])
      }
      const start = parseISO(String(d[startProperty]))
      const end = parseISO(String(d[endProperty]))
      let duration
      if (!isNil(durationProperty)) {
        duration = Number(d[durationProperty])
      } else {
        duration = differenceInMilliseconds(end, start)
      }
      const category = !isNil(categoryProperty)
        ? String(d[categoryProperty])
        : undefined
      combined.get(name)?.push({
        start: start,
        end: end,
        duration: duration,
        category: category
      })
    }
    return combined
  }, [
    categoryProperty,
    data,
    durationProperty,
    endProperty,
    nameProperty,
    startProperty
  ])

  const activeHeight = useMemo(() => {
    if (!isNil(bandwidth)) {
      if (!isNil(combinedData)) {
        return (combinedData.size * bandwidth) + margin.top + margin.bottom
      }
    } else {
      return height
    }
  }, [bandwidth, combinedData, height, margin.bottom, margin.top])

  const [min, max] = useMemo(() =>
    getMinMax(
      data.flatMap(d => (
        [parseISO(String(d[startProperty])), parseISO(String(d[endProperty]))]
      ))
    ), [data, endProperty, startProperty])

  const xMax = width - margin.left - margin.right

  const xScale = useMemo(() =>
    scaleUtc({
      domain: [min, max],
      range: [0, xMax]
    }), [max, min, xMax])

  const xScaleTransformed = useMemo(() => {
    const minScaled = xScale(min)
    const maxScaled = xScale(max)
    if (!isNil(minScaled) && !isNil(maxScaled)) {
      return scaleUtc({
        domain: [
          xScale.invert((minScaled - zoom.transformMatrix.translateX) / zoom.transformMatrix.scaleX),
          xScale.invert((maxScaled - zoom.transformMatrix.translateX) / zoom.transformMatrix.scaleX)
        ],
        range: [0, xMax]
      })
    }
  }, [max, min, xMax, xScale, zoom.transformMatrix.scaleX, zoom.transformMatrix.translateX])

  const yMax = useMemo(() => {
    if (!isNil(activeHeight)) {
      return activeHeight - margin.top - margin.bottom
    }
  }, [activeHeight, margin.bottom, margin.top])

  const yScale = useMemo(() => {
    if (!isNil(yMax)) {
      return scaleBand<string>({
        range: [0, yMax],
        round: true,
        domain: Array.from(combinedData.keys()),
        padding: 0.4
      })
    }
  }, [combinedData, yMax])

  const categories = useMemo(() => {
    if (!isNil(categoryProperty)) {
      return uniq(data.map(d => String(d[categoryProperty])))
    }
  }, [data, categoryProperty])

  const colourScale = useMemo(() => {
    if (!isNil(categories)) {
      return scaleOrdinal({
        domain: categories,
        range: Array.from(schemeTableau10)
      })
    }
  }, [categories])

  const handleZoomInClick = () => {
    zoom.scale({ scaleX: 1.2, scaleY: 1.2 })
  }

  const handleZoomOutClick = () => {
    zoom.scale({ scaleX: 0.8, scaleY: 0.8 })
  }

  const handleZoomResetClick = () => {
    zoom.clear()
  }

  const leftTickFormat = (name: string) => {
    if (name.length > maxLeftTickLength) {
      return name.substring(0, maxLeftTickLength - 3) + '...'
    }
    return name
  }

  return (
    <>
      {
        xScaleTransformed &&
        yScale &&
        <Box display="relative">
          <Toolbar buttons={
            [{
              icon: ZoomIn,
              onClick: handleZoomInClick,
              tooltip: 'Zoom in',
              visible: true
            }, {
              icon: ZoomOut,
              onClick: handleZoomOutClick,
              tooltip: 'Zoom out',
              visible: true
            }, {
              icon: ZoomOutMap,
              onClick: handleZoomResetClick,
              tooltip: 'Zoom reset',
              visible: true
            }]
          }
          title={title}
          />
          { categoryProperty && colourScale && (
            <div className={classes.legend}>
              <LegendOrdinal scale={colourScale}>
                { labels => (
                  <Box
                    display="flex"
                    flexDirection="row"
                  >
                    { labels.map((label, i) => (
                      <LegendItem
                        key={`legend-category${i}`}
                        margin="0 5px"
                      >
                        <svg width={15} height={15}>
                          <rect fill={label.value} width={15} height={15}/>
                        </svg>
                        <LegendLabel align="left" margin="0 0 0 4px">
                          {label.text}
                        </LegendLabel>
                      </LegendItem>
                    ))}
                  </Box>
                )}
              </LegendOrdinal>
            </div>
          )}
          <svg
            width={width}
            height={activeHeight}
            ref={containerRef}
            style={{ cursor: zoom.isDragging ? 'grabbing' : 'grab' }}
          >
            <Group left={margin.left} top={margin.top}>
              <rect
                width={width}
                height={activeHeight}
                rx={14}
                fill="transparent"
                onTouchStart={zoom.dragStart}
                onTouchMove={zoom.dragMove}
                onTouchEnd={zoom.dragEnd}
                onMouseDown={zoom.dragStart}
                onMouseMove={zoom.dragMove}
                onMouseUp={zoom.dragEnd}
                onMouseLeave={() => {
                  if (zoom.isDragging) zoom.dragEnd()
                }}
                onDoubleClick={event => {
                  const point = localPoint(event) || { x: 0, y: 0 }
                  zoom.scale({ scaleX: 1.1, scaleY: 1.0, point })
                }}
              />
              { Array.from(combinedData).map(c => {
                return c[1].map((v, index) => {
                  let x1 = xScaleTransformed(v.start)
                  let x2 = xScaleTransformed(v.end)
                  const y = yScale(c[0])
                  let width: number
                  if (isNil(x1) || isNil(x2)) { return undefined }
                  if (x1 > xMax || x2 < 0) { return undefined }
                  if (x1 < 0) { x1 = 0 }
                  if (x2 > xMax) { x2 = xMax }
                  width = x2 - x1
                  if (width < 1) { width = 1 }
                  return (
                    <Bar
                      key={`bar-${c[0]}-${index}`}
                      x={x1}
                      y={y}
                      width={width}
                      height={yScale.bandwidth()}
                      fill={
                        (!isNil(colourScale) && !isNil(v.category))
                          ? colourScale(v.category)
                          : theme.palette.secondary.main
                      }
                      onTouchStart={zoom.dragStart}
                      onTouchMove={zoom.dragMove}
                      onTouchEnd={zoom.dragEnd}
                      onMouseDown={zoom.dragStart}
                      onMouseMove={event => {
                        zoom.dragMove(event)
                        const eventSvgCoords = localPoint(event)
                        showTooltip({
                          tooltipData: {
                            name: c[0],
                            start: v.start,
                            end: v.end,
                            duration: v.duration,
                            category: v.category
                          },
                          tooltipTop: eventSvgCoords?.y,
                          tooltipLeft: eventSvgCoords?.x
                        })
                      }}
                      onMouseUp={zoom.dragEnd}
                      onMouseLeave={() => hideTooltip()}
                      style={{ cursor: zoom.isDragging ? 'grabbing' : 'pointer' }}
                    />
                  )
                })
              })}
              <AxisLeft
                axisClassName={classes.axis}
                tickFormat={leftTickFormat}
                hideTicks
                scale={yScale}
                stroke={'#000000'}
                tickStroke={'#000000'}
                tickLabelProps={() => ({
                  fill: '#000000',
                  fontSize: 11,
                  textAnchor: 'end',
                  dy: '0.33em'
                })}
                tickValues={Array.from(combinedData.keys())}
              />
              <AxisBottom
                axisClassName={classes.axis}
                top={yMax}
                scale={xScaleTransformed}
                stroke={'#000000'}
              />
            </Group>
          </svg>
          { tooltipOpen && tooltipData && (
            <TooltipInPortal left={tooltipLeft} top={tooltipTop}>
              <Typography component="div">
                <Box fontWeight="fontWeightBold">
                  {tooltipData.name}
                </Box>
                <Box>
                  {`Start: ${intl.formatDate(tooltipData.start, {
                    dateStyle: 'short',
                    timeStyle: 'medium'
                  })}`}
                </Box>
                <Box>
                  {`End: ${intl.formatDate(tooltipData.end, {
                    dateStyle: 'short',
                    timeStyle: 'medium'
                  })}`}
                </Box>
                <Box>
                  {`Duration: ${prettyMilliseconds(tooltipData.duration)}`}
                </Box>
                { tooltipData.category &&
                  <Box>
                    {`Category: ${tooltipData.category}`}
                  </Box>
                }
              </Typography>
            </TooltipInPortal>
          )}
        </Box>
      }
    </>
  )
}

const ZoomableTimeline: FunctionComponent<Props> =
(props: Props) => {
  const {
    width,
    height
  } = props

  const initialTransform = {
    scaleX: 1,
    scaleY: 1,
    translateX: 0,
    translateY: 0,
    skewX: 0,
    skewY: 0
  }

  const wheelDelta = (event: React.WheelEvent<Element> | WheelEvent) => {
    return -event.deltaY > 0
      ? { scaleX: 1.1, scaleY: 1.0 }
      : { scaleX: 0.9, scaleY: 1.0 }
  }

  return (
    <Zoom
      width={width}
      height={height}
      scaleXMin={1}
      transformMatrix={initialTransform}
      wheelDelta={wheelDelta}
    >
      { zoom =>
        <Timeline {...props} zoom={zoom}/>
      }
    </Zoom>
  )
}

interface ResponsiveProps extends Omit<Props, 'height' | 'width'> {
  height?: number,
  width?: number
}

const ResponsiveTimeline: FunctionComponent<ResponsiveProps> =
(props: ResponsiveProps) => {
  const {
    height,
    width
  } = props
  const classes = useStyles()

  return (
    <Paper className={classes.appGraph}>
      <ParentSize>
        {({ width: visWidth, height: visHeight }) => (
          <ZoomableTimeline
            {...props}
            width={width ?? visWidth}
            height={height ?? visHeight}
          />
        )}
      </ParentSize>
    </Paper>
  )
}

export default ResponsiveTimeline
