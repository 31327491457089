import React, { FunctionComponent, useEffect, useState } from 'react'
import {
  Link,
  Redirect,
  Route,
  Switch,
  useHistory,
  useRouteMatch
} from 'react-router-dom'
import { useIntl } from 'react-intl'
import { Collapse, ListItemText } from '@material-ui/core'
import { ExpandLess, ExpandMore } from '@material-ui/icons'
import Contents from '../../../components/Contents'
import Nodes from './Nodes'
import TemplateProperties from './TemplateProperties'
import PropertyMapping from './PropertyMapping'
import NodeTemplates from './NodeTemplates'
import { Routes as AppRoutes } from '../../../App'
import { Routes as HomeRoutes } from '../../Home'
import { Routes as ManagerRoutes } from '../FactoryManager'
import nodes from '../../../images/nodes_card.jpg'
import properties from '../../../images/properties_card.jpg'
import mapping from '../../../images/mapping_card.jpg'
import templates from '../../../images/templates_card.jpg'
import hero from '../../../images/factory_configuration_hero.jpg'
import SideMenuCustomListItem from '../../../components/SideMenuCustomListItem'

export enum Routes {
  Nodes = '/nodes',
  TemplateProperties = '/template-properties',
  PropertyMapping = '/property-mapping',
  Templates = '/templates'
}

interface SideMenuProps {
  classes: Record<string, string | undefined>
}

export const FactoryConfigurationSideMenu: FunctionComponent<SideMenuProps> = (
  props: SideMenuProps
) => {
  const { classes } = props
  const configurationMatch = useRouteMatch({
    path: HomeRoutes.FactoryManager + ManagerRoutes.FactoryConfiguration
  })
  const history = useHistory()
  const intl = useIntl()
  const [configurationOpen, setConfigurationOpen] = useState(false)

  const handleConfigurationClick = () => {
    if (!configurationMatch) {
      setConfigurationOpen(!configurationOpen)
    } else {
      history.push(
        HomeRoutes.FactoryManager + ManagerRoutes.FactoryConfiguration
      )
    }
  }

  useEffect(() => {
    setConfigurationOpen(Boolean(configurationMatch))
  }, [configurationMatch])

  return (
    <>
      <SideMenuCustomListItem button onClick={handleConfigurationClick}>
        <ListItemText
          primary={intl.formatMessage({
            id: 'factoryConfiguration.sideMenu.factoryConfiguration',
            description: 'Factory configuration side menu item',
            defaultMessage: 'Factory Configuration'
          })}
        />
        {!configurationMatch && (
          <>{configurationOpen ? <ExpandLess /> : <ExpandMore />}</>
        )}
      </SideMenuCustomListItem>
      <Collapse
        in={configurationOpen}
        timeout="auto"
        unmountOnExit
        className={classes.collapse}
      >
        <Link
          className={classes.link}
          to={
            HomeRoutes.FactoryManager +
            ManagerRoutes.FactoryConfiguration +
            Routes.Nodes
          }
        >
          <SideMenuCustomListItem button>
            <ListItemText
              primary={intl.formatMessage({
                id: 'factoryConfiguration.sideMenu.nodes',
                description: 'Factory configuration, nodes side menu item',
                defaultMessage: 'Nodes'
              })}
            />
          </SideMenuCustomListItem>
        </Link>
        <Link
          className={classes.link}
          to={
            HomeRoutes.FactoryManager +
            ManagerRoutes.FactoryConfiguration +
            Routes.Templates
          }
        >
          <SideMenuCustomListItem button>
            <ListItemText
              primary={intl.formatMessage({
                id: 'factoryConfiguration.sideMenu.templates',
                description: 'Factory configuration, templates side menu item',
                defaultMessage: 'Templates'
              })}
            />
          </SideMenuCustomListItem>
        </Link>
        <Link
          className={classes.link}
          to={
            HomeRoutes.FactoryManager +
            ManagerRoutes.FactoryConfiguration +
            Routes.PropertyMapping
          }
        >
          <SideMenuCustomListItem button>
            <ListItemText
              primary={intl.formatMessage({
                id: 'factoryConfiguration.sideMenu.propertyMapping',
                description:
                  'Factory configuration, property mapping side menu item',
                defaultMessage: 'Property Mapping'
              })}
            />
          </SideMenuCustomListItem>
        </Link>
        <Link
          className={classes.link}
          to={
            HomeRoutes.FactoryManager +
            ManagerRoutes.FactoryConfiguration +
            Routes.TemplateProperties
          }
        >
          <SideMenuCustomListItem button>
            <ListItemText
              primary={intl.formatMessage({
                id: 'factoryConfiguration.sideMenu.templateProperties',
                description:
                  'Factory configuration, template properties side menu item',
                defaultMessage: 'Template Properties'
              })}
            />
          </SideMenuCustomListItem>
        </Link>
      </Collapse>
    </>
  )
}

function FactoryConfigurationContent() {
  const intl = useIntl()
  const { path } = useRouteMatch()

  const items = [
    {
      image: nodes,
      route: path + Routes.Nodes,
      text: intl.formatMessage({
        id: 'factoryConfiguration.nodesDescription',
        description: 'Factory configuration page, nodes description',
        defaultMessage: 'Edit the Node Hierarchy and Node Properties.'
      }),
      title: intl.formatMessage({
        id: 'factoryConfiguration.nodes',
        description: 'Factory configuration page, nodes item',
        defaultMessage: 'Nodes'
      })
    },
    {
      image: properties,
      route: path + Routes.TemplateProperties,
      text: intl.formatMessage({
        id: 'factoryConfiguration.templatePropertiesDescription',
        description:
          'Factory configuration page, template properties description',
        defaultMessage: 'Create reusable Node Template Property definitions.'
      }),
      title: intl.formatMessage({
        id: 'factoryConfiguration.templateProperties',
        description: 'Factory configuration page, template properties item',
        defaultMessage: 'Template Properties'
      })
    },
    {
      image: mapping,
      route: path + Routes.PropertyMapping,
      text: intl.formatMessage({
        id: 'factoryConfiguration.propertyMappingDescription',
        description: 'Factory configuration page, property mapping description',
        defaultMessage: 'Assign Node Template Properties to Node Templates.'
      }),
      title: intl.formatMessage({
        id: 'factoryConfiguration.propertyMapping',
        description: 'Factory configuration page, property mapping description',
        defaultMessage: 'Property Mapping'
      })
    },
    {
      image: templates,
      route: path + Routes.Templates,
      text: intl.formatMessage({
        id: 'factoryConfiguration.templatesDescription',
        description: 'Factory configuration page, templates description',
        defaultMessage: 'Create and edit Node Templates.'
      }),
      title: intl.formatMessage({
        id: 'factoryConfiguration.templates',
        description: 'Factory configuration page, templates item',
        defaultMessage: 'Templates'
      })
    }
  ]

  return (
    <Contents
      hero={hero}
      items={items}
      title={intl.formatMessage({
        id: 'factoryConfiguration.factoryConfiguration',
        description: 'Factory configuration page title',
        defaultMessage: 'Factory Configuration'
      })}
    />
  )
}

const FactoryConfiguration: FunctionComponent = () => {
  const { path } = useRouteMatch()

  return (
    <Switch>
      <Route path={path + Routes.Nodes}>
        <Nodes />
      </Route>
      <Route exact path={path + Routes.TemplateProperties}>
        <TemplateProperties />
      </Route>
      <Route exact path={path + Routes.PropertyMapping}>
        <PropertyMapping />
      </Route>
      <Route exact path={path + Routes.Templates}>
        <NodeTemplates />
      </Route>
      <Route exact path={path}>
        <FactoryConfigurationContent />
      </Route>
      <Redirect to={AppRoutes.PageNotFound} />
    </Switch>
  )
}

export default FactoryConfiguration
