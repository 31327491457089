import { useEffect, useState } from 'react'
import { isNil } from 'lodash'
import { createObjectFromProps, Property } from '../api/RcfactoryApi'
import { useQueryParam } from 'use-query-params'

enum ParamKeys {
  ConfigurationMode = 'configure'
}

export enum ConfigurationMode {
  Create = 'create',
  Edit = 'edit'
}

interface Configurator {
  clear: () => void
  create: (properties: Property[], initial?: Record<string, unknown>) => void
  readonly data?: Record<string, unknown>
  edit: (initial: Record<string, unknown>) => void
  mode: ConfigurationMode | null
  update: (updates: Record<string, unknown>) => void
}

export default function useConfigure(key: string): Configurator {
  const [data, setData] = useState<Record<string, unknown>>()
  const [mode, setMode] = useQueryParam(`${key}_${ParamKeys.ConfigurationMode}`)

  const clear = () => {
    setData(undefined)
    setMode(undefined)
  }

  useEffect(() => {
    if (isNil(mode) && !isNil(data)) {
      setData(undefined)
    }
  }, [data, mode])

  const create = (
    properties: Property[],
    initial?: Record<string, unknown>
  ) => {
    setData(createObjectFromProps(properties, initial))
    setMode(ConfigurationMode.Create)
  }

  const edit = (initial: Record<string, unknown>) => {
    setData(initial)
    setMode(ConfigurationMode.Edit)
  }

  const update = (updates: Record<string, unknown>) => {
    setData({ ...data, ...updates })
  }

  return {
    clear,
    create,
    data,
    edit,
    mode: mode as ConfigurationMode,
    update
  }
}
