import React, { useState } from 'react'
import { Box, createStyles, WithStyles, withStyles } from '@material-ui/core'
import clsx from 'clsx'
import DetectableOverflow from 'react-detectable-overflow'

const styles = () =>
  createStyles({
    expand: {
      zIndex: 100,
      position: 'absolute',
      backgroundColor: '#f6f6f6'
    }
  })

interface HoverLabelProps extends WithStyles<typeof styles> {
  label: string
}

const HoverLabel = withStyles(styles)((props: HoverLabelProps) => {
  const { label, classes } = props
  const [clicked, setClicked] = useState<boolean>(false)
  const [isOverflown, setIsOverflown] = useState<boolean>(false)

  return (
    <DetectableOverflow
      tag="div"
      onChange={(isOverFlown: boolean) => setIsOverflown(isOverFlown)}
      className={clsx({ [classes.expand]: clicked })}
    >
      <Box
        onMouseEnter={() => {
          if (isOverflown) setClicked(!clicked)
        }}
        onMouseLeave={() => setClicked(false)}
        component="span"
        style={{ height: '50px' }}
      >
        {label}
      </Box>
    </DetectableOverflow>
  )
})

export default HoverLabel
