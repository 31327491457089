import React, { FunctionComponent } from 'react'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { useIntl } from 'react-intl'
import { NumericArrayParam, useQueryParam } from 'use-query-params'
import { isNil } from 'lodash'
import { Box, Container, Grid } from '@material-ui/core'
import { useSnackbar } from 'notistack'
import Table from '../../../components/Table/Table'
import PageLoading from '../../../components/Loading/PageLoading'
import TransferList from '../../../components/TransferList'
import usePagination from '../../../hooks/usePagination'
import useSort from '../../../hooks/useSort'
import {
  ExpressionOperator,
  LogicalOperator,
  NodeTemplatePropertyKeys,
  NodeTemplatePropertyMappingKeys,
  NodeTemplateKeys,
  Paths,
  useApi
} from '../../../api/RcfactoryApi'

enum ParamKeys {
  TemplateId = 'templateId'
}

const PropertyMapping: FunctionComponent = () => {
  const intl = useIntl()
  const api = useApi()
  const pagination = usePagination()
  const sort = useSort(NodeTemplateKeys.Id)
  const [selected, setSelected] = useQueryParam(
    ParamKeys.TemplateId, NumericArrayParam
  )
  const { enqueueSnackbar } = useSnackbar()
  const queryClient = useQueryClient()

  const mappingDescQuery = useQuery(
    Paths.NodeTemplatePropertyMapping + Paths.UtilsGetDesc,
    () => api.getDesc({
      path: Paths.NodeTemplatePropertyMapping
    }), {
      onError: () => enqueueSnackbar(
        intl.formatMessage({
          id: 'propertyMapping.failedMappingSchema',
          description: 'Node template property mapping, fetch mapping schema error message',
          defaultMessage: 'Failed to get Node Template Property Mapping Schema!'
        }), {
          variant: 'error'
        }
      )
    }
  )

  const mappingQuery = useQuery(
    [Paths.NodeTemplatePropertyMapping, selected],
    () => {
      if (isNil(selected)) {
        throw Error('Should not query mappings if selected is nil!')
      }
      return api.getList({
        modelExpressions: {
          Expressions: [{
            Prop: NodeTemplatePropertyMappingKeys.TemplateId,
            Op: ExpressionOperator.Equal,
            Val: selected[0]
          }]
        },
        path: Paths.NodeTemplatePropertyMapping
      })
    }, {
      enabled: selected?.length === 1,
      onError: () => enqueueSnackbar(
        intl.formatMessage({
          id: 'propertyMapping.failedMappings',
          description: 'Node template property mapping, fetch mappings error message',
          defaultMessage: 'Failed to get Node Template Property Mappings!'
        }), {
          variant: 'error'
        }
      )
    }
  )

  const mappingCreateMutation = useMutation(
    (items: Record<string, unknown>[]) => api.create({
      items: items,
      path: Paths.NodeTemplatePropertyMapping
    }), {
      onError: () => {
        enqueueSnackbar(
          intl.formatMessage({
            id: 'propertyMapping.failedCreate',
            description: 'Node template property mapping, create mapping error message',
            defaultMessage: 'Failed to create Node Template Property Mapping!'
          }), {
            variant: 'error'
          }
        )
      },
      onSuccess: () => {
        enqueueSnackbar(
          intl.formatMessage({
            id: 'propertyMapping.successfulCreate',
            description: 'Node template property mapping, create mapping success message',
            defaultMessage: 'Successfully created Node Template Property Mapping!'
          }), {
            variant: 'success'
          }
        )
        queryClient.invalidateQueries(Paths.NodeTemplatePropertyMapping)
      }
    }
  )

  const mappingDeleteMutation = useMutation(
    (ids: number[]) => api.delete({
      ids: ids,
      path: Paths.NodeTemplatePropertyMapping
    }), {
      onError: () => {
        enqueueSnackbar(
          intl.formatMessage({
            id: 'propertyMapping.failedDelete',
            description: 'Node template property mapping, delete mapping error message',
            defaultMessage: 'Failed to delete Node Template Property Mapping!'
          }), {
            variant: 'error'
          }
        )
      },
      onSuccess: () => {
        enqueueSnackbar(
          intl.formatMessage({
            id: 'propertyMapping.successfulDelete',
            description: 'Node template property mapping, delete mapping success message',
            defaultMessage: 'Successfully deleted Node Template Property Mapping!'
          }), {
            variant: 'success'
          }
        )
        queryClient.invalidateQueries(Paths.NodeTemplatePropertyMapping)
      }
    }
  )

  const propertiesDescQuery = useQuery(
    Paths.NodeTemplateProperties + Paths.UtilsGetDesc,
    () => api.getDesc({
      path: Paths.NodeTemplateProperties
    }), {
      onError: () => enqueueSnackbar(
        intl.formatMessage({
          id: 'propertyMapping.failedPropertiesSchema',
          description: 'Node template property mapping, fetch properties schema error message',
          defaultMessage: 'Failed to get Node Template Property Schema!'
        }), {
          variant: 'error'
        }
      )
    }
  )

  const propertiesQuery = useQuery(
    [Paths.NodeTemplateProperties, mappingQuery.data?.Items],
    () => api.getList({
      modelExpressions: !isNil(mappingQuery.data)
        ? {
          Expressions: mappingQuery.data?.Items.map(
            (mapping: Record<string, unknown>) => {
              return {
                Prop: NodeTemplatePropertyKeys.Id,
                Op: ExpressionOperator.NotEqual,
                Val: mapping[NodeTemplatePropertyMappingKeys.NodeTempPropId]
              }
            }
          ),
          Operator: LogicalOperator.And
        }
        : undefined,
      path: Paths.NodeTemplateProperties
    }), {
      enabled: !!mappingQuery.data?.Items,
      onError: () => enqueueSnackbar(
        intl.formatMessage({
          id: 'propertyMapping.failedProperties',
          description: 'Fetch properties error message',
          defaultMessage: 'Failed to get Nodes Template Properties!'
        }), {
          variant: 'error'
        }
      )
    }
  )

  const templatesDescQuery = useQuery(
    Paths.NodeTemplates + Paths.UtilsGetDesc,
    () => api.getDesc({
      path: Paths.NodeTemplates
    }), {
      onError: () => enqueueSnackbar(
        intl.formatMessage({
          id: 'propertyMapping.failedTemplateSchema',
          description: 'Fetch template schema error message',
          defaultMessage: 'Failed to get Node Template Schema!'
        }), {
          variant: 'error'
        }
      )
    }
  )

  const templatesQuery = useQuery([
    Paths.NodeTemplates,
    sort.orderBy,
    sort.order,
    pagination.page,
    pagination.rowsPerPage
  ],
  () => api.getList({
    orderBy1: sort.orderBy,
    order1: sort.order,
    pageNumber: pagination.page,
    pageSize: pagination.rowsPerPage,
    path: Paths.NodeTemplates
  }), {
    onError: () => enqueueSnackbar(
      intl.formatMessage({
        id: 'propertyMapping.failedTemplates',
        description: 'Fetch templates error message',
        defaultMessage: 'Failed to get Node Templates!'
      }), {
        variant: 'error'
      }
    )
  })

  const handleLeftChange = (left: number[]) => {
    mappingDeleteMutation.mutate(left)
  }

  const handlePageChange = (newPage: number) => {
    pagination.setPage(newPage)
    setSelected(undefined)
  }

  const handleRequestSort = (property: string) => {
    sort.requestSort(property)
    setSelected(undefined)
  }

  const handleRightChange = (right: number[]) => {
    if (isNil(selected)) {
      throw Error('Cannot handleRightChange if selected is nil!')
    }
    mappingCreateMutation.mutate(right.map((value) => ({
      [NodeTemplatePropertyMappingKeys.NodeTempPropId]: value,
      [NodeTemplatePropertyMappingKeys.TemplateId]: selected[0]
    })))
  }

  const handleRowsPerPageChange = (rows: number) => {
    pagination.setRowsPerPage(rows)
    setSelected(undefined)
  }

  const handleSelect = (ids: (number | string | null)[]) => {
    setSelected(ids.length > 0 ? ids.map(Number) : undefined)
  }

  const pageReady = mappingDescQuery.isSuccess && propertiesDescQuery.isSuccess &&
    templatesQuery.isSuccess && templatesDescQuery.isSuccess

  const pageLoading = mappingDescQuery.isLoading || propertiesDescQuery.isLoading ||
    templatesQuery.isLoading || templatesDescQuery.isLoading

  const mutationLoading = mappingCreateMutation.isLoading ||
    mappingDeleteMutation.isLoading

  const mappingLoading = mappingQuery.isLoading || mutationLoading

  const propertiesLoading = propertiesQuery.isLoading
  return (
    <>
      {
        pageReady &&
        <Box marginBottom={3} marginTop={1}>
          <Container maxWidth={false}>
            <Grid container spacing={2} alignItems="flex-start">
              <Grid item md={12} lg={4}>
                { templatesQuery.data?.Items &&
                  templatesQuery.data?.Pagination &&
                  templatesDescQuery.data?.ViewDescription &&
                    <Table
                      data={templatesQuery.data.Items}
                      idProperty={NodeTemplateKeys.Id}
                      onPageChange={handlePageChange}
                      onRequestSort={handleRequestSort}
                      onRowsPerPageChange={handleRowsPerPageChange}
                      onSelect={handleSelect}
                      order={sort.order}
                      orderBy={sort.orderBy}
                      page={pagination.page}
                      rowsPerPage={pagination.rowsPerPage}
                      schema={templatesDescQuery.data.ViewDescription}
                      selected={selected}
                      title={intl.formatMessage({
                        id: 'propertyMapping.nodeTemplates',
                        description: 'Node Templates table title',
                        defaultMessage: 'Node Templates'
                      })}
                      totalRows={templatesQuery.data.Pagination.TotalCount}
                    />
                }
              </Grid>
              <Grid item md={12} lg={8}>
                { selected?.length === 1 &&
                  mappingDescQuery.data?.ViewDescription &&
                  propertiesDescQuery.data?.ViewDescription &&
                  <TransferList
                    leftList={{
                      data: mappingQuery.data?.Items || [],
                      idProperty: NodeTemplatePropertyMappingKeys.Id,
                      ignoredProperties: [
                        NodeTemplatePropertyMappingKeys.TemplateId,
                        NodeTemplatePropertyMappingKeys.TemplateName,
                        NodeTemplatePropertyMappingKeys.TemplateDescription,
                        NodeTemplatePropertyMappingKeys.NodeTempPropId,
                        NodeTemplatePropertyMappingKeys.DataTypeId,
                        NodeTemplatePropertyMappingKeys.GroupOrdinal
                      ],
                      loading: mappingLoading,
                      onChange: handleLeftChange,
                      schema: mappingDescQuery.data.ViewDescription,
                      title: intl.formatMessage({
                        id: 'propertyMapping.templateProperties',
                        description: 'Template Properties table title',
                        defaultMessage: 'Template Properties'
                      })
                    }}
                    rightList={{
                      data: propertiesQuery.data?.Items || [],
                      idProperty: NodeTemplatePropertyKeys.Id,
                      ignoredProperties: [
                        NodeTemplatePropertyKeys.DataTypeId,
                        NodeTemplatePropertyKeys.TemplatePropertyGroupId,
                        NodeTemplatePropertyKeys.GroupOrdinal,
                        NodeTemplatePropertyKeys.CreateDateTime
                      ],
                      loading: propertiesLoading,
                      onChange: handleRightChange,
                      schema: propertiesDescQuery.data.ViewDescription,
                      title: intl.formatMessage({
                        id: 'propertyMapping.availableProperties',
                        description: 'Available Properties table title',
                        defaultMessage: 'Available Properties'
                      })
                    }}
                  />
                }
              </Grid>
            </Grid>
          </Container>
        </Box>
      }
      { pageLoading &&
        <PageLoading/>
      }
    </>
  )
}

export default PropertyMapping
