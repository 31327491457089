import React, { FunctionComponent, useEffect, useState } from 'react'
import { Route, Switch, useHistory } from 'react-router-dom'
import clsx from 'clsx'
import { useIsAuthenticated, useMsal } from '@azure/msal-react'
import { useIntl } from 'react-intl'
import { useQuery, useQueryClient } from 'react-query'
import { find, isNil } from 'lodash'
import { InteractionStatus } from '@azure/msal-browser'
import {
  Box,
  Drawer,
  Grid,
  IconButton,
  makeStyles,
  useTheme
} from '@material-ui/core'
import { ChevronLeft } from '@material-ui/icons'
import { useSnackbar } from 'notistack'
import Footer from './components/Footer'
import NavBar from './components/NavBar'
import PageLoading from './components/Loading/PageLoading'
import RouteCrumbs from './components/RouteCrumbs'
import SideMenu from './components/SideMenu'
import useWindowSize from './hooks/useWindowSize'
import Home from './pages/Home'
import Landing from './pages/Landing'
import PageNotFound from './pages/PageNotFound'
import { Paths, TenantKeys, useApi } from './api/RcfactoryApi'
import { Theme } from '@material-ui/core/styles'

const drawerWidth = 280

const useStyles = makeStyles((theme: Theme) => ({
  drawer: {
    width: drawerWidth,
    flexShrink: 0
  },
  drawerPaper: {
    width: drawerWidth
  },
  main: {
    display: 'flex',
    flex: 1
  },
  mainContent: {
    flex: 1,
    height: '100%',
    maxWidth: '100%'
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end'
  },
  content: {
    display: 'flex',
    flexGrow: 1,
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    })
  },
  contentShift: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen
    }),
    marginLeft: drawerWidth
  },
  menuBackground: {
    backgroundColor: theme.custom.menuBackground,
    color: theme.custom.menuTextColor,
    paddingRight: '5px',
    '& svg': {
      color: theme.custom.menuTextColor
    }
  }
}))

export enum Routes {
  Home = '/',
  PageNotFound = '/404'
}

const App: FunctionComponent = () => {
  const history = useHistory()
  const intl = useIntl()
  const isAuthenticated = useIsAuthenticated()
  const { inProgress } = useMsal()
  const classes = useStyles()
  const theme = useTheme()
  const api = useApi()
  const { width } = useWindowSize()
  const { enqueueSnackbar } = useSnackbar()
  const [drawerOpen, setDrawerOpen] = useState<boolean>(false)
  const [selectedTenant, setSelectedTenant] = useState<number>()
  const queryClient = useQueryClient()

  useQuery(
    [Paths.Nodes, api.tenantId],
    () =>
      api.getList({
        pageSize: 1,
        path: Paths.Nodes
      }),
    {
      enabled: !isNil(api.tenantId)
    }
  )

  const tenantsQuery = useQuery(Paths.Tenant, () => api.getTenants(), {
    enabled: isAuthenticated && inProgress === InteractionStatus.None,
    onError: () =>
      enqueueSnackbar(
        intl.formatMessage({
          id: 'app.failedTenants',
          description: 'Fetch tenants error notification text',
          defaultMessage: 'Failed to get Tenants!'
        }),
        {
          variant: 'error'
        }
      )
  })

  useEffect(() => {
    if (!isNil(tenantsQuery.data)) {
      let newSelectedTenant = api.tenantId
      if (tenantsQuery.data.length > 0) {
        if (
          isNil(api.tenantId) ||
          !find(tenantsQuery.data, { [TenantKeys.Id]: api.tenantId })
        ) {
          const favourite = tenantsQuery.data.find((tenant) =>
            Boolean(tenant[TenantKeys.Favourite])
          )
          if (!isNil(favourite)) {
            newSelectedTenant = Number(favourite[TenantKeys.Id])
          } else {
            newSelectedTenant = Number(tenantsQuery.data[0][TenantKeys.Id])
          }
        }
      } else {
        newSelectedTenant = undefined
      }
      setSelectedTenant(newSelectedTenant)
    }
  }, [api, tenantsQuery.data])

  useEffect(() => {
    if (!isNil(selectedTenant)) {
      api.tenantId = selectedTenant
    }
  }, [api, selectedTenant])

  useEffect(() => {
    if (width && width < theme.breakpoints.width('md')) {
      setDrawerOpen(false)
    } else {
      setDrawerOpen(true)
    }
  }, [theme.breakpoints, width])

  const handleMenuClick = () => {
    setDrawerOpen(true)
  }

  const handleTenantSelect = (id: number) => {
    setSelectedTenant(id)
    queryClient.resetQueries()
    history.push(Routes.Home)
  }

  if (tenantsQuery.isError) {
    throw Error('Website cannot function without Tenants!')
  }

  return (
    <Box display="flex" flexDirection="column" minHeight="100vh">
      {inProgress === InteractionStatus.None && !tenantsQuery.isLoading && (
        <Switch>
          <Route exact path={Routes.PageNotFound}>
            <PageNotFound />
          </Route>
          <Route path="*">
            {isAuthenticated && tenantsQuery.isSuccess && (
              <>
                <NavBar
                  drawerOpen={drawerOpen}
                  drawerWidth={drawerWidth}
                  onMenuClick={handleMenuClick}
                  onTenantSelect={handleTenantSelect}
                  selectedTenant={selectedTenant}
                  tenants={tenantsQuery.data}
                />
                <div
                  className={clsx(classes.content, {
                    [classes.contentShift]: drawerOpen
                  })}
                >
                  <Grid className={classes.main} container direction="column">
                    <Grid item>
                      <RouteCrumbs />
                    </Grid>
                    <Grid item className={classes.mainContent}>
                      <Home />
                    </Grid>
                  </Grid>
                </div>
                <Footer />
                <Drawer
                  className={classes.drawer}
                  variant="persistent"
                  anchor="left"
                  open={drawerOpen}
                  onClose={() => setDrawerOpen(false)}
                  classes={{
                    paper: classes.drawerPaper + ' ' + classes.menuBackground
                  }}
                >
                  <div className={classes.drawerHeader}>
                    <IconButton onClick={() => setDrawerOpen(false)}>
                      <ChevronLeft />
                    </IconButton>
                  </div>
                  <SideMenu />
                </Drawer>
              </>
            )}
            {!isAuthenticated && <Landing />}
          </Route>
        </Switch>
      )}
      {(inProgress !== InteractionStatus.None || tenantsQuery.isLoading) && (
        <PageLoading />
      )}
    </Box>
  )
}

export default App
