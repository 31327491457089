import React, { FunctionComponent, useEffect, useState } from 'react'
import { useMsal } from '@azure/msal-react'
import { AccountInfo } from '@azure/msal-browser'
import { Container } from '@material-ui/core'
import Json from '../components/Json'

const Me: FunctionComponent = () => {
  const { instance } = useMsal()
  const [accounts, setAccounts] = useState<AccountInfo[]>()

  useEffect(() => {
    const newAccounts = instance.getAllAccounts()
    setAccounts(newAccounts)
  }, [instance])

  return (
    <Container>
      <Json data={accounts}/>
    </Container>
  )
}

export default Me
