import React, { useState, createContext } from 'react'
import { ThemeProvider } from '@material-ui/core/styles'
import getTheme from '../MyThemes'
import {
  FunctionComponent,
  ReactNode
} from 'react-transition-group/node_modules/@types/react'
import { isNil } from 'lodash'

export enum ThemeType {
  Dark,
  Light
}

export interface ICustomThemeContext {
  currentTheme: ThemeType
  setTheme: (themeSet: ThemeType) => void
}

export const CustomThemeContext = createContext<ICustomThemeContext>({
  currentTheme: ThemeType.Light,
  setTheme: (themeSet: ThemeType) => {
    return themeSet
  }
})

interface ICustomThemeProps {
  children: ReactNode
}

const CustomThemeProvider: FunctionComponent<ICustomThemeProps> = (
  props: ICustomThemeProps
) => {
  const { children } = props
  const storage: number = isNil(localStorage.getItem('appTheme'))
    ? 0
    : Number(localStorage.getItem('appTheme'))
  const currentTheme: ThemeType =
    storage === 0 ? ThemeType.Light : ThemeType.Dark
  const [themeName, _setThemeName] = useState<ThemeType>(currentTheme)
  const theme = getTheme(themeName)

  const setThemeName = (name: ThemeType) => {
    localStorage.setItem(
      'appTheme',
      (name === ThemeType.Light ? 0 : 1).toString()
    )
    _setThemeName(name)
  }
  const contextValue: ICustomThemeContext = {
    currentTheme: themeName,
    setTheme: setThemeName
  }

  return (
    <div>
      <CustomThemeContext.Provider value={contextValue}>
        <ThemeProvider theme={{ ...theme }}>{children}</ThemeProvider>
      </CustomThemeContext.Provider>
    </div>
  )
}

export default CustomThemeProvider
