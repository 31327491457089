import React, { FunctionComponent, useMemo, useState } from 'react'
import { useQuery } from 'react-query'
import { useIntl } from 'react-intl'
import {
  ArrayParam,
  NumericArrayParam,
  StringParam,
  useQueryParam
} from 'use-query-params'
import { filter, isNil, uniq } from 'lodash'
import { Duration, sub } from 'date-fns'
import { Box, Container, Grid } from '@material-ui/core'
import { useSnackbar } from 'notistack'
import Autocomplete from '../../components/Autocomplete'
import DataGrid from '../../components/DataGrid'
import Hierarchy from '../../components/Hierarchy/Hierarchy'
import LineChart, { LineSeries } from '../../components/Chart/LineChart'
import PageLoading from '../../components/Loading/PageLoading'
import TimeRange from '../../components/TimeRange'
import usePagination from '../../hooks/usePagination'
import {
  EventVariablesKeys,
  Expression,
  ExpressionOperator,
  LogicalOperator,
  NodeKeys,
  Order,
  Paths,
  useApi
} from '../../api/RcfactoryApi'
import { camelCaseToTitle } from '../../api/Utils'

enum ParamKeys {
  Event = 'eventId',
  EventVarId = 'eventVarId',
  NodeId = 'nodeId'
}

const defaultDuration: Duration = { days: 1 }

const Events: FunctionComponent = () => {
  const intl = useIntl()
  const api = useApi()
  const pagination = usePagination(100)
  const [columnFilter, setColumnFilter] = useState<Expression[]>([])
  const [endTime, setEndTime] = useState<Date | null>(new Date())
  const [selectedEvent, setSelectedEvent] = useQueryParam(
    ParamKeys.Event,
    StringParam
  )
  const [selectedEventVars, setSelectedEventVars] = useQueryParam(
    ParamKeys.EventVarId,
    ArrayParam
  )
  const [selectedNodes, setSelectedNodes] = useQueryParam(
    ParamKeys.NodeId,
    NumericArrayParam
  )
  const [startTime, setStartTime] = useState<Date | null>(
    sub(new Date(), defaultDuration)
  )
  const { enqueueSnackbar } = useSnackbar()

  const eventVarQuery = useQuery(
    [
      Paths.EventVariables,
      endTime,
      columnFilter,
      selectedNodes,
      startTime,
      pagination.page,
      pagination.rowsPerPage
    ],
    () => {
      if (isNil(selectedNodes)) {
        throw Error('Should not query event variables if selectedNodes is nil!')
      }
      const expressions: Expression[] = [
        {
          Op: ExpressionOperator.Equal,
          Prop: EventVariablesKeys.NodeId,
          Val: selectedNodes[0]
        }
      ]
      if (!isNil(startTime)) {
        expressions.push({
          Op: ExpressionOperator.GreaterThanEqual,
          Prop: EventVariablesKeys.RecordTime,
          Val: startTime
        })
      }
      if (!isNil(endTime)) {
        expressions.push({
          Op: ExpressionOperator.LessThanEqual,
          Prop: EventVariablesKeys.RecordTime,
          Val: endTime
        })
      }
      return api.getList({
        modelExpressions: {
          Expressions: expressions.concat(columnFilter),
          Operator: LogicalOperator.And
        },
        order1: Order.desc,
        orderBy1: EventVariablesKeys.RecordTime,
        path: Paths.EventVariables,
        pageNumber: pagination.page,
        pageSize: pagination.rowsPerPage
      })
    },
    {
      enabled: selectedNodes?.length === 1,
      onError: () =>
        enqueueSnackbar(
          intl.formatMessage({
            id: 'events.failedEventVariables',
            description: 'Failed to get event variables notification text',
            defaultMessage: 'Failed to get Event Variables!'
          }),
          {
            variant: 'error'
          }
        )
    }
  )

  const eventVarDescQuery = useQuery(
    Paths.EventVariables + Paths.UtilsGetDesc,
    () => api.getDesc({ path: Paths.EventVariables }),
    {
      onError: () =>
        enqueueSnackbar(
          intl.formatMessage({
            id: 'events.failedEventVariablesSchema',
            description:
              'Failed to get event variables schema notification text',
            defaultMessage: 'Failed to get Event Variable Schema!'
          }),
          {
            variant: 'error'
          }
        )
    }
  )

  const nodesQuery = useQuery(
    Paths.Nodes,
    () =>
      api.getList({
        modelExpressions: {
          Expressions: [
            {
              Prop: NodeKeys.Active,
              Op: ExpressionOperator.Equal,
              Val: true
            }
          ]
        },
        order1: Order.asc,
        orderBy1: NodeKeys.OrdinalPosition,
        path: Paths.Nodes
      }),
    {
      onError: () =>
        enqueueSnackbar(
          intl.formatMessage({
            id: 'events.failedNodes',
            description: 'Failed to get nodes notification text',
            defaultMessage: 'Failed to get Nodes!'
          }),
          {
            variant: 'error'
          }
        )
    }
  )

  const events = useMemo(() => {
    if (isNil(eventVarQuery.data) || isNil(eventVarQuery.data.Items)) {
      return undefined
    }
    return uniq(
      eventVarQuery.data.Items.map((d) =>
        String(d[EventVariablesKeys.EventName])
      )
    )
  }, [eventVarQuery.data])

  const eventVars = useMemo(() => {
    if (
      isNil(eventVarQuery.data) ||
      isNil(eventVarQuery.data.Items) ||
      isNil(selectedEvent)
    ) {
      return undefined
    }
    return uniq(
      eventVarQuery.data.Items.filter(
        (f) => f[EventVariablesKeys.EventName] === selectedEvent
      ).map((m) => String(m[EventVariablesKeys.VariableName]))
    )
  }, [eventVarQuery.data, selectedEvent])

  const eventData = useMemo(() => {
    if (!eventVarQuery.data?.Items || isNil(selectedEventVars)) {
      return []
    }
    const identifiers = selectedEventVars.map((m) => ({
      [EventVariablesKeys.EventName]: selectedEvent,
      [EventVariablesKeys.VariableName]: m
    }))
    const selections: LineSeries[] = []
    for (const identity of identifiers) {
      selections.push({
        data: filter(eventVarQuery.data.Items, identity).reverse(),
        name: String(identity[EventVariablesKeys.VariableName])
      })
    }
    return selections
  }, [eventVarQuery.data?.Items, selectedEvent, selectedEventVars])

  const handleEventChange = (value: string | null | string[]) => {
    if (Array.isArray(value)) {
      throw Error('Expected string or null!')
    }
    setSelectedEvent(value)
    setSelectedEventVars(undefined)
  }

  const handleEventVarsChange = (value: string | null | string[]) => {
    if (isNil(value) || !Array.isArray(value)) {
      throw Error('Expected string array!')
    }
    setSelectedEventVars(value.length > 0 ? value : undefined)
  }

  const handleFilterChange = (expressions: Expression[]) => {
    setColumnFilter(expressions)
  }

  const handleSelectNodes = (ids: number[]) => {
    setSelectedNodes(ids.length > 0 ? ids : undefined)
    setSelectedEvent(undefined)
    setSelectedEventVars(undefined)
    setColumnFilter([])
  }

  const pageReady = eventVarDescQuery.isSuccess && nodesQuery.isSuccess
  const pageLoading = eventVarDescQuery.isLoading && nodesQuery.isLoading

  return (
    <>
      {pageReady && (
        <Box paddingTop={3} paddingBottom={3}>
          <Container maxWidth={false}>
            <Grid container spacing={2}>
              <TimeRange
                endTime={endTime}
                onEndTimeChange={setEndTime}
                onStartTimeChange={setStartTime}
                startTime={startTime}
              />
              {nodesQuery.data?.Items && (
                <Grid item xs={12} md={5} lg={4} xl={3}>
                  <Hierarchy
                    data={nodesQuery.data.Items}
                    idProperty={NodeKeys.Id}
                    label="Node"
                    nameProperty={NodeKeys.Name}
                    onSelect={handleSelectNodes}
                    ordinalProperty={NodeKeys.OrdinalPosition}
                    parentIdProperty={NodeKeys.ParentId}
                    selected={selectedNodes}
                    title={intl.formatMessage({
                      id: 'events.nodes',
                      description: 'Events viewer page, node hierarchy title',
                      defaultMessage: 'Nodes'
                    })}
                  />
                </Grid>
              )}
              <Grid item xs={12} md={7} lg={8} xl={9}>
                <Box display="flex" height="100%" minHeight={750}>
                  <Box flexGrow={1}>
                    {eventVarDescQuery.data?.ViewDescription && (
                      <DataGrid
                        data={eventVarQuery.data}
                        filterMode="server"
                        ignoredProperties={[
                          EventVariablesKeys.EventId,
                          EventVariablesKeys.NodeId,
                          EventVariablesKeys.UnitId
                        ]}
                        loading={eventVarQuery.isLoading}
                        onFilterChange={handleFilterChange}
                        onPageChange={pagination.setPage}
                        onRowsPerPageChange={pagination.setRowsPerPage}
                        page={pagination.page}
                        pagination
                        paginationMode="server"
                        rowsPerPage={pagination.rowsPerPage}
                        rowsPerPageOptions={pagination.rowsPerPageOptions}
                        schema={eventVarDescQuery.data.ViewDescription}
                        widths={{
                          [EventVariablesKeys.EventFilter]: 130,
                          [EventVariablesKeys.EventName]: 200,
                          [EventVariablesKeys.Value]: 200,
                          [EventVariablesKeys.VariableName]: 200,
                          [EventVariablesKeys.UnitName]: 120
                        }}
                        totalRows={eventVarQuery.data?.Pagination.TotalCount}
                      />
                    )}
                  </Box>
                </Box>
              </Grid>
              <Grid container item xs={12} spacing={2}>
                <Grid item xs={12} sm={4}>
                  <Autocomplete
                    disabled={isNil(events)}
                    label={intl.formatMessage({
                      id: 'events.event',
                      description: 'Event input field label',
                      defaultMessage: 'Event'
                    })}
                    loading={eventVarQuery.isLoading}
                    onValueChange={handleEventChange}
                    options={events || []}
                    value={!isNil(selectedEvent) ? selectedEvent : null}
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={12} sm={8}>
                  <Autocomplete
                    disabled={isNil(eventVars)}
                    label={intl.formatMessage({
                      id: 'events.eventVariables',
                      description: 'Event variables input field label',
                      defaultMessage: 'Event Variables'
                    })}
                    loading={eventVarQuery.isLoading}
                    multiple
                    onValueChange={handleEventVarsChange}
                    options={eventVars || []}
                    value={
                      !isNil(selectedEventVars)
                        ? selectedEventVars.map(String)
                        : []
                    }
                    variant="outlined"
                  />
                </Grid>
              </Grid>
              <Grid item xs={12}>
                {selectedEvent &&
                  selectedEventVars &&
                  selectedEventVars.length > 0 && (
                    <>
                      {
                        <LineChart
                          series={eventData}
                          height={750}
                          title={intl.formatMessage(
                            {
                              id: 'events.lineChartTitle',
                              description: 'Events page line chart title',
                              defaultMessage: '{yProperty} vs {xProperty}'
                            },
                            {
                              xProperty: camelCaseToTitle(
                                EventVariablesKeys.RecordTime
                              ),
                              yProperty: selectedEvent
                            }
                          )}
                          xProperty={EventVariablesKeys.RecordTime}
                          yProperty={EventVariablesKeys.Value}
                        />
                      }
                    </>
                  )}
              </Grid>
            </Grid>
          </Container>
        </Box>
      )}
      {pageLoading && <PageLoading />}
    </>
  )
}

export default Events
