import React, { FunctionComponent, useEffect, useState } from 'react'
import {
  Link,
  Redirect,
  Route,
  Switch,
  useHistory,
  useRouteMatch
} from 'react-router-dom'
import { useIntl } from 'react-intl'
import { Collapse, ListItemIcon, ListItemText } from '@material-ui/core'
import { ExpandLess, ExpandMore, ShowChart } from '@material-ui/icons'
import Accumulators from './Accumulators'
import Contents from '../../components/Contents'
import Events from './Events'
import Identifiers from './Identifiers'
import Incidents from './Incidents'
import { Routes as AppRoutes } from '../../App'
import { Routes as HomeRoutes } from '../Home'
import accumulators from '../../images/accumulators_card.jpg'
import events from '../../images/events_card.jpg'
import hero from '../../images/factory_viewer_hero.jpg'
import identifiers from '../../images/identifiers_card.jpg'
import incidents from '../../images/incidents_card.jpg'
import ProductionView from './ProductionView'
import SideMenuCustomListItem from '../../components/SideMenuCustomListItem'

export enum Routes {
  Accumulators = '/accumulators',
  Events = '/events',
  Identifiers = '/identifiers',
  Incidents = '/incidents',
  ProductionGrid = '/production-grid'
}

interface SideMenuProps {
  classes: Record<string, string | undefined>
}

export const FactoryViewerSideMenu: FunctionComponent<SideMenuProps> = (
  props: SideMenuProps
) => {
  const { classes } = props
  const viewerMatch = useRouteMatch({
    path: HomeRoutes.FactoryViewer
  })
  const history = useHistory()
  const intl = useIntl()
  const [viewerOpen, setViewerOpen] = useState(false)

  useEffect(() => {
    setViewerOpen(Boolean(viewerMatch))
  }, [viewerMatch])

  const handeViewerClick = () => {
    if (!viewerMatch) {
      setViewerOpen(!viewerOpen)
    } else {
      history.push(HomeRoutes.FactoryViewer)
    }
  }

  return (
    <>
      <SideMenuCustomListItem
        button
        onClick={handeViewerClick}
        className={classes.menuItem}
      >
        <ListItemIcon className={classes.menuItemIcon}>
          <ShowChart />
        </ListItemIcon>
        <ListItemText
          primary={intl.formatMessage({
            id: 'factoryViewer.sideMenu.factoryViewer',
            description: 'Factory viewer side menu, factory viewer item',
            defaultMessage: 'Factory Viewer'
          })}
        />
        {!viewerMatch && <>{viewerOpen ? <ExpandLess /> : <ExpandMore />}</>}
      </SideMenuCustomListItem>
      <Collapse
        in={viewerOpen}
        timeout="auto"
        unmountOnExit
        className={classes.collapse}
      >
        <Link
          className={classes.link}
          to={HomeRoutes.FactoryViewer + Routes.Accumulators}
        >
          <SideMenuCustomListItem button className={classes.menuItem}>
            <ListItemText
              primary={intl.formatMessage({
                id: 'factoryViewer.sideMenu.accumulators',
                description: 'Factory viewer side menu, accumulators item',
                defaultMessage: 'Accumulators'
              })}
            />
          </SideMenuCustomListItem>
        </Link>
        <Link
          className={classes.link}
          to={HomeRoutes.FactoryViewer + Routes.Events}
        >
          <SideMenuCustomListItem button className={classes.menuItem}>
            <ListItemText
              primary={intl.formatMessage({
                id: 'factoryViewer.sideMenu.events',
                description: 'Factory viewer side menu, events item',
                defaultMessage: 'Events'
              })}
            />
          </SideMenuCustomListItem>
        </Link>
        <Link
          className={classes.link}
          to={HomeRoutes.FactoryViewer + Routes.Identifiers}
        >
          <SideMenuCustomListItem button className={classes.menuItem}>
            <ListItemText
              primary={intl.formatMessage({
                id: 'factoryViewer.sideMenu.identifiers',
                description: 'Factory viewer side menu, identifiers item',
                defaultMessage: 'Identifiers'
              })}
            />
          </SideMenuCustomListItem>
        </Link>
        <Link
          className={classes.link}
          to={HomeRoutes.FactoryViewer + Routes.Incidents}
        >
          <SideMenuCustomListItem button className={classes.menuItem}>
            <ListItemText
              primary={intl.formatMessage({
                id: 'factoryViewer.sideMenu.incidents',
                description: 'Factory viewer side menu, incidents item',
                defaultMessage: 'Incidents'
              })}
            />
          </SideMenuCustomListItem>
        </Link>
        <Link
          className={classes.link}
          to={HomeRoutes.FactoryViewer + Routes.ProductionGrid}
        >
          <SideMenuCustomListItem button className={classes.menuItem}>
            <ListItemText
              primary={intl.formatMessage({
                id: 'factoryViewer.sideMenu.productionGrid',
                description: 'Factory viewer side menu, production grid item',
                defaultMessage: 'Production Grid'
              })}
            />
          </SideMenuCustomListItem>
        </Link>
      </Collapse>
    </>
  )
}

const FactoryViewerContent: FunctionComponent = () => {
  const intl = useIntl()
  const match = useRouteMatch()

  const items = [
    {
      image: accumulators,
      route: match.path + Routes.Accumulators,
      text: intl.formatMessage({
        id: 'factoryViewer.accumulatorsDescription',
        description: 'Factory viewer page, accumulators description',
        defaultMessage: 'View and visualise node accumulators.'
      }),
      title: intl.formatMessage({
        id: 'factoryViewer.accumulators',
        description: 'Factory viewer page, accumulators item',
        defaultMessage: 'Accumulators'
      })
    },
    {
      image: events,
      route: match.path + Routes.Events,
      text: intl.formatMessage({
        id: 'factoryViewer.eventsDescription',
        description: 'Factory viewer page, events description',
        defaultMessage: 'View and visualise node events and event variables.'
      }),
      title: intl.formatMessage({
        id: 'factoryViewer.events',
        description: 'Factory viewer page, events item',
        defaultMessage: 'Events'
      })
    },
    {
      image: identifiers,
      route: match.path + Routes.Identifiers,
      text: intl.formatMessage({
        id: 'factoryViewer.identifiersDescription',
        description: 'Factory viewer page, identifiers description',
        defaultMessage: 'View and visualise node identifiers.'
      }),
      title: intl.formatMessage({
        id: 'factoryViewer.identifiers',
        description: 'Factory viewer page, identifiers item',
        defaultMessage: 'Identifiers'
      })
    },
    {
      image: incidents,
      route: match.path + Routes.Incidents,
      text: intl.formatMessage({
        id: 'factoryViewer.incidentsDescription',
        description: 'Factory viewer page, incidents description',
        defaultMessage: 'View and visualise node incidents.'
      }),
      title: intl.formatMessage({
        id: 'factoryViewer.incidents',
        description: 'Factory viewer page, incidents item',
        defaultMessage: 'Incidents'
      })
    },
    {
      image: incidents,
      route: match.path + Routes.ProductionGrid,
      text: intl.formatMessage({
        id: 'factoryViewer.productionGridDescription',
        description: 'Factory viewer page, production grid description',
        defaultMessage: 'View and visualise production data.'
      }),
      title: intl.formatMessage({
        id: 'factoryViewer.productionView',
        description: 'Factory viewer page, production grid item',
        defaultMessage: 'Production Grid'
      })
    }
  ]

  return (
    <Contents
      hero={hero}
      items={items}
      title={intl.formatMessage({
        id: 'factoryViewer.factoryViewer',
        description: 'Factory viewer page title',
        defaultMessage: 'Factory Viewer'
      })}
    />
  )
}

const FactoryViewer: FunctionComponent = () => {
  const { path } = useRouteMatch()

  return (
    <Switch>
      <Route exact path={path + Routes.Accumulators}>
        <Accumulators />
      </Route>
      <Route exact path={path + Routes.Events}>
        <Events />
      </Route>
      <Route exact path={path + Routes.Identifiers}>
        <Identifiers />
      </Route>
      <Route exact path={path + Routes.Incidents}>
        <Incidents />
      </Route>
      <Route exact path={path + Routes.ProductionGrid}>
        <ProductionView />
      </Route>
      <Route exact path={path}>
        <FactoryViewerContent />
      </Route>
      <Redirect to={AppRoutes.PageNotFound} />
    </Switch>
  )
}

export default FactoryViewer
