import { useState } from 'react'
import { clone, cloneDeep, find, findIndex, isEqual, isNil } from 'lodash'

interface Unsaved {
  copy?: Record<string, unknown>[],
  discard: () => void,
  edits: Record<string, unknown>[],
  hasChanged: boolean,
  refresh: (data: Record<string, unknown>[]) => void,
  remove: (ids: number[]) => void,
  update: (changes: {
    data: Record<string, unknown>,
    property: string,
    value: unknown
  }[]) => void
}

export default function useUnsaved (
  idProperty: string,
  initial?: Record<string, unknown>[]
): Unsaved {
  const [original, setOriginal] = useState<Record<string, unknown>[] | undefined>(
    !isNil(initial) ? cloneDeep(initial) : undefined
  )
  const [copy, setCopy] = useState<Record<string, unknown>[] | undefined>(
    !isNil(initial) ? cloneDeep(initial) : undefined
  )
  const [edits, setEdits] = useState<Record<string, unknown>[]>([])

  const discard = () => {
    setEdits([])
    setCopy(cloneDeep(original))
  }

  const refresh = (data: Record<string, unknown>[]) => {
    const newCopy = cloneDeep(data)
    const newEdits: Record<string, unknown>[] = []
    edits.forEach(edit => {
      const index = findIndex(newCopy, { [idProperty]: edit[idProperty] })
      if (index >= 0) {
        if (!isEqual(newCopy[index], edit)) {
          newCopy[index] = clone(edit)
          newEdits.push(edit)
        }
      }
    })
    setOriginal(cloneDeep(data))
    setCopy(newCopy)
    setEdits(newEdits)
  }

  const remove = (ids: number[]) => {
    const newCopy = cloneDeep(copy)
    const newEdits = cloneDeep(edits)

    ids.forEach(id => {
      const copyIndex = findIndex(copy, { [idProperty]: id })
      const originalIndex = findIndex(original, { [idProperty]: id })
      if (newCopy && copyIndex >= 0 && original && originalIndex >= 0) {
        newCopy[copyIndex] = clone(original[originalIndex])
      }

      const editIndex = findIndex(newEdits, { [idProperty]: id })
      if (editIndex >= 0) {
        newEdits.splice(editIndex, 1)
      }
    })

    setCopy(newCopy)
    setEdits(newEdits)
  }

  const update = (changes: {
    data: Record<string, unknown>,
    property: string,
    value: unknown
  }[]) => {
    const newCopy = cloneDeep(copy)
    const newEdits = cloneDeep(edits)

    changes.forEach(change => {
      const copyIndex = findIndex(newCopy, { [idProperty]: change.data[idProperty] })
      if (!isNil(newCopy) && copyIndex >= 0) {
        newCopy[copyIndex][change.property] = change.value
      }

      let editIndex = findIndex(newEdits, { [idProperty]: change.data[idProperty] })
      if (editIndex < 0) {
        editIndex = newEdits.push(change.data) - 1
      }
      newEdits[editIndex][change.property] = change.value
      const backup = find(original, { [idProperty]: change.data[idProperty] })
      if (isEqual(backup, newEdits[editIndex])) {
        newEdits.splice(editIndex, 1)
      }
    })

    setCopy(newCopy)
    setEdits(newEdits)
  }

  return {
    copy,
    discard,
    edits,
    hasChanged: edits.length > 0,
    refresh,
    remove,
    update
  }
}
