import React, { FunctionComponent, useState } from 'react'
import { useQuery } from 'react-query'
import { useIntl } from 'react-intl'
import { Box, Container, Grid } from '@material-ui/core'
import { GridSortItem } from '@material-ui/x-grid'
import { useSnackbar } from 'notistack'
import DataGrid from '../../../components/DataGrid'
import PageLoading from '../../../components/Loading/PageLoading'
import usePagination from '../../../hooks/usePagination'
import {
  Expression,
  GetListParams,
  LogicalOperator,
  Order,
  Paths,
  PoisonMessageKeys,
  useApi
} from '../../../api/RcfactoryApi'

const PoisonMessages: FunctionComponent = () => {
  const intl = useIntl()
  const api = useApi()
  const pagination = usePagination(25)
  const [filter, setFilter] = useState<Expression[]>([])
  const [sort, setSort] = useState<GridSortItem[]>([{
    field: PoisonMessageKeys.RecordTime,
    sort: 'desc'
  }])
  const { enqueueSnackbar } = useSnackbar()

  const dataQuery = useQuery([
    Paths.PoisonMessages,
    filter,
    sort,
    pagination.page,
    pagination.rowsPerPage
  ], () => {
    const params: GetListParams = {
      modelExpressions: {
        Expressions: filter,
        Operator: LogicalOperator.And
      },
      path: Paths.PoisonMessages,
      pageNumber: pagination.page,
      pageSize: pagination.rowsPerPage
    }
    if (sort.length > 0) {
      params.order1 = sort[0].sort === 'asc' ? Order.asc : Order.desc
      params.orderBy1 = sort[0].field
    }
    return api.getList(params)
  }, {
    onError: () => enqueueSnackbar(
      intl.formatMessage({
        id: 'poisonMessages.failedMessages',
        description: 'Failed to get poison messages notification text',
        defaultMessage: 'Failed to get Poison Messages!'
      }), {
        variant: 'error'
      }
    )
  })

  const descQuery = useQuery(
    [Paths.PoisonMessages + Paths.UtilsGetDesc],
    () => api.getDesc({
      path: Paths.PoisonMessages
    }), {
      onError: () => enqueueSnackbar(
        intl.formatMessage({
          id: 'poisonMessages.failedSchema',
          description: 'Failed to get poison messages schema notification text',
          defaultMessage: 'Failed to get Poison Message Schema!'
        }), {
          variant: 'error'
        }
      )
    }
  )

  const pageReady = descQuery.isSuccess

  const pageLoading = descQuery.isLoading

  return (
    <>
      { pageReady &&
        descQuery.data?.ViewDescription &&
        <Box paddingTop={1} paddingBottom={3} height="100%" clone>
          <Container maxWidth='md'>
            <Box height="100%" clone>
              <Grid container alignItems="stretch">
                <Grid item xs={12}>
                  <DataGrid
                    data={dataQuery.data}
                    filterMode="server"
                    idProperty={PoisonMessageKeys.Id}
                    ignoredProperties={[PoisonMessageKeys.Id]}
                    loading={dataQuery.isLoading}
                    onFilterChange={setFilter}
                    onPageChange={pagination.setPage}
                    onRowsPerPageChange={pagination.setRowsPerPage}
                    onSortChange={setSort}
                    pagination
                    paginationMode="server"
                    page={pagination.page}
                    schema={descQuery.data.ViewDescription}
                    sort={sort}
                    sortingMode="server"
                    rowsPerPage={pagination.rowsPerPage}
                    rowsPerPageOptions={pagination.rowsPerPageOptions}
                    totalRows={dataQuery.data?.Pagination.TotalCount}
                    widths={{
                      [PoisonMessageKeys.RawMessage]: 300,
                      [PoisonMessageKeys.Exception]: 300
                    }}
                  />
                </Grid>
              </Grid>
            </Box>
          </Container>
        </Box>
      }
      { pageLoading &&
        <PageLoading/>
      }
    </>
  )
}

export default PoisonMessages
