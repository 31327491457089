import React, { FunctionComponent, useMemo, useState } from 'react'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { defineMessages, useIntl } from 'react-intl'
import { NumericArrayParam, useQueryParam } from 'use-query-params'
import { isNil } from 'lodash'
import { Container, Grid, makeStyles } from '@material-ui/core'
import { useSnackbar } from 'notistack'
import AlertDialogue from '../../../components/AlertDialogue'
import CollapsibleTable from '../../../components/Table/CollapsibleTable'
import DataForm from '../../../components/DataForm'
import PageLoading from '../../../components/Loading/PageLoading'
import UnsavedDialogue from '../../../components/UnsavedDialogue'
import useConfigure, { ConfigurationMode } from '../../../hooks/useConfigure'
import useUnsaved from '../../../hooks/useUnsaved'
import {
  DataTypeKeys,
  GetListResponse,
  NodeTemplatePropertyKeys,
  Paths,
  PropertyLookup,
  TemplatePropertyGroupKeys,
  useApi
} from '../../../api/RcfactoryApi'

const useStyles = makeStyles((theme) => ({
  container: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(3)
  }
}))

enum ParamKeys {
  GroupId = 'groupId',
  Opened = 'opened',
  PropertyId = 'propertyId'
}

enum ConfigurationKeys {
  Group = 'group',
  Property = 'property'
}

enum AlertSelection {
  Group,
  Property
}

const messages = defineMessages({
  alertGroupMessage: {
    id: 'templateProperties.alertGroupMessage',
    description: 'Delete group alert dialogue message content',
    defaultMessage: 'Are you sure you want to delete the selected Template Property ' +
      '{count, plural, one{Group} other{Groups}}?'
  },
  alertGroupTitle: {
    id: 'templateProperties.alertGroupTitle',
    description: 'Delete group alert dialogue title',
    defaultMessage: 'Delete Template Property ' +
      '{count, plural, one{Group} other{Groups}}'
  },
  alertPropertyMessage: {
    id: 'templateProperties.alertPropertyMessage',
    description: 'Delete property alert dialogue message content',
    defaultMessage: 'Are you sure you want to delete the selected Node Template ' +
      '{count, plural, one{Property} other{Properties}}?'
  },
  alertPropertyTitle: {
    id: 'templateProperties.alertPropertyTitle',
    description: 'Delete property alert dialogue title',
    defaultMessage: 'Delete Node Template ' +
      '{count, plural, one{Property} other{Properties}}'
  }
})

const TemplateProperties: FunctionComponent = () => {
  const classes = useStyles()
  const intl = useIntl()
  const api = useApi()
  const groupConfigurator = useConfigure(ConfigurationKeys.Group)
  const propertyConfigurator = useConfigure(ConfigurationKeys.Property)
  const unsavedProperties = useUnsaved(NodeTemplatePropertyKeys.Id)
  const [alertSelection, setAlertSelection] = useState<AlertSelection>()
  const [opened, setOpened] = useQueryParam(
    ParamKeys.Opened, NumericArrayParam
  )
  const [selectedGroups, setSelectedGroups] = useQueryParam(
    ParamKeys.GroupId, NumericArrayParam
  )
  const [selectedProperties, setSelectedProperties] = useQueryParam(
    ParamKeys.PropertyId, NumericArrayParam
  )
  const { enqueueSnackbar } = useSnackbar()
  const queryClient = useQueryClient()

  const dataTypesQuery = useQuery(
    Paths.DataTypes,
    () => api.getList({
      path: Paths.DataTypes
    }), {
      onError: () => enqueueSnackbar(
        intl.formatMessage({
          id: 'templateProperties.failedDataTypes',
          description: 'Fetch data types error message',
          defaultMessage: 'Failed to get Data Types!'
        }), {
          variant: 'error'
        }
      )
    }
  )

  const groupsQuery = useQuery(
    Paths.TemplatePropertyGroups,
    () => api.getList({
      path: Paths.TemplatePropertyGroups
    }), {
      onError: () => enqueueSnackbar(
        intl.formatMessage({
          id: 'templateProperties.failedPropertyGroups',
          description: 'Fetch template property groups error message',
          defaultMessage: 'Failed to get Template Property Groups!'
        }), {
          variant: 'error'
        }
      )
    }
  )

  const groupsDescQuery = useQuery(
    Paths.TemplatePropertyGroups + Paths.UtilsGetDesc,
    () => api.getDesc({
      path: Paths.TemplatePropertyGroups
    }), {
      onError: () => enqueueSnackbar(
        intl.formatMessage({
          id: 'templateProperties.failedPropertyGroupSchema',
          description: 'Fetch template property group schema error message',
          defaultMessage: 'Failed to get Template Property Group Schema!'
        }), {
          variant: 'error'
        }
      )
    }
  )

  const groupsCreateMutation = useMutation(
    (items: Record<string, unknown>[]) => api.create({
      items: items,
      path: Paths.TemplatePropertyGroups
    }), {
      onError: () => {
        enqueueSnackbar(
          intl.formatMessage({
            id: 'templateProperties.failedGroupCreate',
            description: 'Create template property group error message',
            defaultMessage: 'Failed to create Template Property Group!'
          }), {
            variant: 'error'
          }
        )
      },
      onSuccess: () => {
        enqueueSnackbar(
          intl.formatMessage({
            id: 'templateProperties.successfulGroupCreate',
            description: 'Create template property group success message',
            defaultMessage: 'Successfully created Template Property Group!'
          }), {
            variant: 'success'
          }
        )
        groupConfigurator.clear()
        queryClient.invalidateQueries(Paths.TemplatePropertyGroups)
      }
    }
  )

  const groupsDeleteMutation = useMutation(
    (ids: number[]) => api.delete({
      ids: ids,
      path: Paths.TemplatePropertyGroups
    }), {
      onError: () => {
        enqueueSnackbar(
          intl.formatMessage({
            id: 'templateProperties.failedGroupDelete',
            description: 'Delete template property group error message',
            defaultMessage: 'Failed to delete Template Property Groups!'
          }), {
            variant: 'error'
          }
        )
      },
      onSuccess: () => {
        enqueueSnackbar(
          intl.formatMessage({
            id: 'templateProperties.successfulGroupDelete',
            description: 'Delete template property grroup success message',
            defaultMessage: 'Successfully deleted Template Property Groups!'
          }), {
            variant: 'success'
          }
        )
        setSelectedGroups(undefined)
        queryClient.invalidateQueries(Paths.TemplatePropertyGroups)
      }
    }
  )

  const groupsUpdateMutation = useMutation(
    (items: Record<string, unknown>[]) => api.update({
      items: items,
      path: Paths.TemplatePropertyGroups
    }), {
      onError: () => {
        enqueueSnackbar(
          intl.formatMessage({
            id: 'templateProperties.failedGroupUpdate',
            description: 'Update template property group error message',
            defaultMessage: 'Failed to update Template Property Group!'
          }), {
            variant: 'error'
          }
        )
      },
      onSuccess: () => {
        enqueueSnackbar(
          intl.formatMessage({
            id: 'templateProperties.successfulGroupUpdate',
            description: 'Update template property group success message',
            defaultMessage: 'Successfully updated Template Property Group!'
          }), {
            variant: 'success'
          }
        )
        groupConfigurator.clear()
        queryClient.invalidateQueries(Paths.TemplatePropertyGroups)
      }
    }
  )

  const propertiesQuery = useQuery(
    Paths.NodeTemplateProperties,
    () => api.getList({
      path: Paths.NodeTemplateProperties
    }), {
      onError: () => enqueueSnackbar(
        intl.formatMessage({
          id: 'templateProperties.failedProperties',
          description: 'Fetch template properties error message',
          defaultMessage: 'Failed to get Node Template Properties!'
        }), {
          variant: 'error'
        }
      ),
      onSuccess: (data: GetListResponse) => unsavedProperties.refresh(data.Items)
    }
  )

  const propertiesDescQuery = useQuery(
    Paths.NodeTemplateProperties + Paths.UtilsGetDesc,
    () => api.getDesc({
      path: Paths.NodeTemplateProperties
    }), {
      onError: () => enqueueSnackbar(
        intl.formatMessage({
          id: 'templateProperties.failedPropertySchema',
          description: 'Fetch template property schema error message',
          defaultMessage: 'Failed to get Node Template Property Schema!'
        }), {
          variant: 'error'
        }
      )
    }
  )

  const propertiesCreateMutation = useMutation(
    (items: Record<string, unknown>[]) => api.create({
      items: items,
      path: Paths.NodeTemplateProperties
    }), {
      onError: () => {
        enqueueSnackbar(
          intl.formatMessage({
            id: 'templateProperties.failedPropertyCreate',
            description: 'Create template property error message',
            defaultMessage: 'Failed to create Node Template Property!'
          }), {
            variant: 'error'
          }
        )
      },
      onSuccess: () => {
        enqueueSnackbar(
          intl.formatMessage({
            id: 'templateProperties.successfulPropertyCreate',
            description: 'Create template property success message',
            defaultMessage: 'Successfully created Node Template Property!'
          }), {
            variant: 'success'
          }
        )
        propertyConfigurator.clear()
        queryClient.invalidateQueries(Paths.NodeTemplateProperties)
      }
    }
  )

  const propertiesDeleteMutation = useMutation(
    (ids: number[]) => api.delete({
      ids: ids,
      path: Paths.NodeTemplateProperties
    }), {
      onError: () => {
        enqueueSnackbar(
          intl.formatMessage({
            id: 'templateProperties.failedPropertyDelete',
            description: 'Delete template property error message',
            defaultMessage: 'Failed to delete Node Template Properties!'
          }), {
            variant: 'error'
          }
        )
      },
      onSuccess: () => {
        enqueueSnackbar(
          intl.formatMessage({
            id: 'templateProperties.successfulPropertyDelete',
            description: 'Delete template property success message',
            defaultMessage: 'Successfully deleted Node Template Properties!'
          }), {
            variant: 'success'
          }
        )
        setSelectedProperties(undefined)
        queryClient.invalidateQueries(Paths.NodeTemplateProperties)
      }
    }
  )

  const propertiesUpdateMutation = useMutation(
    (items: Record<string, unknown>[]) => api.update({
      items: items,
      path: Paths.NodeTemplateProperties
    }), {
      onError: () => {
        enqueueSnackbar(
          intl.formatMessage({
            id: 'templateProperties.failedPropertyUpdate',
            description: 'Update template property error message',
            defaultMessage: 'Failed to update Node Template Property!'
          }), {
            variant: 'error'
          }
        )
      },
      onSuccess: () => {
        enqueueSnackbar(
          intl.formatMessage({
            id: 'templateProperties.successfulPropertyUpdate',
            description: 'Update template property success message',
            defaultMessage: 'Successfully updated Node Template Property!'
          }), {
            variant: 'success'
          }
        )
        propertyConfigurator.clear()
        queryClient.invalidateQueries(Paths.NodeTemplateProperties)
      }
    }
  )
  const activeGroup = useMemo(() => {
    if (!groupsQuery.data?.Items) {
      return
    }
    if (selectedGroups?.length !== 1) {
      return
    }
    return groupsQuery.data.Items.find(
      (group) => group[TemplatePropertyGroupKeys.Id] === selectedGroups[0]
    )
  }, [groupsQuery.data?.Items, selectedGroups])

  const activeProperty = useMemo(() => {
    if (!propertiesQuery.data?.Items) {
      return
    }
    if (selectedProperties?.length !== 1) {
      return
    }
    return propertiesQuery.data.Items.find(
      (property) => property[NodeTemplatePropertyKeys.Id] === selectedProperties[0]
    )
  }, [propertiesQuery.data?.Items, selectedProperties])

  const propertyLookupProperties: PropertyLookup[] | null = useMemo(() => {
    if (!dataTypesQuery.data?.Items || !groupsQuery.data?.Items) {
      return null
    }
    return [{
      data: groupsQuery.data?.Items,
      localProperty: NodeTemplatePropertyKeys.TemplatePropertyGroupId,
      nameProperty: TemplatePropertyGroupKeys.Name,
      remoteProperty: TemplatePropertyGroupKeys.Id
    }, {
      data: dataTypesQuery.data?.Items,
      localProperty: NodeTemplatePropertyKeys.DataTypeId,
      nameProperty: DataTypeKeys.Name,
      remoteProperty: DataTypeKeys.Id
    }]
  }, [dataTypesQuery.data?.Items, groupsQuery.data?.Items])

  const handleAddGroupSubmit = () => {
    if (isNil(groupConfigurator.data)) {
      throw Error('Cannot handleAddGroupSubmit if groupConfigurator data is nil!')
    }
    groupsCreateMutation.mutate([groupConfigurator.data])
  }

  const handleAddProperty = (parentId: number | string | null) => {
    if (!propertiesDescQuery.data?.CrudDescription) {
      throw Error('Cannot handleAddProperty if CrudDescription is null!')
    }
    propertyConfigurator.create(
      propertiesDescQuery.data.CrudDescription.Properties, {
        [NodeTemplatePropertyKeys.TemplatePropertyGroupId]: parentId
      }
    )
  }

  const handleAddPropertySubmit = () => {
    if (isNil(propertyConfigurator.data)) {
      throw Error('Cannot handleAddPropertySubmit if propertyConfigurator data is null!')
    }
    propertiesCreateMutation.mutate([propertyConfigurator.data])
  }

  const handleAlertCancel = () => {
    setAlertSelection(undefined)
  }

  const handleAlertDeleteGroup = () => {
    if (isNil(selectedGroups)) {
      throw Error('Cannot handleAlertDeleteGroup if selectedGroups is nil!')
    }
    groupsDeleteMutation.mutate(selectedGroups.map(Number))
    setAlertSelection(undefined)
  }

  const handleAlertDeleteProperty = () => {
    if (isNil(selectedProperties)) {
      throw Error('Cannot handleAlertDeleteGroup if selectedProperties is nil!')
    }
    propertiesDeleteMutation.mutate(selectedProperties.map(Number))
    setAlertSelection(undefined)
  }

  const handleDeleteGroup = () => {
    setAlertSelection(AlertSelection.Group)
  }

  const handleDeleteProperty = () => {
    setAlertSelection(AlertSelection.Property)
  }

  const handleEditGroupSubmit = () => {
    if (isNil(groupConfigurator.data)) {
      throw Error('Cannot handleEditGroupSubmit if groupEditData is nil!')
    }
    groupsUpdateMutation.mutate([groupConfigurator.data])
  }

  const handleEditPropertySubmit = () => {
    if (isNil(propertyConfigurator.data)) {
      throw Error('Cannot handleEditPropertySubmit if propertyEditData is nil!')
    }
    propertiesUpdateMutation.mutate([propertyConfigurator.data])
  }

  const handleFormCancel = () => {
    groupConfigurator.clear()
    propertyConfigurator.clear()
  }

  const handleOpen = (ids: (number | string | null)[]) => {
    setOpened(ids as (number | null)[])
  }

  const handleSave = () => {
    propertiesUpdateMutation.mutate(unsavedProperties.edits)
  }

  const handleSelectGroup = (ids: (number | string | null)[]) => {
    setSelectedGroups(ids.length > 0 ? ids.map(Number) : undefined)
  }

  const handleSelectProperty = (ids: (number | string | null)[]) => {
    setSelectedProperties(ids.length > 0 ? ids.map(Number) : undefined)
  }

  const pageReady = dataTypesQuery.isSuccess && groupsQuery.isSuccess &&
    groupsDescQuery.isSuccess && propertiesDescQuery.isSuccess &&
    propertiesQuery.isSuccess && !propertiesCreateMutation.isLoading &&
    !propertiesDeleteMutation.isLoading && !propertiesUpdateMutation.isLoading

  const pageLoading = dataTypesQuery.isLoading || groupsQuery.isLoading ||
    groupsDescQuery.isLoading || propertiesDescQuery.isLoading ||
    propertiesQuery.isLoading || propertiesCreateMutation.isLoading ||
    propertiesDeleteMutation.isLoading || propertiesUpdateMutation.isLoading

  let handleAlertDelete
  let alertMessage
  let alertTitle
  switch (alertSelection) {
    case AlertSelection.Group:
      handleAlertDelete = handleAlertDeleteGroup
      alertMessage = intl.formatMessage(
        messages.alertGroupMessage,
        { count: selectedGroups?.length }
      )
      alertTitle = intl.formatMessage(
        messages.alertGroupTitle,
        { count: selectedGroups?.length }
      )
      break

    default:
      handleAlertDelete = handleAlertDeleteProperty
      alertMessage = intl.formatMessage(
        messages.alertPropertyMessage,
        { count: selectedProperties?.length }
      )
      alertTitle = intl.formatMessage(
        messages.alertPropertyTitle,
        { count: selectedProperties?.length }
      )
      break
  }

  return (
    <>
      { pageReady &&
        <>
          { groupConfigurator.data &&
            groupsDescQuery.data?.CrudDescription &&
            <DataForm
              formSections={[{
                data: groupConfigurator.data,
                ignoredProperties: [TemplatePropertyGroupKeys.Id],
                onPropertyChange: (property, value) => groupConfigurator.update({
                  [property]: value
                }),
                schema: groupsDescQuery.data.CrudDescription
              }]}
              onCancel={handleFormCancel}
              onSubmit={groupConfigurator.mode === ConfigurationMode.Create
                ? handleAddGroupSubmit
                : handleEditGroupSubmit
              }
              title={groupConfigurator.mode === ConfigurationMode.Create
                ? intl.formatMessage({
                  id: 'templateProperties.createGroup',
                  description: 'Create node template property group dialogue title',
                  defaultMessage: 'Create Template Property Group'
                })
                : intl.formatMessage({
                  id: 'templateProperties.editGroup',
                  description: 'Edit template property group dialogue title',
                  defaultMessage: 'Edit Template Property Group'
                })
              }
            />
          }
          { propertyConfigurator.data &&
            propertyLookupProperties &&
            propertiesDescQuery.data?.CrudDescription &&
            <DataForm
              formSections={[{
                data: propertyConfigurator.data,
                ignoredProperties: [
                  NodeTemplatePropertyKeys.CreateDateTime,
                  NodeTemplatePropertyKeys.GroupOrdinal,
                  NodeTemplatePropertyKeys.Id
                ],
                lookupProperties: propertyLookupProperties,
                onPropertyChange: (property, value) => propertyConfigurator.update({
                  [property]: value
                }),
                schema: propertiesDescQuery.data.CrudDescription
              }]}
              onCancel={handleFormCancel}
              onSubmit={propertyConfigurator.mode === ConfigurationMode.Create
                ? handleAddPropertySubmit
                : handleEditPropertySubmit
              }
              title={propertyConfigurator.mode === ConfigurationMode.Create
                ? intl.formatMessage({
                  id: 'templateProperties.createProperty',
                  description: 'Create node template property dialogue title',
                  defaultMessage: 'Create Node Template Property'
                })
                : intl.formatMessage({
                  id: 'templateProperties.editProperty',
                  description: 'Edit node template property dialogue title',
                  defaultMessage: 'Edit Node Template Property'
                })
              }
            />
          }
          <Container
            className={classes.container}
            maxWidth='md'
          >
            <Grid container>
              <Grid item xs={12}>
                { groupsQuery.data?.Items &&
                  groupsDescQuery.data &&
                  unsavedProperties.copy &&
                  propertiesDescQuery.data?.ViewDescription &&
                  <CollapsibleTable
                    dataInner={[{
                      data: unsavedProperties.copy,
                      idProperty: NodeTemplatePropertyKeys.Id,
                      ignoredKeys: [
                        NodeTemplatePropertyKeys.CreateDateTime,
                        NodeTemplatePropertyKeys.DataTypeId,
                        NodeTemplatePropertyKeys.TemplatePropertyGroupId,
                        NodeTemplatePropertyKeys.GroupOrdinal,
                        NodeTemplatePropertyKeys.GroupName
                      ],
                      multiSelect: true,
                      onAdd: handleAddProperty,
                      onDelete: handleDeleteProperty,
                      onEdit: !isNil(activeProperty)
                        ? () => propertyConfigurator.edit(activeProperty)
                        : undefined,
                      onPropertyChange: unsavedProperties.update,
                      onSelect: handleSelectProperty,
                      ordinalProperty: NodeTemplatePropertyKeys.GroupOrdinal,
                      parentIdProperty: NodeTemplatePropertyKeys.TemplatePropertyGroupId,
                      schema: propertiesDescQuery.data.ViewDescription,
                      selected: selectedProperties,
                      title: intl.formatMessage({
                        id: 'templateProperties.templateProperties',
                        description: 'Template properties table title',
                        defaultMessage: 'Template Properties'
                      })
                    }]}
                    dataOuter={groupsQuery.data.Items}
                    idProperty={TemplatePropertyGroupKeys.Id}
                    ignoredKeys={[TemplatePropertyGroupKeys.Id]}
                    multiSelect
                    onAdd={() => groupConfigurator.create(
                      groupsDescQuery.data.CrudDescription.Properties
                    )}
                    onDelete={handleDeleteGroup}
                    onDiscard={unsavedProperties.discard}
                    onEdit={activeGroup
                      ? () => groupConfigurator.edit(activeGroup)
                      : undefined
                    }
                    onOpen={handleOpen}
                    onSave={handleSave}
                    onSelect={handleSelectGroup}
                    opened={opened || []}
                    schema={groupsDescQuery.data.ViewDescription}
                    selected={selectedGroups}
                    title={intl.formatMessage({
                      id: 'templateProperties.templatePropertyGroups',
                      description: 'Template property groups table title',
                      defaultMessage: 'Template Property Groups'
                    })}
                    unsavedChanges={unsavedProperties.hasChanged}
                  />
                }
              </Grid>
            </Grid>
          </Container>
        </>
      }
      { pageLoading &&
        <PageLoading/>
      }
      <AlertDialogue
        actions={[{
          handler: handleAlertDelete,
          text: intl.formatMessage({
            id: 'templateProperties.alertDelete',
            description: 'Delete alert dialogue, delete button text',
            defaultMessage: 'Delete'
          })
        }, {
          handler: handleAlertCancel,
          text: intl.formatMessage({
            id: 'templateProperties.alertCancel',
            description: 'Delete alert dialogue, cancel button text',
            defaultMessage: 'Cancel'
          })
        }]}
        message={alertMessage}
        open={!isNil(alertSelection)}
        title={alertTitle}
      />
      <UnsavedDialogue
        options={[{
          onDiscard: unsavedProperties.discard,
          onSave: handleSave,
          text: intl.formatMessage({
            id: 'templateProperties.unsavedMessage',
            description: 'Unsaved changes to properties warning message',
            defaultMessage: 'There are unsaved changes to Node Template Properties!'
          }),
          unsavedChanges: unsavedProperties.hasChanged
        }]}
      />
    </>
  )
}

export default TemplateProperties
