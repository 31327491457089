import React, {
  FunctionComponent,
  useCallback,
  useEffect,
  useRef,
  useState
} from 'react'
import {
  DragSourceMonitor,
  DropTargetMonitor,
  useDrag,
  useDrop
} from 'react-dnd'
import { isNil } from 'lodash'
import { HierarchyNode } from 'd3-hierarchy'
import clsx from 'clsx'
import { Box, makeStyles, Menu, MenuItem, Typography } from '@material-ui/core'
import { Add } from '@material-ui/icons'
import { TreeItem } from '@material-ui/lab'
import { DropRegion, ItemType } from './Hierarchy'
import TreeFork from './TreeFork'
import { theme } from '../../theme'

const hoverEdge = 1 / 4
const hoverBorderThickness = theme.spacing(0.5)

const useStyles = makeStyles((theme) => ({
  bottom: {
    borderWidth: hoverBorderThickness,
    borderColor: theme.palette.secondary.main,
    borderBottomStyle: 'solid'
  },
  parent: {
    color: theme.palette.secondary.main,
    fontWeight: theme.typography.fontWeightBold
  },
  top: {
    borderWidth: hoverBorderThickness,
    borderColor: theme.palette.secondary.main,
    borderTopStyle: 'solid'
  },
  inactive: {
    color: theme.palette.text.disabled
  }
}))

interface DragItem {
  id?: number
  region?: DropRegion
}

interface Props {
  activeProperty?: string
  dropRegion?: DropRegion
  idProperty: string
  label?: string
  nameProperty: string
  node: HierarchyNode<Record<string, unknown>>
  onAdd?: (initial: Record<string, unknown>) => void
  onDrop?: (dragId: number, dropId: number, region: DropRegion) => void
  onDropRegionChange: (id: number, region?: DropRegion) => void
  parentIdProperty: string
}

const TreeBranch: FunctionComponent<Props> = (props: Props) => {
  const {
    activeProperty,
    dropRegion,
    idProperty,
    label,
    nameProperty,
    node,
    onAdd,
    onDrop,
    onDropRegionChange,
    parentIdProperty
  } = props
  const classes = useStyles()
  const [dropBottom, setDropBottom] = useState<boolean>(false)
  const [dropParent, setDropParent] = useState<boolean>(false)
  const [dropTop, setDropTop] = useState<boolean>(false)
  const [dropWithin, setDropWithin] = useState<boolean>(false)
  const ref = useRef<HTMLDivElement>(null)
  const [, drag] = useDrag({
    type: ItemType.Branch,
    item: { id: Number(node.id) },
    end: (item: DragItem, monitor: DragSourceMonitor<DragItem>) => {
      if (!onDrop) {
        throw Error('Cannot handle drag end if onDrop is undefined!')
      }
      const dropResult = monitor.getDropResult<DragItem>()
      if (item?.id && dropResult?.id && dropResult?.region) {
        if (item.id !== dropResult.id) {
          onDrop(item.id, dropResult.id, dropResult.region)
        }
      }
    }
  })
  const [{ isOverCurrent }, drop] = useDrop({
    accept: ItemType.Branch,
    drop(_item, monitor) {
      const didDrop = monitor.didDrop()
      if (didDrop) {
        return
      }
      return {
        id: Number(node.id),
        region: dropRegion
      }
    },
    hover: (item: DragItem, monitor: DropTargetMonitor) => {
      if (!ref.current || !isOverCurrent) {
        return
      }
      const dragId = item.id
      if (isNil(dragId)) {
        return
      }

      // Don't replace items with themselves
      const hoverId = Number(node.id)
      if (dragId === hoverId) {
        return
      }

      // Determine rectangle on screen
      const hoverBoundingRect = ref.current?.getBoundingClientRect()

      // Get edge vertical positions
      const hoverUpperY =
        hoverBoundingRect.top + hoverBoundingRect.height * hoverEdge
      const hoverLowerY =
        hoverBoundingRect.bottom - hoverBoundingRect.height * hoverEdge

      // Determine vertical mouse position
      const clientOffset = monitor.getClientOffset()
      let hoverClientY = clientOffset?.y
      if (!hoverClientY) {
        return
      }

      // offset mouse by rendered border for comparison
      if (dropRegion === DropRegion.Bottom) {
        hoverClientY += hoverBorderThickness
      } else if (dropRegion === DropRegion.Top) {
        hoverClientY -= hoverBorderThickness
      }

      // determine hover region
      let region: DropRegion
      if (hoverClientY < hoverUpperY) {
        region = DropRegion.Top
      } else if (hoverClientY > hoverLowerY) {
        region = DropRegion.Bottom
      } else {
        region = DropRegion.Middle
      }

      // raise change event
      onDropRegionChange(hoverId, region)
    },
    collect: (monitor) => ({
      isOverCurrent: monitor.isOver({ shallow: true })
    })
  })
  const [mouseY, setMouseY] = useState<number | null>(null)
  const [mouseX, setMouseX] = useState<number | null>(null)

  useEffect(() => {
    if (!isOverCurrent) {
      onDropRegionChange(Number(node.id), undefined)
    }
  }, [node, idProperty, isOverCurrent, onDropRegionChange])

  useEffect(() => {
    const newDropBottom = dropRegion === DropRegion.Bottom
    if (newDropBottom !== dropBottom) {
      setDropBottom(newDropBottom)
    }
    const newDropParent = dropWithin || dropRegion === DropRegion.Middle
    if (newDropParent !== dropParent) {
      setDropParent(newDropParent)
    }
    const newDropTop = dropRegion === DropRegion.Top
    if (newDropTop !== dropTop) {
      setDropTop(newDropTop)
    }
  }, [dropBottom, dropRegion, dropTop, dropWithin, dropParent])

  const handleDropWithin = useCallback(
    (within: boolean) => {
      if (dropWithin !== within) {
        setDropWithin(within)
      }
    },
    [dropWithin]
  )

  const handleMenuClose = () => {
    setMouseY(null)
    setMouseX(null)
  }

  const handleRightClick = (event: React.MouseEvent<HTMLDivElement>) => {
    event.preventDefault()
    setMouseY(event.clientY - 4)
    setMouseX(event.clientX - 2)
  }

  const handleAddClick = () => {
    if (!onAdd) {
      throw Error('Cannot handleAddClick if onAdd is undefined!')
    }
    onAdd({ [parentIdProperty]: Number(node.id) })
    handleMenuClose()
  }

  if (onDrop) {
    drag(drop(ref))
  }

  const inactive = activeProperty && !node.data[activeProperty]

  return (
    <>
      <TreeItem
        icon={dropParent ? <Add /> : undefined}
        label={
          <div
            onContextMenu={onAdd ? handleRightClick : undefined}
            style={{ cursor: 'context-menu' }}
            ref={ref}
          >
            <Typography component="div">
              <Box
                className={clsx({
                  [classes.inactive]: inactive,
                  [classes.bottom]: dropBottom,
                  [classes.parent]: dropParent,
                  [classes.top]: dropTop
                })}
              >
                {String(node.data[nameProperty])}
              </Box>
            </Typography>
          </div>
        }
        nodeId={String(node.id)}
      >
        {node.children && (
          <TreeFork
            activeProperty={activeProperty}
            data={node.children}
            idProperty={idProperty}
            label={label}
            nameProperty={nameProperty}
            onAdd={onAdd}
            onDragWithin={handleDropWithin}
            onDrop={onDrop}
            parentIdProperty={parentIdProperty}
          />
        )}
      </TreeItem>
      <Menu
        keepMounted
        open={mouseY !== null}
        onClose={handleMenuClose}
        anchorReference="anchorPosition"
        anchorPosition={
          mouseY !== null && mouseX !== null
            ? { top: mouseY, left: mouseX }
            : undefined
        }
      >
        <MenuItem onClick={handleAddClick}>{'Add ' + label}</MenuItem>
      </Menu>
    </>
  )
}

export default TreeBranch
