import React, { FunctionComponent, useEffect, useState } from 'react'
import {
  Redirect,
  Route,
  Switch,
  useHistory,
  useRouteMatch
} from 'react-router-dom'
import { useIntl } from 'react-intl'
import { Collapse, ListItemIcon, ListItemText } from '@material-ui/core'
import { Business, ExpandLess, ExpandMore } from '@material-ui/icons'
import { Routes as AppRoutes } from '../../App'
import { Routes as HomeRoutes } from '../Home'
import Contents from '../../components/Contents'
import DataConfiguration, {
  DataConfigurationSideMenu
} from './DataConfiguration/DataConfiguration'
import FactoryConfiguration, {
  FactoryConfigurationSideMenu
} from './FactoryConfiguration/FactoryConfiguration'
import IotConfiguration, {
  IotConfigurationSideMenu
} from './IotConfiguration/IotConfiguration'
import hero from '../../images/factory_manager_hero.jpg'
import dataConfiguration from '../../images/data_configuration_card.jpg'
import factoryConfiguration from '../../images/factory_configuration_card.jpg'
import iotConfiguration from '../../images/iot_configuration_card.jpg'
import SideMenuCustomListItem from '../../components/SideMenuCustomListItem'

export enum Routes {
  DataConfiguration = '/data-configuration',
  FactoryConfiguration = '/factory-configuration',
  IotConfiguration = '/iot-configuration'
}

interface SideMenuProps {
  classes: Record<string, string | undefined>
}

export const FactoryManagerSideMenu: FunctionComponent<SideMenuProps> = (
  props: SideMenuProps
) => {
  const { classes } = props
  const managerMatch = useRouteMatch({
    path: HomeRoutes.FactoryManager
  })
  const history = useHistory()
  const intl = useIntl()
  const [managerOpen, setManagerOpen] = useState(false)

  useEffect(() => {
    setManagerOpen(Boolean(managerMatch))
  }, [managerMatch])

  const handleManagerClick = () => {
    if (!managerMatch) {
      setManagerOpen(!managerOpen)
    } else {
      history.push(HomeRoutes.FactoryManager)
    }
  }

  return (
    <>
      <SideMenuCustomListItem button onClick={handleManagerClick}>
        <ListItemIcon>
          <Business />
        </ListItemIcon>
        <ListItemText
          primary={intl.formatMessage({
            id: 'factoryManager.sideMenu.factoryManager',
            description: 'Factory manager side menu item',
            defaultMessage: 'Factory Manager'
          })}
        />
        {!managerMatch && <>{managerOpen ? <ExpandLess /> : <ExpandMore />}</>}
      </SideMenuCustomListItem>
      <Collapse
        in={managerOpen}
        timeout="auto"
        unmountOnExit
        className={classes.collapse}
      >
        <FactoryConfigurationSideMenu classes={classes} />
        <DataConfigurationSideMenu classes={classes} />
        <IotConfigurationSideMenu classes={classes} />
      </Collapse>
    </>
  )
}

const FactoryManagerContent: FunctionComponent = () => {
  const intl = useIntl()
  const match = useRouteMatch()

  const items = [
    {
      image: factoryConfiguration,
      route: match.path + Routes.FactoryConfiguration,
      text: intl.formatMessage({
        id: 'factoryManager.factoryConfigurationDescription',
        description: 'Factory manager page, factory configuration description',
        defaultMessage:
          'Configure factory node instances, templates and properties.'
      }),
      title: intl.formatMessage({
        id: 'factoryManager.factoryConfiguration',
        description: 'Factory manager page, factory configuration item',
        defaultMessage: 'Factory Configuration'
      })
    },
    {
      image: dataConfiguration,
      route: match.path + Routes.DataConfiguration,
      text: intl.formatMessage({
        id: 'factoryManager.dataConfigurationDescription',
        description: 'Factory manager page, data configuration description',
        defaultMessage: 'Configure data categories and map data to nodes.'
      }),
      title: intl.formatMessage({
        id: 'factoryManager.dataConfiguration',
        description: 'Factory manager page, data configuration item',
        defaultMessage: 'Data Configuration'
      })
    },
    {
      image: iotConfiguration,
      route: match.path + Routes.IotConfiguration,
      text: intl.formatMessage({
        id: 'factoryManager.iotConfigurationDescription',
        description: 'Factory manager page, IoT configuration description',
        defaultMessage: 'Create and manage your IoT Devices.'
      }),
      title: intl.formatMessage({
        id: 'factoryManeger.iotConfiguration',
        description: 'Factory manager page, IoT configuration item',
        defaultMessage: 'IoT Configuration'
      })
    }
  ]

  return (
    <Contents
      hero={hero}
      items={items}
      title={intl.formatMessage({
        id: 'factoryManager.factoryManager',
        description: 'Factory manager page title',
        defaultMessage: 'Factory Manager'
      })}
    />
  )
}

const FactoryManager: FunctionComponent = () => {
  const { path } = useRouteMatch()

  return (
    <Switch>
      <Route path={path + Routes.FactoryConfiguration}>
        <FactoryConfiguration />
      </Route>
      <Route path={path + Routes.DataConfiguration}>
        <DataConfiguration />
      </Route>
      <Route path={path + Routes.IotConfiguration}>
        <IotConfiguration />
      </Route>
      <Route exact path={path}>
        <FactoryManagerContent />
      </Route>
      <Redirect to={AppRoutes.PageNotFound} />
    </Switch>
  )
}

export default FactoryManager
