import { theme, themeCustomLight } from './theme'
// import themeCustomLight from './themeLight'
import { Theme } from '@material-ui/core'
import { ThemeType } from './components/CustomThemeContext'

export default function getTheme(themeType: ThemeType): Theme {
  switch (themeType) {
    case ThemeType.Dark:
      return theme
    case ThemeType.Light:
      return themeCustomLight
  }
}
