import React, {
  FunctionComponent,
  useCallback,
  useEffect,
  useMemo,
  useState
} from 'react'
import { includes, isEqual, isNil, isNull, sortBy } from 'lodash'
import { HierarchyNode, stratify } from 'd3-hierarchy'
import { Box, Paper } from '@material-ui/core'
import { TreeView } from '@material-ui/lab'
import {
  ExpandMore,
  ChevronRight,
  Add,
  Delete,
  Edit,
  ArrowDownward,
  ArrowUpward,
  Clear,
  Save
} from '@material-ui/icons'
import Toolbar from '../Toolbar'
import TreeFork from './TreeFork'

const defaultExpandedLevel = 3

export enum ItemType {
  Branch = 'branch'
}

export enum DropRegion {
  Bottom,
  Middle,
  Top
}

enum InsertPosition {
  After,
  Before,
  End,
  Start
}

interface Props {
  activeProperty?: string
  data: Record<string, unknown>[]
  expandedLevel?: number
  idProperty: string
  label?: string
  multiSelect?: boolean
  nameProperty: string
  onAdd?: (initial: Record<string, unknown>) => void
  onDelete?: () => void
  onDiscard?: () => void
  onEdit?: () => void
  onSave?: () => void
  onSelect?: (id: number[]) => void
  onUpdate?: (
    changes: {
      data: Record<string, unknown>
      property: string
      value: unknown
    }[]
  ) => void
  ordinalProperty?: string
  parentIdProperty: string
  selected?: (number | string | null)[] | null
  title: string
  unsavedChanges?: boolean
}

const Hierarchy: FunctionComponent<Props> = (props: Props) => {
  const {
    activeProperty,
    data,
    expandedLevel,
    idProperty,
    label,
    multiSelect,
    nameProperty,
    onAdd,
    onDelete,
    onDiscard,
    onEdit,
    onSave,
    onSelect,
    onUpdate,
    ordinalProperty,
    parentIdProperty,
    selected,
    title,
    unsavedChanges
  } = props
  const [expanded, setExpanded] = useState<string[]>([])
  const [hierarchy, setHierarchy] =
    useState<HierarchyNode<Record<string, unknown>>>()
  const [firstRun, setFirstRun] = useState<boolean>(true)

  const hasValidAncestry = useCallback(
    (parentId: number | null, traversedIds: number[]): boolean => {
      if (isNil(parentId)) {
        return true
      }
      if (traversedIds.includes(parentId)) {
        return false
      }
      const parent = data.find((d) => Number(d[idProperty]) === parentId)
      if (isNil(parent)) {
        return false
      }
      const grandParentId = parent[parentIdProperty]
      traversedIds.push(parentId)
      return hasValidAncestry(
        isNil(grandParentId) ? null : Number(grandParentId),
        traversedIds
      )
    },
    [data, idProperty, parentIdProperty]
  )

  const validData = useMemo(() => {
    return data.filter((d) =>
      hasValidAncestry(
        isNil(d[parentIdProperty]) ? null : Number(d[parentIdProperty]),
        [Number(d[idProperty])]
      )
    )
  }, [data, hasValidAncestry, idProperty, parentIdProperty])

  const sorted = useMemo(() => {
    if (!ordinalProperty) {
      return validData
    }
    return sortBy(validData, (d) => d[ordinalProperty])
  }, [validData, ordinalProperty])

  useEffect(() => {
    const newHierarchy = stratify<Record<string, unknown>>()
      .id((d) => String(d[idProperty]))
      .parentId((d) =>
        isNil(d[parentIdProperty]) ? null : String(d[parentIdProperty])
      )(sorted)

    setHierarchy(newHierarchy)
  }, [idProperty, parentIdProperty, sorted])

  useEffect(() => {
    if (!isNil(hierarchy)) {
      // if an item is already selected then expand to that level
      let selectedLevel
      if (selected?.length === 1) {
        hierarchy.each((n) => {
          if (!isNull(n.id) && Number(n.id) === selected[0]) {
            selectedLevel = n.depth
          }
        })
      }
      const level = selectedLevel || expandedLevel || defaultExpandedLevel
      const newExpanded: string[] = []
      hierarchy.each((n) => {
        if (
          !isNil(n.id) &&
          ((firstRun && n.depth < level) || expanded.includes(n.id))
        ) {
          newExpanded.push(n.id)
        }
      })
      if (!isEqual(expanded, newExpanded)) {
        setExpanded(newExpanded)
      }
      setFirstRun(false)
    }
  }, [expanded, expandedLevel, hierarchy, firstRun, selected])

  const selectedHasRoot = useMemo(() => {
    if (isNil(hierarchy)) {
      return false
    }
    if (isNil(selected)) {
      return false
    }
    if (selected.length === 0) {
      return false
    }
    return includes(selected.map(String), hierarchy.id)
  }, [hierarchy, selected])

  const selectedNeighbours = useMemo(() => {
    if (!selected) {
      return null
    }
    if (selected.length === 0) {
      return null
    }
    const filtered = sorted.filter((i) =>
      selected.some((s) => isEqual(s, i[idProperty]))
    )
    const parents = filtered.map((i) => i[parentIdProperty])
    const set = new Set(parents)
    if (set.size !== 1) {
      return null
    }
    const parent = set.values().next().value
    const children = sorted.filter((i) => i[parentIdProperty] === parent)
    return children
  }, [idProperty, parentIdProperty, selected, sorted])

  const canMoveDown = useMemo(() => {
    if (!onUpdate || !selected || !selectedNeighbours) {
      return false
    }
    return !selected.some((s) =>
      isEqual(s, selectedNeighbours[selectedNeighbours.length - 1][idProperty])
    )
  }, [idProperty, onUpdate, selected, selectedNeighbours])

  const canMoveUp = useMemo(() => {
    if (!onUpdate) {
      return
    }
    if (!selected) {
      return false
    }
    if (!selectedNeighbours) {
      return false
    }
    return !selected.some((s) => isEqual(s, selectedNeighbours[0][idProperty]))
  }, [idProperty, onUpdate, selected, selectedNeighbours])

  const moveSelectedItems = (delta: number) => {
    if (!selectedNeighbours) {
      throw Error('Cannot moveSelectedItems if selectedNeighbours is null!')
    }
    if (!selected) {
      throw Error('Cannot moveSelectedItems if selected is undefined!')
    }
    if (!ordinalProperty) {
      throw Error('Cannot moveSelectedItems if ordinalProperty is undefined!')
    }

    const neighbours = [...selectedNeighbours]
    for (const id of selected) {
      const item = neighbours.find((n) => Number(n[idProperty]) === id)
      if (!item) {
        throw Error('Item was not found in selected neighbours!')
      }
      const index = neighbours.indexOf(item)
      neighbours.splice(index, 1)
      neighbours.splice(index + delta, 0, item)
    }

    const changes = []
    for (let i = 0; i < neighbours.length; i++) {
      if (neighbours[i][ordinalProperty] !== i) {
        neighbours[i][ordinalProperty] = i
        changes.push({
          data: neighbours[i],
          property: ordinalProperty,
          value: i
        })
      }
    }

    if (onUpdate) {
      onUpdate(changes)
    }
  }

  const relocate = (
    item: Record<string, unknown>,
    parentId: number,
    position?: InsertPosition,
    neighbourId?: number
  ) => {
    if (
      (position === InsertPosition.After ||
        position === InsertPosition.Before) &&
      isNil(neighbourId)
    ) {
      throw Error('Cannot relocate before or after without neighbour id!')
    } else if (
      (position === InsertPosition.End || position === InsertPosition.Start) &&
      !isNil(neighbourId)
    ) {
      console.warn(
        'Neighbour id is ignored if insert position is start or end!'
      )
    }

    const neighbours = sorted.filter((d) => d[parentIdProperty] === parentId)
    let neighbour
    if (!isNil(neighbourId)) {
      neighbour = neighbours.find((n) => n[idProperty] === neighbourId)
    }

    const previousIndex = neighbours.findIndex(
      (n) => n[idProperty] === item[idProperty]
    )
    if (previousIndex >= 0) {
      neighbours.splice(previousIndex, 1)
    }

    let index
    switch (position) {
      case InsertPosition.After:
        if (isNil(neighbour)) {
          throw Error('Cannot relocate after if neighbour evaluates to nil!')
        }
        index = neighbours.indexOf(neighbour) + 1
        break

      case InsertPosition.Before:
        if (isNil(neighbour)) {
          throw Error('Cannot relocate before if neighbour evaluates to nil!')
        }
        index = neighbours.indexOf(neighbour)
        break

      case InsertPosition.End:
        index = neighbours.length
        break

      default:
        index = 0
    }
    neighbours.splice(index, 0, item)

    const changes = []
    neighbours[index][parentIdProperty] = parentId
    changes.push({
      data: neighbours[index],
      property: parentIdProperty,
      value: parentId
    })
    if (!isNil(ordinalProperty)) {
      neighbours.forEach((n, index) => {
        n[ordinalProperty] = index
        changes.push({
          data: n,
          property: ordinalProperty,
          value: index
        })
      })
    }

    return changes
  }

  const searchDescendants = (dragId: number, parentId: number): boolean => {
    const parentNode = hierarchy
      ?.descendants()
      .find((d) => Number(d.id) === dragId)
    return !isNil(
      parentNode?.descendants().find((d) => Number(d.id) === parentId)
    )
  }

  const handleMoveDown = () => {
    moveSelectedItems(1)
  }

  const handleMoveUp = () => {
    moveSelectedItems(-1)
  }

  const handleDrop = (dragId: number, dropId: number, region: DropRegion) => {
    if (!onUpdate) {
      throw Error('Cannot handleNodeDrop if onUpdate is undefined!')
    }
    const dragged = sorted.find((d) => d[idProperty] === dragId)
    if (!dragged) {
      throw Error('Cannot find drag source in hierarchy data!')
    }
    const target = sorted.find((d) => d[idProperty] === dropId)
    if (!target) {
      throw Error('Cannot find drop target in hierarchy data!')
    }
    let parentId: number
    let changes
    switch (region) {
      case DropRegion.Bottom:
        parentId = Number(target[parentIdProperty])
        changes = relocate(dragged, parentId, InsertPosition.After, dropId)
        break

      case DropRegion.Middle:
        parentId = dropId
        changes = relocate(dragged, parentId, InsertPosition.End)
        break

      case DropRegion.Top:
        parentId = Number(target[parentIdProperty])
        changes = relocate(dragged, parentId, InsertPosition.Before, dropId)
        break
    }
    if (searchDescendants(dragId, parentId)) {
      return
    }

    if (onUpdate) {
      onUpdate(changes)
    }
  }

  const handleSelect = (
    event: React.ChangeEvent<unknown>,
    nodeIds: string[]
  ) => {
    if (!onSelect) {
      throw Error('Cannot handleSelect if onSelect is undefined!')
    }
    const ids: string[] = Array.isArray(nodeIds) ? nodeIds : [nodeIds]
    if (!(event.target as HTMLElement).closest('.MuiTreeItem-iconContainer')) {
      if (!selected || !isEqual(selected, ids.map(Number))) {
        onSelect(ids.map(Number))
      }
    }
  }

  const handleToggle = (
    event: React.ChangeEvent<unknown>,
    nodeIds: string[]
  ) => {
    if ((event.target as Element).closest('.MuiTreeItem-iconContainer')) {
      setExpanded(nodeIds)
    }
  }

  const selectedStrings = useMemo(() => {
    if (!selected) {
      return undefined
    }
    return selected.map(String)
  }, [selected])

  const canAdd = !isNil(selected) && selected.length === 1
  const canDelete = !isNil(selected) && selected.length > 0 && !selectedHasRoot
  const canEdit = !isNil(selected) && selected.length === 1 && !selectedHasRoot

  return (
    <>
      {hierarchy && (
        <Box clone height="100%">
          <Paper>
            {(label || onAdd || selected) && (
              <Toolbar
                buttons={[
                  {
                    icon: Add,
                    onClick:
                      onAdd && selected
                        ? () => onAdd({ [parentIdProperty]: selected[0] })
                        : undefined,
                    tooltip: 'Add',
                    visible: canAdd
                  },
                  {
                    icon: Delete,
                    onClick: onDelete,
                    tooltip: 'Delete',
                    visible: canDelete
                  },
                  {
                    icon: Edit,
                    onClick: onEdit,
                    tooltip: 'Edit',
                    visible: canEdit
                  },
                  {
                    icon: Clear,
                    onClick: onDiscard,
                    tooltip: 'Discard',
                    visible: unsavedChanges
                  },
                  {
                    icon: Save,
                    onClick: onSave,
                    tooltip: 'Save',
                    visible: unsavedChanges
                  },
                  {
                    icon: ArrowDownward,
                    onClick: handleMoveDown,
                    tooltip: 'Move down',
                    visible: canMoveDown
                  },
                  {
                    icon: ArrowUpward,
                    onClick: handleMoveUp,
                    tooltip: 'Move up',
                    visible: canMoveUp
                  }
                ]}
                multiSelect={!!multiSelect}
                selectedLength={selected ? selected.length : undefined}
                title={title}
              />
            )}
            <Box p={3}>
              <TreeView
                defaultCollapseIcon={<ExpandMore />}
                defaultExpandIcon={<ChevronRight />}
                expanded={expanded}
                onNodeToggle={handleToggle}
                onNodeSelect={onSelect ? handleSelect : undefined}
                multiSelect={multiSelect ? true : undefined}
                selected={selectedStrings || []}
              >
                <TreeFork
                  activeProperty={activeProperty}
                  data={[hierarchy]}
                  idProperty={idProperty}
                  label={label}
                  nameProperty={nameProperty}
                  onAdd={onAdd}
                  onDrop={onUpdate ? handleDrop : undefined}
                  parentIdProperty={parentIdProperty}
                />
              </TreeView>
            </Box>
          </Paper>
        </Box>
      )}
    </>
  )
}

export default Hierarchy
