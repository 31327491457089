import React, { FunctionComponent, useMemo, useState } from 'react'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { defineMessages, useIntl } from 'react-intl'
import { NumericArrayParam, useQueryParam } from 'use-query-params'
import { includes, isNil } from 'lodash'
import { Box, Container, Grid } from '@material-ui/core'
import { useSnackbar } from 'notistack'
import AlertDialogue from '../../../../components/AlertDialogue'
import DataForm from '../../../../components/DataForm'
import FilterDialogue from '../../../../components/FilterDialogue'
import Table from '../../../../components/Table/Table'
import PageLoading from '../../../../components/Loading/PageLoading'
import useConfigure, { ConfigurationMode } from '../../../../hooks/useConfigure'
import useFilter from '../../../../hooks/useFilter'
import usePagination from '../../../../hooks/usePagination'
import useSort from '../../../../hooks/useSort'
import {
  IncidentsDefCfgKeys,
  LogicalOperator,
  Paths,
  useApi
} from '../../../../api/RcfactoryApi'

enum ParamKeys {
  DefinitionId = 'definitionId'
}

enum ConfigurationKeys {
  Definition = 'definition'
}

const messages = defineMessages({
  alertMessage: {
    id: 'incidentDefinitions.alertMessage',
    description: 'Delete alert dialogue message content',
    defaultMessage: 'Are you sure you want to delete the selected Incident ' +
      '{count, plural, one{Definition} other{Definitions}}?'
  },
  alertTitle: {
    id: 'incidentDefinitions.alertTitle',
    description: 'Delete alert dialogue title',
    defaultMessage: 'Delete Incident {count, plural, one{Definition} other{Definitions}}'
  }
})

const omitFilterProperties = [IncidentsDefCfgKeys.Id]

const IncidentDefinitions: FunctionComponent = () => {
  const intl = useIntl()
  const api = useApi()
  const configurator = useConfigure(ConfigurationKeys.Definition)
  const filterer = useFilter()
  const pagination = usePagination()
  const sort = useSort(IncidentsDefCfgKeys.Id)
  const [alertOpen, setAlertOpen] = useState<boolean>(false)
  const [selected, setSelected] = useQueryParam(
    ParamKeys.DefinitionId, NumericArrayParam
  )
  const { enqueueSnackbar } = useSnackbar()
  const queryClient = useQueryClient()

  const descQuery = useQuery(
    Paths.IncidentsDefCfg + Paths.UtilsGetDesc,
    () => api.getDesc({
      path: Paths.IncidentsDefCfg
    }), {
      onError: () => enqueueSnackbar(
        intl.formatMessage({
          id: 'incidentDefinitions.failedIncidentDefinitionSchema',
          description: 'Fetch incident definition schema error notification text',
          defaultMessage: 'Failed to get Incident Definition Schema!'
        }), {
          variant: 'error'
        }
      )
    }
  )

  const dataQuery = useQuery([
    Paths.IncidentsDefCfg,
    filterer.active,
    sort.order,
    sort.orderBy,
    pagination.page,
    pagination.rowsPerPage
  ], () => api.getList({
    modelExpressions: {
      Expressions: filterer.active,
      Operator: LogicalOperator.And
    },
    order1: sort.order,
    orderBy1: sort.orderBy,
    pageNumber: pagination.page,
    pageSize: pagination.rowsPerPage,
    path: Paths.IncidentsDefCfg
  }), {
    onError: () => enqueueSnackbar(
      intl.formatMessage({
        id: 'incidentDefinitions.failedIncidentDefinitions',
        description: 'Fetch incident definitions error notification text',
        defaultMessage: 'Failed to get Incident Definitions!'
      }), {
        variant: 'error'
      }
    )
  })

  const createMutation = useMutation(
    (items: Record<string, unknown>[]) => api.create({
      items: items,
      path: Paths.IncidentsDefCfg
    }), {
      onError: () => {
        enqueueSnackbar(
          intl.formatMessage({
            id: 'incidentDefinitions.failedCreateIncidentDefinition',
            description: 'Create incident definition error notification text',
            defaultMessage: 'Failed to create Incident Definition!'
          }), {
            variant: 'error'
          }
        )
      },
      onSuccess: () => {
        enqueueSnackbar(
          intl.formatMessage({
            id: 'incidentDefinitions.successfulCreateIncidentDefinition',
            description: 'Create incident definition success notification text',
            defaultMessage: 'Successfully created Incident Definition!'
          }), {
            variant: 'success'
          }
        )
        configurator.clear()
        queryClient.invalidateQueries(Paths.IncidentsDefCfg)
      }
    }
  )

  const deleteMutation = useMutation(
    (ids: number[]) => api.delete({
      ids: ids,
      path: Paths.IncidentsDefCfg
    }), {
      onError: () => {
        enqueueSnackbar(
          intl.formatMessage({
            id: 'incidentDefinitions.failedDeleteIncidentDefinition',
            description: 'Delete incident definition error notification text',
            defaultMessage: 'Failed to delete Incident Definitions!'
          }), {
            variant: 'error'
          }
        )
      },
      onSuccess: () => {
        enqueueSnackbar(
          intl.formatMessage({
            id: 'incidentDefinitions.successfulDeleteIncidentDefinition',
            description: 'Delete incident definition success notification text',
            defaultMessage: 'Successfully deleted Incident Definitions!'
          }), {
            variant: 'success'
          }
        )
        setSelected(undefined)
        queryClient.invalidateQueries(Paths.IncidentsDefCfg)
      }
    }
  )

  const updateMutation = useMutation(
    (items: Record<string, unknown>[]) => api.update({
      items: items,
      path: Paths.IncidentsDefCfg
    }), {
      onError: () => {
        enqueueSnackbar(
          intl.formatMessage({
            id: 'incidentDefinitions.failedUpdateIncidentDefinition',
            description: 'Update incident definition error notification text',
            defaultMessage: 'Failed to update Incident Definitions!'
          }), {
            variant: 'error'
          }
        )
      },
      onSuccess: () => {
        enqueueSnackbar(
          intl.formatMessage({
            id: 'incidentDefinitions.successfulUpdateIncidentDefinition',
            description: 'Update incident definition success notification text',
            defaultMessage: 'Successfully updated Incident Definition!'
          }), {
            variant: 'success'
          }
        )
        configurator.clear()
        queryClient.invalidateQueries(Paths.IncidentsDefCfg)
      }
    }
  )

  const activeDefinition = useMemo(() => {
    if (!dataQuery.data?.Items) {
      return null
    }
    if (selected?.length !== 1) {
      return null
    }
    return dataQuery.data?.Items.find(
      (d: Record<string, unknown>) => d[IncidentsDefCfgKeys.Id] === selected[0])
  }, [dataQuery.data?.Items, selected])

  const handleAddSubmit = () => {
    if (isNil(configurator.data)) {
      throw Error('Cannot handleAddSubmit if configurator data is nil!')
    }
    createMutation.mutate([configurator.data])
  }

  const handleAlertCancel = () => {
    setAlertOpen(false)
  }

  const handleAlertDelete = () => {
    if (isNil(selected)) {
      throw Error('Cannot handleAlertDelete if selected is nil!')
    }
    deleteMutation.mutate(selected.map(Number))
    setAlertOpen(false)
  }

  const handleDelete = () => {
    setAlertOpen(true)
  }

  const handleEditSubmit = () => {
    if (isNil(configurator.data)) {
      throw Error('Cannot handleEditSubmit if configurator data is null!')
    }
    updateMutation.mutate([configurator.data])
  }

  const handleFilterSubmit = () => {
    filterer.submit()
    pagination.setPage(0)
    filterer.clearData()
  }

  const handleFormCancel = () => {
    configurator.clear()
    filterer.clearData()
  }

  const handlePageChange = (newPage: number) => {
    pagination.setPage(newPage)
    setSelected(undefined)
  }

  const handleRequestSort = (property: string) => {
    sort.requestSort(property)
    setSelected(undefined)
  }

  const handleRowsPerPageChange = (rows: number) => {
    pagination.setRowsPerPage(rows)
    setSelected(undefined)
  }

  const handleSelect = (ids: (number | string | null)[]) => {
    setSelected(ids.length > 0 ? ids.map(Number) : undefined)
  }

  const pageReady = descQuery.isSuccess && dataQuery.isSuccess &&
    !createMutation.isLoading && !deleteMutation.isLoading && !updateMutation.isLoading

  const pageLoading = descQuery.isLoading || dataQuery.isLoading ||
    createMutation.isLoading || deleteMutation.isLoading || updateMutation.isLoading

  return (
    <>
      { pageReady &&
        <Box paddingTop={3} paddingBottom={3}>
          <Container maxWidth='md'>
            <>
              {
                <Grid container>
                  <Grid item xs={12}>
                    { configurator.data &&
                      descQuery.data?.CrudDescription &&
                      <DataForm
                        formSections={[{
                          data: configurator.data,
                          ignoredProperties: [IncidentsDefCfgKeys.Id],
                          onPropertyChange: (property, value) => configurator.update({
                            [property]: value
                          }),
                          schema: descQuery.data.CrudDescription
                        }]}
                        onCancel={handleFormCancel}
                        onSubmit={configurator.mode === ConfigurationMode.Create
                          ? handleAddSubmit
                          : handleEditSubmit
                        }
                        title={configurator.mode === ConfigurationMode.Create
                          ? intl.formatMessage({
                            id: 'incidentDefinitions.create',
                            description: 'Create incident definition dialogue title',
                            defaultMessage: 'Create Incident Definition'
                          })
                          : intl.formatMessage({
                            id: 'incidentDefinitions.edit',
                            description: 'Edit incident definition dialogue title',
                            defaultMessage: 'Edit Incident Definition'
                          })
                        }
                      />
                    }
                    { filterer.data &&
                      descQuery.data?.ViewDescription &&
                      <FilterDialogue
                        filter={filterer.data}
                        onCancel={handleFormCancel}
                        onExpressionChange={filterer.update}
                        onReset={filterer.reset}
                        onSubmit={handleFilterSubmit}
                        schema={descQuery.data.ViewDescription}
                        title={intl.formatMessage({
                          id: 'incidentDefinitions.filter',
                          description: 'Filter incident definitions dialogue title',
                          defaultMessage: 'Filter Incident Definitions'
                        })}
                      />
                    }
                    { dataQuery.data?.Items &&
                      dataQuery.data?.Pagination &&
                      descQuery.data &&
                      <Table
                        data={dataQuery.data.Items}
                        idProperty={IncidentsDefCfgKeys.Id}
                        isFiltered={filterer.isActive}
                        multiSelect
                        onAdd={() => configurator.create(
                          descQuery.data.CrudDescription.Properties
                        )}
                        onDelete={handleDelete}
                        onEdit={!isNil(activeDefinition)
                          ? () => configurator.edit(activeDefinition)
                          : undefined
                        }
                        onFilter={() => filterer.initialise(
                          descQuery.data.ViewDescription.Properties.filter(
                            p => !includes(omitFilterProperties, p.PropertyName)
                          )
                        )}
                        onPageChange={handlePageChange}
                        onRequestSort={handleRequestSort}
                        onRowsPerPageChange={handleRowsPerPageChange}
                        onSelect={handleSelect}
                        order={sort.order}
                        orderBy={sort.orderBy}
                        page={pagination.page}
                        rowsPerPage={pagination.rowsPerPage}
                        schema={descQuery.data.ViewDescription}
                        selected={selected}
                        title={intl.formatMessage({
                          id: 'incidentDefinitions',
                          description: 'Incident definitions table title',
                          defaultMessage: 'Incident Definitions'
                        })}
                        totalRows={dataQuery.data.Pagination.TotalCount}
                      />
                    }
                  </Grid>
                </Grid>
              }
            </>
          </Container>
        </Box>
      }
      {
        pageLoading &&
        <PageLoading/>
      }
      <AlertDialogue
        actions={[{
          handler: handleAlertDelete,
          text: intl.formatMessage({
            id: 'incidentDefinitions.alertDelete',
            description: 'Delete alert dialogue, delete button text',
            defaultMessage: 'Delete'
          })
        }, {
          handler: handleAlertCancel,
          text: intl.formatMessage({
            id: 'incidentDefinitions.alertCancel',
            description: 'Delete alert dialogue, cancel button text',
            defaultMessage: 'Cancel'
          })
        }]}
        message={intl.formatMessage(messages.alertMessage, {
          count: selected?.length
        })}
        open={alertOpen}
        title={intl.formatMessage(messages.alertTitle, {
          count: selected?.length
        })}
      />
    </>
  )
}

export default IncidentDefinitions
