import React, { FunctionComponent, useEffect, useMemo, useState } from 'react'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { defineMessages, useIntl } from 'react-intl'
import { NumericArrayParam, useQueryParam } from 'use-query-params'
import { filter, isNil } from 'lodash'
import { Box, Container, Grid } from '@material-ui/core'
import { useSnackbar } from 'notistack'
import AlertDialogue from '../../../../components/AlertDialogue'
import CollapsibleTable from '../../../../components/Table/CollapsibleTable'
import ContentLoading from '../../../../components/Loading/ContentLoading'
import DataForm from '../../../../components/DataForm'
import Hierarchy from '../../../../components/Hierarchy/Hierarchy'
import PageLoading from '../../../../components/Loading/PageLoading'
import Placeholder from '../../../../components/Placeholder'
import useConfigure, { ConfigurationMode } from '../../../../hooks/useConfigure'
import useIotMapping from '../../../../hooks/useIotMapping'
import {
  DataCollectionCategoryKeys,
  EventAlphaVarCfgKeys,
  EventCfgKeys,
  EventVarCfgKeys,
  NodeKeys,
  ExpressionOperator,
  Order,
  Paths,
  PropertyLookup,
  UnitKeys,
  useApi,
  VariableKeys
} from '../../../../api/RcfactoryApi'

enum ParamKeys {
  NodeId = 'nodeId',
  EventId = 'eventId',
  EventOpened = 'eventOpened',
  NumericVarId = 'numericVarId',
  AlphaVarId = 'alphaVarId'
}

enum ConfigurationKeys {
  AlphaVar = 'alphaVar',
  Event = 'event',
  NumericVar = 'numericVar'
}

enum AlertSelection {
  AlphaVar,
  Event,
  NumericVar
}

const messages = defineMessages({
  alertAlphaVarMessage: {
    id: 'eventInstances.alertAlphaVarMessage',
    description: 'Delete alpha var alert dialogue message content',
    defaultMessage:
      'Are you sure you want to delete the selected Alphanumeric Event ' +
      '{count, plural, one{Variable} other{Variables}}?'
  },
  alertAlphaVarTitle: {
    id: 'eventInstances.alertAlphaVarTitle',
    description: 'Delete alpha var alert dialogue title',
    defaultMessage:
      'Delete Alphanumeric Event ' +
      '{count, plural, one{Variable} other{Variables}}'
  },
  alertEventMessage: {
    id: 'eventInstances.alertEventMessage',
    description: 'Delete event alert dialogue message content',
    defaultMessage:
      'Are you sure you want to delete the selected ' +
      '{count, plural, one{Event} other{Events}}?'
  },
  alertEventTitle: {
    id: 'eventInstances.alertEventTitle',
    description: 'Delete event alert dialogue title',
    defaultMessage: 'Delete {count, plural, one{Event} other{Events}}'
  },
  alertNumericVarMessage: {
    id: 'eventInstances.alertNumericVarMessage',
    description: 'Delete numeric var alert dialogue message content',
    defaultMessage:
      'Are you sure you want to delete the selected Numeric Event ' +
      '{count, plural, one{Variable} other{Variables}}?'
  },
  alertNumericVarTitle: {
    id: 'eventInstances.alertNumericVarTitle',
    description: 'Delete numeric var alert dialogue title',
    defaultMessage:
      'Delete Numeric Event ' +
      '{count, plural, one{Variable} other{Variables}}'
  }
})

const EventInstances: FunctionComponent = () => {
  const intl = useIntl()
  const api = useApi()
  const alphaVarConfigurator = useConfigure(ConfigurationKeys.AlphaVar)
  const eventConfigurator = useConfigure(ConfigurationKeys.Event)
  const numericVarConfigurator = useConfigure(ConfigurationKeys.NumericVar)
  const [activeAlphaVar, setActiveAlphaVar] =
    useState<Record<string, unknown>>()
  const alphaVarIotMapping = useIotMapping(
    ConfigurationKeys.AlphaVar,
    !isNil(activeAlphaVar)
      ? String(activeAlphaVar[EventAlphaVarCfgKeys.RcfId])
      : undefined
  )
  const [activeEvent, setActiveEvent] = useState<Record<string, unknown>>()
  const eventIotMapping = useIotMapping(
    ConfigurationKeys.Event,
    !isNil(activeEvent) ? String(activeEvent[EventCfgKeys.RcfId]) : undefined
  )
  const [activeNumericVar, setActiveNumericVar] =
    useState<Record<string, unknown>>()
  const numericVarIotMapping = useIotMapping(
    ConfigurationKeys.NumericVar,
    !isNil(activeNumericVar)
      ? String(activeNumericVar[EventVarCfgKeys.RcfId])
      : undefined
  )
  const [alertSelection, setAlertSelection] = useState<AlertSelection>()
  const [openedEvents, setOpenedEvents] = useQueryParam(
    ParamKeys.EventOpened,
    NumericArrayParam
  )
  const [selectedAlphaVars, setSelectedAlphaVars] = useQueryParam(
    ParamKeys.AlphaVarId,
    NumericArrayParam
  )
  const [selectedEvents, setSelectedEvents] = useQueryParam(
    ParamKeys.EventId,
    NumericArrayParam
  )
  const [selectedNodes, setSelectedNodes] = useQueryParam(
    ParamKeys.NodeId,
    NumericArrayParam
  )
  const [selectedNumericVars, setSelectedNumericVars] = useQueryParam(
    ParamKeys.NumericVarId,
    NumericArrayParam
  )
  const { enqueueSnackbar } = useSnackbar()
  const queryClient = useQueryClient()

  const alphaVarsDescQuery = useQuery(
    Paths.EventAlphaVarCfg + Paths.UtilsGetDesc,
    () =>
      api.getDesc({
        path: Paths.EventAlphaVarCfg
      }),
    {
      onError: () =>
        enqueueSnackbar(
          intl.formatMessage({
            id: 'eventInstances.failedAlphaVarSchema',
            description: 'Fetch alpha variable schema error notification text',
            defaultMessage: 'Failed to get Alphanumeric Event Variables schema!'
          }),
          {
            variant: 'error'
          }
        )
    }
  )

  const alphaVarsQuery = useQuery(
    [Paths.EventAlphaVarCfg, selectedNodes],
    () => {
      if (isNil(selectedNodes)) {
        throw Error('Should not query alpha vars if selectedNodes is nil!')
      }
      return api.getList({
        modelExpressions: {
          Expressions: [
            {
              Prop: EventAlphaVarCfgKeys.NodeId,
              Op: ExpressionOperator.Equal,
              Val: selectedNodes[0]
            }
          ]
        },
        path: Paths.EventAlphaVarCfg
      })
    },
    {
      enabled: selectedNodes?.length === 1,
      onError: () =>
        enqueueSnackbar(
          intl.formatMessage({
            id: 'eventInstances.failedAlphaVars',
            description: 'Fetch alpha variables error notification text',
            defaultMessage: 'Failed to get Alphanumeric Event Variables!'
          }),
          {
            variant: 'error'
          }
        )
    }
  )

  const alphaVarCreateMutation = useMutation(
    (items: Record<string, unknown>[]) =>
      api.create({
        items: items,
        path: Paths.EventAlphaVarCfg
      }),
    {
      onError: () => {
        enqueueSnackbar(
          intl.formatMessage({
            id: 'eventInstances.failedAlphaVarCreate',
            description: 'Create alpha variable error notification text',
            defaultMessage: 'Failed to create Alphanumeric Event Variable!'
          }),
          {
            variant: 'error'
          }
        )
      },
      onSuccess: () => {
        enqueueSnackbar(
          intl.formatMessage({
            id: 'eventInstances.successfulAlphaVarCreate',
            description: 'Create alpha variable success notification text',
            defaultMessage: 'Successfully created Alphanumeric Event Variable!'
          }),
          {
            variant: 'success'
          }
        )
        alphaVarConfigurator.clear()
        queryClient.invalidateQueries(Paths.EventAlphaVarCfg)
      }
    }
  )

  const alphaVarDeleteMutation = useMutation(
    (ids: number[]) =>
      api.delete({
        ids: ids,
        path: Paths.EventAlphaVarCfg
      }),
    {
      onError: () => {
        enqueueSnackbar(
          intl.formatMessage({
            id: 'eventInstances.failedAlphaVarDelete',
            description: 'Delete alpha variables error notification text',
            defaultMessage: 'Failed to delete Alphanumeric Event Variables!'
          }),
          {
            variant: 'error'
          }
        )
      },
      onSuccess: () => {
        enqueueSnackbar(
          intl.formatMessage({
            id: 'eventInstances.successfulAlphaVarDelete',
            description: 'Delete alpha variables success notification text',
            defaultMessage: 'Successfully deleted Alphanumeric Event Variables!'
          }),
          {
            variant: 'success'
          }
        )
        setSelectedAlphaVars(undefined)
        queryClient.invalidateQueries(Paths.EventAlphaVarCfg)
      }
    }
  )

  const alphaVarUpdateMutation = useMutation(
    (items: Record<string, unknown>[]) =>
      api.update({
        items: items,
        path: Paths.EventAlphaVarCfg
      }),
    {
      onError: () => {
        enqueueSnackbar(
          intl.formatMessage({
            id: 'eventInstances.failedAlphaVarUpdate',
            description: 'Update alpha variable error notification text',
            defaultMessage: 'Failed to update Alphanumeric Event Variables!'
          }),
          {
            variant: 'error'
          }
        )
      },
      onSuccess: () => {
        enqueueSnackbar(
          intl.formatMessage({
            id: 'eventInstances.successfulAlphaVarUpdate',
            description: 'Update alpha variable success notification text',
            defaultMessage: 'Successfully updated Alphanumeric Event Variable!'
          }),
          {
            variant: 'success'
          }
        )
        alphaVarConfigurator.clear()
        queryClient.invalidateQueries(Paths.EventAlphaVarCfg)
      }
    }
  )

  const categoriesQuery = useQuery(
    Paths.DataCollectionCategories,
    () =>
      api.getList({
        modelExpressions: {
          Expressions: [
            {
              Prop: DataCollectionCategoryKeys.DataCategoryName,
              Op: ExpressionOperator.Equal,
              Val: 'Events'
            }
          ]
        },
        path: Paths.DataCollectionCategories
      }),
    {
      onError: () =>
        enqueueSnackbar(
          intl.formatMessage({
            id: 'eventInstances.failedCollectionCategories',
            description:
              'Fetch data collection categories error notification text',
            defaultMessage: 'Failed to get Data Collection Categories!'
          }),
          {
            variant: 'error'
          }
        )
    }
  )

  const eventsDescQuery = useQuery(
    Paths.EventCfg + Paths.UtilsGetDesc,
    () =>
      api.getDesc({
        path: Paths.EventCfg
      }),
    {
      onError: () =>
        enqueueSnackbar(
          intl.formatMessage({
            id: 'eventInstances.failedEventSchema',
            description: 'Fetch event schema error notification text',
            defaultMessage: 'Failed to get Event schema!'
          }),
          {
            variant: 'error'
          }
        )
    }
  )

  const eventsQuery = useQuery(
    [Paths.EventCfg, selectedNodes],
    () => {
      if (isNil(selectedNodes)) {
        throw Error('Should not query events if selectedNodes is nil!')
      }
      return api.getList({
        modelExpressions: {
          Expressions: [
            {
              Prop: EventCfgKeys.NodeId,
              Op: ExpressionOperator.Equal,
              Val: selectedNodes[0]
            }
          ]
        },
        path: Paths.EventCfg
      })
    },
    {
      enabled: selectedNodes?.length === 1,
      onError: () =>
        enqueueSnackbar(
          intl.formatMessage({
            id: 'eventInstances.failedEvents',
            description: 'Fetch events error notification text',
            defaultMessage: 'Failed to get Events!'
          }),
          {
            variant: 'error'
          }
        )
    }
  )

  const eventCreateMutation = useMutation(
    (items: Record<string, unknown>[]) =>
      api.create({
        items: items,
        path: Paths.EventCfg
      }),
    {
      onError: () => {
        enqueueSnackbar(
          intl.formatMessage({
            id: 'eventInstances.failedCreateEvent',
            description: 'Create event error notification text',
            defaultMessage: 'Failed to create Event!'
          }),
          {
            variant: 'error'
          }
        )
      },
      onSuccess: () => {
        enqueueSnackbar(
          intl.formatMessage({
            id: 'eventInstances.successfulCreateEvent',
            description: 'Create event success notification text',
            defaultMessage: 'Successfully created Event!'
          }),
          {
            variant: 'success'
          }
        )
        eventConfigurator.clear()
        queryClient.invalidateQueries(Paths.EventCfg)
      }
    }
  )

  const eventDeleteMutation = useMutation(
    (ids: number[]) =>
      api.delete({
        ids: ids,
        path: Paths.EventCfg
      }),
    {
      onError: () => {
        enqueueSnackbar(
          intl.formatMessage({
            id: 'eventInstances.failedDeleteEvents',
            description: 'Delete events error notification text',
            defaultMessage: 'Failed to delete Events!'
          }),
          {
            variant: 'error'
          }
        )
      },
      onSuccess: () => {
        enqueueSnackbar(
          intl.formatMessage({
            id: 'eventInstances.successfulDeleteEvents',
            description: 'Delete events success notification text',
            defaultMessage: 'Successfully deleted Events!'
          }),
          {
            variant: 'success'
          }
        )
        setOpenedEvents(undefined)
        setSelectedEvents(undefined)
        queryClient.invalidateQueries(Paths.EventCfg)
      }
    }
  )

  const eventUpdateMutation = useMutation(
    (items: Record<string, unknown>[]) =>
      api.update({
        items: items,
        path: Paths.EventCfg
      }),
    {
      onError: () => {
        enqueueSnackbar(
          intl.formatMessage({
            id: 'eventInstances.failedUpdateEvent',
            description: 'Update event error notification text',
            defaultMessage: 'Failed to update Event!'
          }),
          {
            variant: 'error'
          }
        )
      },
      onSuccess: () => {
        enqueueSnackbar(
          intl.formatMessage({
            id: 'eventInstances.successfulUpdateEvent',
            description: 'Update event success notification text',
            defaultMessage: 'Successfully updated Event!'
          }),
          {
            variant: 'success'
          }
        )
        eventConfigurator.clear()
        queryClient.invalidateQueries(Paths.EventCfg)
      }
    }
  )

  const nodesQuery = useQuery(
    Paths.Nodes,
    () =>
      api.getList({
        order1: Order.asc,
        orderBy1: NodeKeys.OrdinalPosition,
        path: Paths.Nodes
      }),
    {
      onError: () =>
        enqueueSnackbar(
          intl.formatMessage({
            id: 'eventInstances.failedNodes',
            description: 'Fetch nodes error notification text',
            defaultMessage: 'Failed to get Nodes!'
          }),
          {
            variant: 'error'
          }
        )
    }
  )

  const numericVarsDescQuery = useQuery(
    Paths.EventVarCfg + Paths.UtilsGetDesc,
    () =>
      api.getDesc({
        path: Paths.EventVarCfg
      }),
    {
      onError: () =>
        enqueueSnackbar(
          intl.formatMessage({
            id: 'eventInstances.failedNumericVarSchema',
            description: 'Fetch numeric var schema error notification text',
            defaultMessage: 'Failed to get Numeric Event Variables schema!'
          }),
          {
            variant: 'error'
          }
        )
    }
  )

  const numericVarsQuery = useQuery(
    [Paths.EventVarCfg, selectedNodes],
    () => {
      if (isNil(selectedNodes)) {
        throw Error('Should not query numeric vars if selected nodes is nil!')
      }
      return api.getList({
        modelExpressions: {
          Expressions: [
            {
              Prop: EventVarCfgKeys.NodeId,
              Op: ExpressionOperator.Equal,
              Val: selectedNodes[0]
            }
          ]
        },
        path: Paths.EventVarCfg
      })
    },
    {
      enabled: selectedNodes?.length === 1,
      onError: () =>
        enqueueSnackbar(
          intl.formatMessage({
            id: 'eventInstances.failedNumericVars',
            description: 'Fetch numeric variables error notification text',
            defaultMessage: 'Failed to get Numeric Event Variables!'
          }),
          {
            variant: 'error'
          }
        )
    }
  )

  const numericVarCreateMutation = useMutation(
    (items: Record<string, unknown>[]) =>
      api.create({
        items: items,
        path: Paths.EventVarCfg
      }),
    {
      onError: () => {
        enqueueSnackbar(
          intl.formatMessage({
            id: 'eventInstances.failedCreateNumericVar',
            description: 'Create numeric variable error notification text',
            defaultMessage: 'Failed to create Numeric Event Variable!'
          }),
          {
            variant: 'error'
          }
        )
      },
      onSuccess: () => {
        enqueueSnackbar(
          intl.formatMessage({
            id: 'eventInstances.successfulCreateNumericVar',
            description: 'Create numeric variable success notification text',
            defaultMessage: 'Successfully created Numeric Event Variable!'
          }),
          {
            variant: 'success'
          }
        )
        numericVarConfigurator.clear()
        queryClient.invalidateQueries(Paths.EventVarCfg)
      }
    }
  )

  const numericVarDeleteMutation = useMutation(
    (ids: number[]) =>
      api.delete({
        ids: ids,
        path: Paths.EventVarCfg
      }),
    {
      onError: () => {
        enqueueSnackbar(
          intl.formatMessage({
            id: 'eventInstances.failedDeleteNumericVar',
            description: 'Delete numeric variable error notification text',
            defaultMessage: 'Failed to delete Numeric Event Variables!'
          }),
          {
            variant: 'error'
          }
        )
      },
      onSuccess: () => {
        enqueueSnackbar(
          intl.formatMessage({
            id: 'eventInstances.successfulDeleteNumericVars',
            description: 'Delete numeric variables success notification text',
            defaultMessage: 'Successfully deleted Numeric Event Variables!'
          }),
          {
            variant: 'success'
          }
        )
        setSelectedNumericVars(undefined)
        queryClient.invalidateQueries(Paths.EventVarCfg)
      }
    }
  )

  const numericVarUpdateMutation = useMutation(
    (items: Record<string, unknown>[]) =>
      api.update({
        items: items,
        path: Paths.EventVarCfg
      }),
    {
      onError: () => {
        enqueueSnackbar(
          intl.formatMessage({
            id: 'eventInstances.failedUpdateNumericVar',
            description: 'Update numeric variable error notification text',
            defaultMessage: 'Failed to update Numeric Event Variables!'
          }),
          {
            variant: 'error'
          }
        )
      },
      onSuccess: () => {
        enqueueSnackbar(
          intl.formatMessage({
            id: 'eventInstances.successfulUpdateNumericVar',
            description: 'Update numeric variable success notification text',
            defaultMessage: 'Successfully updated Numeric Event Variable!'
          }),
          {
            variant: 'success'
          }
        )
        numericVarConfigurator.clear()
        queryClient.invalidateQueries(Paths.EventVarCfg)
      }
    }
  )

  const unitsQuery = useQuery(
    Paths.Unit,
    () => api.getList({ path: Paths.Unit }),
    {
      onError: () =>
        enqueueSnackbar(
          intl.formatMessage({
            id: 'eventInstances.failedUnits',
            description: 'Fetch units error notification',
            defaultMessage: 'Failed to get Units!'
          }),
          {
            variant: 'error'
          }
        )
    }
  )

  const variablesQuery = useQuery(
    Paths.VariableCfg,
    () => api.getList({ path: Paths.VariableCfg }),
    {
      onError: () =>
        enqueueSnackbar(
          intl.formatMessage({
            id: 'eventInstances.failedVariables',
            description: 'Fetch variables error notification',
            defaultMessage: 'Failed to get Variables!'
          }),
          {
            variant: 'error'
          }
        )
    }
  )

  useEffect(() => {
    if (
      !isNil(alphaVarConfigurator.data) &&
      isNil(alphaVarIotMapping.formSection?.data)
    ) {
      switch (alphaVarConfigurator.mode) {
        case ConfigurationMode.Create:
          alphaVarIotMapping.add()
          break
        case ConfigurationMode.Edit:
          alphaVarIotMapping.edit()
      }
    } else if (
      isNil(alphaVarConfigurator.data) &&
      !isNil(alphaVarIotMapping.formSection?.data)
    ) {
      alphaVarIotMapping.clear()
    }
  }, [alphaVarConfigurator, alphaVarIotMapping])

  useEffect(() => {
    if (
      !isNil(eventConfigurator.data) &&
      isNil(eventIotMapping.formSection?.data)
    ) {
      switch (eventConfigurator.mode) {
        case ConfigurationMode.Create:
          eventIotMapping.add()
          break
        case ConfigurationMode.Edit:
          eventIotMapping.edit()
      }
    } else if (
      isNil(eventConfigurator.data) &&
      !isNil(eventIotMapping.formSection?.data)
    ) {
      eventIotMapping.clear()
    }
  }, [eventConfigurator, eventIotMapping])

  useEffect(() => {
    if (
      !isNil(numericVarConfigurator.data) &&
      isNil(numericVarIotMapping.formSection?.data)
    ) {
      switch (numericVarConfigurator.mode) {
        case ConfigurationMode.Create:
          numericVarIotMapping.add()
          break
        case ConfigurationMode.Edit:
          numericVarIotMapping.edit()
      }
    } else if (
      isNil(numericVarConfigurator.data) &&
      !isNil(numericVarIotMapping.formSection?.data)
    ) {
      numericVarIotMapping.clear()
    }
  }, [numericVarConfigurator, numericVarIotMapping])

  useEffect(() => {
    if (!eventsQuery.data?.Items) {
      return
    }
    if (selectedEvents?.length !== 1) {
      return
    }
    const newActiveEvent = eventsQuery.data.Items.find(
      (d: Record<string, unknown>) => d[EventCfgKeys.Id] === selectedEvents[0]
    )
    setActiveEvent(newActiveEvent)
  }, [eventsQuery.data?.Items, selectedEvents])

  useEffect(() => {
    if (!numericVarsQuery.data?.Items) {
      return
    }
    if (selectedNumericVars?.length !== 1) {
      return
    }
    const newActiveNumericVar = numericVarsQuery.data.Items.find(
      (d: Record<string, unknown>) =>
        d[EventVarCfgKeys.Id] === selectedNumericVars[0]
    )
    setActiveNumericVar(newActiveNumericVar)
  }, [numericVarsQuery.data?.Items, selectedNumericVars])

  useEffect(() => {
    if (!alphaVarsQuery.data?.Items) {
      return
    }
    if (selectedAlphaVars?.length !== 1) {
      return
    }
    const newActiveAlphaVar = alphaVarsQuery.data.Items.find(
      (d: Record<string, unknown>) =>
        d[EventAlphaVarCfgKeys.Id] === selectedAlphaVars[0]
    )
    setActiveAlphaVar(newActiveAlphaVar)
  }, [alphaVarsQuery.data?.Items, selectedAlphaVars])

  const alphaVarLookupProperties: PropertyLookup[] | null = useMemo(() => {
    if (!variablesQuery.data?.Items) {
      return null
    }
    return [
      {
        data: filter(variablesQuery.data.Items, [
          VariableKeys.Type,
          'Alphanumeric'
        ]),
        label: 'Global Variable',
        localProperty: EventVarCfgKeys.VariableId,
        nameProperty: VariableKeys.Name,
        remoteProperty: VariableKeys.Id
      }
    ]
  }, [variablesQuery.data?.Items])

  const eventLookupProperties: PropertyLookup[] | null = useMemo(() => {
    if (!categoriesQuery.data?.Items) {
      return null
    }
    return [
      {
        data: categoriesQuery.data.Items,
        localProperty: EventCfgKeys.DataCollectionCategoryId,
        nameProperty: DataCollectionCategoryKeys.Name,
        remoteProperty: DataCollectionCategoryKeys.Id
      }
    ]
  }, [categoriesQuery.data?.Items])

  const numericVarLookupProperties: PropertyLookup[] | null = useMemo(() => {
    if (!unitsQuery.data?.Items || !variablesQuery.data?.Items) {
      return null
    }
    return [
      {
        data: unitsQuery.data.Items,
        label: 'Unit',
        localProperty: EventVarCfgKeys.UnitId,
        nameProperty: UnitKeys.Name,
        remoteProperty: UnitKeys.Id
      },
      {
        data: filter(variablesQuery.data.Items, [VariableKeys.Type, 'Numeric']),
        label: 'Global Variable',
        localProperty: EventVarCfgKeys.VariableId,
        nameProperty: VariableKeys.Name,
        remoteProperty: VariableKeys.Id
      }
    ]
  }, [unitsQuery.data?.Items, variablesQuery.data?.Items])

  const createAlphaVar = async (data: Record<string, unknown>) => {
    const createResponse = await alphaVarCreateMutation.mutateAsync([data])
    const getListResponse = await api.getList({
      modelExpressions: {
        Expressions: [
          {
            Op: ExpressionOperator.Equal,
            Prop: EventAlphaVarCfgKeys.Id,
            Val: createResponse.data[0]
          }
        ]
      },
      path: Paths.EventAlphaVarCfg
    })
    return String(getListResponse.Items[0][EventAlphaVarCfgKeys.RcfId])
  }

  const createEvent = async (data: Record<string, unknown>) => {
    const createResponse = await eventCreateMutation.mutateAsync([data])
    const getListResponse = await api.getList({
      modelExpressions: {
        Expressions: [
          {
            Op: ExpressionOperator.Equal,
            Prop: EventCfgKeys.Id,
            Val: createResponse.data[0]
          }
        ]
      },
      path: Paths.EventCfg
    })
    return String(getListResponse.Items[0][EventCfgKeys.RcfId])
  }

  const createNumericVar = async (data: Record<string, unknown>) => {
    const createResponse = await numericVarCreateMutation.mutateAsync([data])
    const getListResponse = await api.getList({
      modelExpressions: {
        Expressions: [
          {
            Op: ExpressionOperator.Equal,
            Prop: EventVarCfgKeys.Id,
            Val: createResponse.data[0]
          }
        ]
      },
      path: Paths.EventVarCfg
    })
    return String(getListResponse.Items[0][EventVarCfgKeys.RcfId])
  }

  const handleAddAlphaVar = (eventId: number | string | null) => {
    if (isNil(alphaVarsDescQuery.data)) {
      throw Error(
        'Cannot handleAddAlphaVar if alpha event variable description is nil!'
      )
    }
    if (isNil(selectedNodes)) {
      throw Error('Cannot handleAddAlphaVar if selectedNodes is nil!')
    }
    alphaVarConfigurator.create(
      alphaVarsDescQuery.data.CrudDescription.Properties,
      {
        [EventVarCfgKeys.NodeId]: selectedNodes[0],
        [EventVarCfgKeys.EventId]: eventId
      }
    )
  }

  const handleAddEvent = () => {
    if (!eventsDescQuery.data?.CrudDescription) {
      throw Error('Cannot handleAddEvent if events CrudDescription is null!')
    }
    if (isNil(selectedNodes)) {
      throw Error('Cannot handleAddEvent if selectedNodes is nil!')
    }
    eventConfigurator.create(eventsDescQuery.data.CrudDescription.Properties, {
      [EventCfgKeys.NodeId]: selectedNodes[0]
    })
  }

  const handleAddNumericVar = (eventId: number | string | null) => {
    if (isNil(numericVarsDescQuery.data)) {
      throw Error(
        'Cannot handleAddNumericVar if numeric variable description is nil!'
      )
    }
    if (isNil(selectedNodes)) {
      throw Error('Cannot handleAddNumericVar if selectedNodes is nil!')
    }
    numericVarConfigurator.create(
      numericVarsDescQuery.data.CrudDescription.Properties,
      {
        [EventVarCfgKeys.NodeId]: selectedNodes[0],
        [EventVarCfgKeys.EventId]: eventId,
        [EventVarCfgKeys.Multiplier]: 1,
        [EventVarCfgKeys.DecimalPlace]: 0
      }
    )
  }

  const handleAddSubmit = async () => {
    let rcfId
    if (!isNil(eventConfigurator.data)) {
      rcfId = await createEvent(eventConfigurator.data)
      eventIotMapping.addSubmit(rcfId)
    } else if (!isNil(numericVarConfigurator.data)) {
      rcfId = await createNumericVar(numericVarConfigurator.data)
      numericVarIotMapping.addSubmit(rcfId)
    } else if (!isNil(alphaVarConfigurator.data)) {
      rcfId = await createAlphaVar(alphaVarConfigurator.data)
      alphaVarIotMapping.addSubmit(rcfId)
    } else {
      throw Error(
        'Cannot handleAddSubmit if eventConfigurator, ' +
          'numericVarConfigurator and alphaVarConfigurator data are all nil!'
      )
    }
  }

  const handleAlertAlphaVarDelete = () => {
    if (isNil(selectedAlphaVars)) {
      throw Error(
        'Cannot handleAlertAlphaVarDelete if selectedAlphaVars is nil!'
      )
    }
    alphaVarDeleteMutation.mutate(selectedAlphaVars.map(Number))
    setAlertSelection(undefined)
  }

  const handleAlertCancel = () => {
    setAlertSelection(undefined)
  }

  const handleAlertEventDelete = () => {
    if (isNil(selectedEvents)) {
      throw Error('Cannot handleAlertEventDelete if selectedEvents is nil!')
    }
    eventDeleteMutation.mutate(selectedEvents.map(Number))
    setAlertSelection(undefined)
  }

  const handleAlertNumericVarDelete = () => {
    if (isNil(selectedNumericVars)) {
      throw Error(
        'Cannot handleAlertNumericVarDelete if selectedNumericVars is nil!'
      )
    }
    numericVarDeleteMutation.mutate(selectedNumericVars.map(Number))
    setAlertSelection(undefined)
  }

  const handleDeleteAlphaVar = () => {
    setAlertSelection(AlertSelection.AlphaVar)
  }

  const handleDeleteEvent = () => {
    setAlertSelection(AlertSelection.Event)
  }

  const handleDeleteNumericVar = () => {
    setAlertSelection(AlertSelection.NumericVar)
  }

  const handleEditAlphaVar = () => {
    if (isNil(activeAlphaVar)) {
      throw Error('Cannot handleEditAlphaVar if activeAlphaVar is nil!')
    }
    alphaVarConfigurator.edit(activeAlphaVar)
  }

  const handleEditEvent = () => {
    if (isNil(activeEvent)) {
      throw Error('Cannot handleEditEvent if activeEvent is nil!')
    }
    eventConfigurator.edit(activeEvent)
  }

  const handleEditNumericVar = () => {
    if (isNil(activeNumericVar)) {
      throw Error('Cannot handleEditNumericVar if activeNumericVar is null!')
    }
    numericVarConfigurator.edit(activeNumericVar)
  }

  const handleEditSubmit = () => {
    if (!isNil(eventConfigurator.data)) {
      eventUpdateMutation.mutate([eventConfigurator.data])
      eventIotMapping.editSubmit()
    } else if (!isNil(numericVarConfigurator.data)) {
      numericVarUpdateMutation.mutate([numericVarConfigurator.data])
      numericVarIotMapping.editSubmit()
    } else if (!isNil(alphaVarConfigurator.data)) {
      alphaVarUpdateMutation.mutate([alphaVarConfigurator.data])
      alphaVarIotMapping.editSubmit()
    } else {
      throw Error(
        'Cannot handleEditSubmit if eventConfigurator, ' +
          'numericVarConfigurator and alphaVarConfigurator data are all nil!'
      )
    }
  }

  const handleEventPropertyChange = (property: string, value: unknown) => {
    if (!isNil(eventConfigurator.data)) {
      eventConfigurator.update({ [property]: value })
    } else if (!isNil(numericVarConfigurator.data)) {
      numericVarConfigurator.update({ [property]: value })
    } else if (!isNil(alphaVarConfigurator.data)) {
      alphaVarConfigurator.update({ [property]: value })
    } else {
      throw Error(
        'Cannot handleEventPropertyChange if eventConfigurator, ' +
          'numericVarConfigurator, alphaVarConfigurator data are all nil!'
      )
    }
  }

  const handleOpen = (ids: (number | string | null)[]) => {
    setOpenedEvents(ids.length > 0 ? ids.map(Number) : undefined)
  }

  const handleAlphaVarFormCancel = () => {
    alphaVarConfigurator.clear()
  }

  const handleEventFormCancel = () => {
    eventConfigurator.clear()
  }

  const handleNumericVarFormCancel = () => {
    numericVarConfigurator.clear()
  }

  const handleSelectAlphaVars = (ids: (number | string | null)[]) => {
    setSelectedAlphaVars(ids.length > 0 ? ids.map(Number) : undefined)
  }

  const handleSelectEvents = (ids: (number | string | null)[]) => {
    setSelectedEvents(ids.length > 0 ? ids.map(Number) : undefined)
    setSelectedAlphaVars(undefined)
    setSelectedNumericVars(undefined)
  }

  const handleSelectNodes = (ids: (number | string)[]) => {
    setSelectedNodes(ids.length > 0 ? ids.map(Number) : undefined)
    setOpenedEvents(undefined)
    setSelectedEvents(undefined)
    setSelectedAlphaVars(undefined)
    setSelectedNumericVars(undefined)
  }

  const handleSelectNumericVars = (ids: (number | string | null)[]) => {
    setSelectedNumericVars(ids.length > 0 ? ids.map(Number) : undefined)
  }

  const handleSubmit = () => {
    if (
      eventConfigurator.mode === ConfigurationMode.Create ||
      numericVarConfigurator.mode === ConfigurationMode.Create ||
      alphaVarConfigurator.mode === ConfigurationMode.Create
    ) {
      handleAddSubmit()
    } else if (
      eventConfigurator.mode === ConfigurationMode.Edit ||
      numericVarConfigurator.mode === ConfigurationMode.Edit ||
      alphaVarConfigurator.mode === ConfigurationMode.Edit
    ) {
      handleEditSubmit()
    } else {
      throw Error(
        'Cannot handleSubmit if all configurator modes are undefined!'
      )
    }
  }

  const pageReady =
    alphaVarsDescQuery.isSuccess &&
    categoriesQuery.isSuccess &&
    eventsDescQuery.isSuccess &&
    nodesQuery.isSuccess &&
    numericVarsDescQuery.isSuccess &&
    unitsQuery.isSuccess &&
    variablesQuery.isSuccess &&
    eventIotMapping.isReady &&
    alphaVarIotMapping.isReady &&
    numericVarIotMapping.isReady &&
    !alphaVarCreateMutation.isLoading &&
    !alphaVarDeleteMutation.isLoading &&
    !alphaVarUpdateMutation.isLoading &&
    !eventCreateMutation.isLoading &&
    !eventDeleteMutation.isLoading &&
    !eventUpdateMutation.isLoading &&
    !numericVarCreateMutation.isLoading &&
    !numericVarDeleteMutation.isLoading &&
    !numericVarUpdateMutation.isLoading &&
    !(
      isNil(alphaVarConfigurator.mode) &&
      alphaVarIotMapping.mode === ConfigurationMode.Create
    ) &&
    !(
      isNil(eventConfigurator.mode) &&
      eventIotMapping.mode === ConfigurationMode.Create
    ) &&
    !(
      isNil(numericVarConfigurator.mode) &&
      numericVarIotMapping.mode === ConfigurationMode.Create
    )

  const pageLoading =
    alphaVarsDescQuery.isLoading ||
    categoriesQuery.isLoading ||
    eventsDescQuery.isLoading ||
    nodesQuery.isLoading ||
    numericVarsDescQuery.isLoading ||
    unitsQuery.isLoading ||
    variablesQuery.isLoading ||
    eventIotMapping.isLoading ||
    alphaVarIotMapping.isLoading ||
    numericVarIotMapping.isLoading ||
    alphaVarCreateMutation.isLoading ||
    alphaVarDeleteMutation.isLoading ||
    alphaVarUpdateMutation.isLoading ||
    eventCreateMutation.isLoading ||
    eventDeleteMutation.isLoading ||
    eventUpdateMutation.isLoading ||
    numericVarCreateMutation.isLoading ||
    numericVarDeleteMutation.isLoading ||
    numericVarUpdateMutation.isLoading ||
    (isNil(alphaVarConfigurator.mode) &&
      alphaVarIotMapping.mode === ConfigurationMode.Create) ||
    (isNil(eventConfigurator.mode) &&
      eventIotMapping.mode === ConfigurationMode.Create) ||
    (isNil(numericVarConfigurator.mode) &&
      numericVarIotMapping.mode === ConfigurationMode.Create)

  const eventsReady =
    alphaVarsQuery.isSuccess &&
    eventsQuery.isSuccess &&
    numericVarsQuery.isSuccess

  const eventsLoading =
    alphaVarsQuery.isLoading ||
    eventsQuery.isLoading ||
    numericVarsQuery.isLoading

  const mappingFormSection =
    alphaVarIotMapping.formSection ||
    eventIotMapping.formSection ||
    numericVarIotMapping.formSection
  const mappingFormCustomHelpers =
    alphaVarIotMapping.customHelpers ||
    eventIotMapping.customHelpers ||
    numericVarIotMapping.customHelpers

  let handleAlertDelete
  let alertMessage
  let alertTitle
  switch (alertSelection) {
    case AlertSelection.AlphaVar:
      handleAlertDelete = handleAlertAlphaVarDelete
      alertMessage = intl.formatMessage(messages.alertAlphaVarMessage, {
        count: selectedAlphaVars?.length
      })
      alertTitle = intl.formatMessage(messages.alertAlphaVarTitle, {
        count: selectedAlphaVars?.length
      })
      break
    case AlertSelection.NumericVar:
      handleAlertDelete = handleAlertNumericVarDelete
      alertMessage = intl.formatMessage(messages.alertNumericVarMessage, {
        count: selectedNumericVars?.length
      })
      alertTitle = intl.formatMessage(messages.alertNumericVarTitle, {
        count: selectedNumericVars?.length
      })
      break
    default:
      handleAlertDelete = handleAlertEventDelete
      alertMessage = intl.formatMessage(messages.alertEventMessage, {
        count: selectedEvents?.length
      })
      alertTitle = intl.formatMessage(messages.alertEventTitle, {
        count: selectedEvents?.length
      })
      break
  }

  return (
    <>
      {pageReady && (
        <>
          {eventConfigurator.data &&
            eventsDescQuery.data &&
            eventLookupProperties &&
            mappingFormSection && (
              <DataForm
                formSections={[
                  {
                    data: eventConfigurator.data,
                    ignoredProperties: [EventCfgKeys.Id, EventCfgKeys.NodeId],
                    lookupProperties: eventLookupProperties,
                    onPropertyChange: handleEventPropertyChange,
                    schema: eventsDescQuery.data.CrudDescription
                  },
                  mappingFormSection
                ]}
                onCancel={handleEventFormCancel}
                onSubmit={handleSubmit}
                title={
                  eventConfigurator.mode === ConfigurationMode.Create
                    ? intl.formatMessage({
                        id: 'eventInstances.createEvent',
                        description: 'Create event dialogue title',
                        defaultMessage: 'Create Event'
                      })
                    : intl.formatMessage({
                        id: 'eventInstances.editEvent',
                        description: 'Edit event dialogue title',
                        defaultMessage: 'Edit Event'
                      })
                }
                customHelpers={mappingFormCustomHelpers}
              />
            )}
          {numericVarConfigurator.data &&
            numericVarsDescQuery.data?.CrudDescription &&
            numericVarLookupProperties &&
            mappingFormSection && (
              <DataForm
                formSections={[
                  {
                    data: numericVarConfigurator.data,
                    ignoredProperties: [
                      EventVarCfgKeys.EventId,
                      EventVarCfgKeys.Id,
                      EventVarCfgKeys.NodeId
                    ],
                    lookupProperties: numericVarLookupProperties,
                    onPropertyChange: handleEventPropertyChange,
                    schema: numericVarsDescQuery.data.CrudDescription
                  },
                  mappingFormSection
                ]}
                onCancel={handleNumericVarFormCancel}
                onSubmit={handleSubmit}
                title={
                  numericVarConfigurator.mode === ConfigurationMode.Create
                    ? intl.formatMessage({
                        id: 'eventInstances.createNumericVar',
                        description: 'Create numeric variable dialogue title',
                        defaultMessage: 'Create Numeric Variable'
                      })
                    : intl.formatMessage({
                        id: 'eventInstances.editNumericVar',
                        description: 'Edit numeric variable dialogue title',
                        defaultMessage: 'Edit Numeric Variable'
                      })
                }
              />
            )}
          {alphaVarConfigurator.data &&
            alphaVarsDescQuery.data?.CrudDescription &&
            alphaVarLookupProperties &&
            mappingFormSection && (
              <DataForm
                formSections={[
                  {
                    data: alphaVarConfigurator.data,
                    ignoredProperties: [
                      EventAlphaVarCfgKeys.EventId,
                      EventAlphaVarCfgKeys.Id,
                      EventAlphaVarCfgKeys.NodeId
                    ],
                    lookupProperties: alphaVarLookupProperties,
                    onPropertyChange: handleEventPropertyChange,
                    schema: alphaVarsDescQuery.data.CrudDescription
                  },
                  mappingFormSection
                ]}
                onCancel={handleAlphaVarFormCancel}
                onSubmit={handleSubmit}
                title={
                  alphaVarConfigurator.mode === ConfigurationMode.Create
                    ? intl.formatMessage({
                        id: 'eventInstances.createAlphaVar',
                        description:
                          'Create alphanumeric variable dialogue title',
                        defaultMessage: 'Create Alphanumeric Variable'
                      })
                    : intl.formatMessage({
                        id: 'eventInstances.editAlphaVar',
                        description:
                          'Edit alphanumeric variable dialogue title',
                        defaultMessage: 'Edit Alphanumeric Variable'
                      })
                }
              />
            )}
          <Box paddingTop={3} paddingBottom={3}>
            <Container maxWidth={false}>
              <>
                {nodesQuery.data?.Items && (
                  <Grid container spacing={2} alignItems="stretch">
                    <Grid item xs={12} md={5} lg={4} xl={3}>
                      <Box alignItems="flex-start">
                        <Hierarchy
                          activeProperty={NodeKeys.Active}
                          data={nodesQuery.data.Items}
                          idProperty={NodeKeys.Id}
                          nameProperty={NodeKeys.Name}
                          onSelect={handleSelectNodes}
                          ordinalProperty={NodeKeys.OrdinalPosition}
                          parentIdProperty={NodeKeys.ParentId}
                          selected={selectedNodes}
                          title={intl.formatMessage({
                            id: 'eventInstances.nodes',
                            description:
                              'Event instances page, node hierarchy title',
                            defaultMessage: 'Nodes'
                          })}
                        />
                      </Box>
                    </Grid>
                    <Grid item xs={12} md={7} lg={8} xl={9}>
                      <Box height="100%" position="sticky" top={3}>
                        {eventsReady &&
                          alphaVarsQuery.data?.Items &&
                          alphaVarsDescQuery.data?.ViewDescription &&
                          eventsQuery.data?.Items &&
                          eventsDescQuery.data?.ViewDescription &&
                          numericVarsQuery.data?.Items &&
                          numericVarsDescQuery.data?.ViewDescription && (
                            <CollapsibleTable
                              dataInner={[
                                {
                                  data: numericVarsQuery.data.Items,
                                  idProperty: EventVarCfgKeys.Id,
                                  ignoredKeys: [
                                    EventVarCfgKeys.EventId,
                                    EventVarCfgKeys.Id,
                                    EventVarCfgKeys.NodeId,
                                    EventVarCfgKeys.RcfId,
                                    EventVarCfgKeys.VariableId,
                                    EventVarCfgKeys.UnitId
                                  ],
                                  multiSelect: true,
                                  onAdd: handleAddNumericVar,
                                  onDelete: handleDeleteNumericVar,
                                  onEdit: numericVarIotMapping.canEdit
                                    ? handleEditNumericVar
                                    : undefined,
                                  onSelect: handleSelectNumericVars,
                                  parentIdProperty: EventVarCfgKeys.EventId,
                                  schema:
                                    numericVarsDescQuery.data.ViewDescription,
                                  selected: selectedNumericVars,
                                  title: intl.formatMessage({
                                    id: 'eventInstances.numericVariables',
                                    description:
                                      'Numeric event variables table title',
                                    defaultMessage: 'Numeric Variables'
                                  })
                                },
                                {
                                  data: alphaVarsQuery.data.Items,
                                  idProperty: EventAlphaVarCfgKeys.Id,
                                  ignoredKeys: [
                                    EventAlphaVarCfgKeys.EventId,
                                    EventAlphaVarCfgKeys.Id,
                                    EventAlphaVarCfgKeys.NodeId,
                                    EventAlphaVarCfgKeys.RcfId,
                                    EventAlphaVarCfgKeys.VariableId
                                  ],
                                  multiSelect: true,
                                  onAdd: handleAddAlphaVar,
                                  onDelete: handleDeleteAlphaVar,
                                  onEdit: alphaVarIotMapping.canEdit
                                    ? handleEditAlphaVar
                                    : undefined,
                                  onSelect: handleSelectAlphaVars,
                                  parentIdProperty:
                                    EventAlphaVarCfgKeys.EventId,
                                  schema:
                                    alphaVarsDescQuery.data.ViewDescription,
                                  selected: selectedAlphaVars,
                                  title: intl.formatMessage({
                                    id: 'eventInstances.alphanumericVariables',
                                    description:
                                      'Alphanumeric event variables table title',
                                    defaultMessage: 'Alphanumeric Variables'
                                  })
                                }
                              ]}
                              dataOuter={eventsQuery.data.Items}
                              idProperty={EventCfgKeys.Id}
                              ignoredKeys={[
                                EventCfgKeys.DataCollectionCategoryId,
                                EventCfgKeys.Id,
                                EventCfgKeys.NodeId,
                                EventCfgKeys.RcfId
                              ]}
                              multiSelect
                              onAdd={handleAddEvent}
                              onDelete={handleDeleteEvent}
                              onEdit={
                                eventIotMapping.canEdit
                                  ? handleEditEvent
                                  : undefined
                              }
                              onOpen={handleOpen}
                              onSelect={handleSelectEvents}
                              opened={openedEvents || []}
                              schema={eventsDescQuery.data.ViewDescription}
                              selected={selectedEvents}
                              title={intl.formatMessage({
                                id: 'eventInstances.events',
                                description: 'Events table title',
                                defaultMessage: 'Events'
                              })}
                            />
                          )}
                        {eventsLoading && <ContentLoading />}
                        {selectedNodes?.length !== 1 && (
                          <Placeholder
                            message={intl.formatMessage({
                              id: 'eventInstances.placeholderMessage',
                              description:
                                'Event instances selection placeholder text',
                              defaultMessage:
                                'Select a Node to View and Edit its Events'
                            })}
                          />
                        )}
                      </Box>
                    </Grid>
                  </Grid>
                )}
              </>
            </Container>
          </Box>
        </>
      )}
      {pageLoading && <PageLoading />}
      <AlertDialogue
        actions={[
          {
            handler: handleAlertDelete,
            text: intl.formatMessage({
              id: 'eventInstances.alertDelete',
              description: 'Delete alert dialogue, delete button text',
              defaultMessage: 'Delete'
            })
          },
          {
            handler: handleAlertCancel,
            text: intl.formatMessage({
              id: 'eventInstances.alertCancel',
              description: 'Delete alert dialogue, cancel button text',
              defaultMessage: 'Cancel'
            })
          }
        ]}
        message={alertMessage}
        open={!isNil(alertSelection)}
        title={alertTitle}
      />
    </>
  )
}

export default EventInstances
