import React, { FunctionComponent, useState } from 'react'
import { useQuery } from 'react-query'
import { useIntl } from 'react-intl'
import { NumericArrayParam, useQueryParam } from 'use-query-params'
import { Duration, sub } from 'date-fns'
import { isNil } from 'lodash'
import { Box, Container, Grid } from '@material-ui/core'
import { useSnackbar } from 'notistack'
import DataGrid from '../../components/DataGrid'
import Hierarchy from '../../components/Hierarchy/Hierarchy'
import PageLoading from '../../components/Loading/PageLoading'
import TimeRange from '../../components/TimeRange'
import usePagination from '../../hooks/usePagination'
import {
  Expression,
  ExpressionOperator,
  IdentifierKeys,
  LogicalOperator,
  NodeKeys,
  Order,
  Paths,
  useApi
} from '../../api/RcfactoryApi'

enum ParamKeys {
  NodeId = 'nodeId'
}

const defaultDuration: Duration = { days: 1 }

const Identifiers: FunctionComponent = () => {
  const intl = useIntl()
  const api = useApi()
  const pagination = usePagination(100)
  const [endTime, setEndTime] = useState<Date | null>(new Date())
  const [filter, setFilter] = useState<Expression[]>([])
  const [selected, setSelected] = useQueryParam(
    ParamKeys.NodeId,
    NumericArrayParam
  )
  const [startTime, setStartTime] = useState<Date | null>(
    sub(new Date(), defaultDuration)
  )
  const { enqueueSnackbar } = useSnackbar()

  const identifiersQuery = useQuery(
    [
      Paths.Identifiers,
      endTime,
      filter,
      selected,
      startTime,
      pagination.page,
      pagination.rowsPerPage
    ],
    () => {
      if (isNil(selected)) {
        throw Error('Should not query identifiers if selected is nil!')
      }
      const expressions: Expression[] = [
        {
          Op: ExpressionOperator.Equal,
          Prop: IdentifierKeys.NodeId,
          Val: selected[0]
        }
      ]
      if (!isNil(startTime)) {
        expressions.push({
          Op: ExpressionOperator.GreaterThanEqual,
          Prop: IdentifierKeys.EndDateTime,
          Val: startTime
        })
      }
      if (!isNil(endTime)) {
        expressions.push({
          Op: ExpressionOperator.LessThanEqual,
          Prop: IdentifierKeys.StartDateTime,
          Val: endTime
        })
      }
      return api.getList({
        modelExpressions: {
          Expressions: expressions.concat(filter),
          Operator: LogicalOperator.And
        },
        order1: Order.desc,
        orderBy1: IdentifierKeys.StartDateTime,
        path: Paths.Identifiers,
        pageNumber: pagination.page,
        pageSize: pagination.rowsPerPage
      })
    },
    {
      enabled: selected?.length === 1,
      onError: () =>
        enqueueSnackbar(
          intl.formatMessage({
            id: 'identifiers.failedIdentifiers',
            description: 'Failed to get identifiers notification text',
            defaultMessage: 'Failed to get Identifiers!'
          }),
          {
            variant: 'error'
          }
        )
    }
  )

  const identifiersDescQuery = useQuery(
    Paths.Identifiers + Paths.UtilsGetDesc,
    () => api.getDesc({ path: Paths.Identifiers }),
    {
      onError: () =>
        enqueueSnackbar(
          intl.formatMessage({
            id: 'identifiers.failedIdentifierSchema',
            description: 'Failed to get identifier schema notification text',
            defaultMessage: 'Failed to get Identifier Schema!'
          }),
          {
            variant: 'error'
          }
        )
    }
  )

  const nodesQuery = useQuery(
    Paths.Nodes,
    () =>
      api.getList({
        modelExpressions: {
          Expressions: [
            {
              Prop: NodeKeys.Active,
              Op: ExpressionOperator.Equal,
              Val: true
            }
          ]
        },
        order1: Order.asc,
        orderBy1: NodeKeys.OrdinalPosition,
        path: Paths.Nodes
      }),
    {
      onError: () =>
        enqueueSnackbar(
          intl.formatMessage({
            id: 'identifiers.failedNodes',
            description: 'Failed to get nodes notification text',
            defaultMessage: 'Failed to get Nodes!'
          }),
          {
            variant: 'error'
          }
        )
    }
  )

  const handleSelectNodes = (ids: number[]) => {
    setSelected(ids.length > 0 ? ids.map(Number) : undefined)
    setFilter([])
  }

  const pageReady = identifiersDescQuery.isSuccess && nodesQuery.isSuccess

  const pageLoading = identifiersDescQuery.isLoading || nodesQuery.isLoading

  return (
    <>
      {pageReady && (
        <Box paddingTop={3} paddingBottom={3}>
          <Container maxWidth={false}>
            <Grid container spacing={2}>
              <TimeRange
                endTime={endTime}
                onEndTimeChange={setEndTime}
                onStartTimeChange={setStartTime}
                startTime={startTime}
              />
              {nodesQuery.data?.Items && (
                <Grid item xs={12} md={5} lg={4} xl={3}>
                  <Hierarchy
                    data={nodesQuery.data.Items}
                    idProperty={NodeKeys.Id}
                    label="Node"
                    nameProperty={NodeKeys.Name}
                    onSelect={handleSelectNodes}
                    ordinalProperty={NodeKeys.OrdinalPosition}
                    parentIdProperty={NodeKeys.ParentId}
                    selected={selected}
                    title={intl.formatMessage({
                      id: 'identifiers.nodes',
                      description:
                        'Identifiers viewer page, node hierarchy title',
                      defaultMessage: 'Nodes'
                    })}
                  />
                </Grid>
              )}
              <Grid item xs={12} md={7} lg={8} xl={9}>
                <Box display="flex" height="100%" minHeight={750}>
                  <Box flexGrow={1}>
                    {identifiersDescQuery.data?.ViewDescription && (
                      <DataGrid
                        data={identifiersQuery.data}
                        filterMode="server"
                        ignoredProperties={[IdentifierKeys.NodeId]}
                        loading={identifiersQuery.isLoading}
                        onFilterChange={setFilter}
                        onPageChange={pagination.setPage}
                        onRowsPerPageChange={pagination.setRowsPerPage}
                        page={pagination.page}
                        pagination
                        paginationMode="server"
                        rowsPerPage={pagination.rowsPerPage}
                        rowsPerPageOptions={pagination.rowsPerPageOptions}
                        schema={identifiersDescQuery.data.ViewDescription}
                        widths={{
                          [IdentifierKeys.Category]: 200,
                          [IdentifierKeys.Description]: 200,
                          [IdentifierKeys.GlobalIdentifierName]: 200,
                          [IdentifierKeys.Name]: 200,
                          [IdentifierKeys.Value]: 160
                        }}
                        totalRows={identifiersQuery.data?.Pagination.TotalCount}
                      />
                    )}
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </Container>
        </Box>
      )}
      {pageLoading && <PageLoading />}
    </>
  )
}

export default Identifiers
