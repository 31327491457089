import React, { FunctionComponent, useMemo, useState } from 'react'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { defineMessages, useIntl } from 'react-intl'
import { NumericArrayParam, useQueryParam } from 'use-query-params'
import { includes, isNil } from 'lodash'
import { Box, Container, Grid } from '@material-ui/core'
import { useSnackbar } from 'notistack'
import AlertDialogue from '../../../../components/AlertDialogue'
import DataForm from '../../../../components/DataForm'
import Table from '../../../../components/Table/Table'
import FilterDialogue from '../../../../components/FilterDialogue'
import PageLoading from '../../../../components/Loading/PageLoading'
import useConfigure, { ConfigurationMode } from '../../../../hooks/useConfigure'
import useFilter from '../../../../hooks/useFilter'
import usePagination from '../../../../hooks/usePagination'
import useSort from '../../../../hooks/useSort'
import {
  EventDataVarTypeKeys,
  LogicalOperator,
  Paths,
  useApi,
  VariableKeys
} from '../../../../api/RcfactoryApi'

enum ParamKeys {
  VariableId = 'variableId'
}

enum ConfigurationKeys {
  Variable = 'variable'
}

const messages = defineMessages({
  alertMessage: {
    id: 'eventVariables.alertMessage',
    description: 'Delete alert dialogue message content',
    defaultMessage: 'Are you sure you want to delete the selected Event ' +
    '{count, plural, one{Variable} other{Variables}}?'
  },
  alertTitle: {
    id: 'eventVariables.alertTitle',
    description: 'Delete alert dialogue title',
    defaultMessage: 'Delete Event {count, plural, one{Variable} other{Variables}}'
  }
})

const omitFilterProperties = [
  VariableKeys.Id,
  VariableKeys.Type
]

const EventVariables: FunctionComponent = () => {
  const intl = useIntl()
  const api = useApi()
  const configurator = useConfigure(ConfigurationKeys.Variable)
  const filterer = useFilter()
  const pagination = usePagination()
  const sort = useSort(VariableKeys.Id)
  const [alertOpen, setAlertOpen] = useState<boolean>(false)
  const [selected, setSelected] = useQueryParam(
    ParamKeys.VariableId, NumericArrayParam
  )
  const { enqueueSnackbar } = useSnackbar()
  const queryClient = useQueryClient()
  const dataVarTypesQuery = useQuery(
    Paths.EventDataVarType,
    () => api.getList({
      path: Paths.EventDataVarType
    }), {
      onError: () => enqueueSnackbar(
        intl.formatMessage({
          id: 'eventVariables.failedEventDataVariableTypes',
          description: 'Fetch event data variable types error notification text',
          defaultMessage: 'Failed to get Event Data Variable Types!'
        }), {
          variant: 'error'
        }
      )
    }
  )
  const variablesDescQuery = useQuery(
    Paths.VariableCfg + Paths.UtilsGetDesc,
    () => api.getDesc({
      path: Paths.VariableCfg
    }), {
      onError: () => enqueueSnackbar(
        intl.formatMessage({
          id: 'eventVariables.failedSchema',
          description: 'Fetch event variable schema error notification text',
          defaultMessage: 'Failed to get Variable Schema!'
        }), {
          variant: 'error'
        }
      )
    }
  )
  const variablesQuery = useQuery([
    Paths.VariableCfg,
    filterer.active,
    sort.order,
    sort.orderBy,
    pagination.page,
    pagination.rowsPerPage
  ],
  () => api.getList({
    modelExpressions: {
      Expressions: filterer.active,
      Operator: LogicalOperator.And
    },
    order1: sort.order,
    orderBy1: sort.orderBy,
    pageNumber: pagination.page,
    pageSize: pagination.rowsPerPage,
    path: Paths.VariableCfg
  }), {
    onError: () => enqueueSnackbar(
      intl.formatMessage({
        id: 'eventVariables.failedVariables',
        description: 'Fetch event variables error notification text',
        defaultMessage: 'Failed to get Variables!'
      }), {
        variant: 'error'
      }
    )
  })
  const createMutation = useMutation(
    (items: Record<string, unknown>[]) => api.create({
      items: items,
      path: Paths.VariableCfg
    }), {
      onError: () => {
        enqueueSnackbar(
          intl.formatMessage({
            id: 'eventVariables.failedCreate',
            description: 'Event variables create error notification text',
            defaultMessage: 'Failed to create Variable!'
          }), {
            variant: 'error'
          }
        )
      },
      onSuccess: () => {
        enqueueSnackbar(
          intl.formatMessage({
            id: 'eventVariables.successfulCreate',
            description: 'Event variables create success notification text',
            defaultMessage: 'Successfully created Variable!'
          }), {
            variant: 'success'
          }
        )
        configurator.clear()
        queryClient.invalidateQueries(Paths.VariableCfg)
      }
    }
  )
  const deleteMutation = useMutation(
    (ids: number[]) => api.delete({
      ids: ids,
      path: Paths.VariableCfg
    }), {
      onError: () => {
        enqueueSnackbar(
          intl.formatMessage({
            id: 'eventVariables.failedDelete',
            description: 'Event variables delete error notification text',
            defaultMessage: 'Failed to delete Variables!'
          }), {
            variant: 'error'
          }
        )
      },
      onSuccess: () => {
        enqueueSnackbar(
          'Successfully deleted Variables!', {
            variant: 'success'
          }
        )
        setSelected(undefined)
        queryClient.invalidateQueries(Paths.VariableCfg)
      }
    }
  )
  const updateMutation = useMutation(
    (items: Record<string, unknown>[]) => api.update({
      items: items,
      path: Paths.VariableCfg
    }), {
      onError: () => {
        enqueueSnackbar(
          intl.formatMessage({
            id: 'eventVariables.failedUpdate',
            description: 'Event variable update error notification text',
            defaultMessage: 'Failed to update Variable!'
          }), {
            variant: 'error'
          }
        )
      },
      onSuccess: () => {
        enqueueSnackbar(
          intl.formatMessage({
            id: 'eventVariables.successfulUpdate',
            description: 'Event variable update success notification text',
            defaultMessage: 'Successfully updated Variable!'
          }), {
            variant: 'success'
          }
        )
        configurator.clear()
        queryClient.invalidateQueries(Paths.VariableCfg)
      }
    }
  )

  const activeVariable = useMemo(() => {
    if (!variablesQuery.data?.Items) {
      return
    }
    if (selected?.length !== 1) {
      return
    }
    return variablesQuery.data?.Items.find(
      (d: Record<string, unknown>) => d[VariableKeys.Id] === selected[0]
    )
  }, [selected, variablesQuery.data?.Items])

  const lookupProperties = useMemo(() => {
    if (!dataVarTypesQuery.data?.Items) {
      return undefined
    }
    return [{
      data: dataVarTypesQuery.data.Items,
      localProperty: VariableKeys.EventDataVarId,
      nameProperty: EventDataVarTypeKeys.Name,
      remoteProperty: EventDataVarTypeKeys.Id
    }]
  }, [dataVarTypesQuery.data?.Items])

  const handleAddSubmit = () => {
    if (!configurator.data) {
      throw Error('Cannot handleAddSubmit if configurator data is null!')
    }
    createMutation.mutate([configurator.data])
  }

  const handleAlertCancel = () => {
    setAlertOpen(false)
  }

  const handleAlertDelete = () => {
    if (isNil(selected)) {
      throw Error('Cannot handleAlertDelete if selected is nil!')
    }
    deleteMutation.mutate(selected.map(Number))
    setAlertOpen(false)
  }

  const handleDelete = () => {
    setAlertOpen(true)
  }

  const handleEditSubmit = () => {
    if (!configurator.data) {
      throw Error('Cannot handleEditSubmit if editData is null!')
    }
    updateMutation.mutate([configurator.data])
  }

  const handleFilterSubmit = () => {
    filterer.submit()
    pagination.setPage(0)
    filterer.clearData()
  }

  const handleFormCancel = () => {
    configurator.clear()
    filterer.clearData()
  }

  const handlePageChange = (newPage: number) => {
    pagination.setPage(newPage)
    setSelected(undefined)
  }

  const handleRequestSort = (property: string) => {
    sort.requestSort(property)
    setSelected(undefined)
  }

  const handleRowsPerPageChange = (rows: number) => {
    pagination.setRowsPerPage(rows)
    setSelected(undefined)
  }

  const handleSelect = (ids: (number | string | null)[]) => {
    setSelected(ids.length > 0 ? ids.map(Number) : undefined)
  }

  const pageReady = variablesDescQuery.isSuccess && variablesQuery.isSuccess &&
  !createMutation.isLoading && !deleteMutation.isLoading && !updateMutation.isLoading

  const pageLoading = variablesDescQuery.isLoading || variablesQuery.isLoading ||
  createMutation.isLoading || deleteMutation.isLoading || updateMutation.isLoading

  return (
    <>
      {
        pageReady &&
        <Box
          paddingTop={3}
          paddingBottom={3}
        >
          <Container maxWidth='md'>
            <Grid container>
              <Grid item xs={12}>
                { configurator.data &&
                  variablesDescQuery.data?.CrudDescription &&
                  lookupProperties &&
                  <DataForm
                    formSections={[{
                      data: configurator.data,
                      ignoredProperties: [VariableKeys.Id],
                      lookupProperties: lookupProperties,
                      onPropertyChange: (property, value) => configurator.update({
                        [property]: value
                      }),
                      schema: variablesDescQuery.data.CrudDescription
                    }]}
                    onCancel={handleFormCancel}
                    onSubmit={configurator.mode === ConfigurationMode.Create
                      ? handleAddSubmit
                      : handleEditSubmit
                    }
                    title={configurator.mode === ConfigurationMode.Create
                      ? intl.formatMessage({
                        id: 'eventVariables.create',
                        description: 'Event variables create dialogue title',
                        defaultMessage: 'Create Event Variable'
                      })
                      : intl.formatMessage({
                        id: 'eventVariables.edit',
                        description: 'Event variables edit dialogue title',
                        defaultMessage: 'Edit Event Variable'
                      })
                    }
                  />
                }
                { filterer.data &&
                  variablesDescQuery.data?.ViewDescription &&
                  <FilterDialogue
                    filter={filterer.data}
                    lookupProperties={lookupProperties}
                    onCancel={handleFormCancel}
                    onExpressionChange={filterer.update}
                    onReset={filterer.reset}
                    onSubmit={handleFilterSubmit}
                    schema={variablesDescQuery.data.ViewDescription}
                    title={intl.formatMessage({
                      id: 'eventVariables.filter',
                      description: 'Event variables filter dialogue title',
                      defaultMessage: 'Filter Event Variables'
                    })}
                  />
                }
                { variablesQuery.data?.Items &&
                  variablesQuery.data?.Pagination &&
                  variablesDescQuery.data &&
                  <Table
                    data={variablesQuery.data.Items}
                    idProperty={VariableKeys.Id}
                    ignoredProperties={[VariableKeys.EventDataVarId]}
                    isFiltered={filterer.isActive}
                    multiSelect
                    onAdd={() => configurator.create(
                      variablesDescQuery.data.CrudDescription.Properties
                    )}
                    onDelete={handleDelete}
                    onEdit={activeVariable
                      ? () => configurator.edit(activeVariable)
                      : undefined
                    }
                    onFilter={() => filterer.initialise(
                      variablesDescQuery.data.ViewDescription.Properties.filter(
                        p => !includes(omitFilterProperties, p.PropertyName)
                      )
                    )}
                    onPageChange={handlePageChange}
                    onRequestSort={handleRequestSort}
                    onRowsPerPageChange={handleRowsPerPageChange}
                    onSelect={handleSelect}
                    order={sort.order}
                    orderBy={sort.orderBy}
                    page={pagination.page}
                    rowsPerPage={pagination.rowsPerPage}
                    schema={variablesDescQuery.data.ViewDescription}
                    selected={selected}
                    title={intl.formatMessage({
                      id: 'eventVariables.eventVariables',
                      description: 'Event variables table title',
                      defaultMessage: 'Event Variables'
                    })}
                    totalRows={variablesQuery.data.Pagination.TotalCount}
                  />
                }
              </Grid>
            </Grid>
          </Container>
        </Box>
      }
      {
        pageLoading &&
        <PageLoading/>
      }
      <AlertDialogue
        actions={[{
          handler: handleAlertDelete,
          text: intl.formatMessage({
            id: 'eventVariables.alertDelete',
            description: 'Delete alert dialogue, delete button text',
            defaultMessage: 'Delete'
          })
        }, {
          handler: handleAlertCancel,
          text: intl.formatMessage({
            id: 'eventVariables.alertCancel',
            description: 'Delete alert dialogue, cancel button text',
            defaultMessage: 'Cancel'
          })
        }]}
        message={intl.formatMessage(messages.alertMessage, {
          count: selected?.length
        })}
        open={alertOpen}
        title={intl.formatMessage(messages.alertTitle, {
          count: selected?.length
        })}
      />
    </>
  )
}

export default EventVariables
