import React, { FunctionComponent, useMemo } from 'react'
import { useIntl } from 'react-intl'
import { find, isNil } from 'lodash'
import {
  GridColDef,
  GridColTypeDef,
  GridFeatureMode,
  GridFilterModelParams,
  GridPageChangeParams,
  GridRowsProp,
  GridSelectionModelChangeParams,
  GridSortItem,
  GridSortModelParams,
  XGrid
} from '@material-ui/x-grid'
import {
  Expression,
  GetListResponse,
  ExpressionOperator,
  Schema
} from '../api/RcfactoryApi'
import { camelCaseToTitle } from '../api/Utils'

const convertOperator = (operator: string): ExpressionOperator => {
  switch (operator) {
    case '=':
    case 'equals':
    case 'is':
      return ExpressionOperator.Equal

    case '!=':
    case 'not':
      return ExpressionOperator.NotEqual

    case '>':
    case 'after':
      return ExpressionOperator.GreaterThan

    case '>=':
    case 'onOrAfter':
      return ExpressionOperator.GreaterThanEqual

    case '<':
    case 'before':
      return ExpressionOperator.LessThan

    case '<=':
    case 'onOrBefore':
      return ExpressionOperator.LessThanEqual

    case 'contains': return ExpressionOperator.Contains
    case 'endsWith': return ExpressionOperator.EndsWith
    case 'startsWith': return ExpressionOperator.StartsWith

    default: throw Error(`Operator string not supported! ${operator}`)
  }
}

interface Props {
  checkboxSelection?: boolean,
  customColumns?: { property: string, gridColTypeDef: GridColTypeDef }[],
  data?: GetListResponse,
  filterMode?: GridFeatureMode,
  idProperty?: string,
  ignoredProperties?: string[],
  loading?: boolean,
  onFilterChange?: (expressions: Expression[]) => void,
  onPageChange?: (page: number) => void,
  onRowsPerPageChange?: (rowsPerPage: number) => void,
  onSelectionChange?: (ids: number[]) => void,
  onSortChange?: (params: GridSortItem[]) => void,
  page?: number,
  pagination?: boolean,
  paginationMode?: GridFeatureMode
  rowsPerPage?: number,
  rowsPerPageOptions?: number[],
  schema: Schema,
  sort?: GridSortItem[],
  sortingMode?: GridFeatureMode,
  totalRows?: number,
  widths?: Record<string, number>
}

const DataGrid: FunctionComponent<Props> = (props: Props) => {
  const {
    checkboxSelection,
    customColumns,
    data,
    filterMode,
    idProperty,
    ignoredProperties,
    loading,
    onFilterChange,
    onPageChange,
    onRowsPerPageChange,
    onSelectionChange,
    onSortChange,
    page,
    pagination,
    paginationMode,
    rowsPerPage,
    rowsPerPageOptions,
    schema,
    sort,
    sortingMode,
    totalRows,
    widths
  } = props
  const intl = useIntl()

  const rows: GridRowsProp = useMemo(() => {
    if (!data) {
      return []
    }
    if (idProperty) {
      return data.Items.map((item) => ({ id: Number(item[idProperty]), ...item }))
    } else {
      return data.Items.map((item, index) => ({ id: index, ...item }))
    }
  }, [data, idProperty])

  const columns: GridColDef[] = useMemo(() => {
    const dateColumn: GridColTypeDef = {
      type: 'dateTime',
      width: 190,
      valueFormatter: ({ value }) => intl.formatDate(
        new Date(String(value)), {
          dateStyle: 'short',
          timeStyle: 'medium'
        }
      )
    }

    const filtered = schema.Properties.filter(property =>
      !ignoredProperties?.includes(property.PropertyName)
    )
    return filtered.map(property => {
      const custom = find(customColumns, ['property', property.PropertyName])
      if (custom) {
        return {
          field: property.PropertyName,
          headerName: camelCaseToTitle(property.PropertyName),
          ...custom.gridColTypeDef
        }
      }
      if (property.JsPropertyType === 'Date') {
        return {
          field: property.PropertyName,
          headerName: camelCaseToTitle(property.PropertyName),
          ...dateColumn
        }
      } else {
        return {
          field: property.PropertyName,
          headerName: camelCaseToTitle(property.PropertyName),
          type: property.JsPropertyType,
          width: widths && widths[property.PropertyName]
            ? widths[property.PropertyName]
            : 100
        }
      }
    })
  }, [customColumns, ignoredProperties, intl, schema, widths])

  const handleFilterModelChange = (params: GridFilterModelParams) => {
    if (!onFilterChange) {
      return
    }
    const expressions: Expression[] = []
    for (const item of params.filterModel.items) {
      if (item.columnField && item.operatorValue && item.value) {
        expressions.push({
          Prop: item.columnField,
          Op: convertOperator(item.operatorValue),
          Val: item.value
        })
      }
    }
    onFilterChange(expressions)
  }

  const handlePageChange = (params: GridPageChangeParams) => {
    if (!isNil(onPageChange) && params.page !== page) {
      onPageChange(params.page)
    }
    if (!isNil(onRowsPerPageChange) && params.pageSize !== rowsPerPage) {
      onRowsPerPageChange(params.pageSize)
    }
  }

  const handleSelectionModelChange = (params: GridSelectionModelChangeParams) => {
    if (!onSelectionChange || !data) {
      return
    }
    if (idProperty) {
      onSelectionChange(
        params.selectionModel.map(i => Number(data.Items[Number(i)][idProperty]))
      )
    } else {
      onSelectionChange(params.selectionModel.map(i => Number(i)))
    }
  }

  const handleSortModelChange = (params: GridSortModelParams) => {
    if (!onSortChange) {
      return
    }
    onSortChange(params.sortModel)
  }

  return (
    <XGrid
      checkboxSelection={checkboxSelection}
      columns={columns}
      filterMode={filterMode}
      loading={loading}
      onFilterModelChange={handleFilterModelChange}
      onPageChange={handlePageChange}
      onPageSizeChange={handlePageChange}
      onSelectionModelChange={handleSelectionModelChange}
      onSortModelChange={handleSortModelChange}
      page={page}
      pageSize={rowsPerPage}
      pagination={pagination}
      paginationMode={paginationMode}
      rowCount={totalRows}
      rows={rows}
      rowsPerPageOptions={rowsPerPageOptions}
      sortModel={sort}
      sortingMode={sortingMode}
    />
  )
}

export default DataGrid
