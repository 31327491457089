import React, {
  Box,
  makeStyles,
  TableCell,
  TableRow,
  Tooltip
} from '@material-ui/core'
import { isEmpty, isNull } from 'lodash'
import { FunctionComponent, useEffect, useState } from 'react'
import clsx from 'clsx'
import { differenceInMilliseconds, format, isBefore, isAfter } from 'date-fns'
import stc from 'string-to-color'
import fontColorContrast from 'font-color-contrast'

const useStyles = makeStyles((theme) => ({
  cell: {
    padding: 0,
    overflow: 'visible'
  },
  cellBorder: {
    border: '2px solid'
  },
  line: {
    height: '23px',
    textAlign: 'left',
    padding: '2px',
    fontSize: '10pt',
    float: 'left',
    whiteSpace: 'nowrap'
  },
  label: {
    zIndex: 2
  },
  fixedCell: {
    position: 'sticky',
    left: 0,
    background: theme.palette.background.paper,
    fontWeight: 'bold',
    zIndex: 1000
  },
  fixedRight: {
    position: 'sticky',
    right: 0,
    background: theme.palette.type === 'light' ? '#e6f4ea' : '#575f56',
    fontWeight: 'bold',
    width: 200,
    zIndex: 1000
  }
}))

interface Props {
  label: string
  cells?: TimeLineCell[]
  showCellBorder?: boolean
  cellWidthInPixels: number
}

export interface TimeLineCell {
  data: TimeLineData[]
  interval: TimeLineInterval
}

export interface TimeLineData {
  value: string
  startTime: Date
  endTime: Date
}

interface TimeLineInterval {
  start: Date
  end: Date
}

interface TimeLineCellInternal {
  data: TimeLineDataInternal[]
}

interface TimeLineDataInternal {
  value: string
  originalValue: string
  startTime: Date
  endTime: Date
  hexColour: string
  fillCell: boolean
  width: number
}

const ProductionGridTimeLine: FunctionComponent<Props> = (props: Props) => {
  const { label, cells, showCellBorder, cellWidthInPixels } = props
  const classes = useStyles()
  const [cellData, setCellData] = useState<TimeLineCellInternal[]>()

  let zIndex = 2

  // calculates the width based on lenth of time of interval and the data item.
  useEffect(() => {
    const calculateWidth = (
      item: TimeLineData,
      interval: TimeLineInterval
    ): number => {
      const startTime = isBefore(item.startTime, interval.start)
        ? interval.start
        : item.startTime
      const endTime = isAfter(item.endTime, interval.end)
        ? interval.end
        : item.endTime
      const minutesInData = differenceInMilliseconds(endTime, startTime)
      const intervalLength = differenceInMilliseconds(
        interval.end,
        interval.start
      )
      const width = (minutesInData / intervalLength) * 100
      return width
    }
    let valueFound = false
    let prevColour: string
    let prevCellValue = ''
    const cellData: TimeLineCellInternal[] | undefined = cells?.map((cell) => {
      const internalData: TimeLineDataInternal[] = []

      // TODO: check if dummy record is needed to fill the first space.

      cell.data.forEach((item) => {
        let cellValue = item.value
        const isNullOrEmpty = isNull(cellValue) || isEmpty(cellValue)
        if (!isNullOrEmpty && cellValue !== prevCellValue) {
          valueFound = true
          prevColour = stc(`${cellValue}`)
        } else if (cellValue === prevCellValue) {
          cellValue = ''
        }
        internalData.push({
          value: cellValue,
          originalValue: item.value,
          hexColour: prevColour,
          fillCell: valueFound,
          width: calculateWidth(item, cell.interval),
          startTime: item.startTime,
          endTime: item.endTime
        })
      })
      // always maintain the value of the last cell.
      const previousData = cell.data[cell.data.length - 1]
      prevCellValue = previousData.value ?? prevCellValue
      return { interval: cell.interval, data: internalData }
    })
    setCellData(cellData)
  }, [cells])

  const renderBoxes = (data: TimeLineDataInternal[], cellIndex: number) => {
    const boxes = data.map((d, dataIndex) => {
      if (d.fillCell) {
        let useZIndex = 0
        if (d.value) {
          zIndex++
          useZIndex = zIndex
        } else {
          useZIndex = zIndex - 1
        }
        return (
          <Tooltip
            key={`${label}-tooltip-${cellIndex}-${dataIndex}`}
            title={`${d.originalValue}: (${format(
              d.startTime,
              'dd/MM/yyyy HH:mm:ss'
            )} - ${format(d.endTime, 'dd/MM/yyyy HH:mm:ss')})`}
            arrow
            placement="bottom-start"
          >
            <Box
              key={`${label}-inner-box-${cellIndex}${dataIndex}`}
              width={`${d.width}%`}
              style={{
                backgroundColor: `${d.hexColour}`,
                zIndex: useZIndex,
                color: fontColorContrast(d.hexColour)
              }}
              className={clsx(classes.line)}
            >
              {d.value}
            </Box>
          </Tooltip>
        )
      } else {
        return <Box key={`${label}-inner-box-${cellIndex}-${dataIndex}`}></Box>
      }
    })
    return boxes
  }

  return (
    <TableRow>
      <TableCell className={classes.fixedCell} key={`${label}-label`}>
        {label}
      </TableCell>
      {/* <TableCell key={`${label}-spacer}`}></TableCell> */}
      {cellData?.map((cell, cellIndex) => {
        return (
          <TableCell
            key={`${label}-cell-${cellIndex}`}
            className={clsx(classes.cell, {
              [classes.cellBorder]: showCellBorder
            })}
          >
            <Box
              key={`${label}-box-${cellIndex}`}
              display="flex"
              flexDirection="row"
              width={cellWidthInPixels}
            >
              {renderBoxes(cell.data, cellIndex)}
            </Box>
          </TableCell>
        )
      })}
      <TableCell
        key={`${label}-end-spacer`}
        className={classes.fixedRight}
      ></TableCell>
    </TableRow>
  )
}

export default ProductionGridTimeLine
