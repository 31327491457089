import React, { FunctionComponent, useState } from 'react'
import { TextField } from '@material-ui/core'
import { Autocomplete as MuiAutocomplete } from '@material-ui/lab'

interface Props {
  disableClearable?: boolean,
  disabled?: boolean,
  label?: string,
  loading?: boolean,
  multiple?: boolean,
  onValueChange: (value: string | string[] | null) => void,
  options: string[],
  required?: boolean,
  value: string | string[] | null,
  variant?: 'standard' | 'filled' | 'outlined'
}

const Autocomplete: FunctionComponent<Props> = (props: Props) => {
  const {
    disableClearable,
    disabled,
    label,
    loading,
    multiple,
    onValueChange,
    options,
    required,
    value,
    variant
  } = props
  const [inputValue, setInputValue] = useState('')

  return (
    <MuiAutocomplete
      disableClearable={disableClearable}
      disabled={disabled}
      options={options}
      inputValue={inputValue}
      loading={loading}
      multiple={multiple}
      onChange={(_event, newValue) => {
        onValueChange(newValue)
      }}
      onInputChange={(_event, newInputValue) => {
        setInputValue(newInputValue)
      }}
      renderInput={params =>
        <TextField
          {...params}
          disabled={disabled}
          label={label}
          required={required}
          variant={variant}
        />
      }
      value={value}
    />
  )
}

export default Autocomplete
