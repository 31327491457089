import React, { ChangeEventHandler, FunctionComponent } from 'react'
import {
  Box,
  FormControl,
  IconButton,
  Input,
  InputAdornment,
  InputLabel
} from '@material-ui/core'
import { FileCopy, Visibility, VisibilityOff } from '@material-ui/icons'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import { useSnackbar } from 'notistack'
import { useIntl } from 'react-intl'

interface Props {
  canCopy?: boolean,
  label?: string,
  onChange?: ChangeEventHandler<HTMLTextAreaElement | HTMLInputElement>,
  onToggleShow?: () => void,
  showPassword?: boolean,
  value: string
}

const PasswordInput: FunctionComponent<Props> = (props: Props) => {
  const {
    canCopy,
    label,
    onChange,
    onToggleShow,
    showPassword,
    value
  } = props
  const intl = useIntl()
  const { enqueueSnackbar } = useSnackbar()

  const onCopy = () => {
    enqueueSnackbar(intl.formatMessage({
      id: 'passwordInput.passwordCopied',
      description: 'Password copied to clipboard notification text',
      defaultMessage: 'Password copied to clipboard!'
    }))
  }

  const handleMouseDownPassword: React.MouseEventHandler<HTMLButtonElement> = (event) => {
    event.preventDefault()
  }

  return (
    <Box mx={2} my={1}>
      <FormControl style={{ width: '100%' }}>
        { label &&
          <InputLabel htmlFor="standard-adornment-password">{label}</InputLabel>
        }
        <Input
          id="standard-adornment-password"
          type={showPassword ? 'text' : 'password'}
          value={value}
          onChange={onChange}
          inputProps={{ spellCheck: false }}
          endAdornment={
            <InputAdornment position="end">
              { onToggleShow &&
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={onToggleShow}
                  onMouseDown={handleMouseDownPassword}
                >
                  {showPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              }
              { canCopy &&
                <CopyToClipboard text={value} onCopy={onCopy}>
                  <IconButton
                    aria-label="copy password to clipboard"
                    onMouseDown={handleMouseDownPassword}
                  >
                    <FileCopy />
                  </IconButton>
                </CopyToClipboard>
              }
            </InputAdornment>
          }
        />
      </FormControl>
    </Box>
  )
}

export default PasswordInput
