import React, { FunctionComponent, useEffect, useState } from 'react'
import {
  Link,
  Redirect,
  Route,
  Switch,
  useHistory,
  useRouteMatch
} from 'react-router-dom'
import { useIntl } from 'react-intl'
import { Collapse, ListItemText } from '@material-ui/core'
import { ExpandLess, ExpandMore } from '@material-ui/icons'
import Contents from '../../../components/Contents'
import CollectionCategories from './CollectionCategories'
import { Routes as AppRoutes } from '../../../App'
import { Routes as HomeRoutes } from '../../Home'
import { Routes as ManagerRoutes } from '../FactoryManager'
import AccumulatorsCfg, {
  Routes as AccumulatorRoutes
} from './Accumulators/AccumulatorsCfg'
import EventsCfg, { Routes as EventRoutes } from './Events/EventsCfg'
import IncidentsConfig, {
  Routes as IncidentRoutes
} from './Incidents/IncidentsConfig'
import IdentifiersConfig, {
  Routes as IdentifierRoutes
} from './Identifiers/IdentifiersConfig'
import Units from './Units'
import accumulators from '../../../images/accumulatorscfg_card.jpg'
import categories from '../../../images/collection_categories_card.jpg'
import events from '../../../images/eventcfg_card.jpg'
import hero from '../../../images/data_configuration_hero.jpg'
import identifiers from '../../../images/identifiercfg_card.jpg'
import incidents from '../../../images/incidentscfg_card.jpg'
import units from '../../../images/units_card.jpg'
import SideMenuCustomListItem from '../../../components/SideMenuCustomListItem'

export enum Routes {
  CollectionCategories = '/collection-categories',
  Units = '/units'
}

interface SideMenuProps {
  classes: Record<string, string | undefined>
}

export const DataConfigurationSideMenu: FunctionComponent<SideMenuProps> = (
  props: SideMenuProps
) => {
  const { classes } = props
  const configurationMatch = useRouteMatch({
    path: HomeRoutes.FactoryManager + ManagerRoutes.DataConfiguration
  })
  const history = useHistory()
  const intl = useIntl()
  const [configurationOpen, setConfigurationOpen] = useState(false)

  const handleConfigurationClick = () => {
    if (!configurationMatch) {
      setConfigurationOpen(!configurationOpen)
    } else {
      history.push(HomeRoutes.FactoryManager + ManagerRoutes.DataConfiguration)
    }
  }

  useEffect(() => {
    setConfigurationOpen(Boolean(configurationMatch))
  }, [configurationMatch])

  return (
    <>
      <SideMenuCustomListItem button onClick={handleConfigurationClick}>
        <ListItemText
          primary={intl.formatMessage({
            id: 'dataConfiguration.sideMenu.dataConfiguration',
            description: 'Data configuration side menu item',
            defaultMessage: 'Data Configuration'
          })}
        />
        {!configurationMatch && (
          <>{configurationOpen ? <ExpandLess /> : <ExpandMore />}</>
        )}
      </SideMenuCustomListItem>
      <Collapse
        in={configurationOpen}
        timeout="auto"
        unmountOnExit
        className={classes.collapse}
      >
        <Link
          className={classes.link}
          to={
            HomeRoutes.FactoryManager +
            ManagerRoutes.DataConfiguration +
            Routes.CollectionCategories
          }
        >
          <SideMenuCustomListItem button>
            <ListItemText
              primary={intl.formatMessage({
                id: 'dataConfiguration.sideMenu.collectionCategories',
                description:
                  'Data configuration side menu, collection categorties item',
                defaultMessage: 'Collection Categories'
              })}
            />
          </SideMenuCustomListItem>
        </Link>
        <Collapse
          in={configurationOpen}
          timeout="auto"
          unmountOnExit
          className={classes.collapse}
        >
          <Link
            className={classes.link}
            to={
              HomeRoutes.FactoryManager +
              ManagerRoutes.DataConfiguration +
              AccumulatorRoutes.Instances
            }
          >
            <SideMenuCustomListItem button>
              <ListItemText
                primary={intl.formatMessage({
                  id: 'dataConfiguration.sideMenu.accumulators',
                  description:
                    'Data configuration side menu, accumulators item',
                  defaultMessage: 'Accumulators'
                })}
              />
            </SideMenuCustomListItem>
          </Link>
          <Link
            className={classes.link}
            to={
              HomeRoutes.FactoryManager +
              ManagerRoutes.DataConfiguration +
              EventRoutes.Events
            }
          >
            <SideMenuCustomListItem button>
              <ListItemText
                primary={intl.formatMessage({
                  id: 'dataConfiguration.sideMenu.events',
                  description: 'Data configuration side menu, events item',
                  defaultMessage: 'Events'
                })}
              />
            </SideMenuCustomListItem>
          </Link>
          <Link
            className={classes.link}
            to={
              HomeRoutes.FactoryManager +
              ManagerRoutes.DataConfiguration +
              IdentifierRoutes.Instances
            }
          >
            <SideMenuCustomListItem button>
              <ListItemText
                primary={intl.formatMessage({
                  id: 'dataConfiguration.sideMenu.identifiers',
                  description: 'Data configuration side menu, identifiers item',
                  defaultMessage: 'Identifiers'
                })}
              />
            </SideMenuCustomListItem>
          </Link>
          <Link
            className={classes.link}
            to={
              HomeRoutes.FactoryManager +
              ManagerRoutes.DataConfiguration +
              IncidentRoutes.Instances
            }
          >
            <SideMenuCustomListItem button>
              <ListItemText
                primary={intl.formatMessage({
                  id: 'dataConfiguration.sideMenu.incidents',
                  description: 'Data configuration side menu, incidents item',
                  defaultMessage: 'Incidents'
                })}
              />
            </SideMenuCustomListItem>
          </Link>
        </Collapse>
        <Link
          className={classes.link}
          to={
            HomeRoutes.FactoryManager +
            ManagerRoutes.DataConfiguration +
            Routes.Units
          }
        >
          <SideMenuCustomListItem button>
            <ListItemText
              primary={intl.formatMessage({
                id: 'dataConfiguration.sideMenu.units',
                description: 'Data configuration side menu, units item',
                defaultMessage: 'Units'
              })}
            />
          </SideMenuCustomListItem>
        </Link>
      </Collapse>
    </>
  )
}

const DataConfigurationContent: FunctionComponent = () => {
  const intl = useIntl()
  const { path } = useRouteMatch()

  const items = [
    {
      image: categories,
      route: path + Routes.CollectionCategories,
      text: intl.formatMessage({
        id: 'dataConfiguration.collectionCategoriesDescription',
        description:
          'Data configuration page, collection categories description',
        defaultMessage: 'Create and edit data Collection Categories.'
      }),
      title: intl.formatMessage({
        id: 'dataConfiguration.collectionCategories',
        description: 'Data configuration page, collection categories item',
        defaultMessage: 'Collection Categories'
      })
    },
    {
      image: accumulators,
      route: path + AccumulatorRoutes.Instances,
      text: intl.formatMessage({
        id: 'dataConfiguration.accumulatorsDescription',
        description: 'Data configuration page, accumulators description',
        defaultMessage: 'Configure factory node accumulators.'
      }),
      title: intl.formatMessage({
        id: 'dataConfiguration.accumulators',
        description: 'Data configuration page, accumulators item',
        defaultMessage: 'Accumulators'
      })
    },
    {
      image: events,
      route: path + EventRoutes.Events,
      text: intl.formatMessage({
        id: 'dataConfiguration.eventsDescription',
        description: 'Data configuration page, events description',
        defaultMessage: 'Configure factory node events and variables.'
      }),
      title: intl.formatMessage({
        id: 'dataConfiguration.events',
        description: 'Data configuration page, events item',
        defaultMessage: 'Events'
      })
    },
    {
      image: incidents,
      route: path + IncidentRoutes.Instances,
      text: intl.formatMessage({
        id: 'dataConfiguration.incidentsDescription',
        description: 'Data configuration page, incidents description',
        defaultMessage: 'Configure factory node incidents definitions.'
      }),
      title: intl.formatMessage({
        id: 'dataConfiguration.incidents',
        description: 'Data configuration page, incidents item',
        defaultMessage: 'Incidents'
      })
    },
    {
      image: identifiers,
      route: path + IdentifierRoutes.Instances,
      text: intl.formatMessage({
        id: 'dataConfiguration.identifiersDescription',
        description: 'Data configuration page, identifiers description',
        defaultMessage: 'Configure factory node data identifiers.'
      }),
      title: intl.formatMessage({
        id: 'dataConfiguration.identifiers',
        description: 'Data configuration page, identifiers item',
        defaultMessage: 'Identifers'
      })
    },
    {
      image: units,
      route: path + Routes.Units,
      text: intl.formatMessage({
        id: 'dataConfiguration.unitsDescription',
        description: 'Data configuration page, units description',
        defaultMessage: 'Configure global data unit definitions.'
      }),
      title: intl.formatMessage({
        id: 'dataConfiguration.units',
        description: 'Data configuration page, units item',
        defaultMessage: 'Units'
      })
    }
  ]

  return (
    <Contents
      hero={hero}
      items={items}
      title={intl.formatMessage({
        id: 'dataConfiguration.dataConfiguration',
        description: 'Data configuration page title',
        defaultMessage: 'Data Configuration'
      })}
    />
  )
}

const DataConfiguration: FunctionComponent = () => {
  const { path } = useRouteMatch()

  return (
    <Switch>
      <Route
        exact
        path={[
          path + AccumulatorRoutes.Definitions,
          path + AccumulatorRoutes.Instances
        ]}
      >
        <AccumulatorsCfg />
      </Route>
      <Route exact path={path + Routes.CollectionCategories}>
        <CollectionCategories />
      </Route>
      <Route
        exact
        path={[path + EventRoutes.Events, path + EventRoutes.EventVariables]}
      >
        <EventsCfg />
      </Route>
      <Route
        exact
        path={[
          path + IdentifierRoutes.Definitions,
          path + IdentifierRoutes.Instances
        ]}
      >
        <IdentifiersConfig />
      </Route>
      <Route
        exact
        path={[
          path + IncidentRoutes.Definitions,
          path + IncidentRoutes.Instances
        ]}
      >
        <IncidentsConfig />
      </Route>
      <Route exact path={path + Routes.Units}>
        <Units />
      </Route>
      <Route exact path={path}>
        <DataConfigurationContent />
      </Route>
      <Redirect to={AppRoutes.PageNotFound} />
    </Switch>
  )
}

export default DataConfiguration
