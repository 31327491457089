import React, { FunctionComponent, useState } from 'react'
import { useQuery } from 'react-query'
import { useIntl } from 'react-intl'
import { NumericArrayParam, useQueryParam } from 'use-query-params'
import { isNil } from 'lodash'
import { Duration, sub } from 'date-fns'
import prettyMilliseconds from 'pretty-ms'
import { Box, Container, Grid } from '@material-ui/core'
import { useSnackbar } from 'notistack'
import DataGrid from '../../components/DataGrid'
import Hierarchy from '../../components/Hierarchy/Hierarchy'
import PageLoading from '../../components/Loading/PageLoading'
import Timeline from '../../components/Chart/Timeline'
import TimeRange from '../../components/TimeRange'
import Treemap from '../../components/Chart/Treemap'
import usePagination from '../../hooks/usePagination'
import {
  Expression,
  ExpressionOperator,
  IncidentKeys,
  LogicalOperator,
  NodeKeys,
  Order,
  Paths,
  useApi
} from '../../api/RcfactoryApi'

enum ParamKeys {
  NodeId = 'nodeId'
}

const defaultDuration: Duration = { days: 1 }

const Incidents: FunctionComponent = () => {
  const intl = useIntl()
  const api = useApi()
  const pagination = usePagination(100)
  const [endTime, setEndTime] = useState<Date | null>(new Date())
  const [filter, setFilter] = useState<Expression[]>([])
  const [selected, setSelected] = useQueryParam(
    ParamKeys.NodeId,
    NumericArrayParam
  )
  const [startTime, setStartTime] = useState<Date | null>(
    sub(new Date(), defaultDuration)
  )
  const { enqueueSnackbar } = useSnackbar()

  const incidentsQuery = useQuery(
    [
      Paths.Incidents,
      endTime,
      filter,
      selected,
      startTime,
      pagination.page,
      pagination.rowsPerPage
    ],
    () => {
      if (isNil(selected)) {
        throw Error('Should not query incidents if selected is nil!')
      }
      const expressions: Expression[] = [
        {
          Op: ExpressionOperator.Equal,
          Prop: IncidentKeys.NodeId,
          Val: selected[0]
        }
      ]
      if (!isNil(startTime)) {
        expressions.push({
          Op: ExpressionOperator.GreaterThanEqual,
          Prop: IncidentKeys.EndDateTime,
          Val: startTime
        })
      }
      if (!isNil(endTime)) {
        expressions.push({
          Op: ExpressionOperator.LessThanEqual,
          Prop: IncidentKeys.StartDateTime,
          Val: endTime
        })
      }
      return api.getList({
        modelExpressions: {
          Expressions: expressions.concat(filter),
          Operator: LogicalOperator.And
        },
        order1: Order.desc,
        orderBy1: IncidentKeys.StartDateTime,
        path: Paths.Incidents,
        pageNumber: pagination.page,
        pageSize: pagination.rowsPerPage
      })
    },
    {
      enabled: selected?.length === 1,
      onError: () =>
        enqueueSnackbar(
          intl.formatMessage({
            id: 'incidents.failedIncidents',
            description: 'Failed to fetch incidents error notification text',
            defaultMessage: 'Failed to get Incidents!'
          }),
          {
            variant: 'error'
          }
        )
    }
  )

  const incidentsDescQuery = useQuery(
    Paths.Incidents + Paths.UtilsGetDesc,
    () => api.getDesc({ path: Paths.Incidents }),
    {
      onError: () =>
        enqueueSnackbar(
          intl.formatMessage({
            id: 'incidents.failedIncidentSchema',
            description:
              'Failed to fetch incident schema error notification text',
            defaultMessage: 'Failed to get Incident Schema!'
          }),
          {
            variant: 'error'
          }
        )
    }
  )

  const nodesQuery = useQuery(
    Paths.Nodes,
    () =>
      api.getList({
        modelExpressions: {
          Expressions: [
            {
              Prop: NodeKeys.Active,
              Op: ExpressionOperator.Equal,
              Val: true
            }
          ]
        },
        order1: Order.asc,
        orderBy1: NodeKeys.OrdinalPosition,
        path: Paths.Nodes
      }),
    {
      onError: () =>
        enqueueSnackbar(
          intl.formatMessage({
            id: 'incidents.failedNodes',
            description: 'Failed to fetch nodes error notification text',
            defaultMessage: 'Failed to get Nodes!'
          }),
          {
            variant: 'error'
          }
        )
    }
  )

  const handleFilterChange = (expressions: Expression[]) => {
    setFilter(expressions)
  }

  const handleSelectNodes = (ids: number[]) => {
    setSelected(ids.length > 0 ? ids : undefined)
    setFilter([])
  }

  const pageReady = incidentsDescQuery.isSuccess && nodesQuery.isSuccess
  const pageLoading = incidentsDescQuery.isLoading || nodesQuery.isLoading

  return (
    <>
      {pageReady && (
        <Box paddingTop={3} paddingBottom={3}>
          <Container maxWidth={false}>
            <Grid container spacing={2}>
              <TimeRange
                endTime={endTime}
                onEndTimeChange={setEndTime}
                onStartTimeChange={setStartTime}
                startTime={startTime}
              />
              <Grid container item spacing={2} alignItems="stretch">
                {nodesQuery.data?.Items && (
                  <Grid item xs={12} md={5} lg={4} xl={3}>
                    <Hierarchy
                      data={nodesQuery.data.Items}
                      idProperty={NodeKeys.Id}
                      nameProperty={NodeKeys.Name}
                      onSelect={handleSelectNodes}
                      ordinalProperty={NodeKeys.OrdinalPosition}
                      parentIdProperty={NodeKeys.ParentId}
                      selected={selected}
                      title={intl.formatMessage({
                        id: 'incidents.nodes',
                        description:
                          'Incidents viewer page, node hierarchy title',
                        defaultMessage: 'Nodes'
                      })}
                    />
                  </Grid>
                )}
                <Grid item xs={12} md={7} lg={8} xl={9}>
                  <Box display="flex" height="100%" minHeight={750}>
                    <Box flexGrow={1}>
                      {incidentsDescQuery.data?.ViewDescription && (
                        <DataGrid
                          customColumns={[
                            {
                              property: IncidentKeys.Duration,
                              gridColTypeDef: {
                                type: 'number',
                                width: 160,
                                valueFormatter: ({ value }) =>
                                  prettyMilliseconds(Number(value))
                              }
                            }
                          ]}
                          data={incidentsQuery.data}
                          filterMode="server"
                          ignoredProperties={[IncidentKeys.NodeId]}
                          loading={incidentsQuery.isLoading}
                          onFilterChange={handleFilterChange}
                          onPageChange={pagination.setPage}
                          onRowsPerPageChange={pagination.setRowsPerPage}
                          page={pagination.page}
                          pagination
                          paginationMode="server"
                          rowsPerPage={pagination.rowsPerPage}
                          rowsPerPageOptions={pagination.rowsPerPageOptions}
                          schema={incidentsDescQuery.data?.ViewDescription}
                          widths={{
                            [IncidentKeys.Category]: 200,
                            [IncidentKeys.Description]: 280,
                            [IncidentKeys.GlobalIncidentName]: 280,
                            [IncidentKeys.Name]: 280
                          }}
                          totalRows={incidentsQuery.data?.Pagination.TotalCount}
                        />
                      )}
                    </Box>
                  </Box>
                </Grid>
              </Grid>
              {incidentsQuery.data?.Items &&
                incidentsQuery.data.Items.length > 0 && (
                  <>
                    <Grid item xs={12}>
                      <Timeline
                        bandwidth={27}
                        categoryProperty={IncidentKeys.Category}
                        data={incidentsQuery.data.Items}
                        durationProperty={IncidentKeys.Duration}
                        endProperty={IncidentKeys.EndDateTime}
                        nameProperty={IncidentKeys.Name}
                        startProperty={IncidentKeys.StartDateTime}
                        title={intl.formatMessage({
                          id: 'incidents.incidentTimeline',
                          description: 'Incident timeline chart title',
                          defaultMessage: 'Incident Timeline'
                        })}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Treemap
                        data={incidentsQuery.data.Items}
                        height={750}
                        nameProperty={IncidentKeys.Name}
                        renderValue={prettyMilliseconds}
                        valueProperty={IncidentKeys.Duration}
                        title={intl.formatMessage({
                          id: 'incidents.incidentTreemap',
                          description: 'Incident treemap chart title',
                          defaultMessage: 'Incident Treemap'
                        })}
                      />
                    </Grid>
                  </>
                )}
            </Grid>
          </Container>
        </Box>
      )}
      {pageLoading && <PageLoading />}
    </>
  )
}

export default Incidents
