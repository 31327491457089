import { red } from '@material-ui/core/colors'
import { createMuiTheme } from '@material-ui/core/styles'
import { enUS } from '@material-ui/core/locale'
import '@fontsource/lato'

// allow use of custom variables in createMuiTheme obj`
declare module '@material-ui/core/styles' {
  interface Theme {
    custom: {
      menuBackground: string
      menuBtnHover: string
      menuTextColor: string
      borderHoverValue: string
      menuFontSize: string
    }
  }
  // allow configuration using `createTheme`
  interface ThemeOptions {
    custom?: {
      menuBackground?: string
      menuBtnHover?: string
      menuTextColor?: string
      borderHoverValue?: string
      menuFontSize?: string
    }
  }
}

let locale
switch (navigator.language) {
  default:
    locale = enUS
}

// A custom theme for this app, this is the dark theme
const theme = createMuiTheme(
  {
    palette: {
      type: 'dark',
      primary: {
        main: '#ab003c'
      },
      secondary: {
        main: '#1b1d2c'
      },
      error: {
        main: red.A400
      },
      background: {
        default: '#424242',
        paper: '#121212'
      },
      warning: { main: red[500] },
      text: {
        primary: '#fff',
        secondary: '#e6e6e6',
        disabled: '#7e7e7e'
      }
    },
    overrides: {
      MuiCheckbox: {
        colorSecondary: {
          '&$checked': {
            color: 'pink'
          }
        }
      },
      MuiRadio: {
        colorSecondary: {
          '&$checked': {
            color: 'pink'
          }
        }
      }
    },
    typography: {
      fontFamily: [
        'Lato',
        'Roboto',
        '-apple-system',
        'BlinkMacSystemFont',
        '"Segoe UI"'
      ].join(',')
    },
    custom: {
      menuBackground: '#121212',
      menuBtnHover: '#353535',
      menuTextColor: '#e6e6e6',
      borderHoverValue: '4px',
      menuFontSize: '0.969rem'
    }
  },
  locale
)

// A custom theme for this app, this is the light theme
const themeCustomLight = createMuiTheme(
  {
    palette: {
      primary: {
        main: '#ab003c'
      },
      secondary: {
        main: '#1b1d2c'
      },
      error: {
        main: red.A400
      },
      background: {
        default: '#fff'
      },
      warning: { main: red[500] }
    },
    typography: {
      fontFamily: [
        'Lato',
        'Roboto',
        '-apple-system',
        'BlinkMacSystemFont',
        '"Segoe UI"'
      ].join(',')
    },
    custom: {
      menuBackground: '#f7f1f3',
      menuBtnHover: '#eadada',
      menuTextColor: '#000',
      borderHoverValue: '4px',
      menuFontSize: '0.969rem'
    }
  },
  locale
)

export { theme, themeCustomLight }
