import { withStyles, createStyles, MenuItem } from '@material-ui/core'

const NavbarCustomMenuItem = withStyles((theme) =>
  createStyles({
    root: {
      borderRight: `${theme.custom.borderHoverValue} solid transparent`,
      borderRadius: '18px 0px 0 0',
      marginBottom: '3px',
      '&$selected': {
        backgroundColor: 'transparent',
        borderRight: `${theme.custom.borderHoverValue} solid ${theme.palette.primary.main}`,
        '&:hover': {
          backgroundColor: theme.custom.menuBtnHover
        }
      },
      '&:hover': {
        background: theme.custom.menuBtnHover,
        borderRight: `${theme.custom.borderHoverValue} solid ${theme.palette.primary.main}`
      },
      '& span': {
        fontSize: theme.custom.menuFontSize
      }
    },
    selected: {}
  })
)(MenuItem)

export default NavbarCustomMenuItem
