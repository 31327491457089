import {
  BrowserAuthOptions,
  Configuration,
  InteractionRequiredAuthError
} from '@azure/msal-browser'
import { isNil } from 'lodash'
import { msalInstance } from '..'

if (!process.env.REACT_APP_APP_ID) {
  throw Error('REACT_APP_APP_ID environment variable is not defined!')
}
const authority = process.env.REACT_APP_AUTHORITY
const clientId: string = process.env.REACT_APP_APP_ID
const knownAuthorities: string[] | undefined =
  process.env.REACT_APP_KNOWN_AUTHORITIES?.split(' ')
const development: boolean = process.env.NODE_ENV === 'development'
let redirectUri: string
if (development) {
  if (!process.env.REACT_APP_DEVELOPMENT_REDIRECT_URI) {
    throw Error('REACT_APP_DEVELOPMENT_REDIRECT_URI environment variable is not defined!')
  }
  redirectUri = process.env.REACT_APP_DEVELOPMENT_REDIRECT_URI
} else {
  if (!process.env.REACT_APP_PRODUCTION_REDIRECT_URI) {
    throw Error('REACT_APP_PRODUCTION_REDIRECT_URI environment variable is not defined!')
  }
  redirectUri = process.env.REACT_APP_PRODUCTION_REDIRECT_URI
}

const authConfiguration: BrowserAuthOptions = {
  authority: authority,
  clientId: clientId,
  knownAuthorities: knownAuthorities,
  postLogoutRedirectUri: redirectUri,
  redirectUri: redirectUri
}

export const msalConfiguration: Configuration = {
  auth: authConfiguration
}

export const homeAccountIdStorageKey = 'homeAccountId'

export const getHomeAccountId = (): string | null => {
  const homeAccountId = sessionStorage.getItem(homeAccountIdStorageKey)
  return homeAccountId
}

export const setHomeAccountId = (id: string | null): void => {
  if (isNil(id)) {
    sessionStorage.removeItem(homeAccountIdStorageKey)
  } else {
    sessionStorage.setItem(homeAccountIdStorageKey, id)
  }
}

export const getAccessToken = async (scopes: string[]): Promise<string | undefined> => {
  const homeAccountId = getHomeAccountId()
  if (!homeAccountId) {
    msalInstance.acquireTokenRedirect({ scopes: scopes })
    return
  }
  const account = msalInstance.getAccountByHomeId(homeAccountId)
  if (!account) {
    msalInstance.acquireTokenRedirect({ scopes: scopes })
    return
  }
  const request = { account: account, scopes: scopes }
  try {
    const response = await msalInstance.acquireTokenSilent(request)
    return response.accessToken
  } catch (error) {
    if (error instanceof InteractionRequiredAuthError) {
      msalInstance.acquireTokenRedirect(request)
    }
  }
}
