import React, { FunctionComponent, useEffect, useState } from 'react'
import {
  Link,
  Redirect,
  Route,
  Switch,
  useHistory,
  useRouteMatch
} from 'react-router-dom'
import { useIntl } from 'react-intl'
import { Collapse, ListItemText } from '@material-ui/core'
import { ExpandLess, ExpandMore } from '@material-ui/icons'
import { Routes as AppRoutes } from '../../../App'
import { Routes as HomeRoutes } from '../../Home'
import { Routes as ManagerRoutes } from '../FactoryManager'
import IotConnections from './IotConnections'
import Contents, { ItemProps } from '../../../components/Contents'
import connections from '../../../images/iot_connections_card.jpg'
import hero from '../../../images/iot_configuration_hero.jpg'
import poisonMessages from '../../../images/poison_messages_card.jpg'
import PoisonMessages from './PoisonMessages'
import SideMenuCustomListItem from '../../../components/SideMenuCustomListItem'

enum Routes {
  Connections = '/connections',
  FailedMessages = '/failed-messages'
}

interface SideMenuProps {
  classes: Record<string, string | undefined>
}

export const IotConfigurationSideMenu: FunctionComponent<SideMenuProps> = (
  props: SideMenuProps
) => {
  const { classes } = props
  const configurationMatch = useRouteMatch({
    path: HomeRoutes.FactoryManager + ManagerRoutes.IotConfiguration
  })
  const history = useHistory()
  const intl = useIntl()
  const [configurationOpen, setConfigurationOpen] = useState(false)

  const handleConfigurationClick = () => {
    if (!configurationMatch) {
      setConfigurationOpen(!configurationOpen)
    } else {
      history.push(HomeRoutes.FactoryManager + ManagerRoutes.IotConfiguration)
    }
  }

  useEffect(() => {
    setConfigurationOpen(Boolean(configurationMatch))
  }, [configurationMatch])

  return (
    <>
      <SideMenuCustomListItem button onClick={handleConfigurationClick}>
        <ListItemText
          primary={intl.formatMessage({
            id: 'iotConfiguration.sideMenu.iotConfiguration',
            description: 'IoT configuration side menu item',
            defaultMessage: 'IoT Configuration'
          })}
        />
        {!configurationMatch && (
          <>{configurationOpen ? <ExpandLess /> : <ExpandMore />}</>
        )}
      </SideMenuCustomListItem>
      <Collapse
        in={configurationOpen}
        timeout="auto"
        unmountOnExit
        className={classes.collapse}
      >
        <Link
          className={classes.link}
          to={
            HomeRoutes.FactoryManager +
            ManagerRoutes.IotConfiguration +
            Routes.Connections
          }
        >
          <SideMenuCustomListItem button>
            <ListItemText
              primary={intl.formatMessage({
                id: 'iotConfiguration.sideMenu.connections',
                description: 'IoT configuration side menu, connections item',
                defaultMessage: 'Connections'
              })}
            />
          </SideMenuCustomListItem>
        </Link>
        <Link
          className={classes.link}
          to={
            HomeRoutes.FactoryManager +
            ManagerRoutes.IotConfiguration +
            Routes.FailedMessages
          }
        >
          <SideMenuCustomListItem button>
            <ListItemText
              primary={intl.formatMessage({
                id: 'iotConfiguration.sideMenu.failedMessages',
                description:
                  'IoT configuration side menu, failed messages item',
                defaultMessage: 'Failed Messages'
              })}
            />
          </SideMenuCustomListItem>
        </Link>
      </Collapse>
    </>
  )
}

const IotConfigurationContent: FunctionComponent = () => {
  const intl = useIntl()
  const { path } = useRouteMatch()

  const items: ItemProps[] = [
    {
      image: connections,
      route: path + Routes.Connections,
      text: intl.formatMessage({
        id: 'iotConfiguration.iotConnectionsDescription',
        description: 'IoT configuration page, connections description',
        defaultMessage:
          'Manage and monitor IoT hub connections and access keys.'
      }),
      title: intl.formatMessage({
        id: 'iotConfiguration.iotConnections',
        description: 'IoT configuration page, IoT connections item',
        defaultMessage: 'IoT Connections'
      })
    },
    {
      image: poisonMessages,
      route: path + Routes.FailedMessages,
      text: intl.formatMessage({
        id: 'iotConfiguration.failedMessagesDescription',
        description: 'IoT configuration page, failed messages description',
        defaultMessage:
          'Observe a list of blocked, erroneous incoming IoT messages.'
      }),
      title: intl.formatMessage({
        id: 'iotConfiguration.failedMessages',
        description: 'IoT configuration page, failed messages item',
        defaultMessage: 'Failed Messages'
      })
    }
  ]

  return (
    <Contents
      hero={hero}
      items={items}
      title={intl.formatMessage({
        id: 'iotConfiguration.iotConfiguration',
        description: 'IoT Configuration page title',
        defaultMessage: 'IoT Configuration'
      })}
    />
  )
}

const IotConfiguration: FunctionComponent = () => {
  const { path } = useRouteMatch()

  return (
    <Switch>
      <Route exact path={path + Routes.Connections}>
        <IotConnections />
      </Route>
      <Route exact path={path + Routes.FailedMessages}>
        <PoisonMessages />
      </Route>
      <Route exact path={path}>
        <IotConfigurationContent />
      </Route>
      <Redirect to={AppRoutes.PageNotFound} />
    </Switch>
  )
}

export default IotConfiguration
