import React, { FunctionComponent, useEffect, useMemo, useState } from 'react'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { defineMessages, useIntl } from 'react-intl'
import { NumericArrayParam, useQueryParam } from 'use-query-params'
import { includes, isNil } from 'lodash'
import { Box, Container, Grid } from '@material-ui/core'
import { useSnackbar } from 'notistack'
import AlertDialogue from '../../../../components/AlertDialogue'
import ContentLoading from '../../../../components/Loading/ContentLoading'
import DataForm from '../../../../components/DataForm'
import Table from '../../../../components/Table/Table'
import FilterDialogue from '../../../../components/FilterDialogue'
import Hierarchy from '../../../../components/Hierarchy/Hierarchy'
import PageLoading from '../../../../components/Loading/PageLoading'
import Placeholder from '../../../../components/Placeholder'
import useConfigure, { ConfigurationMode } from '../../../../hooks/useConfigure'
import useFilter from '../../../../hooks/useFilter'
import useIotMapping from '../../../../hooks/useIotMapping'
import usePagination from '../../../../hooks/usePagination'
import useSort from '../../../../hooks/useSort'
import {
  DataCollectionCategoryKeys,
  IdentifiersConfigKeys,
  IdentifiersDefCfgKeys,
  NodeKeys,
  ExpressionOperator,
  Order,
  Paths,
  useApi,
  LogicalOperator
} from '../../../../api/RcfactoryApi'

enum ParamKeys {
  NodeId = 'nodeId',
  IdentifierId = 'identifierId'
}

enum ConfigurationKeys {
  Identifier = 'identifier'
}

const messages = defineMessages({
  alertMessage: {
    id: 'identifierInstances.alertMessage',
    description: 'Delete alert dialogue message content',
    defaultMessage:
      'Are you sure you want to delete the selected ' +
      '{count, plural, one{Identifier} other{Identifiers}}?'
  },
  alertTitle: {
    id: 'identifierInstances.alertTitle',
    description: 'Delete alert dialogue title',
    defaultMessage: 'Delete {count, plural, one{Identifier} other{Identifiers}}'
  }
})

const omitFilterProperties = [
  IdentifiersConfigKeys.CollectionCategoryName,
  IdentifiersConfigKeys.GlobalIdentifierName,
  IdentifiersConfigKeys.Id,
  IdentifiersConfigKeys.NodeId,
  IdentifiersConfigKeys.RcfId
]

const IdentifierInstances: FunctionComponent = () => {
  const intl = useIntl()
  const api = useApi()
  const filterer = useFilter()
  const configurator = useConfigure(ConfigurationKeys.Identifier)
  const pagination = usePagination()
  const sort = useSort(IdentifiersConfigKeys.Id)
  const [activeIdentifier, setActiveIdentifier] =
    useState<Record<string, unknown>>()
  const iotMapping = useIotMapping(
    ConfigurationKeys.Identifier,
    !isNil(activeIdentifier)
      ? String(activeIdentifier[IdentifiersConfigKeys.RcfId])
      : undefined
  )
  const [alertOpen, setAlertOpen] = useState<boolean>(false)
  const [selectedIdentifiers, setSelectedIdentifiers] = useQueryParam(
    ParamKeys.IdentifierId,
    NumericArrayParam
  )
  const [selectedNodes, setSelectedNodes] = useQueryParam(
    ParamKeys.NodeId,
    NumericArrayParam
  )
  const { enqueueSnackbar } = useSnackbar()
  const queryClient = useQueryClient()

  const categoriesQuery = useQuery(
    Paths.DataCollectionCategories,
    () =>
      api.getList({
        modelExpressions: {
          Expressions: [
            {
              Prop: DataCollectionCategoryKeys.DataCategoryName,
              Op: ExpressionOperator.Equal,
              Val: 'Identifiers'
            }
          ]
        },
        path: Paths.DataCollectionCategories
      }),
    {
      onError: () =>
        enqueueSnackbar(
          intl.formatMessage({
            id: 'identifierInstances.failedCategories',
            description:
              'Fetch data collection categories error notification text',
            defaultMessage: 'Failed to get Data Collection Categories!'
          }),
          {
            variant: 'error'
          }
        )
    }
  )

  const identifiersDefCfgQuery = useQuery(
    Paths.IdentifiersDefCfg,
    () =>
      api.getList({
        path: Paths.IdentifiersDefCfg
      }),
    {
      onError: () =>
        enqueueSnackbar(
          intl.formatMessage({
            id: 'identifierInstances.failedIdentifierDefinitions',
            description: 'Fetch identifier definitions error notification text',
            defaultMessage: 'Failed to get Identifier Definitions!'
          }),
          {
            variant: 'error'
          }
        )
    }
  )

  const identifiersDescQuery = useQuery(
    Paths.IdentifiersConfig + Paths.UtilsGetDesc,
    () =>
      api.getDesc({
        path: Paths.IdentifiersConfig
      }),
    {
      onError: () =>
        enqueueSnackbar(
          intl.formatMessage({
            id: 'identifierInstances.failedIdentifierSchema',
            description: 'Fetch identifier schema error notification text',
            defaultMessage: 'Failed to get Identifier Schema!'
          }),
          {
            variant: 'error'
          }
        )
    }
  )

  const identifiersQuery = useQuery(
    [
      Paths.IdentifiersConfig,
      filterer.active,
      sort.orderBy,
      sort.order,
      pagination.page,
      pagination.rowsPerPage,
      selectedNodes
    ],
    () => {
      if (isNil(selectedNodes)) {
        throw Error('Should not query identifiers if selectedNodes is nil!')
      }
      return api.getList({
        modelExpressions: {
          Expressions: [
            ...filterer.active,
            {
              Prop: IdentifiersConfigKeys.NodeId,
              Op: ExpressionOperator.Equal,
              Val: selectedNodes[0]
            }
          ],
          Operator: LogicalOperator.And
        },
        orderBy1: sort.orderBy,
        order1: sort.order,
        pageNumber: pagination.page,
        pageSize: pagination.rowsPerPage,
        path: Paths.IdentifiersConfig
      })
    },
    {
      enabled: selectedNodes?.length === 1,
      onError: () =>
        enqueueSnackbar(
          intl.formatMessage({
            id: 'identifierInstances.failedIdentifiers',
            description: 'Fetch identifiers error notification text',
            defaultMessage: 'Failed to get Identifiers!'
          }),
          {
            variant: 'error'
          }
        )
    }
  )

  const identifierCreateMutation = useMutation(
    (items: Record<string, unknown>[]) =>
      api.create({
        items: items,
        path: Paths.IdentifiersConfig
      }),
    {
      onError: () => {
        enqueueSnackbar(
          intl.formatMessage({
            id: 'identifierInstances.failedCreateIdentifier',
            description: 'Create identifier error notification text',
            defaultMessage: 'Failed to create Identifier!'
          }),
          {
            variant: 'error'
          }
        )
      },
      onSuccess: () => {
        enqueueSnackbar(
          intl.formatMessage({
            id: 'identifierInstances.successfulCreateIdentifier',
            description: 'Create identifier success notification text',
            defaultMessage: 'Successfully created Identifier!'
          }),
          {
            variant: 'success'
          }
        )
        configurator.clear()
        queryClient.invalidateQueries(Paths.IdentifiersConfig)
      }
    }
  )

  const identifierDeleteMutation = useMutation(
    (ids: number[]) =>
      api.delete({
        ids: ids,
        path: Paths.IdentifiersConfig
      }),
    {
      onError: () => {
        enqueueSnackbar(
          intl.formatMessage({
            id: 'identifierInstances.failedDeleteIdentifier',
            description: 'Delete identifier error notification text',
            defaultMessage: 'Failed to delete Identifiers!'
          }),
          {
            variant: 'error'
          }
        )
      },
      onSuccess: () => {
        enqueueSnackbar(
          intl.formatMessage({
            id: 'identifierInstances.successfulDeleteIdentifier',
            description: 'Delete identifier success notification text',
            defaultMessage: 'Successfully deleted Identifiers!'
          }),
          {
            variant: 'success'
          }
        )
        setSelectedIdentifiers(undefined)
        queryClient.invalidateQueries(Paths.IdentifiersConfig)
      }
    }
  )

  const identifierUpdateMutation = useMutation(
    (items: Record<string, unknown>[]) =>
      api.update({
        items: items,
        path: Paths.IdentifiersConfig
      }),
    {
      onError: () => {
        enqueueSnackbar(
          intl.formatMessage({
            id: 'identifierInstances.failedUpdateIdentifier',
            description: 'Update identifier error notification text',
            defaultMessage: 'Failed to update Identifier!'
          }),
          {
            variant: 'error'
          }
        )
      },
      onSuccess: () => {
        enqueueSnackbar(
          intl.formatMessage({
            id: 'identifierInstances.successfulUpdateIdentifier',
            description: 'Update identifier success notification text',
            defaultMessage: 'Successfully updated Identifier!'
          }),
          {
            variant: 'success'
          }
        )
        configurator.clear()
        queryClient.invalidateQueries(Paths.IdentifiersConfig)
      }
    }
  )

  const nodesQuery = useQuery(
    Paths.Nodes,
    () =>
      api.getList({
        order1: Order.asc,
        orderBy1: NodeKeys.OrdinalPosition,
        path: Paths.Nodes
      }),
    {
      onError: () =>
        enqueueSnackbar(
          intl.formatMessage({
            id: 'identifierInstances.failedNodes',
            description: 'Fetch nodes error notification text',
            defaultMessage: 'Failed to get Nodes!'
          }),
          {
            variant: 'error'
          }
        )
    }
  )

  useEffect(() => {
    if (!isNil(configurator.data) && isNil(iotMapping.formSection?.data)) {
      switch (configurator.mode) {
        case ConfigurationMode.Create:
          iotMapping.add()
          break
        case ConfigurationMode.Edit:
          iotMapping.edit()
      }
    } else if (
      isNil(configurator.data) &&
      !isNil(iotMapping.formSection?.data)
    ) {
      iotMapping.clear()
    }
  }, [configurator, iotMapping])

  useEffect(() => {
    if (!identifiersQuery.data?.Items) {
      return
    }
    if (selectedIdentifiers?.length !== 1) {
      return
    }
    const newActiveIdentifier = identifiersQuery.data.Items.find(
      (d: Record<string, unknown>) =>
        d[IdentifiersConfigKeys.Id] === selectedIdentifiers[0]
    )
    setActiveIdentifier(newActiveIdentifier)
  }, [identifiersQuery.data?.Items, selectedIdentifiers])

  const identifierLookupProperties = useMemo(() => {
    if (!categoriesQuery.data?.Items || !identifiersDefCfgQuery.data?.Items) {
      return
    }
    return [
      {
        data: categoriesQuery.data.Items,
        localProperty: IdentifiersConfigKeys.DataCollectionCategoryId,
        nameProperty: DataCollectionCategoryKeys.Name,
        remoteProperty: DataCollectionCategoryKeys.Id
      },
      {
        data: identifiersDefCfgQuery.data.Items,
        label: 'Global Identifier Name',
        localProperty: IdentifiersConfigKeys.IdentifierDefId,
        nameProperty: IdentifiersDefCfgKeys.Name,
        remoteProperty: IdentifiersDefCfgKeys.Id
      }
    ]
  }, [categoriesQuery.data?.Items, identifiersDefCfgQuery.data?.Items])

  const handleAdd = () => {
    if (isNil(identifiersDescQuery.data)) {
      throw Error(
        'Cannot handleAdd if identifiers identifier description is nil!'
      )
    }
    if (isNil(selectedNodes)) {
      throw Error('Cannot handleAdd if selectedNodes is nil!')
    }
    configurator.create(identifiersDescQuery.data.CrudDescription.Properties, {
      [IdentifiersConfigKeys.NodeId]: selectedNodes[0]
    })
  }

  const handleAddSubmit = async () => {
    if (isNil(configurator.data)) {
      throw Error(
        'Cannot handleAddSubmit if identifierConfigurator data is nil!'
      )
    }
    const createResponse = await identifierCreateMutation.mutateAsync([
      configurator.data
    ])
    const getListResponse = await api.getList({
      modelExpressions: {
        Expressions: [
          {
            Op: ExpressionOperator.Equal,
            Prop: IdentifiersConfigKeys.Id,
            Val: createResponse.data[0]
          }
        ]
      },
      path: Paths.IdentifiersConfig
    })
    iotMapping.addSubmit(
      String(getListResponse.Items[0][IdentifiersConfigKeys.RcfId])
    )
  }

  const handleAlertCancel = () => {
    setAlertOpen(false)
  }

  const handleAlertDelete = () => {
    if (isNil(selectedIdentifiers)) {
      throw Error('Cannot handleAlertDelete if selectedIdentifiers is nil!')
    }
    identifierDeleteMutation.mutate(selectedIdentifiers.map(Number))
    setAlertOpen(false)
  }

  const handleDelete = () => {
    setAlertOpen(true)
  }

  const handleEdit = () => {
    if (isNil(activeIdentifier)) {
      throw Error('Cannot handleEdit if activeIdentifier is nil!')
    }
    configurator.edit(activeIdentifier)
  }

  const handleEditSubmit = () => {
    if (isNil(configurator.data)) {
      throw Error(
        'Cannot handleEditSubmit if identifierConfigurator data is nil!'
      )
    }
    identifierUpdateMutation.mutate([configurator.data])
    iotMapping.editSubmit()
  }

  const handleFilterSubmit = () => {
    filterer.submit()
    pagination.setPage(0)
    filterer.clearData()
  }

  const handleFormCancel = () => {
    configurator.clear()
    filterer.clearData()
  }

  const handlePageChange = (newPage: number) => {
    pagination.setPage(newPage)
    setSelectedIdentifiers(undefined)
  }

  const handleRequestSort = (property: string) => {
    sort.requestSort(property)
    setSelectedIdentifiers(undefined)
  }

  const handleRowsPerPageChange = (rows: number) => {
    pagination.setRowsPerPage(rows)
    setSelectedIdentifiers(undefined)
  }

  const handleSelectIdentifiers = (ids: (number | string | null)[]) => {
    setSelectedIdentifiers(ids.length > 0 ? ids.map(Number) : undefined)
  }

  const handleSelectNodes = (ids: (number | string)[]) => {
    setSelectedNodes(ids.length > 0 ? ids.map(Number) : undefined)
    setSelectedIdentifiers(undefined)
  }

  const handleSubmit = () => {
    switch (configurator.mode) {
      case ConfigurationMode.Create:
        handleAddSubmit()
        break

      case ConfigurationMode.Edit:
        handleEditSubmit()
        break

      default:
        throw Error(
          'Cannot handleSubmit if both identificerConfigurator mode is undefined!'
        )
    }
  }

  const pageReady =
    categoriesQuery.isSuccess &&
    identifiersDefCfgQuery.isSuccess &&
    identifiersDescQuery.isSuccess &&
    nodesQuery.isSuccess &&
    iotMapping.isReady &&
    !identifierCreateMutation.isLoading &&
    !identifierDeleteMutation.isLoading &&
    !identifierUpdateMutation.isLoading &&
    !(isNil(configurator.mode) && iotMapping.mode === ConfigurationMode.Create)

  const pageLoading =
    categoriesQuery.isLoading ||
    identifiersDefCfgQuery.isLoading ||
    identifiersDescQuery.isLoading ||
    nodesQuery.isLoading ||
    iotMapping.isLoading ||
    identifierCreateMutation.isLoading ||
    identifierDeleteMutation.isLoading ||
    identifierUpdateMutation.isLoading ||
    (isNil(configurator.mode) && iotMapping.mode === ConfigurationMode.Create)

  return (
    <>
      {configurator.data &&
        identifiersDescQuery.data?.CrudDescription &&
        identifierLookupProperties &&
        iotMapping.formSection && (
          <DataForm
            formSections={[
              {
                data: configurator.data,
                ignoredProperties: [
                  IdentifiersConfigKeys.Id,
                  IdentifiersConfigKeys.NodeId
                ],
                lookupProperties: identifierLookupProperties,
                onPropertyChange: (property, value) =>
                  configurator.update({
                    [property]: value
                  }),
                schema: identifiersDescQuery.data.CrudDescription
              },
              iotMapping.formSection
            ]}
            onCancel={handleFormCancel}
            onSubmit={handleSubmit}
            title={
              configurator.mode === ConfigurationMode.Create
                ? intl.formatMessage({
                    id: 'identifierInstances.create',
                    description: 'Create identifier dialogue title',
                    defaultMessage: 'Create Identifier'
                  })
                : intl.formatMessage({
                    id: 'identifierInstances.edit',
                    description: 'Edit identifier dialogue title',
                    defaultMessage: 'Edit Identifier'
                  })
            }
            customHelpers={iotMapping.customHelpers}
          />
        )}
      {filterer.data && identifiersDescQuery.data?.ViewDescription && (
        <FilterDialogue
          filter={filterer.data}
          lookupProperties={identifierLookupProperties}
          onCancel={handleFormCancel}
          onExpressionChange={filterer.update}
          onReset={filterer.reset}
          onSubmit={handleFilterSubmit}
          schema={identifiersDescQuery.data.ViewDescription}
          title={intl.formatMessage({
            id: 'identifierInstances.title',
            description: 'Filter identifiers dialogue title',
            defaultMessage: 'Filter Identifiers'
          })}
        />
      )}
      {pageReady && (
        <Box paddingTop={3} paddingBottom={3}>
          <Container maxWidth={false}>
            <>
              {nodesQuery.data?.Items && (
                <Grid container spacing={2} alignItems="stretch">
                  <Grid item xs={12} md={5} lg={4} xl={3}>
                    <Box alignItems="flex-start">
                      <Hierarchy
                        activeProperty={NodeKeys.Active}
                        data={nodesQuery.data.Items}
                        idProperty={NodeKeys.Id}
                        nameProperty={NodeKeys.Name}
                        onSelect={handleSelectNodes}
                        ordinalProperty={NodeKeys.OrdinalPosition}
                        parentIdProperty={NodeKeys.ParentId}
                        selected={selectedNodes}
                        title={intl.formatMessage({
                          id: 'identifierInstances.nodes',
                          description:
                            'Identifier instances page, node hierarchy title',
                          defaultMessage: 'Nodes'
                        })}
                      />
                    </Box>
                  </Grid>
                  <Grid item xs={12} md={7} lg={8} xl={9}>
                    <Box height="100%" position="sticky" top={3}>
                      {identifiersQuery.isSuccess &&
                        identifiersQuery.data?.Items &&
                        identifiersQuery.data?.Pagination &&
                        identifiersDescQuery.data?.ViewDescription && (
                          <Table
                            data={identifiersQuery.data.Items}
                            idProperty={IdentifiersConfigKeys.Id}
                            ignoredProperties={[
                              IdentifiersConfigKeys.NodeId,
                              IdentifiersConfigKeys.IdentifierDefId,
                              IdentifiersConfigKeys.DataCollectionCategoryId,
                              IdentifiersConfigKeys.RcfId
                            ]}
                            isFiltered={filterer.isActive}
                            multiSelect
                            onAdd={handleAdd}
                            onDelete={handleDelete}
                            onEdit={iotMapping.canEdit ? handleEdit : undefined}
                            onFilter={() =>
                              filterer.initialise(
                                identifiersDescQuery.data.ViewDescription.Properties.filter(
                                  (p) =>
                                    !includes(
                                      omitFilterProperties,
                                      p.PropertyName
                                    )
                                )
                              )
                            }
                            onPageChange={handlePageChange}
                            onRequestSort={handleRequestSort}
                            onRowsPerPageChange={handleRowsPerPageChange}
                            onSelect={handleSelectIdentifiers}
                            order={sort.order}
                            orderBy={sort.orderBy}
                            page={pagination.page}
                            rowsPerPage={pagination.rowsPerPage}
                            schema={identifiersDescQuery.data.ViewDescription}
                            selected={selectedIdentifiers}
                            title={intl.formatMessage({
                              id: 'identifierInstances.identifiers',
                              description: 'Identifiers table title',
                              defaultMessage: 'Identifiers'
                            })}
                            totalRows={
                              identifiersQuery.data.Pagination.TotalCount
                            }
                          />
                        )}
                      {identifiersQuery.isLoading && <ContentLoading />}
                      {selectedNodes?.length !== 1 && (
                        <Placeholder
                          message={intl.formatMessage({
                            id: 'identifierInstances.placeholderMessage',
                            description: 'Node selection placeholder message',
                            defaultMessage:
                              'Select a Node to View and Edit its Identifiers'
                          })}
                        />
                      )}
                    </Box>
                  </Grid>
                </Grid>
              )}
            </>
          </Container>
        </Box>
      )}
      {pageLoading && <PageLoading />}
      <AlertDialogue
        actions={[
          {
            handler: handleAlertDelete,
            text: intl.formatMessage({
              id: 'identifierInstances.alertDelete',
              description: 'Delete alert dialogue, delete button text',
              defaultMessage: 'Delete'
            })
          },
          {
            handler: handleAlertCancel,
            text: intl.formatMessage({
              id: 'identifierInstances.alertCancel',
              description: 'Delete alert dialogue, cancel button text',
              defaultMessage: 'Cancel'
            })
          }
        ]}
        message={intl.formatMessage(messages.alertMessage, {
          count: selectedIdentifiers?.length
        })}
        open={alertOpen}
        title={intl.formatMessage(messages.alertTitle, {
          count: selectedIdentifiers?.length
        })}
      />
    </>
  )
}

export default IdentifierInstances
