import React, { FunctionComponent, useMemo, useState } from 'react'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { defineMessages, useIntl } from 'react-intl'
import { includes, isNil } from 'lodash'
import { Box, Container, Grid } from '@material-ui/core'
import { useSnackbar } from 'notistack'
import AlertDialogue from '../../../components/AlertDialogue'
import DataForm from '../../../components/DataForm'
import Table from '../../../components/Table/Table'
import FilterDialogue from '../../../components/FilterDialogue'
import PageLoading from '../../../components/Loading/PageLoading'
import useConfigure, { ConfigurationMode } from '../../../hooks/useConfigure'
import useFilter from '../../../hooks/useFilter'
import usePagination from '../../../hooks/usePagination'
import useSort from '../../../hooks/useSort'
import {
  LogicalOperator,
  NodeTemplateKeys,
  Paths,
  useApi
} from '../../../api/RcfactoryApi'
import { NumericArrayParam, useQueryParam } from 'use-query-params'

enum ParamKeys {
  TemplateId = 'templateId'
}

enum ConfigurationKeys {
  Template = 'template'
}

const messages = defineMessages({
  alertMessage: {
    id: 'nodeTemplates.alertMessage',
    description: 'Delete alert dialogue message content',
    defaultMessage:
      'Are you sure you want to delete the selected Node ' +
      '{count, plural, one{Template} other{Templates}}?'
  },
  alertTitle: {
    id: 'nodeTemplates.alertTitle',
    description: 'Delete alert dialogue title',
    defaultMessage:
      'Delete Node {count, plural, one{Template} other{Templates}}'
  }
})

const omitFilterProperties = [NodeTemplateKeys.Id]

const NodeTemplates: FunctionComponent = () => {
  const intl = useIntl()
  const api = useApi()
  const configurator = useConfigure(ConfigurationKeys.Template)
  const filterer = useFilter()
  const pagination = usePagination()
  const sort = useSort(NodeTemplateKeys.Id)
  const [alertOpen, setAlertOpen] = useState<boolean>(false)
  const [selected, setSelected] = useQueryParam(
    ParamKeys.TemplateId,
    NumericArrayParam
  )
  const { enqueueSnackbar } = useSnackbar()
  const queryClient = useQueryClient()
  const [selectedTemplates, setSelectedTemplates] = useState<
    Record<string, unknown>[] | undefined
  >(undefined)

  const descQuery = useQuery(
    Paths.NodeTemplates + Paths.UtilsGetDesc,
    () =>
      api.getDesc({
        path: Paths.NodeTemplates
      }),
    {
      onError: () =>
        enqueueSnackbar(
          intl.formatMessage({
            id: 'nodeTemplates.failedSchema',
            description: 'Node template schema fetch error message',
            defaultMessage: 'Failed to get Node Template Schema!'
          }),
          {
            variant: 'error'
          }
        )
    }
  )

  const templatesQuery = useQuery(
    [
      Paths.NodeTemplates,
      filterer.active,
      sort.order,
      sort.orderBy,
      pagination.page,
      pagination.rowsPerPage
    ],
    () =>
      api.getList({
        modelExpressions: {
          Expressions: filterer.active,
          Operator: LogicalOperator.And
        },
        order1: sort.order,
        orderBy1: sort.orderBy,
        pageNumber: pagination.page,
        pageSize: pagination.rowsPerPage,
        path: Paths.NodeTemplates
      }),
    {
      onError: () =>
        enqueueSnackbar(
          intl.formatMessage({
            id: 'nodeTemplates.failedTemplates',
            description: 'Node template fetch error message',
            defaultMessage: 'Failed to get Node Templates!'
          }),
          {
            variant: 'error'
          }
        )
    }
  )

  const createMutation = useMutation(
    (items: Record<string, unknown>[]) =>
      api.create({
        items: items,
        path: Paths.NodeTemplates
      }),
    {
      onError: () => {
        enqueueSnackbar(
          intl.formatMessage({
            id: 'nodeTemplates.failedCreate',
            description: 'Node template create error message',
            defaultMessage: 'Failed to create Node Template!'
          }),
          {
            variant: 'error'
          }
        )
      },
      onSuccess: () => {
        enqueueSnackbar(
          intl.formatMessage({
            id: 'nodeTemplates.successfulCreate',
            description: 'Node template create success message',
            defaultMessage: 'Successfully created Node Template!'
          }),
          {
            variant: 'success'
          }
        )
        configurator.clear()
        queryClient.invalidateQueries(Paths.NodeTemplates)
      }
    }
  )

  const deleteMutation = useMutation(
    (ids: number[]) =>
      api.delete({
        ids: ids,
        path: Paths.NodeTemplates
      }),
    {
      onError: () => {
        enqueueSnackbar(
          intl.formatMessage({
            id: 'nodeTemplates.failedDelete',
            description: 'Node template delete error message',
            defaultMessage: 'Failed to delete Node Templates!'
          }),
          {
            variant: 'error'
          }
        )
      },
      onSuccess: () => {
        enqueueSnackbar(
          intl.formatMessage({
            id: 'nodeTemplates.successfulDelete',
            description: 'Node template delete success message',
            defaultMessage: 'Successfully deleted Node templates!'
          }),
          {
            variant: 'success'
          }
        )
        setSelected(undefined)
        setSelectedTemplates(undefined)
        queryClient.invalidateQueries(Paths.NodeTemplates)
      }
    }
  )

  const updateMutation = useMutation(
    (items: Record<string, unknown>[]) =>
      api.update({
        items: items,
        path: Paths.NodeTemplates
      }),
    {
      onError: () => {
        enqueueSnackbar(
          intl.formatMessage({
            id: 'nodeTemplates.failedUpdate',
            description: 'Node template update error message',
            defaultMessage: 'Failed to update Node Template!'
          }),
          {
            variant: 'error'
          }
        )
      },
      onSuccess: () => {
        enqueueSnackbar(
          intl.formatMessage({
            id: 'nodeTemplates.successfulUpdate',
            description: 'Node template update success message',
            defaultMessage: 'Successfully updated Node Template!'
          }),
          {
            variant: 'success'
          }
        )
        configurator.clear()
        queryClient.invalidateQueries(Paths.NodeTemplates)
      }
    }
  )

  const activeTemplate = useMemo(() => {
    if (isNil(templatesQuery.data)) {
      return null
    }
    if (selected?.length !== 1) {
      return null
    }
    return templatesQuery.data.Items.find(
      (d: Record<string, unknown>) => d[NodeTemplateKeys.Id] === selected[0]
    )
  }, [selected, templatesQuery.data])

  const handleAlertCancel = () => {
    setAlertOpen(false)
  }

  const handleAlertDelete = () => {
    if (isNil(selected)) {
      throw Error('Cannot handleAlertDelete if selected is nil!')
    }
    deleteMutation.mutate(selected.map(Number))
    setAlertOpen(false)
  }

  const handleDelete = () => {
    setAlertOpen(true)
  }

  const handleFilterSubmit = () => {
    filterer.submit()
    pagination.setPage(0)
    filterer.clearData()
  }

  const handleFormCancel = () => {
    configurator.clear()
    filterer.clearData()
  }

  const handleFormSubmit = () => {
    if (!configurator.data) {
      throw Error('Cannot handleFormSubmit if configurator data is undefined!')
    }
    switch (configurator.mode) {
      case ConfigurationMode.Create:
        createMutation.mutate([configurator.data])
        break

      case ConfigurationMode.Edit:
        updateMutation.mutate([configurator.data])
        break

      default:
        throw Error(
          'Cannot handleFormSubmit if configurator mode is undefined!'
        )
    }
  }

  const handlePageChange = (newPage: number) => {
    pagination.setPage(newPage)
    setSelected(undefined)
    setSelectedTemplates(undefined)
  }

  const handleRequestSort = (property: string) => {
    sort.requestSort(property)
    setSelected(undefined)
    setSelectedTemplates(undefined)
  }

  const handleRowsPerPageChange = (rows: number) => {
    pagination.setRowsPerPage(rows)
    setSelected(undefined)
    setSelectedTemplates(undefined)
  }

  const handleSelect = (ids: (number | string | null)[]) => {
    setSelected(ids.length > 0 ? ids.map(Number) : undefined)
    setSelectedTemplates(
      templatesQuery?.data?.Items.filter((d: Record<string, unknown>) =>
        ids?.some((s) => s === d[NodeTemplateKeys.Id])
      )
    )
  }

  const pageReady =
    descQuery.isSuccess &&
    templatesQuery.isSuccess &&
    !createMutation.isLoading &&
    !deleteMutation.isLoading &&
    !updateMutation.isLoading

  const pageLoading =
    descQuery.isLoading ||
    templatesQuery.isLoading ||
    createMutation.isLoading ||
    deleteMutation.isLoading ||
    updateMutation.isLoading

  return (
    <>
      {pageReady && (
        <>
          {configurator.data && descQuery.data?.CrudDescription && (
            <DataForm
              formSections={[
                {
                  data: configurator.data,
                  ignoredProperties: [NodeTemplateKeys.Id],
                  onPropertyChange: (property, value) =>
                    configurator.update({
                      [property]: value
                    }),
                  schema: descQuery.data.CrudDescription
                }
              ]}
              onCancel={handleFormCancel}
              onSubmit={handleFormSubmit}
              title={
                configurator.mode === ConfigurationMode.Create
                  ? intl.formatMessage({
                      id: 'nodeTemplates.create',
                      description: 'Node template create dialogue title',
                      defaultMessage: 'Create Node Template'
                    })
                  : intl.formatMessage({
                      id: 'nodeTemplates.edit',
                      description: 'Node template edit dialogue title',
                      defaultMessage: 'Edit Node Template'
                    })
              }
            />
          )}
          {filterer.data && descQuery.data?.ViewDescription && (
            <FilterDialogue
              filter={filterer.data}
              onCancel={handleFormCancel}
              onExpressionChange={filterer.update}
              onReset={filterer.reset}
              onSubmit={handleFilterSubmit}
              schema={descQuery.data.ViewDescription}
              title={intl.formatMessage({
                id: 'nodeTemplates.filter',
                description: 'Node templates filter dialogue title',
                defaultMessage: 'Filter Node Templates'
              })}
            />
          )}
          <Box paddingTop={1} paddingBottom={3}>
            <Container maxWidth="md">
              <Grid container>
                <Grid item xs={12}>
                  {templatesQuery.data?.Items &&
                    templatesQuery.data?.Pagination &&
                    descQuery.data && (
                      <Table
                        data={templatesQuery.data.Items}
                        idProperty={NodeTemplateKeys.Id}
                        isFiltered={filterer.isActive}
                        multiSelect
                        onAdd={() =>
                          configurator.create(
                            descQuery.data?.CrudDescription.Properties
                          )
                        }
                        onDelete={handleDelete}
                        onEdit={
                          activeTemplate
                            ? () => configurator.edit(activeTemplate)
                            : undefined
                        }
                        onFilter={() =>
                          filterer.initialise(
                            descQuery.data.ViewDescription.Properties.filter(
                              (p) =>
                                !includes(omitFilterProperties, p.PropertyName)
                            )
                          )
                        }
                        onPageChange={handlePageChange}
                        onRequestSort={handleRequestSort}
                        onRowsPerPageChange={handleRowsPerPageChange}
                        onSelect={handleSelect}
                        order={sort.order}
                        orderBy={sort.orderBy}
                        page={pagination.page}
                        rowsPerPage={pagination.rowsPerPage}
                        schema={descQuery.data.ViewDescription}
                        selected={selected}
                        title={intl.formatMessage({
                          id: 'nodeTemplates.nodeTemplates',
                          description: 'Node templates table title',
                          defaultMessage: 'Node Templates'
                        })}
                        totalRows={templatesQuery.data.Pagination.TotalCount}
                      />
                    )}
                </Grid>
              </Grid>
            </Container>
          </Box>
        </>
      )}
      {pageLoading && <PageLoading />}
      <AlertDialogue
        actions={[
          {
            handler: handleAlertDelete,
            text: intl.formatMessage({
              id: 'nodeTemplates.alertDelete',
              description: 'Delete alert dialogue, delete button text',
              defaultMessage: 'Delete'
            })
          },
          {
            handler: handleAlertCancel,
            text: intl.formatMessage({
              id: 'nodeTemplates.alertCancel',
              description: 'Delete alert dialogue, cancel button text',
              defaultMessage: 'Cancel'
            })
          }
        ]}
        message={intl.formatMessage(messages.alertMessage, {
          count: selected?.length
        })}
        open={alertOpen}
        title={intl.formatMessage(messages.alertTitle, {
          count: selected?.length
        })}
        listItems={selectedTemplates?.map(
          (x) => x[NodeTemplateKeys.Name] as string
        )}
      />
    </>
  )
}

export default NodeTemplates
