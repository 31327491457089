import React, { FunctionComponent, useMemo, useState } from 'react'
import { defineMessages, useIntl } from 'react-intl'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { NumericArrayParam, useQueryParam } from 'use-query-params'
import { includes, isNil } from 'lodash'
import { useSnackbar } from 'notistack'
import { Box, Container, Grid } from '@material-ui/core'
import AlertDialogue from '../../../components/AlertDialogue'
import DataForm from '../../../components/DataForm'
import Table from '../../../components/Table/Table'
import FilterDialogue from '../../../components/FilterDialogue'
import PageLoading from '../../../components/Loading/PageLoading'
import useConfigure, { ConfigurationMode } from '../../../hooks/useConfigure'
import useFilter from '../../../hooks/useFilter'
import usePagination from '../../../hooks/usePagination'
import useSort from '../../../hooks/useSort'
import {
  LogicalOperator,
  Paths,
  UnitKeys,
  useApi
} from '../../../api/RcfactoryApi'

enum ParamKeys {
  UnitId = 'unitId'
}

enum ConfigurationKeys {
  Unit = 'unit'
}

const messages = defineMessages({
  alertMessage: {
    id: 'units.alertMessage',
    description: 'Delete alert dialogue message content',
    defaultMessage: 'Are you sure you want to delete the selected ' +
      '{count, plural, one{Unit} other{Units}}?'
  },
  alertTitle: {
    id: 'units.alertTitle',
    description: 'Delete alert dialogue title',
    defaultMessage: 'Delete {count, plural, one{Unit} other{Units}}'
  }
})

const omitFilterProperties = [UnitKeys.Id]

const Units: FunctionComponent = () => {
  const intl = useIntl()
  const api = useApi()
  const configurator = useConfigure(ConfigurationKeys.Unit)
  const filterer = useFilter()
  const pagination = usePagination()
  const sort = useSort(UnitKeys.Id)
  const [alertOpen, setAlertOpen] = useState<boolean>(false)
  const [selected, setSelected] = useQueryParam(
    ParamKeys.UnitId, NumericArrayParam
  )
  const { enqueueSnackbar } = useSnackbar()
  const queryClient = useQueryClient()

  const descQuery = useQuery(
    Paths.Unit + Paths.UtilsGetDesc,
    () => api.getDesc({
      path: Paths.Unit
    }), {
      onError: () => enqueueSnackbar(
        intl.formatMessage({
          id: 'units.failedSchema',
          description: 'Unit schema fetch error message',
          defaultMessage: 'Failed to get Unit Schema!'
        }), {
          variant: 'error'
        }
      )
    }
  )

  const unitsQuery = useQuery([
    Paths.Unit,
    filterer.active,
    sort.order,
    sort.orderBy,
    pagination.page,
    pagination.rowsPerPage
  ],
  () => api.getList({
    modelExpressions: {
      Expressions: filterer.active,
      Operator: LogicalOperator.And
    },
    order1: sort.order,
    orderBy1: sort.orderBy,
    pageNumber: pagination.page,
    pageSize: pagination.rowsPerPage,
    path: Paths.Unit
  }), {
    onError: () => enqueueSnackbar(
      intl.formatMessage({
        id: 'units.failedUnits',
        description: 'Units fetch error message',
        defaultMessage: 'Failed to get Units!'
      }), {
        variant: 'error'
      }
    )
  })

  const createMutation = useMutation(
    (items: Record<string, unknown>[]) => api.create({
      items: items,
      path: Paths.Unit
    }), {
      onError: () => {
        enqueueSnackbar(
          intl.formatMessage({
            id: 'units.failedCreate',
            description: 'Unit create error message',
            defaultMessage: 'Failed to create Unit!'
          }), {
            variant: 'error'
          }
        )
      },
      onSuccess: () => {
        enqueueSnackbar(
          intl.formatMessage({
            id: 'units.successfulCreate',
            description: 'Unit create success message',
            defaultMessage: 'Successfully created Unit!'
          }), {
            variant: 'success'
          }
        )
        configurator.clear()
        queryClient.invalidateQueries(Paths.Unit)
      }
    }
  )

  const deleteMutation = useMutation(
    (ids: number[]) => api.delete({
      ids: ids,
      path: Paths.Unit
    }), {
      onError: () => {
        enqueueSnackbar(
          intl.formatMessage({
            id: 'units.failedDelete',
            description: 'Unit delete error message',
            defaultMessage: 'Failed to delete Units!'
          }), {
            variant: 'error'
          }
        )
      },
      onSuccess: () => {
        enqueueSnackbar(
          intl.formatMessage({
            id: 'units.successfulDelete',
            description: 'Unit delete success message',
            defaultMessage: 'Successfully deleted Units!'
          }), {
            variant: 'success'
          }
        )
        setSelected(undefined)
        queryClient.invalidateQueries(Paths.Unit)
      }
    }
  )

  const updateMutation = useMutation(
    (items: Record<string, unknown>[]) => api.update({
      items: items,
      path: Paths.Unit
    }), {
      onError: () => {
        enqueueSnackbar(
          intl.formatMessage({
            id: 'units.failedUpdate',
            description: 'Unit update error message',
            defaultMessage: 'Failed to update Unit!'
          }), {
            variant: 'error'
          }
        )
      },
      onSuccess: () => {
        enqueueSnackbar(
          intl.formatMessage({
            id: 'units.successfulUpdate',
            description: 'Unit update success message',
            defaultMessage: 'Successfully updated Unit!'
          }), {
            variant: 'success'
          }
        )
        configurator.clear()
        queryClient.invalidateQueries(Paths.Unit)
      }
    }
  )

  const activeUnit = useMemo(() => {
    if (!unitsQuery.data?.Items) {
      return null
    }
    if (selected?.length !== 1) {
      return null
    }
    return unitsQuery.data?.Items.find(
      (d: Record<string, unknown>) => d[UnitKeys.Id] === selected[0])
  }, [selected, unitsQuery.data?.Items])

  const handleAlertCancel = () => {
    setAlertOpen(false)
  }

  const handleAlertDelete = () => {
    if (isNil(selected)) {
      throw Error('Cannot handleAlertDelete if selected is nil!')
    }
    deleteMutation.mutate(selected.map(Number))
    setAlertOpen(false)
  }

  const handleDelete = () => {
    setAlertOpen(true)
  }

  const handleFilterSubmit = () => {
    filterer.submit()
    pagination.setPage(0)
    filterer.clearData()
  }

  const handleFormCancel = () => {
    configurator.clear()
    filterer.clearData()
  }

  const handleFormSubmit = () => {
    if (!configurator.data) {
      throw Error('Cannot handleFormSubmit if configurator data is undefined!')
    }
    switch (configurator.mode) {
      case ConfigurationMode.Create:
        createMutation.mutate([configurator.data])
        break

      case ConfigurationMode.Edit:
        updateMutation.mutate([configurator.data])
        break

      default:
        throw Error('Cannot handleFormSubmit if configurator mode is undefined!')
    }
  }

  const handlePageChange = (newPage: number) => {
    pagination.setPage(newPage)
    setSelected(undefined)
  }

  const handleRequestSort = (property: string) => {
    sort.requestSort(property)
    setSelected(undefined)
  }

  const handleRowsPerPageChange = (rows: number) => {
    pagination.setRowsPerPage(rows)
    setSelected(undefined)
  }

  const handleSelect = (ids: (number | string | null)[]) => {
    setSelected(ids.length > 0 ? ids.map(Number) : undefined)
  }

  const pageReady = descQuery.isSuccess && unitsQuery.isSuccess &&
    !createMutation.isLoading && !deleteMutation.isLoading && !updateMutation.isLoading

  const pageLoading = descQuery.isLoading || unitsQuery.isLoading ||
    createMutation.isLoading || deleteMutation.isLoading || updateMutation.isLoading

  return (
    <>
      { pageReady &&
        <>
          { configurator.data &&
            descQuery.data?.CrudDescription &&
            <DataForm
              formSections={[{
                data: configurator.data,
                ignoredProperties: [UnitKeys.Id],
                onPropertyChange: (property, value) => configurator.update({
                  [property]: value
                }),
                schema: descQuery.data.CrudDescription
              }]}
              onCancel={handleFormCancel}
              onSubmit={handleFormSubmit}
              title={configurator.mode === ConfigurationMode.Create
                ? intl.formatMessage({
                  id: 'units.create',
                  description: 'Unit create dialogue title',
                  defaultMessage: 'Create Unit'
                })
                : intl.formatMessage({
                  id: 'units.edit',
                  description: 'Unit edit dialogue title',
                  defaultMessage: 'Edit Unit'
                })
              }
            />
          }
          { filterer.data &&
            descQuery.data?.ViewDescription &&
            <FilterDialogue
              filter={filterer.data}
              onCancel={handleFormCancel}
              onExpressionChange={filterer.update}
              onReset={filterer.reset}
              onSubmit={handleFilterSubmit}
              schema={descQuery.data.ViewDescription}
              title={intl.formatMessage({
                id: 'units.filter',
                description: 'Units filter dialogue title',
                defaultMessage: 'Filter Units'
              })}
            />
          }
          <Box paddingTop={1} paddingBottom={3}>
            <Container maxWidth='md'>
              <Grid container>
                <Grid item xs={12}>
                  { unitsQuery.data?.Items &&
                    unitsQuery.data?.Pagination &&
                    descQuery.data &&
                    <Table
                      data={unitsQuery.data.Items}
                      idProperty={UnitKeys.Id}
                      isFiltered={filterer.isActive}
                      multiSelect
                      onAdd={() => configurator.create(
                        descQuery.data.CrudDescription.Properties
                      )}
                      onDelete={handleDelete}
                      onEdit={activeUnit
                        ? () => configurator.edit(activeUnit)
                        : undefined
                      }
                      onFilter={() => filterer.initialise(
                        descQuery.data.ViewDescription.Properties.filter(
                          p => !includes(omitFilterProperties, p.PropertyName)
                        )
                      )}
                      onPageChange={handlePageChange}
                      onRequestSort={handleRequestSort}
                      onRowsPerPageChange={handleRowsPerPageChange}
                      onSelect={handleSelect}
                      order={sort.order}
                      orderBy={sort.orderBy}
                      page={pagination.page}
                      rowsPerPage={pagination.rowsPerPage}
                      schema={descQuery.data.ViewDescription}
                      selected={selected}
                      title={intl.formatMessage({
                        id: 'units.units',
                        description: 'Units table title',
                        defaultMessage: 'Units'
                      })}
                      totalRows={unitsQuery.data.Pagination.TotalCount}
                    />
                  }
                </Grid>
              </Grid>
            </Container>
          </Box>
        </>
      }
      { pageLoading &&
        <PageLoading/>
      }
      <AlertDialogue
        actions={[{
          handler: handleAlertDelete,
          text: intl.formatMessage({
            id: 'units.alertDelete',
            description: 'Delete alert dialogue, delete button text',
            defaultMessage: 'Delete'
          })
        }, {
          handler: handleAlertCancel,
          text: intl.formatMessage({
            id: 'units.alertCancel',
            description: 'Delete alert dialogue, cancel button text',
            defaultMessage: 'Cancel'
          })
        }]}
        message={intl.formatMessage(messages.alertMessage, {
          count: selected?.length
        })}
        open={alertOpen}
        title={intl.formatMessage(messages.alertTitle, {
          count: selected?.length
        })}
      />
    </>
  )
}

export default Units
