import React, {
  FunctionComponent,
  useCallback,
  useEffect,
  useState
} from 'react'
import { HierarchyNode } from 'd3-hierarchy'
import clsx from 'clsx'
import { DropRegion } from './Hierarchy'
import TreeBranch from './TreeBranch'
import { lighten, makeStyles } from '@material-ui/core'
import produce from 'immer'
import { isNil } from 'lodash'

const useStyles = makeStyles((theme) => ({
  highlight:
    theme.palette.type === 'light'
      ? {
          color: theme.palette.secondary.main,
          backgroundColor: lighten(theme.palette.secondary.light, 0.85)
        }
      : {
          color: theme.palette.text.primary,
          backgroundColor: lighten(theme.palette.secondary.main, 0.2)
        }
}))

interface Props {
  activeProperty?: string
  data: HierarchyNode<Record<string, unknown>>[]
  idProperty: string
  label?: string
  nameProperty: string
  onAdd?: (initial: Record<string, unknown>) => void
  onDrop?: (dragId: number, dropId: number, region: DropRegion) => void
  onDragWithin?: (within: boolean) => void
  parentIdProperty: string
}

const TreeFork: FunctionComponent<Props> = (props: Props) => {
  const {
    activeProperty,
    data,
    idProperty,
    label,
    nameProperty,
    onAdd,
    onDragWithin,
    onDrop,
    parentIdProperty
  } = props
  const classes = useStyles()
  const [dragWithin, setDragWithin] = useState<boolean>(false)
  const [dropRegions, setDropRegions] = useState<Map<number, DropRegion>>(
    new Map()
  )

  useEffect(() => {
    const newDragWithin = Array.from(dropRegions.values()).some(
      (d) => d === DropRegion.Bottom || d === DropRegion.Top
    )
    if (newDragWithin !== dragWithin) {
      if (onDragWithin) {
        onDragWithin(newDragWithin)
      }
      setDragWithin(newDragWithin)
    }
  }, [dropRegions, dragWithin, onDragWithin])

  const handleDropRegionChange = useCallback(
    (id: number, region?: DropRegion) => {
      const previousRegion = dropRegions.get(id)
      if (!isNil(region)) {
        if (previousRegion !== region) {
          setDropRegions(
            produce(dropRegions, (draft) => draft?.set(id, region))
          )
        }
      } else {
        if (!isNil(previousRegion)) {
          setDropRegions(
            produce(dropRegions, (draft) => {
              draft?.delete(id)
              return draft
            })
          )
        }
      }
    },
    [dropRegions]
  )

  return (
    <div className={clsx({ [classes.highlight]: dragWithin })}>
      {data.map((item) => {
        const dropRegion = dropRegions?.get(Number(item.id))
        return (
          <TreeBranch
            activeProperty={activeProperty}
            key={item.id}
            node={item}
            dropRegion={dropRegion}
            idProperty={idProperty}
            label={label}
            nameProperty={nameProperty}
            onAdd={onAdd}
            onDropRegionChange={handleDropRegionChange}
            onDrop={onDrop}
            parentIdProperty={parentIdProperty}
          />
        )
      })}
    </div>
  )
}

export default TreeFork
