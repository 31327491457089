import { withStyles, createStyles, ListItem } from '@material-ui/core'

const SideMenuCustomListItem = withStyles((theme) =>
  createStyles({
    root: {
      borderLeft: `${theme.custom.borderHoverValue} solid transparent`,
      borderRadius: '0 0 20px',
      '&:hover': {
        background: theme.custom.menuBtnHover,
        borderLeft: `${theme.custom.borderHoverValue} solid ${theme.palette.primary.main}`
      },
      '& span': {
        fontSize: theme.custom.menuFontSize
      },
      '& .MuiListItemIcon-root': {
        minWidth: '38px'
      }
    },
    selected: {}
  })
)(ListItem)

export default SideMenuCustomListItem
