import React, { FunctionComponent, useEffect, useState } from 'react'
import {
  Paper,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  makeStyles,
  Box,
  Breadcrumbs,
  Link
} from '@material-ui/core'
import {
  eachDayOfInterval,
  eachMinuteOfInterval,
  endOfDay,
  format,
  isAfter,
  isBefore,
  isWithinInterval,
  startOfDay,
  addMinutes
} from 'date-fns'
import ProductionGridTimeLine, {
  TimeLineCell
} from './components/ProductionViewTimeLine/ProductionGridTimeLine'
import clsx from 'clsx'
import ProductionGridCalculatedRow, {
  PGCalculatedCell
} from './components/ProductionViewCalculatedRow/ProductionViewCalculatedRow'
import { isNil } from 'lodash'

const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: '400px'
  },
  running: {
    backgroundColor: 'red',
    height: '23px',
    color: 'white',
    textAlign: 'left',
    padding: '2px',
    fontSize: '10pt'
  },
  cell: {
    padding: 0
  },
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white
  },
  dateCell: {
    borderLeft: `2px solid ${theme.palette.background.paper}`
  },
  fixedCell: {
    position: 'sticky',
    left: 0,
    background: theme.palette.background.paper,
    fontWeight: 'bold',
    maxWidth: '121px'
  },
  fixedRight: {
    position: 'sticky',
    right: 0,
    background: theme.palette.type === 'light' ? '#e6f4ea' : '#575f56',
    fontWeight: 'bold',
    width: 200
  },
  blackCell: {
    background: 'black'
  },
  nodeLabel: {
    background:
      theme.palette.type === 'light'
        ? '#f0eeef'
        : theme.palette.background.paper
  }
}))

interface Props {
  start: Date
  end: Date
  intervalInMinutes: number
  inputData: ProductionGridInputData[]
  cellWidthInPixels: number
  onCellClicked?: (data: Record<string, unknown>[]) => void
  onBreadCrumbClicked?: (nodeId: number) => void
}

export enum ProductionDataType {
  Count,
  Totals,
  Line,
  StartStop
}

export interface ProductionGridInputData {
  id: number
  label?: string
  nodePath: NodePath[]
  type: ProductionDataType
  data?: ProductionGridDataLine[]
  total?: number
}

export interface NodePath {
  id: number
  name: string
}

export interface ProductionGridDataLine {
  startTime: Date
  endTime?: Date
  value: string
}

interface Interval {
  start: Date
  end: Date
}

interface ProductionData {
  dataDetails: ProductionGridInputData[]
  days: Day[]
}

interface Day {
  day: Date
  dayStr: string
  intervalsStr: string[]
  rows: PVRow[]
  intervalCount: number
}

interface PVRow {
  cells: PVCell[]
}

interface PVCell {
  data: PVData[]
  interval?: PVInterval
}

interface PVData {
  value: string
  startTime?: Date
  endTime?: Date
}

interface PVInterval {
  start: Date
  end: Date
}

const ProductionGrid: FunctionComponent<Props> = (props: Props) => {
  const classes = useStyles()
  const {
    start,
    end,
    inputData,
    intervalInMinutes,
    cellWidthInPixels,
    onCellClicked,
    onBreadCrumbClicked
  } = props
  const [productionData, setProductionData] = useState<ProductionData>()
  const [totalCells, setTotalCells] = useState<number>(0)

  useEffect(() => {
    const interval: Interval = {
      start: start,
      end: end
    }
    // work out all the days between the start and end date.
    const dayIntervals = eachDayOfInterval(interval)
    const days: Day[] = []
    dayIntervals.forEach((day, dayIndex) => {
      // TODO: Potential there is a date-fns method that will check if interval is between two dates!
      const minuteIntervals = eachMinuteOfInterval(
        {
          start: startOfDay(day),
          end: endOfDay(day)
        },
        { step: intervalInMinutes }
      ).filter(
        (interval) =>
          (!isBefore(interval, start) || dayIndex !== 0) &&
          !isAfter(interval, end)
      )
      const isLastDay = dayIndex + 1 === dayIntervals.length
      const isFirstDay = dayIndex === 0
      // if this is first day, add the start time
      if (isFirstDay) {
        minuteIntervals.unshift(start)
      }
      // if this is the last day add the end time to catch data at the end.
      if (isLastDay) {
        minuteIntervals.push(end)
      }
      const rows: PVRow[] = inputData.map((data) => {
        if (!data.data) {
          return {
            cells: minuteIntervals.map(() => {
              return { data: [{ value: '' }] }
            })
          }
        }
        switch (data.type) {
          case ProductionDataType.Count:
          case ProductionDataType.Totals: {
            const cells: PVCell[] = minuteIntervals.map((interval, index) => {
              const value: PVCell = {
                data: []
              }
              const cellValues = data.data
                ?.filter(
                  (d) =>
                    isAfter(d.startTime, interval) &&
                    isBefore(d.startTime, minuteIntervals[index + 1])
                )
                .map((x) => {
                  return {
                    startTime: x.startTime,
                    endTime: x.endTime,
                    value: x.value
                  }
                })

              if (cellValues && cellValues.length > 0) {
                value.data = cellValues
              }
              return value
            })
            return {
              cells
            }
          }
          case ProductionDataType.Line: {
            const cells: PVCell[] = minuteIntervals.map(
              (interval, minuteIndex) => {
                let endInterval = addMinutes(interval, intervalInMinutes)
                if (minuteIndex === 0 && isFirstDay) {
                  endInterval = minuteIntervals[minuteIndex + 1]
                } else if (
                  // the very last interval is removed, below so it's actually send to last needs to be changed for width.
                  minuteIndex === minuteIntervals.length - 2 &&
                  isLastDay
                ) {
                  endInterval = end
                }
                const value: PVCell = {
                  interval: {
                    start: interval,
                    end: endInterval
                  },
                  data: [{ value: '' }]
                }
                const colValues = data.data
                  ?.filter(
                    (d) =>
                      (isAfter(d.startTime, interval) &&
                        isBefore(
                          d.startTime,
                          addMinutes(interval, intervalInMinutes)
                        )) ||
                      (d.endTime &&
                        isAfter(d.endTime, interval) &&
                        isBefore(
                          d.endTime,
                          addMinutes(interval, intervalInMinutes)
                        )) ||
                      (d.endTime &&
                        isWithinInterval(interval, {
                          start: d.startTime,
                          end: d.endTime
                        }))
                  )
                  .sort(
                    (a, b) =>
                      a.startTime.getTime() -
                      (b.endTime ? b.endTime.getTime() : 0)
                  )
                  .map((x) => {
                    return {
                      value: x.value,
                      startTime: x.startTime,
                      endTime: x.endTime
                    }
                  })

                if (colValues && colValues.length > 0) {
                  value.data = colValues
                }
                return value
              }
            )
            return {
              cells
            }
          }
          case ProductionDataType.StartStop: {
            const intervals: Date[] = []
            data.data.forEach((data) => {
              // find all intervals that exist within this range.
              intervals.push(
                ...minuteIntervals.filter((interval, index) => {
                  if (
                    isAfter(interval, data.startTime) &&
                    (isBefore(
                      minuteIntervals[index + 1],
                      data.endTime as Date
                    ) ||
                      !minuteIntervals[index + 1])
                  ) {
                    return interval
                  }
                  return null
                })
              )
            })
            const cells = minuteIntervals.map((interval) =>
              intervals.find((i) => i === interval) ? 'Open' : ''
            )
            return {
              cells: cells.map((col) => {
                return { data: [{ value: col }] }
              })
            }
          }
          default: {
            return { cells: [] }
          }
        }
      })

      // remove last column so it doesn't show on screen.
      if (isLastDay) {
        minuteIntervals.splice(-1)
        rows.forEach((row) => row.cells.splice(-1))
      }

      days.push({
        day: day,
        intervalsStr: minuteIntervals.map((i) => format(i, 'HH:mm:ss')),
        dayStr: format(day, 'dd/MM/yyyy'),
        rows: rows,
        intervalCount: minuteIntervals.length
      })
    })
    setProductionData({ dataDetails: inputData, days })
    // onLoadingStatusChange(false)
  }, [start, end, inputData, intervalInMinutes])

  useEffect(() => {
    if (
      isNil(productionData?.days) ||
      productionData?.days[0].rows.length === 0
    ) {
      return
    }
    let totalCells = 0
    productionData?.days.forEach((day) => {
      totalCells += day.rows[0].cells.length
    })
    setTotalCells(totalCells)
  }, [productionData])

  const handleCellClicked = (cell: PGCalculatedCell) => {
    if (onCellClicked) {
      const data = productionData?.days[cell.dayIndex].rows[
        cell.rowIndex
      ].cells[cell.cellIndex].data
        .map((d) => {
          return { time: d.startTime ?? new Date(), value: d.value }
        })
        .sort((a, b) => a.time.getTime() - b.time.getTime())
      onCellClicked(data ?? [])
    }
  }

  const handleOnBreadCrumbClicked = (nodeId: number) => {
    if (onBreadCrumbClicked) {
      onBreadCrumbClicked(nodeId)
    }
  }

  let previousNodeName: string | undefined
  const renderRow = (
    type: ProductionDataType,
    label: string,
    rowIndex: number,
    nodePaths: NodePath[]
  ) => {
    let render
    switch (type) {
      case ProductionDataType.Line: {
        const cells: TimeLineCell[] = []
        productionData?.days.forEach((day) => {
          day.rows[rowIndex].cells.forEach((cell) => {
            cells.push({
              interval: cell.interval ?? { start: new Date(), end: new Date() },
              data: cell.data.map((x) => {
                return {
                  value: x.value,
                  startTime: x.startTime ?? new Date(),
                  endTime: x.endTime ?? new Date()
                }
              })
            })
          })
        })
        render = (
          <ProductionGridTimeLine
            key={`pgtl-${rowIndex}`}
            label={label}
            cells={cells}
            cellWidthInPixels={cellWidthInPixels}
          ></ProductionGridTimeLine>
        )
        break
      }
      case ProductionDataType.Count: {
        const cells: PGCalculatedCell[] | undefined =
          productionData?.days.flatMap((day, dayIndex) => {
            return day.rows[rowIndex].cells.map((cell, cellIndex) => {
              return {
                dayIndex,
                rowIndex,
                cellIndex,
                value: cell.data.length
              }
            })
          })
        render = (
          <ProductionGridCalculatedRow
            label={label}
            key={`pgcw-${rowIndex}`}
            cells={cells ?? []}
            onCellClicked={handleCellClicked}
          ></ProductionGridCalculatedRow>
        )
        break
      }
      case ProductionDataType.Totals: {
        const cells: PGCalculatedCell[] | undefined =
          productionData?.days.flatMap((day, dayIndex) => {
            return day.rows[rowIndex].cells.map((cell, cellIndex) => {
              return {
                dayIndex,
                rowIndex,
                cellIndex,
                value: cell.data
                  .map((d) => Number(d.value))
                  .reduce((prev, curr) => prev + curr, 0)
              }
            })
          })
        render = (
          <ProductionGridCalculatedRow
            label={label}
            key={`pgcw-${rowIndex}`}
            cells={cells ?? []}
            onCellClicked={handleCellClicked}
          ></ProductionGridCalculatedRow>
        )
        break
      }
      case ProductionDataType.StartStop: {
        render = (
          <TableRow key={rowIndex}>
            <TableCell key={rowIndex + 'title'} className={classes.fixedCell}>
              {label}
            </TableCell>
            {productionData?.days.map((day) =>
              day.rows[rowIndex].cells.map((cell, index1) => {
                if (cell.data[0].value.toLocaleLowerCase() === 'open') {
                  const showLabel =
                    !day.rows[rowIndex].cells[index1 - 1] ||
                    !day.rows[rowIndex].cells[index1 - 1].data[0].value ||
                    day.rows[rowIndex].cells[
                      index1 - 1
                    ].data[0].value.toLowerCase() !== 'open'
                  return (
                    <TableCell className={classes.cell} key={rowIndex + index1}>
                      <Box className={classes.running}>
                        {showLabel ? `${cell.data[0].value}` : ''}
                      </Box>
                    </TableCell>
                  )
                } else {
                  return <TableCell key={rowIndex + index1}></TableCell>
                }
              })
            )}
            <TableCell className={classes.fixedRight}></TableCell>
          </TableRow>
        )
        break
      }
      default:
        break
    }
    const currentNodeName = nodePaths[nodePaths.length - 1].name
    const showHeader = previousNodeName !== currentNodeName
    previousNodeName = currentNodeName
    return (
      <>
        {showHeader && (
          <>
            <TableRow>
              <TableCell colSpan={totalCells + 1}></TableCell>
              <TableCell className={classes.fixedRight}></TableCell>
            </TableRow>
            <TableRow>
              <TableCell className={clsx(classes.fixedCell, classes.nodeLabel)}>
                <Box
                  component="div"
                  style={{
                    whiteSpace: 'nowrap',
                    overflow: 'visible',
                    width: '600px'
                  }}
                >
                  <Breadcrumbs aria-label="breadcrumb">
                    {nodePaths
                      .slice(0, nodePaths.length - 1)
                      .map((node, index) => {
                        return (
                          <Link
                            key={`breadcrumb-${node}-${index}`}
                            color="inherit"
                            onClick={() => handleOnBreadCrumbClicked(node.id)}
                            style={{ cursor: 'pointer ' }}
                          >
                            {node.name}
                          </Link>
                        )
                      })}
                    <Link
                      key={`breadcrumb-${
                        nodePaths[nodePaths.length - 1].name
                      }-${nodePaths.length}`}
                      color="inherit"
                      style={{ fontWeight: 'bold', cursor: 'pointer' }}
                      onClick={() =>
                        handleOnBreadCrumbClicked(
                          nodePaths[nodePaths.length - 1].id
                        )
                      }
                    >
                      {nodePaths[nodePaths.length - 1].name}
                    </Link>
                  </Breadcrumbs>
                </Box>
              </TableCell>
              <TableCell
                className={classes.nodeLabel}
                colSpan={totalCells}
              ></TableCell>
              <TableCell className={classes.fixedRight}></TableCell>
            </TableRow>
          </>
        )}
        {render}
      </>
    )
  }

  return (
    <TableContainer component={Paper} className={classes.root}>
      <Table size="small" aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell className={classes.fixedCell} />
            {productionData?.days.map((day, index) => (
              <TableCell
                className={clsx(classes.head, classes.dateCell)}
                key={`th-${index}`}
                align="left"
                colSpan={day.intervalCount}
              >
                {day.dayStr}
              </TableCell>
            ))}
            <TableCell
              className={clsx(classes.fixedRight, classes.blackCell)}
            />
          </TableRow>
          <TableRow>
            <TableCell className={classes.fixedCell} />
            {productionData?.days.map((day, index) =>
              day.intervalsStr.map((interval, index1) => (
                <TableCell key={`day-header-${index}${index1}`}>
                  {interval}
                </TableCell>
              ))
            )}
            <TableCell className={classes.fixedRight}>Total</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {productionData?.dataDetails?.length === 0 && (
            <TableRow>
              <TableCell className={classes.fixedCell}></TableCell>
              <TableCell></TableCell>
              <TableCell>No Data</TableCell>
            </TableRow>
          )}
          {productionData?.dataDetails.map((data, index) =>
            renderRow(
              data.type,
              data.label ? data.label : '',
              index,
              data.nodePath
            )
          )}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

export default ProductionGrid
