import React, { FunctionComponent } from 'react'
import {
  Checkbox,
  makeStyles,
  TableCell,
  TableHead as MuiTableHead,
  TableRow,
  TableSortLabel
} from '@material-ui/core'
import { camelCaseToTitle } from '../../api/Utils'
import { Order } from '../../api/RcfactoryApi'

const useStyles = makeStyles((theme) => ({
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1
  },
  cell: {
    fontSize: '0.9rem',
    [theme.breakpoints.down('xs')]: {
      fontSize: '0.75rem'
    }
  }
}))

interface Props {
  canExpand?: boolean,
  canSelect?: boolean,
  keys: Array<string>,
  numSelected?: number,
  order?: Order,
  orderBy?: string,
  onRequestSort?: (property: string) => void
  onSelectAllClick?: (event: React.ChangeEvent<HTMLInputElement>) => void,
  rowCount?: number
}

const TableHead: FunctionComponent<Props> = (props: Props) => {
  const {
    canExpand,
    canSelect,
    keys,
    numSelected,
    order,
    orderBy,
    onRequestSort,
    onSelectAllClick,
    rowCount
  } = props
  const classes = useStyles()

  const createSortHandler = (property: string) => {
    if (!onRequestSort) {
      throw Error('Cannot createSortHandler if onRequestSort is undefined!')
    }
    onRequestSort(property)
  }

  return (
    <MuiTableHead>
      <TableRow>
        { canSelect &&
          <>
            { onSelectAllClick &&
              numSelected !== undefined &&
              rowCount !== undefined &&
              <TableCell padding="checkbox">
                <Checkbox
                  indeterminate={numSelected > 0 && numSelected < rowCount}
                  checked={rowCount > 0 && numSelected === rowCount}
                  onChange={onSelectAllClick}
                  inputProps={{ 'aria-label': 'select all items' }}
                />
              </TableCell>
            }
            { !onSelectAllClick &&
              <TableCell padding="checkbox"/>
            }
          </>
        }
        {
          canExpand &&
          <TableCell/>
        }
        {keys.map((key) => (
          <TableCell
            key={key}
            sortDirection={orderBy === key ? order : false}
            className={classes.cell}
          >
            { onRequestSort &&
              order &&
              orderBy &&
              <TableSortLabel
                active={orderBy === key}
                direction={orderBy === key ? order : 'asc'}
                onClick={() => createSortHandler(key)}
              >
                {camelCaseToTitle(key)}
                {orderBy === key
                  ? <span className={classes.visuallyHidden}>
                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                  </span>
                  : null
                }
              </TableSortLabel>
            }
            { !onRequestSort &&
              <>{camelCaseToTitle(key)}</>
            }
          </TableCell>
        ))}
      </TableRow>
    </MuiTableHead>
  )
}

export default TableHead
