import React, { FunctionComponent, useMemo, useState } from 'react'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { NumericArrayParam, useQueryParam } from 'use-query-params'
import { assign, concat, isNil } from 'lodash'
import {
  Box,
  Container,
  FormControl,
  FormGroup,
  Grid,
  makeStyles,
  Paper,
  TableCell,
  TextField
} from '@material-ui/core'
import { Brightness1 } from '@material-ui/icons'
import { green, red } from '@material-ui/core/colors'
import { GridCellParams, GridSortItem } from '@material-ui/x-grid'
import { useSnackbar } from 'notistack'
import produce from 'immer'
import { defineMessages, useIntl } from 'react-intl'
import AlertDialogue from '../../../components/AlertDialogue'
import CollapsibleTable from '../../../components/Table/CollapsibleTable'
import DataForm from '../../../components/DataForm'
import DataGrid from '../../../components/DataGrid'
import Toolbar from '../../../components/Toolbar'
import PageLoading from '../../../components/Loading/PageLoading'
import PasswordInput from '../../../components/PasswordInput'
import Placeholder from '../../../components/Placeholder'
import useConfigure, { ConfigurationMode } from '../../../hooks/useConfigure'
import usePagination from '../../../hooks/usePagination'
import {
  Expression,
  GetListParams,
  GetListResponse,
  IotConnectionKeys,
  IotConnectionDeviceKeys,
  IotDeviceProvisioningKeys,
  IotTagDistinctKeys,
  IotTagMappingKeys,
  ExpressionOperator,
  Order,
  Paths,
  useApi,
  LogicalOperator
} from '../../../api/RcfactoryApi'
import { camelCaseToTitle } from '../../../api/Utils'
import HoverLabel from '../../../components/HoverLabel'

enum ParamKeys {
  OpenedConnections = 'openedConnections',
  SelectedConnections = 'selectedConnections',
  SelectedDevices = 'selectedDevices'
}

enum ConfigurationKeys {
  Connection = 'connection',
  Device = 'device'
}

enum AlertSelection {
  Connection,
  Device
}

const messages = defineMessages({
  alertConnectionMessage: {
    id: 'iotConnections.alertConnectionMessage',
    description: 'Delete connection alert dialogue message content',
    defaultMessage:
      'Are you sure you want to delete the selected IoT connection?'
  },
  alertConnectionTitle: {
    id: 'iotConnections.alertDialogueTitle',
    description: 'Delete connection alert dialogue title',
    defaultMessage: 'Delete IoT Connection'
  },
  alertDeviceMessage: {
    id: 'iotConnections.alertDeviceMessage',
    description: 'Delete device alert dialogue message content',
    defaultMessage: 'Are you sure you want to delete the selected IoT device?'
  },
  alertDeviceTitle: {
    id: 'iotConnections.alertDeviceTitle',
    description: 'Delete device alert dialogue title',
    defaultMessage: 'Delete IoT Device'
  }
})

const useStyles = makeStyles({
  form: {
    '& .MuiInputBase-root': {
      position: 'relative',
      width: '100%'
    }
  },
  message: {
    'font-size': '16px'
  }
})

const IotConnections: FunctionComponent = () => {
  const classes = useStyles()
  const intl = useIntl()
  const api = useApi()
  const connectionConfigurator = useConfigure(ConfigurationKeys.Connection)
  const connectionPagination = usePagination()
  const deviceConfigurator = useConfigure(ConfigurationKeys.Device)
  const tagPagination = usePagination(25)
  const [alertSelection, setAlertSelection] = useState<AlertSelection>()
  const [alertList, setAlertList] = useState<string[]>()
  const [continuationToken, setContinuationToken] = useState<
    string | undefined
  >()
  const [continuationTokens, setContinuationTokens] = useState<
    Map<number, string>
  >(new Map())
  const [filter, setFilter] = useState<Expression[]>([])
  const [opened, setOpened] = useQueryParam(
    ParamKeys.OpenedConnections,
    NumericArrayParam
  )
  const [selectedConnections, setSelectedConnections] = useQueryParam(
    ParamKeys.SelectedConnections,
    NumericArrayParam
  )
  const [selectedDevices, setSelectedDevices] = useQueryParam(
    ParamKeys.SelectedDevices,
    NumericArrayParam
  )
  const [showSakPassword, setShowSakPassword] = useState<boolean>(false)
  const [showSasPassword, setShowSasPassword] = useState<boolean>(false)
  const [sort, setSort] = useState<GridSortItem[]>([
    {
      field: IotTagDistinctKeys.LastSeen,
      sort: 'desc'
    }
  ])
  const { enqueueSnackbar } = useSnackbar()
  const queryClient = useQueryClient()

  const connectionDescQuery = useQuery(
    Paths.IotConnections + Paths.UtilsGetDesc,
    () => api.getDesc({ path: Paths.IotConnections }),
    {
      onError: () =>
        enqueueSnackbar(
          intl.formatMessage({
            id: 'iotConnections.connectionSchemaFailed',
            description:
              'Failed to get IoT Connection schema error notification text',
            defaultMessage: 'Failed to get IoT Connection Schema!'
          }),
          {
            variant: 'error'
          }
        )
    }
  )

  const provisioningQuery = useQuery(
    [
      Paths.IotDeviceProvisioning,
      connectionPagination.page,
      connectionPagination.rowsPerPage
    ],
    () =>
      api.getList({
        path: Paths.IotDeviceProvisioning,
        pageNumber: connectionPagination.page,
        pageSize: connectionPagination.rowsPerPage,
        continuationToken: continuationToken
      }),
    {
      onSuccess: (data: GetListResponse) => {
        if (data.Pagination.HasNext && data.Pagination.ContinuationToken) {
          setContinuationTokens(
            produce(continuationTokens, (tokens) =>
              tokens?.set(
                data.Pagination.CurrentPage + 1,
                data.Pagination.ContinuationToken as string
              )
            )
          )
        }
      },
      onError: () =>
        enqueueSnackbar(
          intl.formatMessage({
            id: 'iotConnections.provisioningFailed',
            description:
              'Failed to get IoT Device Provisioning error notification text',
            defaultMessage: 'Failed to get IoT Device Provisioning information!'
          }),
          {
            variant: 'error'
          }
        )
    }
  )

  const connectionsQuery = useQuery(
    [Paths.IotConnections, { data: provisioningQuery?.data }],
    (queryKey) => {
      const provisioningQuery = queryKey.queryKey[1] as {
        data: GetListResponse | undefined
      }
      return api.getList({
        modelExpressions: !isNil(provisioningQuery?.data)
          ? {
              Expressions: provisioningQuery?.data?.Items.map((p) => ({
                Prop: IotConnectionKeys.Name,
                Op: ExpressionOperator.Equal,
                Val: p[IotDeviceProvisioningKeys.Name]
              })),
              Operator: LogicalOperator.Or
            }
          : undefined,
        path: Paths.IotConnections
      })
    },
    {
      enabled: !isNil(provisioningQuery.data),
      onError: () =>
        enqueueSnackbar(
          intl.formatMessage({
            id: 'iotConnections.connectionsFailed',
            description:
              'Failed to get IoT Connections error notification text',
            defaultMessage: 'Failed to get IoT Connections!'
          }),
          {
            variant: 'error'
          }
        )
    }
  )

  const deviceDescQuery = useQuery(
    Paths.IotConnectionDevices + Paths.UtilsGetDesc,
    () => api.getDesc({ path: Paths.IotConnectionDevices }),
    {
      onError: () =>
        enqueueSnackbar(
          intl.formatMessage({
            id: 'iotConnections.deviceSchemaFailed',
            description:
              'Failed to get IoT Connection Device schema error notification text',
            defaultMessage: 'Failed to get IoT Connection Device Schema!'
          }),
          {
            variant: 'error'
          }
        )
    }
  )

  const devicesQuery = useQuery(
    Paths.IotConnectionDevices,
    () =>
      api.getList({
        modelExpressions: !isNil(connectionsQuery.data)
          ? {
              Expressions: connectionsQuery.data?.Items.map((c) => ({
                Prop: IotConnectionDeviceKeys.ConnectionId,
                Op: ExpressionOperator.Equal,
                Val: c[IotConnectionKeys.Id]
              })),
              Operator: LogicalOperator.Or
            }
          : undefined,
        path: Paths.IotConnectionDevices
      }),
    {
      enabled: !isNil(connectionsQuery.data),
      onError: () =>
        enqueueSnackbar(
          intl.formatMessage({
            id: 'iotConnections.devicesFailed',
            description:
              'Failed to get IoT Connection Devices error notification text',
            defaultMessage: 'Failed to get IoT Connection Devices!'
          }),
          {
            variant: 'error'
          }
        )
    }
  )

  const deviceCreateMutation = useMutation(
    (items: Record<string, unknown>[]) =>
      api.create({
        items: items,
        path: Paths.IotConnectionDevices
      }),
    {
      onError: () => {
        enqueueSnackbar(
          intl.formatMessage({
            id: 'iotConnections.failedDeviceCreate',
            description: 'Failed to create device error notification text',
            defaultMessage: 'Failed to create IoT Device!'
          }),
          {
            variant: 'error'
          }
        )
      },
      onSuccess: () => {
        enqueueSnackbar(
          intl.formatMessage({
            id: 'iotConnections.successfulDeviceCreate',
            description: 'Successfully created device notification text',
            defaultMessage: 'Successfully created IoT Device!'
          }),
          {
            variant: 'success'
          }
        )
        deviceConfigurator.clear()
        queryClient.invalidateQueries(Paths.IotConnectionDevices)
      }
    }
  )

  const deviceDeleteMutation = useMutation(
    (ids: number[]) =>
      api.delete({
        ids: ids,
        path: Paths.IotConnectionDevices
      }),
    {
      onError: () => {
        enqueueSnackbar(
          intl.formatMessage({
            id: 'iotConnections.failedDeviceDelete',
            description: 'Failed to delete device notification text',
            defaultMessage: 'Failed to delete IoT Device!'
          }),
          {
            variant: 'error'
          }
        )
      },
      onSuccess: () => {
        enqueueSnackbar(
          intl.formatMessage({
            id: 'iotConnections.successfulDeviceDelete',
            description: 'Successfully deleted device notification text',
            defaultMessage: 'Successfully deleted IoT Device!'
          }),
          {
            variant: 'success'
          }
        )
        setSelectedDevices(undefined)
        queryClient.invalidateQueries(Paths.IotConnectionDevices)
      }
    }
  )

  const deviceUpdateMutation = useMutation(
    (items: Record<string, unknown>[]) =>
      api.update({
        items: items,
        path: Paths.IotConnectionDevices
      }),
    {
      onError: () => {
        enqueueSnackbar(
          intl.formatMessage({
            id: 'iotConnections.failedDeviceUpdate',
            description: 'Update IoT device error message',
            defaultMessage: 'Failed to update IoT Device!'
          }),
          {
            variant: 'error'
          }
        )
      },
      onSuccess: () => {
        enqueueSnackbar(
          intl.formatMessage({
            id: 'iotConnections.successfulDeviceUpdate',
            description: 'Update IoT device success message',
            defaultMessage: 'Successfully updated IoT Device!'
          }),
          {
            variant: 'success'
          }
        )
        deviceConfigurator.clear()
        queryClient.invalidateQueries(Paths.IotConnectionDevices)
      }
    }
  )

  const provisioningDescQuery = useQuery(
    Paths.IotDeviceProvisioning + Paths.UtilsGetDesc,
    () => api.getDesc({ path: Paths.IotDeviceProvisioning }),
    {
      onError: () =>
        enqueueSnackbar(
          intl.formatMessage({
            id: 'iotConnections.provisioningSchemaFailed',
            description:
              'Failed to get IoT Device Provisioning schema error notification text',
            defaultMessage: 'Failed to get IoT Device Provisioning Schema!'
          }),
          {
            variant: 'error'
          }
        )
    }
  )

  const provisioningCreateMutation = useMutation(
    (items: Record<string, unknown>[]) =>
      api.create({
        items: items,
        path: Paths.IotDeviceProvisioning
      }),
    {
      onError: () => {
        enqueueSnackbar(
          intl.formatMessage({
            id: 'iotConnections.failedProvisioningCreate',
            description: 'Failed to create connection error notification text',
            defaultMessage: 'Failed to create IoT Connection!'
          }),
          {
            variant: 'error'
          }
        )
      },
      onSuccess: () => {
        enqueueSnackbar(
          intl.formatMessage({
            id: 'iotConnections.successfulProvisioningCreate',
            description: 'Successfully created connection notification text',
            defaultMessage: 'Successfully created IoT Connection!'
          }),
          {
            variant: 'success'
          }
        )
        connectionConfigurator.clear()
        queryClient.invalidateQueries(Paths.IotDeviceProvisioning)
      }
    }
  )

  const provisioningDeleteMutation = useMutation(
    (ids: string[]) =>
      api.delete({
        ids: ids,
        path: Paths.IotDeviceProvisioning
      }),
    {
      onError: () => {
        enqueueSnackbar(
          intl.formatMessage({
            id: 'iotConnections.failedProvisioningDelete',
            description: 'Failed to delete connection notification text',
            defaultMessage: 'Failed to delete IoT Connection!'
          }),
          {
            variant: 'error'
          }
        )
      },
      onSuccess: () => {
        enqueueSnackbar(
          intl.formatMessage({
            id: 'iotConnections.successfulProvisioningDelete',
            description: 'Successfully deleted connection notification text',
            defaultMessage: 'Successfully deleted IoT Connection!'
          }),
          {
            variant: 'success'
          }
        )
        setSelectedConnections(undefined)
        queryClient.invalidateQueries(Paths.IotDeviceProvisioning)
      }
    }
  )

  const selectedConnection = useMemo(() => {
    if (isNil(selectedConnections)) {
      return
    }
    return connectionsQuery.data?.Items.find(
      (d) => d[IotConnectionKeys.Id] === selectedConnections[0]
    )
  }, [connectionsQuery.data?.Items, selectedConnections])

  const selectedDevice = useMemo(() => {
    if (isNil(selectedDevices)) {
      return
    }
    return devicesQuery.data?.Items.find(
      (d) => d[IotConnectionDeviceKeys.Id] === selectedDevices[0]
    )
  }, [devicesQuery.data?.Items, selectedDevices])

  const tagsQuery = useQuery(
    [
      Paths.IotTagDistinct,
      filter,
      sort,
      selectedConnection?.[IotDeviceProvisioningKeys.Name],
      selectedDevice?.[IotConnectionDeviceKeys.Name],
      tagPagination.page,
      tagPagination.rowsPerPage
    ],
    () => {
      let name
      if (!isNil(selectedConnection)) {
        name = selectedConnection[IotDeviceProvisioningKeys.Name]
      } else if (!isNil(selectedDevice)) {
        name = selectedDevice[IotConnectionDeviceKeys.Name]
      }
      const params: GetListParams = {
        modelExpressions: {
          Expressions: filter.concat({
            Prop: IotTagDistinctKeys.IotDeviceId,
            Op: ExpressionOperator.Equal,
            Val: name
          })
        },
        path: Paths.IotTagDistinct,
        pageNumber: tagPagination.page,
        pageSize: tagPagination.rowsPerPage
      }
      if (sort.length > 0) {
        params.order1 = sort[0].sort === 'asc' ? Order.asc : Order.desc
        params.orderBy1 = sort[0].field
      }
      return api.getList(params)
    },
    {
      enabled: !isNil(selectedConnection) || !isNil(selectedDevice),
      onError: () =>
        enqueueSnackbar(
          intl.formatMessage({
            id: 'iotConnections.failedTags',
            description: 'Failed to get IoT Tags notification text',
            defaultMessage: 'Failed to get IoT Tags!'
          }),
          {
            variant: 'error'
          }
        )
    }
  )

  const tagsDescQuery = useQuery(
    Paths.IotTagDistinct + Paths.UtilsGetDesc,
    () =>
      api.getDesc({
        path: Paths.IotTagDistinct
      }),
    {
      onError: () =>
        enqueueSnackbar(
          intl.formatMessage({
            id: 'iotConnections.failedTagsSchema',
            description: 'Failed to get IoT tags schema notification text',
            defaultMessage: 'Failed to get Iot Tags Schema!'
          }),
          {
            variant: 'error'
          }
        )
    }
  )

  const connectionData = useMemo(() => {
    if (isNil(connectionsQuery.data)) {
      return
    }
    if (isNil(provisioningQuery.data)) {
      return
    }

    return connectionsQuery.data.Items.map((c) => {
      return assign(
        c,
        provisioningQuery.data?.Items.find(
          (p) => c[IotConnectionKeys.Name] === p[IotDeviceProvisioningKeys.Name]
        )
      )
    })
  }, [connectionsQuery.data, provisioningQuery.data])

  const connectionSchema = useMemo(() => {
    if (isNil(connectionDescQuery.data)) {
      return
    }
    if (isNil(provisioningDescQuery.data)) {
      return
    }
    return {
      ClassName: connectionDescQuery.data.ViewDescription.ClassName,
      Properties: concat(
        connectionDescQuery.data.ViewDescription.Properties,
        provisioningDescQuery.data.ViewDescription.Properties
      )
    }
  }, [connectionDescQuery.data, provisioningDescQuery.data])

  const handleAlertCancel = () => {
    setAlertSelection(undefined)
  }

  const handleAlertDelete = () => {
    switch (alertSelection) {
      case AlertSelection.Connection:
        if (isNil(selectedConnection)) {
          throw Error(
            'Cannot handleAlertDelete if alertSelection is Connection and ' +
              'selectedConnection is nil!'
          )
        }
        provisioningDeleteMutation.mutate([
          String(selectedConnection[IotDeviceProvisioningKeys.Name])
        ])
        break

      case AlertSelection.Device:
        if (isNil(selectedDevice)) {
          throw Error(
            'Cannot handleAlertDelete if alertSelection is Device and ' +
              'selectedDevice is nil!'
          )
        }
        deviceDeleteMutation.mutate([
          Number(selectedDevice[IotConnectionDeviceKeys.Id])
        ])
        break
    }
    setAlertSelection(undefined)
  }

  const handleConnectionPageChange = (newPage: number) => {
    connectionPagination.setPage(newPage)
    setSelectedConnections(undefined)
    setContinuationToken(continuationTokens.get(newPage + 1))
  }

  const handleConnectionRowsPerPageChange = (rows: number) => {
    connectionPagination.setRowsPerPage(rows)
    setSelectedConnections(undefined)
  }

  const handleDeleteConnection = () => {
    setAlertList(
      selectedConnection
        ? [selectedConnection[IotConnectionKeys.Name] as string]
        : undefined
    )
    setAlertSelection(AlertSelection.Connection)
  }

  const handleDeleteDevice = () => {
    setAlertList(
      selectedDevice
        ? [selectedDevice[IotConnectionDeviceKeys.Name] as string]
        : undefined
    )
    setAlertSelection(AlertSelection.Device)
  }

  const handleFormCancel = () => {
    connectionConfigurator.clear()
    deviceConfigurator.clear()
  }

  const handleFormSubmit = () => {
    if (connectionConfigurator.data) {
      provisioningCreateMutation.mutate([connectionConfigurator.data])
    } else if (deviceConfigurator.data) {
      switch (deviceConfigurator.mode) {
        case ConfigurationMode.Create:
          deviceCreateMutation.mutate([deviceConfigurator.data])
          break

        case ConfigurationMode.Edit:
          deviceUpdateMutation.mutate([deviceConfigurator.data])
          break

        default:
          throw Error(
            'Cannot handleFormSubmit with device data if configuration mode is not set!'
          )
      }
    } else {
      throw Error('Cannot handleFormSubmit if no configurator data is defined!')
    }
  }

  const handleOpen = (ids: (number | string | null)[]) => {
    setOpened(ids.length > 0 ? ids.map(Number) : undefined)
  }

  const handleSelectConnections = (ids: (number | string | null)[]) => {
    setFilter([])
    tagPagination.setPage(0)
    setShowSakPassword(false)
    setShowSasPassword(false)
    setSelectedDevices(undefined)
    setAlertList(undefined)
    setSelectedConnections(ids.length > 0 ? ids.map(Number) : undefined)
  }

  const handleSelectedDevices = (ids: (number | string | null)[]) => {
    setSelectedConnections(undefined)
    setAlertList(undefined)
    setSelectedDevices(ids.length > 0 ? ids.map(Number) : undefined)
  }

  const handleToggleShowSakPassword = () => {
    setShowSakPassword(!showSakPassword)
  }

  const handleToggleShowSasPassword = () => {
    setShowSasPassword(!showSasPassword)
  }

  const renderConnected = (row: Record<string, unknown>, key: string) => {
    return (
      <TableCell padding="checkbox">
        {row[key] && <Brightness1 style={{ color: green[500] }} />}
        {!row[key] && <Brightness1 style={{ color: red[500] }} />}
      </TableCell>
    )
  }

  const validateDeviceName = (value: string) => {
    const regEx = /[^A-Za-z0-9-_]/g
    return !regEx.test(value)
  }

  const pageReady =
    provisioningDescQuery.isSuccess &&
    provisioningQuery.isSuccess &&
    tagsDescQuery.isSuccess &&
    !provisioningCreateMutation.isLoading &&
    !provisioningDeleteMutation.isLoading

  const pageLoading =
    provisioningDescQuery.isLoading ||
    provisioningQuery.isLoading ||
    tagsDescQuery.isLoading ||
    provisioningCreateMutation.isLoading ||
    provisioningDeleteMutation.isLoading

  let alertDialogueMessage
  let alertDialogueTitle
  switch (alertSelection) {
    case AlertSelection.Connection:
      alertDialogueMessage = intl.formatMessage(messages.alertConnectionMessage)
      alertDialogueTitle = intl.formatMessage(messages.alertConnectionTitle)
      break

    default:
      alertDialogueMessage = intl.formatMessage(messages.alertDeviceMessage)
      alertDialogueTitle = intl.formatMessage(messages.alertDeviceTitle)
      break
  }

  const selected = selectedConnection || selectedDevice
  const title = !isNil(selectedConnection)
    ? String(selectedConnection[IotDeviceProvisioningKeys.Name])
    : !isNil(selectedDevice)
    ? String(selectedDevice[IotConnectionDeviceKeys.Name])
    : undefined

  const labelCellRender = (params: GridCellParams) => {
    return <HoverLabel label={params.value?.toString() ?? ''} />
  }
  return (
    <>
      {pageReady && (
        <>
          {connectionConfigurator.data && connectionDescQuery.data && (
            <DataForm
              customValidators={[
                {
                  propertyName: IotConnectionKeys.Name,
                  validateFunction: validateDeviceName,
                  errorMessage: intl.formatMessage({
                    id: 'iotConnections.validate.connectionName',
                    description:
                      'IoT configuration page, IoT Connection name validation',
                    defaultMessage:
                      'Connection Name can only contain upper and lowercase letters, numbers, hyphens (-) or underscores (_)'
                  })
                }
              ]}
              formSections={[
                {
                  data: connectionConfigurator.data,
                  ignoredProperties: [IotConnectionKeys.Id],
                  onPropertyChange: (property, value) =>
                    connectionConfigurator.update({
                      [property]: value
                    }),
                  schema: connectionDescQuery.data.CrudDescription
                }
              ]}
              onCancel={handleFormCancel}
              onSubmit={handleFormSubmit}
              title={intl.formatMessage({
                id: 'iotConnections.createIotConnection',
                description:
                  'IoT configuration page, connection create dialogue title',
                defaultMessage: 'Create IoT Connection'
              })}
            />
          )}
          {deviceConfigurator.data && deviceDescQuery.data && (
            <DataForm
              title={intl.formatMessage({
                id: 'iotConnections.createIotDeviceConnection',
                description:
                  'IoT configuration page, device create dialogue title',
                defaultMessage: 'Create IoT Device'
              })}
              formSections={[
                {
                  data: deviceConfigurator.data,
                  ignoredProperties: [
                    IotConnectionDeviceKeys.Id,
                    IotConnectionDeviceKeys.ConnectionId
                  ],
                  onPropertyChange: (property, value) =>
                    deviceConfigurator.update({
                      [property]: value
                    }),
                  schema: deviceDescQuery.data.CrudDescription
                }
              ]}
              onCancel={handleFormCancel}
              onSubmit={handleFormSubmit}
              customValidators={[
                {
                  propertyName: IotConnectionDeviceKeys.Name,
                  validateFunction: validateDeviceName,
                  errorMessage: intl.formatMessage({
                    id: 'iotConnections.validate.connectionDeviceName',
                    description:
                      'IoT configuration page, IoT Connection device name validation',
                    defaultMessage:
                      'Device Name can only contain upper and lowercase letters, numbers, hyphens (-) or underscores (_)'
                  })
                }
              ]}
            />
          )}
          <Box paddingTop={1} paddingBottom={3} height="100%" clone>
            <Container maxWidth="xl">
              <Box height="100%" clone>
                <Grid container spacing={2} alignItems="stretch">
                  <Grid item xs={12} md={6}>
                    {connectionSchema &&
                      connectionData &&
                      connectionDescQuery.data &&
                      deviceDescQuery.data &&
                      devicesQuery.data &&
                      provisioningQuery.data && (
                        <CollapsibleTable
                          customRenders={[
                            {
                              propertyName: IotDeviceProvisioningKeys.Connected,
                              renderFunction: renderConnected
                            }
                          ]}
                          dataInner={[
                            {
                              data: devicesQuery.data.Items,
                              idProperty: IotConnectionDeviceKeys.Id,
                              ignoredKeys: [
                                IotConnectionDeviceKeys.ConnectionId
                              ],
                              onAdd: (parentId) =>
                                deviceConfigurator.create(
                                  deviceDescQuery.data.CrudDescription
                                    .Properties,
                                  {
                                    [IotConnectionDeviceKeys.ConnectionId]:
                                      parentId
                                  }
                                ),
                              onDelete: handleDeleteDevice,
                              onEdit: selectedDevice
                                ? () => deviceConfigurator.edit(selectedDevice)
                                : undefined,
                              onSelect: handleSelectedDevices,
                              parentIdProperty:
                                IotConnectionDeviceKeys.ConnectionId,
                              schema: deviceDescQuery.data.ViewDescription,
                              selected: selectedDevices,
                              title: intl.formatMessage({
                                id: 'iotConnections.iotDevices',
                                description:
                                  'IoT configuration page, device table title',
                                defaultMessage: 'IoT Devices'
                              })
                            }
                          ]}
                          dataOuter={connectionData}
                          idProperty={IotConnectionKeys.Id}
                          ignoredKeys={[
                            IotConnectionKeys.Id,
                            IotConnectionKeys.Name,
                            IotDeviceProvisioningKeys.AssignedHub,
                            IotDeviceProvisioningKeys.ConnectionState,
                            IotDeviceProvisioningKeys.CreatedDateTime,
                            IotDeviceProvisioningKeys.Id,
                            IotDeviceProvisioningKeys.LastUpdatedDateTime,
                            IotDeviceProvisioningKeys.SAKConnectionString,
                            IotDeviceProvisioningKeys.SASConnectionString,
                            IotDeviceProvisioningKeys.StatusUpdatedTime
                          ]}
                          onAdd={() =>
                            connectionConfigurator.create(
                              connectionDescQuery.data.CrudDescription
                                .Properties
                            )
                          }
                          onDelete={handleDeleteConnection}
                          onOpen={handleOpen}
                          onPageChange={handleConnectionPageChange}
                          onRowsPerPageChange={
                            handleConnectionRowsPerPageChange
                          }
                          onSelect={handleSelectConnections}
                          opened={opened || []}
                          page={connectionPagination.page}
                          rowsPerPage={connectionPagination.rowsPerPage}
                          schema={connectionSchema}
                          selected={selectedConnections}
                          title={intl.formatMessage({
                            id: 'iotConnections.iotConnections',
                            description:
                              'IoT configuration page, connection table title',
                            defaultMessage: 'IoT Connections'
                          })}
                          totalRows={
                            provisioningQuery.data.Pagination.TotalCount
                          }
                        />
                      )}
                  </Grid>
                  <Grid item xs={12} md={6}>
                    {selected && (
                      <Box
                        clone
                        display="flex"
                        flexDirection="column"
                        height="100%"
                      >
                        <Paper>
                          <Toolbar title={title} />
                          <form
                            className={classes.form}
                            noValidate
                            autoComplete="off"
                          >
                            <FormGroup>
                              {selectedConnection && (
                                <>
                                  <PasswordInput
                                    canCopy
                                    label={camelCaseToTitle(
                                      IotDeviceProvisioningKeys.SAKConnectionString
                                    )}
                                    onToggleShow={handleToggleShowSakPassword}
                                    showPassword={showSakPassword}
                                    value={String(
                                      selected[
                                        IotDeviceProvisioningKeys
                                          .SAKConnectionString
                                      ]
                                    )}
                                  />
                                  <PasswordInput
                                    canCopy
                                    label={camelCaseToTitle(
                                      IotDeviceProvisioningKeys.SASConnectionString
                                    )}
                                    onToggleShow={handleToggleShowSasPassword}
                                    showPassword={showSasPassword}
                                    value={String(
                                      selected[
                                        IotDeviceProvisioningKeys
                                          .SASConnectionString
                                      ]
                                    )}
                                  />
                                </>
                              )}
                              {selectedDevice && (
                                <Box mx={2} my={1}>
                                  <FormControl style={{ width: '100%' }}>
                                    <TextField
                                      value={JSON.stringify(
                                        {
                                          IotDeviceIdOverride: title
                                        },
                                        null,
                                        2
                                      )}
                                      InputProps={{
                                        readOnly: true
                                      }}
                                      label="Example JSON"
                                      multiline
                                    />
                                  </FormControl>
                                </Box>
                              )}
                            </FormGroup>
                          </form>
                          <Box
                            paddingLeft={2}
                            paddingRight={2}
                            paddingBottom={2}
                            flex="1"
                          >
                            {tagsDescQuery.data?.ViewDescription && (
                              <DataGrid
                                customColumns={[
                                  {
                                    property: IotTagDistinctKeys.Name,
                                    gridColTypeDef: {
                                      renderCell: labelCellRender
                                    }
                                  }
                                ]}
                                data={tagsQuery.data}
                                idProperty={IotTagMappingKeys.IotTagId}
                                ignoredProperties={[
                                  IotTagDistinctKeys.FriendlyName,
                                  IotTagDistinctKeys.IotDeviceId,
                                  IotTagDistinctKeys.IotTagId
                                ]}
                                filterMode="server"
                                loading={tagsQuery.isLoading}
                                onFilterChange={setFilter}
                                onPageChange={tagPagination.setPage}
                                onRowsPerPageChange={
                                  tagPagination.setRowsPerPage
                                }
                                onSortChange={setSort}
                                paginationMode="server"
                                page={tagPagination.page}
                                pagination
                                schema={tagsDescQuery.data.ViewDescription}
                                sort={sort}
                                sortingMode="server"
                                rowsPerPage={tagPagination.rowsPerPage}
                                rowsPerPageOptions={
                                  tagPagination.rowsPerPageOptions
                                }
                                totalRows={
                                  tagsQuery.data?.Pagination.TotalCount
                                }
                                widths={{
                                  [IotTagDistinctKeys.Name]: 200,
                                  [IotTagDistinctKeys.ObjectName]: 160,
                                  [IotTagDistinctKeys.LatestTagValue]: 160
                                }}
                              />
                            )}
                          </Box>
                        </Paper>
                      </Box>
                    )}
                    {!selectedConnection && !selectedDevice && (
                      <Placeholder
                        message={intl.formatMessage({
                          id: 'iotConnections.selectPlaceholder',
                          description:
                            'IoT configuration page, selection placeholder text',
                          defaultMessage:
                            'Select an IoT Connection or Device to view its details and tags'
                        })}
                      />
                    )}
                  </Grid>
                </Grid>
              </Box>
            </Container>
          </Box>
        </>
      )}
      {pageLoading && <PageLoading />}
      <AlertDialogue
        actions={[
          {
            handler: handleAlertDelete,
            text: intl.formatMessage({
              id: 'iotConnections.delete',
              description: 'Delete alert dialogue, delete button text',
              defaultMessage: 'Delete'
            })
          },
          {
            handler: handleAlertCancel,
            text: intl.formatMessage({
              id: 'iotConnections.cancel',
              description: 'Delete alert dialogue, cancel button text',
              defaultMessage: 'Cancel'
            })
          }
        ]}
        message={alertDialogueMessage}
        open={!isNil(alertSelection)}
        title={alertDialogueTitle}
        listItems={alertList}
      />
    </>
  )
}

export default IotConnections
