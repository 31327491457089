import React, { FunctionComponent, useEffect, useState } from 'react'
import { makeStyles, TableCell, TableRow } from '@material-ui/core'
import clsx from 'clsx'

const useStyles = makeStyles((theme) => ({
  fixedCell: {
    position: 'sticky',
    left: 0,
    background: theme.palette.background.paper,
    fontWeight: 'bold'
  },
  fixedRight: {
    position: 'sticky',
    right: 0,
    background: theme.palette.type === 'light' ? '#e6f4ea' : '#575f56',
    fontWeight: 'bold',
    width: 200
  },
  pointer: {
    cursor: 'pointer'
  }
}))

interface Props {
  label: string
  cells: PGCalculatedCell[]
  onCellClicked?: (cell: PGCalculatedCell) => void
}

export interface PGCalculatedCell {
  dayIndex: number
  rowIndex: number
  cellIndex: number
  value: number
}

const ProductionGridCalculatedRow: FunctionComponent<Props> = (
  props: Props
) => {
  const classes = useStyles()
  const { label, cells, onCellClicked } = props
  const [total, setTotal] = useState<number>(0)

  useEffect(() => {
    setTotal(cells?.map((d) => d).reduce((prev, curr) => prev + curr.value, 0))
  }, [cells])

  const handleCellClicked = (cell: PGCalculatedCell) => {
    if (onCellClicked) {
      onCellClicked(cell)
    }
  }

  return (
    <>
      <TableRow key={`${label}-index`}>
        <TableCell key={`${label}-title`} className={classes.fixedCell}>
          {label}
        </TableCell>
        {cells.map((cell, index) => (
          <TableCell
            className={clsx({ [classes.pointer]: cell.value > 0 })}
            onClick={() => handleCellClicked(cell)}
            key={`${label}-${index}-cell`}
          >
            {cell.value}
          </TableCell>
        ))}
        <TableCell key={`${label}-total`} className={classes.fixedRight}>
          {total}
        </TableCell>
      </TableRow>
    </>
  )
}

export default ProductionGridCalculatedRow
