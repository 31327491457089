import React, { FunctionComponent } from 'react'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  List,
  ListItem,
  ListItemIcon,
  ListItemText
} from '@material-ui/core'
import { ArrowRight } from '@material-ui/icons'

export interface Action {
  handler: () => void
  text: string
}

interface Props {
  actions: Action[]
  message: string
  open: boolean
  title: string
  listItems?: string[]
}

const AlertDialogue: FunctionComponent<Props> = (props: Props) => {
  const { actions, message, open, title, listItems } = props

  return (
    <Dialog open={open}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <DialogContentText>{message}</DialogContentText>
        {listItems && (
          <List>
            {listItems.map((item, index) => (
              <ListItem key={index}>
                <ListItemIcon>
                  <ArrowRight />
                </ListItemIcon>
                <ListItemText primary={item} />
              </ListItem>
            ))}
          </List>
        )}
      </DialogContent>
      <DialogActions>
        {actions.map((action, index) => (
          <Button onClick={action.handler} key={index}>
            {action.text}
          </Button>
        ))}
      </DialogActions>
    </Dialog>
  )
}

export default AlertDialogue
