import React, { FunctionComponent, ReactNode } from 'react'
import axios, { AxiosResponse } from 'axios'
import { getAccessToken } from './MsalService'
import { clean } from './Utils'
import { isNil } from 'lodash'

const tenantIdStorageKey = 'tenantId'

export enum AccumulatorType {
  ValueEqualTrigger = 1,
  Solicited = 2,
  SetValue = 3,
  ValueChangeTrigger = 4
}

export enum ExpressionOperator {
  Contains = 'contains',
  EndsWith = 'endsWith',
  Equal = '==',
  GreaterThan = '>',
  GreaterThanEqual = '>=',
  LessThan = '<',
  LessThanEqual = '<=',
  NotEqual = '!=',
  StartsWith = 'startsWith'
}

export interface Expression {
  Op: ExpressionOperator
  Prop: string
  Val: unknown
}

export enum LogicalOperator {
  And = '&&',
  Or = '||'
}

export interface ModelExpressions {
  Expressions: Expression[]
  Operator?: LogicalOperator
}

export enum Order {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  asc = 'asc',
  // eslint-disable-next-line @typescript-eslint/naming-convention
  desc = 'desc'
}

export interface Pagination {
  TotalCount: number
  ContinuationToken?: string
  HasNext: boolean
  CurrentPage: number
}

export interface Property {
  ForeignKey?: boolean
  JsPropertyType: string
  KeyAttribute?: boolean
  MaximumLength?: number
  MinimumLength?: number
  PropertyName: string
  PropertyType?: string
  Required?: boolean
}

export interface PropertyLookup {
  data?: Record<string, unknown>[]
  label?: string
  loading?: boolean
  localProperty: string
  nameProperty?: string
  remoteProperty: string
}

export interface Schema {
  ClassName: string
  Properties: Property[]
}

export interface BaseRequestParams {
  path: string
}

export type GetDescParams = BaseRequestParams

export interface GetDescResponse {
  ViewDescription: Schema
  CrudDescription: Schema
}

export interface GetListAllParams extends BaseRequestParams {
  modelExpressions?: ModelExpressions
  orderBy1?: string
  order1?: Order
  orderBy2?: string
  order2?: Order
}

export interface GetListParams extends BaseRequestParams {
  continuationToken?: string
  modelExpressions?: ModelExpressions
  orderBy1?: string
  order1?: Order
  orderBy2?: string
  order2?: Order
  pageNumber?: number
  pageSize?: number
}

export interface GetListResponse {
  Pagination: Pagination
  Items: Record<string, unknown>[]
  id?: number
}

export interface GetListAllResponse {
  Items: Record<string, unknown>[]
  TotalItems: number
  id?: number
}

export interface CreateParams extends BaseRequestParams {
  items: Record<string, unknown>[]
}

export interface DeleteParams extends BaseRequestParams {
  ids: number[] | string[]
}

export enum Paths {
  AccumulatorCfg = 'AccumulatorCfg',
  AccumulatorDefCfg = 'AccumulatorDefCfg',
  Accumulators = 'Accumulators',
  AccumulatorTypeCfg = 'AccumulatorTypeCfg',
  DataCategoryDefCfg = 'DataCategoryDefCfg',
  DataCollectionCategories = 'DataCollectionCategories',
  DataTypes = 'DataTypes',
  EventAlphaVarCfg = 'EventAlphaVarCfg',
  EventCfg = 'EventCfg',
  EventDataVarType = 'EventDataVarType',
  EventVar = 'EventVar',
  EventVarCfg = 'EventVarCfg',
  EventVariablesCfgAll = 'EventVariablesCfgAll',
  EventVariables = 'EventVariables',
  Identifiers = 'Identifier',
  IdentifiersConfig = 'IdentifiersConfig',
  IdentifiersDefCfg = 'IdentifiersDefCfg',
  Incidents = 'Incidents',
  IncidentsConfig = 'IncidentsConfig',
  IncidentsDefCfg = 'IncidentsDefCfg',
  IotConnections = 'IotConnections',
  IotConnectionDevices = 'IotConnectionDevices',
  IotDevices = 'IotDevices',
  IotTagDistinct = 'IotTagDistinct',
  IotTagMapping = 'IotTagMapping',
  IotDeviceProvisioning = 'IotDeviceProvisioning',
  NodeProperties = 'NodeProperties',
  Nodes = 'Nodes',
  NodeTemplateProperties = 'NodeTemplateProperties',
  NodeTemplatePropertyMapping = 'NodeTemplatePropertyMapping',
  NodeTemplates = 'NodeTemplates',
  PoisonMessages = 'PoisonedMessages',
  UserView = 'UserView',
  UserViewItems = 'UserViewItem',
  Subscribers = 'Subscribers',
  Subscription = 'Subscription',
  TemplatePropertyGroups = 'TemplatePropertyGroups',
  Tenant = 'Tenant',
  Unit = 'Unit',
  UpdateMappedTagStatus = 'UpdateMappedTagStatus',
  UtilsGetDesc = '/Utils/GetDesc',
  VariableCfg = 'VariableCfg'
}

export enum AccumulatorKeys {
  Id = 'AccumulatorId',
  Name = 'AccumulatorName',
  NodeId = 'NodeId',
  RecordDateTime = 'RecordDateTime',
  Value = 'Value',
  RcfId = 'RcfId'
}

export enum AccumulatorCfgKeys {
  DataCollectionCategoryId = 'DataCollectionCategoryId',
  DefId = 'AccumulatorDefId',
  Id = 'AccumulatorId',
  NodeId = 'NodeId',
  RcfId = 'RcfId',
  RolloverValue = 'RollOverValue',
  Trigger = 'AccumulatorTrigger',
  TriggerValue = 'AccumulatorTriggerValue',
  Type = 'AccumulatorType',
  TypeDescription = 'AccumulatorTypeDescription',
  TypeId = 'AccumulatorTypeId',
  AccumulatorName = 'AccumulatorName',
  Active = 'Active'
}

export enum AccumulatorDefCfgKeys {
  Id = 'AccumulatorDefId',
  Name = 'AccumulatorName'
}

export enum AccumulatorTypeCfgKeys {
  Id = 'AccumulatorTypeId',
  Name = 'AccumulatorType'
}

export enum DataCategoryDefCfgKeys {
  DataCategoryName = 'DataCategoryName',
  Id = 'DataCategoryId'
}

export enum DataCollectionCategoryKeys {
  DataCategoryId = 'DataCategoryId',
  DataCategoryName = 'DataCategoryName',
  Id = 'DataCollectionCategoryId',
  Name = 'CollectionCategoryName'
}

export enum DataTypeKeys {
  Id = 'DataTypeId',
  Name = 'DataType'
}

export enum EventAlphaVarCfgKeys {
  EventId = 'EventId',
  Id = 'EventAlphaVarId',
  NodeId = 'NodeId',
  RcfId = 'RcfId',
  VariableId = 'VariableId',
  VariableName = 'VariableName',
  Active = 'Active'
}

export enum EventCfgKeys {
  DataCollectionCategoryId = 'DataCollectionCategoryId',
  Id = 'EventId',
  Name = 'EventName',
  NodeId = 'NodeId',
  RcfId = 'RcfId'
}

export enum EventDataVarTypeKeys {
  Id = 'EventDataVarId',
  Name = 'VariableTypeName'
}

export enum EventVarCfgKeys {
  DecimalPlace = 'DecimalPlace',
  EventId = 'EventId',
  Id = 'EventVarId',
  Multiplier = 'Multiplier',
  NodeId = 'NodeId',
  RcfId = 'RcfId',
  UnitId = 'UnitId',
  VariableId = 'VariableId',
  VariableName = 'VariableName',
  Active = 'Active'
}

export enum EventVarKeys {
  EventId = 'EventId',
  EventName = 'EventName',
  NodeId = 'NodeId',
  RecordTime = 'RecordTime',
  Value = 'Value',
  VariableName = 'VariableName',
  UnitId = 'UnitId',
  UnitName = 'UnitName'
}

export enum EventVariablesKeys {
  EventFilter = 'EventFilter',
  EventId = 'EventId',
  EventName = 'EventName',
  NodeId = 'NodeId',
  RecordTime = 'RecordTime',
  Value = 'Value',
  VariableName = 'VariableName',
  UnitId = 'UnitId',
  UnitName = 'UnitName',
  Id = 'VariableId',
  RcfId = 'RcfId',
  LocalVarId = 'LocalVarId'
}

export enum EventVariablesCfgKeys {
  EventDataVarId = 'EventDataVarId',
  EventId = 'EventId',
  Id = 'Id',
  NodeId = 'NodeId',
  RcfId = 'RcfId'
}

export enum IdentifierKeys {
  Category = 'IdentifierCategory',
  Description = 'IdentifierDescription',
  EndDateTime = 'EndDateTime',
  GlobalIdentifierName = 'GlobalIdentifierName',
  Name = 'IdentifierName',
  NodeId = 'NodeId',
  StartDateTime = 'StartDateTime',
  Value = 'Value',
  Id = 'IdentifierId',
  RcfId = 'RcfId'
}

export enum IdentifiersConfigKeys {
  CollectionCategoryName = 'CollectionCategoryName',
  DataCollectionCategoryId = 'DataCollectionCategoryId',
  GlobalIdentifierName = 'GlobalIncidentName',
  Id = 'IdentifierId',
  IdentifierDefId = 'IdentifierDefId',
  NodeId = 'NodeId',
  RcfId = 'RcfId',
  IdentifierName = 'IdentifierName',
  Active = 'Active'
}

export enum IdentifiersDefCfgKeys {
  Id = 'IdentifierDefId',
  Name = 'IdentifierName'
}

export enum IncidentKeys {
  Category = 'IncidentCategory',
  Description = 'IncidentDescription',
  Duration = 'Duration',
  EndDateTime = 'EndDateTime',
  GlobalIncidentName = 'GlobalIncidentLinkName',
  Name = 'IncidentName',
  NodeId = 'NodeId',
  StartDateTime = 'StartDateTime',
  Status = 'Status',
  Id = 'IncidentId',
  RcfId = 'RcfId'
}

export enum IncidentsConfigKeys {
  CollectionCategoryName = 'CollectionCategoryName',
  CreateDateTime = 'CreateDateTime',
  DataCollectionCategoryId = 'DataCollectionCategoryId',
  GlobalIncidentName = 'GlobalIncidentName',
  HasAttachment = 'HasAttachment',
  Id = 'IncidentId',
  IncidentDefId = 'IncidentDefId',
  NodeId = 'NodeId',
  RcfId = 'RcfId',
  IncidentName = 'IncidentName',
  Active = 'Active'
}

export enum IncidentsDefCfgKeys {
  Id = 'IncidentDefId',
  Name = 'IncidentName'
}

export enum IotConnectionKeys {
  Id = 'IotConnectionId',
  Name = 'IotConnectionName'
}

export enum IotConnectionDeviceKeys {
  Id = 'IotDeviceId',
  Name = 'IotDeviceName',
  ConnectionId = 'IotConnectionId'
}

export enum IotDevicesKeys {
  Id = 'IotDeviceId'
}

export enum IotDeviceProvisioningKeys {
  AssignedHub = 'AssignedHub',
  Connected = 'Connected',
  ConnectionState = 'ConnectionState',
  CreatedDateTime = 'CreatedDateTime',
  Id = 'Id',
  LastActivityTime = 'LastActivityTime',
  LastUpdatedDateTime = 'LastUpdatedDateTime',
  Name = 'Name',
  SAKConnectionString = 'SAKConnectionString',
  SASConnectionString = 'SASConnectionString',
  Status = 'Status',
  StatusUpdatedTime = 'StatusUpdatedTime'
}

export enum IotTagDistinctKeys {
  FriendlyName = 'FriendlyName',
  Id = 'IotTagId',
  IotDeviceId = 'IotDeviceId',
  IotTagId = 'IotTagId',
  Name = 'TagName',
  ObjectName = 'ObjectName',
  LatestTagValue = 'LatestTagValue',
  LastSeen = 'LastSeen'
}

export enum IotTagMappingKeys {
  Id = 'MappingId',
  IotDeviceId = 'IotDeviceId',
  IotTagId = 'IotTagId',
  ObjectName = 'ObjectName',
  RcfId = 'RcfId'
}

export enum NodePropertyKeys {
  Id = 'NodePropertyId',
  GroupOrdinal = 'GroupOrdinal',
  GroupName = 'GroupName',
  NodeId = 'NodeId',
  NodePropertyName = 'NodePropertyName',
  NodePropertyValue = 'NodePropertyValue',
  NodeTempPropId = 'NodeTempPropId',
  TemplatePropertyGroupId = 'TemplatePropertyGroupId'
}

export enum NodeKeys {
  Id = 'NodeId',
  Name = 'NodeName',
  Active = 'Active',
  NodeTemplateId = 'NodeTemplateId',
  OrdinalPosition = 'OrdinalPosition',
  ParentId = 'ParentNodeId'
}

export enum NodeTemplatePropertyKeys {
  CreateDateTime = 'CreateDateTime',
  DataTypeId = 'DataTypeId',
  GroupName = 'GroupName',
  GroupOrdinal = 'GroupOrdinal',
  Id = 'NodeTempPropId',
  Name = 'NodePropertyName',
  TemplatePropertyGroupId = 'TemplatePropertyGroupId'
}

export enum NodeTemplatePropertyMappingKeys {
  DataTypeId = 'DataTypeId',
  GroupOrdinal = 'GroupOrdinal',
  Id = 'NodeTempPropMapId',
  NodePropertyName = 'NodePropertyName',
  NodeTempPropId = 'NodeTempPropId',
  TemplateId = 'NodeTemplateId',
  TemplateName = 'TemplateName',
  TemplateDescription = 'TemplateDescription'
}

export enum NodeTemplateKeys {
  Id = 'NodeTemplateId',
  Name = 'TemplateName'
}

export enum PoisonMessageKeys {
  Id = 'MessageId',
  RawMessage = 'RawMessage',
  Exception = 'ExceptionMessage',
  RecordTime = 'RecordTime'
}

export enum TemplatePropertyGroupKeys {
  Id = 'TemplatePropertyGroupId',
  Name = 'GroupName'
}

export enum TenantKeys {
  Favourite = 'Favourite',
  Id = 'SecurityTenantsId',
  Name = 'FriendlyName'
}

export enum UnitKeys {
  Id = 'UnitId',
  Name = 'UnitName'
}

export enum UpdateMappedTagResponseKeys {
  TagsActivated = 'TagsActivated',
  TagsDeactivated = 'TagsDeactivated',
  ActiveTags = 'ActiveTags'
}

export enum VariableKeys {
  EventDataVarId = 'EventDataVarId',
  Name = 'VariableName',
  Id = 'VariableId',
  Type = 'VariableTypeName'
}

export enum UserViewKeys {
  Id = 'UserViewId',
  Name = 'ViewName',
  Interval = 'Interval',
  GridSize = 'GridSize',
  ViewRange = 'ViewRange',
  SharedGroupId = 'SharedGroupId',
  CreatedBy = 'CreatedBy',
  UserViewItemList = 'UserViewItemList'
}

export enum UserViewDataItemKeys {
  DataId = 'DataId',
  DataCategoryId = 'DataCategoryId',
  NodeId = 'NodeId',
  UserViewItemId = 'UserViewItemId',
  UserViewId = 'UserViewId'
}

const cleanExpressions = (
  expressions: ModelExpressions
): ModelExpressions | undefined => {
  if (expressions.Expressions.length === 0) {
    return undefined
  }
  const newExpressions = {
    Expressions: expressions.Expressions.filter(
      (expression) => expression.Val !== '' && expression.Val !== undefined
    ),
    Operator: expressions.Operator
  }
  return newExpressions
}

const getApiAccessToken = async () => {
  if (!process.env.REACT_APP_FUNCTION_APP_SCOPES) {
    throw Error(
      'REACT_FUNCTION_APP_SCOPES environment variable is not defined!'
    )
  }
  const scopes = process.env.REACT_APP_FUNCTION_APP_SCOPES.split(' ')
  const accessToken = await getAccessToken(scopes)
  if (!accessToken) {
    throw Error('Failed to get Function app API access token!')
  }
  return accessToken
}

export const createObjectFromProps = (
  properties: Property[],
  initial?: Record<string, unknown>
): Record<string, unknown> => {
  const data: Record<string, unknown> = {}
  for (const property of properties) {
    if (property.JsPropertyType === 'boolean' && property.Required) {
      data[property.PropertyName] = false
    } else {
      data[property.PropertyName] = undefined
    }
  }
  return { ...data, ...initial }
}

export const filterProps = (
  data: Record<string, unknown>,
  properties: Property[]
): Record<string, unknown> => {
  const filtered: Record<string, unknown> = {}
  for (const key in data) {
    if (properties.some((p) => p.PropertyName === key)) {
      filtered[key] = data[key]
    }
  }
  return filtered
}

export const getSchemaKeys = (schema: Schema): string[] => {
  return schema.Properties.map((p) => p.PropertyName)
}

interface RcfactoryApiOptions {
  maxPageSize: number
  requestTimeoutMs: number
}

const defaultOptions: RcfactoryApiOptions = {
  maxPageSize: 25000,
  requestTimeoutMs: 20000
}

export class RcfactoryApi {
  constructor(options?: RcfactoryApiOptions) {
    this.options = { ...defaultOptions, ...options }
  }

  options: RcfactoryApiOptions

  async getListAll(
    props: GetListAllParams,
    id?: number
  ): Promise<GetListAllResponse> {
    const { modelExpressions, orderBy1, order1, orderBy2, order2, path } = props

    let cleanedExpressions
    if (modelExpressions) {
      cleanedExpressions = cleanExpressions(modelExpressions)
    }
    const params = {
      modelExpressions: modelExpressions
        ? JSON.stringify(cleanedExpressions)
        : null,
      pageNumber: 1,
      pageSize: 25000,
      sort1: {
        orderByProp: orderBy1,
        orderByDir: order1?.toUpperCase()
      },
      sort2: {
        orderByProp: orderBy2,
        orderByDir: order2?.toUpperCase()
      },
      tenantId: this.tenantId
    }
    let cleanedParams = clean(params)
    const accessToken = await getApiAccessToken()
    let isMoreData = true
    const response: GetListAllResponse = { Items: [], TotalItems: 0 }
    while (isMoreData) {
      const { data } = await axios.get(
        process.env.REACT_APP_FUNCTION_APP_URL + '/api/' + path,
        {
          headers: { Authorization: 'Bearer ' + accessToken },
          params: cleanedParams,
          timeout: this.options.requestTimeoutMs
        }
      )
      response.Items.push(...data.Items)
      isMoreData = data.Pagination.HasNext
      params.pageNumber = params.pageNumber + 1
      cleanedParams = clean(params)
    }
    response.TotalItems = response.Items.length
    response.id = id
    return response
  }

  async getList(props: GetListParams, id?: number): Promise<GetListResponse> {
    const {
      continuationToken,
      modelExpressions,
      orderBy1,
      order1,
      orderBy2,
      order2,
      pageNumber = 0,
      pageSize = this.options.maxPageSize,
      path
    } = props
    let cleanedExpressions
    if (modelExpressions) {
      cleanedExpressions = cleanExpressions(modelExpressions)
    }
    const params = {
      continuationToken: continuationToken,
      modelExpressions: modelExpressions
        ? JSON.stringify(cleanedExpressions)
        : null,
      pageNumber: pageNumber + 1,
      pageSize: pageSize,
      sort1: {
        orderByProp: orderBy1,
        orderByDir: order1?.toUpperCase()
      },
      sort2: {
        orderByProp: orderBy2,
        orderByDir: order2?.toUpperCase()
      },
      tenantId: this.tenantId
    }
    const cleanedParams = clean(params)
    const accessToken = await getApiAccessToken()
    const { data } = await axios.get(
      process.env.REACT_APP_FUNCTION_APP_URL + '/api/' + path,
      {
        headers: { Authorization: 'Bearer ' + accessToken },
        params: cleanedParams,
        timeout: this.options.requestTimeoutMs
      }
    )
    data.id = id
    return data
  }

  async getDesc(props: GetDescParams): Promise<GetDescResponse> {
    const { path } = props
    const accessToken = await getApiAccessToken()
    const { data } = await axios.get(
      process.env.REACT_APP_FUNCTION_APP_URL +
        '/api/' +
        path +
        Paths.UtilsGetDesc,
      {
        headers: { Authorization: 'Bearer ' + accessToken },
        params: { tenantId: this.tenantId },
        timeout: this.options.requestTimeoutMs
      }
    )
    return data
  }

  async create(props: CreateParams): Promise<AxiosResponse> {
    const { items, path } = props
    const accessToken = await getApiAccessToken()
    const cleanedItems = clean(items)
    return axios.post(
      process.env.REACT_APP_FUNCTION_APP_URL + '/api/' + path,
      cleanedItems,
      {
        headers: { Authorization: 'Bearer ' + accessToken },
        params: { tenantId: this.tenantId },
        timeout: this.options.requestTimeoutMs
      }
    )
  }

  async update(props: CreateParams): Promise<AxiosResponse> {
    const { items, path } = props
    const accessToken = await getApiAccessToken()
    return axios.put(
      process.env.REACT_APP_FUNCTION_APP_URL + '/api/' + path,
      items,
      {
        headers: { Authorization: 'Bearer ' + accessToken },
        params: { tenantId: this.tenantId },
        timeout: this.options.requestTimeoutMs
      }
    )
  }

  async delete(props: DeleteParams): Promise<AxiosResponse> {
    const { ids, path } = props
    const accessToken = await getApiAccessToken()
    return axios.delete(
      process.env.REACT_APP_FUNCTION_APP_URL + '/api/' + path,
      {
        data: { Ids: ids },
        headers: { Authorization: 'Bearer ' + accessToken },
        params: { tenantId: this.tenantId },
        timeout: this.options.requestTimeoutMs
      }
    )
  }

  async getTenants(): Promise<Record<string, unknown>[]> {
    const accessToken = await getApiAccessToken()
    const { data } = await axios.get(
      process.env.REACT_APP_FUNCTION_APP_URL + '/api/' + Paths.Tenant,
      {
        headers: { Authorization: 'Bearer ' + accessToken },
        timeout: this.options.requestTimeoutMs
      }
    )
    return data
  }

  async setFavouriteTenant(favourite: number): Promise<AxiosResponse> {
    const accessToken = await getApiAccessToken()
    return axios.put(
      process.env.REACT_APP_FUNCTION_APP_URL + '/api/' + Paths.Tenant,
      [{ Favourite: favourite }],
      {
        headers: { Authorization: 'Bearer ' + accessToken },
        timeout: this.options.requestTimeoutMs
      }
    )
  }

  get tenantId(): number | undefined {
    const sessionTenantId = sessionStorage.getItem(tenantIdStorageKey)
    return !isNil(sessionTenantId) ? Number(sessionTenantId) : undefined
  }

  set tenantId(id: number | undefined) {
    if (!isNil(id)) {
      sessionStorage.setItem(tenantIdStorageKey, String(id))
    } else {
      sessionStorage.removeItem(tenantIdStorageKey)
    }
  }
}

const ApiContext = React.createContext<RcfactoryApi | null>(null)

interface Props {
  api: RcfactoryApi
  children: ReactNode
}

export const ApiProvider: FunctionComponent<Props> = (props: Props) => {
  const { api, children } = props

  return <ApiContext.Provider value={api}>{children}</ApiContext.Provider>
}

export const useApi = (): RcfactoryApi => {
  const api = React.useContext(ApiContext)
  if (isNil(api)) {
    throw Error('RcfactoryApi instance is nil!')
  }
  return api
}
